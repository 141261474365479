<template>
<v-container fluid>
    <v-row class="hidden-md-and-down">
    <ipanel v-if="true"></ipanel>
    </v-row>
    <!-- <v-row class="">
        <v-list rounded>
          <v-list-item-group v-model="sel" color="primary">
          <v-list-item>111</v-list-item>
          <v-list-item>222</v-list-item>
          </v-list-item-group>
        </v-list>
    </v-row> -->
</v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import ipanel from "./sensors_corpuses.vue"
export default {
  components: {
      ipanel,
  },
  props: {},
  data() {
    return {
      sel: 1,
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
  },
  mounted() {},
};
</script>
