<template>
  <v-container>
    <v-row class="justify-center">
        <v-col cols="8">
      <!-- <form action="http://localhost:2002/upload/" enctype="multipart/form-data" method="POST">  -->
      <form
        action="https://us.kbsu.ru:2002/upload/"
        enctype="multipart/form-data"
        method="POST"
        target="_blank"
      >
        <!-- <input type="file" name="file" accept="*" />
    <button type="submit">submit </button> -->
        <v-file-input label="Файл со списком компьютеров КБГУ" name="file"></v-file-input>
        <v-btn type="submit" color="primary">Отправить </v-btn>
      </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  mounted() {},
};
</script>