<template>
  <!-- :style="'width: 100%; height: ' + hhh + 'px;'" -->

  <!-- <v-dialog v-model="dshow"> -->
  <!-- <div :style="'width: 100%; height: ' + hhh + 'px;'" ref="myMap" id="myMap">
  </div> -->
  <v-container fluid class="fill-height" ref="myMap" id="myMap">
    <!-- <v-card class="fill-height">
          <v-card-text>
          <div >
          </div>
          </v-card-text>
      </v-card> -->
  </v-container>
  <!-- </v-dialog> -->
</template>
<script>
// import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import L from "leaflet";
export default {
  components: {},
  props: {
    obj: Object,
    // dshow: { type: Boolean, default: false },
    // hhh: {
    //   type: String,
    //   default: "300",
    // },
  },
  data() {
    return {
      map: null,
      //   obj: null,
      //   hhh: 500,
    };
  },
  methods: {
    // ...mapActions(["loadSettings"]),
    // zoommap() {
    //   let zoomlevel = this.map.getZoom();
    //   this.curZoom = zoomlevel;
    //   if (zoomlevel == 20) {
    //     this.HideMarkers(this.map);
    //     this.rr();
    //   }
    //   if (zoomlevel == 0) {
    //     this.mm();
    //     this.HideMarkers(this.map);
    //     this.SetMarkers(this.map, this.obj);
    //     if (this.curKorpus == null) {
    //       this.map.setView([43.49508, 43.59555], 18);
    //     } else {
    //       this.map.setView([this.curKorpus.Lat, this.curKorpus.Lng]);
    //     }
    //   }
    // },
    SetMarker(map, m) {
      let cc = "cluster-high";
      cc = "cluster-ok";
      var okIcon = new L.divIcon({
        html:
          //            "<div class='pin' style='background:#2aa043'></div><div class='pulse'></div> <br><div><b>" +
          "<div class='" +
          cc +
          " pin'></div><div class='pulse'></div> <br><div><b>" +
          m.Tooltip +
          "</b></div>",
        iconSize: 0,
        shadowSize: 1,
        popupAnchor: [-4, -33],
        iconAnchor: [0, 15],
      });
      let marker = L.marker([m.Lat, m.Lng], {
        draggable: this.isedit,
        icon: okIcon,
      });
      marker.bindPopup(m.Popup);
      marker.korpus = m;
      //   let ppp = this;
      marker.on("dragend", function () {
        // ppp.curKorpus.Lat = String(this.getLatLng().lat);
        // ppp.curKorpus.Lng = String(this.getLatLng().lng);
      });
      marker.addTo(map);
    },
    setmap(obj0) {
      //      this.map = L.map("myMap").setView([43.49508, 43.59555], 18);
      // var latlng = L.latLng(obj0.Lat, obj0.Lng);
      this.map = L.map("myMap", { attributionControl: false })
        // .setView
        // latlng,
        // 16
        // ();
      //   this.map.on("zoom", this.zoommap);
      this.map.setView([obj0.Lat, obj0.Lng], 17);
      this.lmap = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution: "Карта",
          maxZoom: 20,
        }
      ).addTo(this.map);
      this.SetMarker(this.map, obj0);
    },
  },
  computed: {
    // ...mapGetters(["urlstat"]),
    // ...mapState(["markers"]),
  },
  // watch: {
  //   dshow: function () {
  //     // if (this.dshow && this.map == null) {
  //     //   // this.$nextTick(() => {
  //     //   this.setmap(this.obj);
  //     //   // });
  //     // }
  //     // if (!this.dshow && this.map != null) {
  //     //   //        this.map.remove();
  //     //   this.map = null;
  //     // }
  //     // console.log(this.map);
  //   },
  // },
  mounted() {
    //  console.log("mounted");
    this.$nextTick(() => {
      this.setmap(this.obj);
    });
  },
  // created() {
  //    console.log("created");
  // },
  // destroyed(){
  //    console.log("destroyed");
  // },
  // deactivated() {
  //    console.log("deactivated");
  // },
};
</script>
<style>
.cluster {
  border-radius: 40px;

  height: 5px;
  margin: 15px;
  width: 5px;

  text-align: center;
  padding-top: 7px;
  font-size: 24px;
  color: white;
}
.cluster-ok {
  background: #2aa043;
}
.cluster-info {
  background: #00bfff;
}
.cluster-warning {
  background: #d16e0a;
}
.cluster-average {
  background: #ff6800;
}
.cluster-high {
  background: #ca2b1d;
  /* background: red; */
}
.cluster-disaster {
  background: #6f001f;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  /* background: grey; */
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px grey;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
</style>
