<template>
  <v-row class="justify-center text-center ma-1" v-resize="draw">
    <v-col cols="12">
      <v-row class="justify-center ma-2">
        <h1>Температура и влажность</h1>
        <!-- </v-col> -->

      </v-row>
      <v-row class="justify-center align-center ma-1">
        <v-col xs="12" sm="8" md="4" lg="3">
          <v-row class="justify-center align-center ma-1">
            <v-btn small color="primary" class="mr-1" @click="start()">Обновить</v-btn>
            <di
              :rdates="rdt"
              v-on:dateschange="
                sday = $event[0];
                sday2 = $event[1];
              "
            ></di>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col xs="12" sm="11" md="10" lg="7">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="justify-center ma-1">
        <v-col xs="12" sm="11" md="10" lg="9">
          <v-data-table
            id="tbld"
            :headers="headers"
            :items="sensors"
            :expanded.sync="expanded"
            :single-expand="false"
            :search="search"
            show-expand
            group-by="Корпус"
            item-key="sensor"
            class="elevation-1"
            :mobile-breakpoint="100"
            :footer-props="{
              showFirstLastPage: true,
              itemsPerPageText: 'Записей на странице',
              itemsPerPageOptions: [10, 20, 50, 100, { text: 'Все записи', value: -1 }],
            }"
          >
            <template v-slot:item.sensor="{ item }">
              <!-- ItemZone датчика: {{item.sensor}} -->
              {{ item.sensor }}
            </template>
            <template v-slot:item.nn="{ item }">
              <!-- <v-chip :color="item.tip == 'Влажность' ? 'info' : 'warning'"
                >{{ item.nn }}
              </v-chip> -->
              <v-chip :color="scolor(item.tip)">{{ item.nn }} </v-chip>
            </template>
            <template v-slot:item.dd="{ item }">
              {{ item.data[item.data.length - 1].date }}
            </template>
            <template v-slot:item.vv="{ item }">
              {{ item.data[item.data.length - 1].val.Avg.toFixed(2) }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <!-- {{item}} -->
                <!-- hdr: ["Заявителей", "Контрактников", "Оплачено", "Подано заявлений"], // "Ошибки","Вход без пропуска", "Выход без пропуска",],
      colorgr: ["#6A1B9A", "#01579B", "#1B5E20", "#D50000"], //,"#FFEA00","black", "grey"],
      xx: "date",
      yy: ["applicants", "contracts", "paidcontracts", "statements"], -->
                <v-container>
                  <v-row>
                    <v-checkbox
                      v-model="marks"
                      label="Показывать значения"
                      @change="upd += 1"
                    ></v-checkbox>
                  </v-row>
                  <v-row>
                    <!-- {{item.data}} -->
                    <graphmany
                      :id="'gr' + item.nn"
                      :stat="item.data"
                      title=""
                      :hdr="[item.tip]"
                      :colorgr="[scolor2(item.tip)]"
                      xx="dt"
                      :yy="['yy']"
                      :hhh="hhh"
                      :www="www"
                      :marks="marks"
                      :key="upd"
                    ></graphmany>
                  </v-row>
                  <v-row class="justify-center ma-2">
                    <v-col md="11" lg="8">
                      <values :values="item.data"></values>
                    </v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <wait :progress="progress"></wait>
    <v-snackbar v-model="sbok" :timeout="5000" color="green" top right>
      {{ strok }}
      <v-btn @click="sbok = false" class="ml-5" color="primary" fab>OK</v-btn>
    </v-snackbar>
    <v-snackbar v-model="sberr" :timeout="5000" color="red" top right>
      {{ strerr }}
      <v-btn @click="sberr = false" class="ml-5" fab>OK</v-btn>
    </v-snackbar>
  </v-row>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import values from "./values";
import graphmany from "./graphmany.vue";
import di from "../dateinterval";
import wait from "../progress";
export default {
  components: {
    values,
    graphmany,
    di,
    wait,
  },
  props: {},
  data() {
    return {
      //      sensors: [],
      expanded: [],
      search: "",
      sday: new Date().toISOString().substr(0, 10),
      sday2: new Date().toISOString().substr(0, 10),
      rdt: [this.sday, this.sday2],
      hhh: 300,
      www: 500,
      upd: 0,
      marks: true,
      progress: false,
      sbok: false,
      strok: "Успешно",
      sberr: false,
      strerr: "Ошибка",
      headers: [
        { text: "№", value: "nn", align: "left" },
        {
          text: "Датчик",
          value: "sensor",
          align: "left",
          //  groupable: true,
        },
        { text: "Дата(последняя)", value: "dd", align: "left" },
        { text: "Значение", value: "vv", align: "left" },
      ],
    };
  },
  methods: {
    ...mapActions({
      loadSensors: "loadSensors",
    }),
    draw() {
      this.www = document.getElementById("tbld").clientWidth - 20;
      //  console.log(this.www)
      this.upd += 1;
    },
    scolor(tip) {
      let rr = "white";
      switch (tip) {
        case "Влажность":
          rr = "info";
          break;
        case "Температура":
          rr = "warning";
          break;
        case "ИК":
        case "":
          rr = "secondary";
          break;
      }
      return rr;
    },
    scolor2(tip) {
      let rr = "#1976D2";
      switch (tip) {
        case "Влажность":
          rr = "#2196F3";
          break;
        case "Температура":
          rr = "#FFC107";
          break;
        case "ИК":
        case "":
          rr = "#424242";
          break;
      }
      return rr;
    },
    async getSensors() {
      this.progress = true;
      let url;
      let resp;
      //      url = this.urlschstat;
      // url = "us.kbsu.ru";
      // url = "http://" + url + ":2016/";
      url = "localhost";
      url = "http://" + url + ":2020/";
      //      url += "/?d1=20201218";
      // url = url + "?d1=" + this.sday;
      // url = url + "&d2=" + this.sday2;
      // url = url + "?d1=" + this.sday.replaceAll("-", "");
      // url = url + "&d2=" + this.sday2.replaceAll("-", "");
      url = url + "?d1=" + this.sday //.replace(/-/g, "");
      url = url + "&d2=" + this.sday2 //.replace(/-/g, "");
      console.log(url);
      try {
        resp = await fetch(url);
      } catch (err) {
        this.strerr = "Ошибка соединения: " + err;
        this.sberr = true;
        this.progress = false;
        return;
      }
      let sen = await resp.json();
      sen = sen.sensors;
      ///      this.sensors = [];
      let nn = 1;
      for (var key in sen) {
        let dd = [];
        for (let k2 in sen[key]) {
          dd.push({ date: k2, val: sen[key][k2] });
        }
        let tmp;
        tmp = key.split("/");
        //        console.log(tmp)
        if (tmp.length == 4) {
          tmp[2] = tmp[2] + "(" + tmp[3] + ")";
        }
        if (tmp.length < 3) {
          tmp[0] = "";
          tmp[1] = "";
          tmp[2] = "";
        }
        // this.sensors.push({
        //   nn: nn,
        //   sensor: key,
        //   data: dd,
        //   tip: tmp[0],
        //   korpus: tmp[1],
        //   room: tmp[2],
        // });
        nn++;
      }
      // console.log(this.sensors);
      this.strok = "Данные успешно загружены.";
      this.sbok = true;
      this.progress = false;
    },
    async start() {
      this.progress = true;
      await this.loadSensors({ sday: this.sday, sday2: this.sday2 });
      if (this.error == "") {
        this.strok = "Данные успешно загружены.";
        this.sbok = true;
        this.upd += 1;
        this.progress = false;
      } else {
        this.strerr = "Ошибка соединения: " + this.error;
        this.sberr = true;
        this.progress = false;
      }
    },
  },
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  computed: {
    ...mapState({
      sensors: (state) => state.sensors,
      error: (state) => state.error,
      //      nogroup: (state) => state.authm.nogroup,
    }),
  },
  mounted() {
    let d0 = new Date();
    d0.setDate(d0.getDate() - 0);
    this.sday = d0.toISOString().substr(0, 10);
    this.rdt = [this.sday, this.sday2];
    //    this.getSensors();
    this.start();
  },
};
</script>
<style>
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px !important;
  padding: 0px;
} */
th {
  background-color: #a5d6a7;
}
</style>
