<template>
  <div>
    <v-row class="justify-center" dense>
      <v-col xs="12" sm="11" md="10" lg="7">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-center" dense>
      <!-- <v-col xs="12" sm="11" md="10" lg="9"> -->
      <v-col>
        <!-- {{expanded}} -->
        <v-data-table
          :single-expand="true"
          show-expand
          id="tbld"
          :headers="headers"
          :items="firesensstat.stat"
          :search="search"
          item-key="name"
          class="elevation-0"
          :mobile-breakpoint="100"
          :items-per-page="-1"
          sort-by="name"
          dense
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageText: 'Записей на странице',
            itemsPerPageOptions: [
              { text: 'Все записи', value: -1 },
              10,
              20,
              50,
              100,
            ],
          }"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <fsall :firesensfilter="item.vals"></fsall>
              <!-- {{ item.vals }} -->
            </td>
          </template>

          <!-- <template v-slot:body.append="{items}">
            <tr>
                <td v-for="(h, i) in items" :key="i">
                  {{ h }}
                </td>
            </tr>
          </template> -->

          <template v-slot:body.prepend>
            <tr>
              <td></td>
              <td class="font-weight-black">Итого</td>
              <td></td>
              <td class="font-weight-black">{{ firesensstat.sum["evt"] }}</td>
            </tr>
          </template>
          <template v-slot:body.append>
            <tr>
              <td></td>
              <td class="font-weight-black">Итого</td>
              <td></td>
              <td class="font-weight-black">{{ firesensstat.sum["evt"] }}</td>
            </tr>
          </template>
          <!-- <template v-slot:footer="{ headers }">
            <td :colspan="headers.length">
              <tr>
                <td v-for="(h, i) in headers" :key="i">
                  {{ h.text }}
                </td>
              </tr>
            </td>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import fsall from "./wl_all";
export default {
  components: {
    fsall,
  },
  props: {
    firesensstat: Object,
  },
  data() {
    return {
      expanded: [],
      search: "",
      headers: [
        // { text: "Время добавления", value: "TimeVal", align: "left" },
        { text: "Наименование", value: "name", align: "left" },
        { text: "Ответственный", value: "otv", align: "left" },
        {
          text: "Кол-во срабатываний датчиков протечки",
          value: "evt",
          align: "center",
        },
        // {
        //   text: "Сработал один датчик",
        //   value: "Внимание! Опасность пожара",
        //   align: "center",
        // },
        // {
        //   text: "Кол-во тревог переданных в МЧС",
        //   value: "Запуск сценария управления",
        //   align: "center",
        // },
        // { text: "Причина срабатывания", value: "t1", align: "left" },
        // { text: "Принятые меры", value: "t2", align: "left" },
        // {
        //   text: "Вход перешел из режима ВНИМАНИЕ в режим ПОЖАР",
        //   value: "Вход перешел из режима ВНИМАНИЕ в режим ПОЖАР",
        //   align: "center",
        // },
        // { text: "Объект", value: "Name", align: "left" },
        // { text: "Устройство", value: "Remark", align: "left" },
        // { text: "Событие", value: "Contents", align: "left" },
        // { text: "Адрес", value: "adr", align: "left" },
        // { text: "Comment", value: "Comment", align: "left" },
        // { text: "Раздел", value: "RazdIndex", align: "left" },
        // { text: "IndexZone", value: "IndexZone", align: "left" },
      ],
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
  },
  watch: {
    //  firesensstat: function() {
    //      console.log(this.key)
    //  },
  },
  mounted() {
    //  console.log(this.firesensstat)
  },
};
</script>
