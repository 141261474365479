<template>
  <v-row class="justify-center text-center ma-1">
    <v-col cols="12">
      <v-row class="justify-center ma-2">
        <h1>Компьютеры</h1>
        <!-- </v-col> -->
      </v-row>
      <!-- <v-row class="justify-center align-center ma-1"> -->
      <v-row class="justify-center align-center ma-1">
        <v-col xs="12" sm="6" md="6" lg="3">
          <v-row class="align-center">
            <v-btn small color="primary" class="mr-1" @click="start()"
              >Обновить</v-btn
            >
            <!-- v-if="false" -->
            <di
              :rdates="rdt"
              v-on:dateschange="
                sday = $event[0];
                sday2 = $event[1];
              "
            ></di>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="align-center justify-center">
        <!-- <v-col cols="11"> -->
        <!-- {{selnot}} -->
        <v-checkbox v-model="selnot" label="Отрицание"></v-checkbox>
        <span class="ml-1 text-h3">[</span>
        <v-checkbox
          v-model="sel"
          label="Вкл. постоянно"
          value="halways"
          class="ml-1"
        ></v-checkbox>
        <span class="ml-2 mr-1 font-weight-black"> ИЛИ </span>
        <v-checkbox
          v-model="sel"
          label="Найден в сети"
          value="hwork"
          class="ml-2"
        ></v-checkbox>
        <span class="ml-2 mr-1 font-weight-black"> ИЛИ </span>
        <v-checkbox
          v-model="sel"
          label="Есть в Excel"
          value="hexcel"
          class="ml-2"
        ></v-checkbox>
        <span class="ml-2 mr-1 font-weight-black"> ИЛИ </span>
        <v-checkbox
          v-model="sel"
          label="Включен"
          value="honline"
          class="ml-2"
        ></v-checkbox>
        <!-- <v-checkbox v-model="sel" label="СКУД (stud_id)" value="skudstud_id" class="ml-2"></v-checkbox>
        <v-checkbox v-model="sel" label="Пустые" value="empty" class="ml-2"></v-checkbox> -->
        <span class="ml-2 text-h3">]</span>
        <!-- </v-col> -->
      </v-row>
      <!-- </v-row> -->
      <v-row class="justify-center">
        <v-col xs="12" sm="11" md="10" lg="7">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="justify-center ma-1">
        <v-col xs="12" sm="11" md="10" lg="9">
          <v-data-table
            :headers="headers"
            :items="data"
            :expanded.sync="expanded"
            :single-expand="false"
            :search="search"
            show-expand
            group-by="Build"
            item-key="Ip"
            class="elevation-1"
            :mobile-breakpoint="100"
            :footer-props="{
              showFirstLastPage: true,
              itemsPerPageText: 'Записей на странице',
              itemsPerPageOptions: [
                10,
                20,
                50,
                100,
                { text: 'Все записи', value: -1 },
              ],
            }"
          >
            <template v-slot:item.Always="{ item }">
              <!-- <span v-if="item.Always">Да</span> -->
              <v-chip
                v-if="item.Always"
                :color="item.Always == true ? 'error' : 'white'"
                ><span>Да</span>
              </v-chip>
            </template>
            <template v-slot:item.Ip="{ item }">
              <div class="inline-block">
                <v-icon :color="item.Lastvalue == '1' ? 'success' : 'warning'"
                  >mdi-desktop-classic</v-icon
                >
                {{ item.Ip }}
              </div>
              <!-- <v-chip :color="item.Lastvalue == '1' ? 'success' : 'warning'"
                >{{ item.Ip }}
              </v-chip> -->
            </template>
            <template v-slot:item.Lastclock="{ item }">
              {{ dd(item.Lastclock) }}
            </template>
            <template v-slot:item.nn="{ item }">
              <!-- <v-chip :color="item.tip == 'Влажность' ? 'info' : 'warning'"
                >{{ item.nn }}
              </v-chip> -->
              <v-chip :color="scolor(item.tip)">{{ item.nn }} </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <!-- {{item}} -->
                <v-row>
                  <!-- :value="gg(item.History)"
                     :value="[1, 2, 4, 2, 5]"
                   -->
                  <v-col cols="12">
                    <graphic
                      :id="'idgr'+item.Hostsid"
                      :grd="forGr(item.History)"
                      :height="300"
                      colorgr="blue"
                      :marks="false"
                      razm=""
                    ></graphic>
                    <!-- <v-sparkline
                      :value="gg(item.History)"
                      color="blue"
                      :line-width="1"
                    >
                    </v-sparkline> -->
                    <!-- <template v-slot:label="item0"> {{ item0.clock.substr(13,2) }} </template> -->
                  </v-col>
                </v-row>
                <v-row class="justify-center ma-2">
                  <v-col md="11" lg="8">
                    <values :values="item.History"></values>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <wait :progress="progress"></wait>
    <v-snackbar v-model="sbok" :timeout="5000" color="green" top right>
      {{ strok }}
      <v-btn @click="sbok = false" class="ml-5" color="primary" fab>OK</v-btn>
    </v-snackbar>
    <v-snackbar v-model="sberr" :timeout="5000" color="red" top right>
      {{ strerr }}
      <v-btn @click="sberr = false" class="ml-5" fab>OK</v-btn>
    </v-snackbar>
  </v-row>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { fDATE1 } from "../lib1.js";
import values from "./values";
import di from "../dateinterval";
import wait from "../progress";
import graphic from "./graphic";
export default {
  components: {
    values,
    di,
    wait,
    graphic,
  },
  props: {},
  data() {
    return {
      sel: [],
      selnot: false,
      hstzbx: [],
      expanded: [],
      search: "",
      sday: new Date().toISOString().substr(0, 10),
      sday2: new Date().toISOString().substr(0, 10),
      rdt: [this.sday, this.sday2],
      progress: false,
      sbok: false,
      strok: "Успешно",
      sberr: false,
      strerr: "Ошибка",
      headers: [
        // { text: "№", value: "nn", align: "left" },
        {
          text: "Корпус",
          value: "Build",
          align: "left",
          //  groupable: true,
        },
        { text: "Этаж", value: "Floor", align: "left" },
        { text: "Комната", value: "Room", align: "left" },
        // { text: "Включен", value: "online", align: "left" },
        { text: "IP", value: "Ip", align: "left", width: "200px" },
        { text: "Владелец", value: "Owner", align: "left" },
        { text: "Hostid", value: "Hostid", align: "left" },
        { text: "Вкл. пост.", value: "Always", align: "left" },
        { text: "Посл. опрос", value: "Lastclock", align: "left" },
      ],
    };
  },
  methods: {
    ...mapActions({
      loadHosts: "loadHosts",
      loadZabbix: "loadZabbix",
    }),
    forGr(gg) {
      // let gr1 = [];
      if (gg == undefined) return [];
      let tmp = gg.map((m) => {
        // return { x: m.clock.substr(11, 2), y: Number(m.value)};
        // return { x: m.clock.substr(8,8), y: Number(m.value)};
//        console.log(new Date(m.clock))
         return { x: new Date(m.clock), y: Number(m.value)};
      });
      // tmp.shift();
      tmp.reverse()
      return tmp;
    },
    gg(hh) {
      let rr;
      rr = hh.map((h) => {
        return Number(h.value);
      });
      //      console.log(rr)
      // rr.unshift(0);
      return rr.reverse();
    },
    ll(hh) {
      let rr;
      rr = hh.map((h) => {
        return Number(h.clock.substr(11, 2));
      });
      console.log(rr);
      return rr;
    },
    dd(d) {
      if (d == undefined || d == "" || d.length < 5) {
        return "";
      }
      return fDATE1(d);
    },
    scolor(tip) {
      let rr = "white";
      switch (tip) {
        case "Влажность":
          rr = "info";
          break;
        case "Температура":
          rr = "warning";
          break;
        case "ИК":
        case "":
          rr = "secondary";
          break;
      }
      return rr;
    },
    async getSensors() {
      this.progress = true;
      let url;
      let resp;
      //      url = this.urlschstat;
      url = "us.kbsu.ru";
      url = "http://" + url + ":2027/";
      //      url += "/?d1=20201218";
      // url = url + "?d1=" + this.sday;
      // url = url + "&d2=" + this.sday2;
      // url = url + "?d1=" + this.sday.replaceAll("-", "");
      // url = url + "&d2=" + this.sday2.replaceAll("-", "");

      // url = url + "?d1=" + this.sday.replace(/-/g, "");
      // url = url + "&d2=" + this.sday2.replace(/-/g, "");
      // console.log(url);
      try {
        resp = await fetch(url);
      } catch (err) {
        this.strerr = "Ошибка соединения: " + err;
        this.sberr = true;
        this.progress = false;
        return;
      }
      let sen = await resp.json();
      sen = sen.sensors;
      ///      this.sensors = [];
      let nn = 1;
      for (var key in sen) {
        let dd = [];
        for (let k2 in sen[key]) {
          dd.push({ date: k2, val: sen[key][k2] });
        }
        let tmp;
        tmp = key.split("/");
        //        console.log(tmp)
        if (tmp.length == 4) {
          tmp[2] = tmp[2] + "(" + tmp[3] + ")";
        }
        if (tmp.length < 3) {
          tmp[0] = "";
          tmp[1] = "";
          tmp[2] = "";
        }
        // this.sensors.push({
        //   nn: nn,
        //   sensor: key,
        //   data: dd,
        //   tip: tmp[0],
        //   korpus: tmp[1],
        //   room: tmp[2],
        // });
        nn++;
      }
      // console.log(this.sensors);
      this.strok = "Данные успешно загружены.";
      this.sbok = true;
      this.progress = false;
    },
    async start() {
      this.progress = true;
      // await this.loadSensors({ sday: this.sday, sday2: this.sday2 });
      await this.loadHosts();
      await this.loadZabbix({ sday: this.sday, sday2: this.sday2 });
      if (this.error == "") {
        this.hstzbx = [];
        //  console.log(this.zbx);
        this.hosts.forEach((h) => {
          // console.log(h.Ip)
          let always = false;
          if (this.zbx[h.Ip] && this.zbx[h.Ip].history != undefined) {
            always = this.zbx[h.Ip].history.every((h) => {
              return h.value == 1;
            });
          }
          if (h.Ip in this.zbx) {
            // in zabbix
            h.Hostid = this.zbx[h.Ip].hostid;
            h.History = this.zbx[h.Ip].history;
            h.Lastvalue = this.zbx[h.Ip].lastvalue;
            h.Lastclock = this.zbx[h.Ip].lastclock;
            h.Always = always;
            //  h.History.every((h) => {
            //   return h.value == "1";
            // });
            this.hstzbx.push(h);
          } else {
            // not in zabbix
            h.History = [];
            // h.Lastvalue = this.zbx[h.Ip].lastvalue;
            this.hstzbx.push(h);
          }
        });

        for (let key in this.zbx) {
          let ff = false;
          // console.log(key)
          for (let z = 0; z < this.hosts.length; z++) {
            // console.log(this.hosts[z])
            if (key == this.hosts[z].Ip) {
              ff = true;
              break;
            }
          }
          if (!ff) {
            // not in xls
            let always = false;
            if (this.zbx[key] && this.zbx[key].history != undefined) {
              always = this.zbx[key].history.every((h) => {
                return h.value == 1;
              });
            }
            this.hstzbx.push({
              Build: "Нет в списке Excel",
              Ip: this.zbx[key].name,
              Hostid: this.zbx[key].hostid,
              History: this.zbx[key].history,
              Lastvalue: this.zbx[key].lastvalue,
              Lastclock: this.zbx[key].lastclock,
              Always: always,
            });
          }
        }
        this.strok = "Данные успешно загружены.";
        this.sbok = true;
        this.progress = false;
      } else {
        this.strerr = "Ошибка соединения: " + this.error;
        this.sberr = true;
        this.progress = false;
      }
    },
  },
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  computed: {
    ...mapState({
      hosts: (state) => state.hosts,
      zbx: (state) => state.zbx,
      // hoststree: (state) => state.hoststree,
      error: (state) => state.hostserror,
      //      nogroup: (state) => state.authm.nogroup,
    }),
    data: function () {
      if (this.sel.length == 0) {
        return this.hstzbx;
      }
      let tmp = [];
      tmp = this.hstzbx.filter((d) => {
        let rr = false;
        //        console.log(this.sel)
        this.sel.forEach((s) => {
          switch (s) {
            case "halways":
              rr = rr || d.Always;
              // console.log(d.photodev)
              break;
            case "hwork":
              rr = rr || d.Hostid != undefined;
              break;
            case "hexcel":
              rr = rr || d.Room != undefined;
              break;
            case "honline":
              rr = rr || d.Lastvalue == 1;
              break;
            case "empty":
              rr =
                rr ||
                d.photodev == "" ||
                d.photoshara == undefined ||
                d.skudfio == undefined ||
                d.skudstud_id == undefined;
              break;
          }
          // if (d.cmp.length == 0 && s == "empty") {
          //   rr = rr || true;
          // } else {
          //   rr = rr || d.cmp.indexOf(s) != -1;
          // }
        });
        if (this.selnot == true) {
          rr = !rr;
        }
        return rr;
      });
      return tmp;
    },
  },
  mounted() {
    let d0 = new Date();
    d0.setDate(d0.getDate() - 0);
    this.sday = d0.toISOString().substr(0, 10);
    this.rdt = [this.sday, this.sday2];
    //    this.getSensors();
    this.start();
  },
};
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px !important;
  padding: 2px;
  /* border: 1px solid black; */
}
th {
  background-color: #a5d6a7;
}
</style>
