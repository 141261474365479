<template>
          <v-card class="elevation-5">
            <v-card-title>{{ dd.sensor }}</v-card-title>
            <v-card-text>
              <!-- :labels="irdate(dd)" -->
              <v-sparkline
                :value="irval(dd)"
                line-width="0.3"
                :gradient="[
                  'red',
                  'red',
                  'red',
                  'red',
                  'green',
                  'red',
                  'red',
                  'red',
                  'red',
                ]"
                stroke-linecap="round"
                auto-draw
                :labels="irdate(dd)"
                label-size="3"
              >
                <!-- <template v-slot:label="item">
            <span v-if="item.value < 120 || item.value > 135">{{ item.value }}</span>
            <span>{{ item.value }}1</span>
          </template> -->
              </v-sparkline>
            </v-card-text>
          </v-card>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
      dd: Object
  },
  data() {
    return {

    };
  },
  methods: {
    irval(dd) {
      if (dd.data == undefined || dd.data.length <= 0) return [];
      let vv = dd.data;
      let rr = [0, 255];
      if (dd.sensor.indexOf("радио") != -1) {
        rr = [];
      }
      vv.forEach((ee) => {
        // rr.push(Number(ee.val.Max));
        rr.push( (Number(ee.val.Max) + Number(ee.val.Min) )/2);
//        rr.push(Number(ee.val.Min));
      });
      return rr;
    },
    irdate(dd) {
      if (dd.data == undefined || dd.data.length <= 0) return [];
      let vv = dd.data;
      let rr = [" ", " "];
      let nn;
      nn = vv[0].date.substr(11, 2);
      vv.forEach((ee) => {
        //nn = ee.date.substr(11, 2);
        if (ee.date.substr(11, 2) == nn) {
          rr.push(ee.date.substr(11, 5));
          nn++;
        } else {
          rr.push(" ");
        }
        if (ee.date.substr(11, 2) > nn) {
          nn++;
        }
        //         nn++
      });
      return rr;
    },

  },
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  mounted() {},
};
</script>