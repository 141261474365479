<template>
  <!--
<routestopage></routestopage>

import routestopage from "./authvuex/routestopage"
 
components: {
   routestopage
},
 -->
  <!-- <v-row class="justify-center align-center">
    <v-col cols="12"> -->
  <div>
    <v-row v-for="nn in nrow" :key="nn" class="justify-end align-start">
      <v-col lg="5" md="6" xs="12" v-for="cc in ncol" :key="cc">
        <routesgroup :mm="menus[(nn - 1) * ncol + cc - 1]"></routesgroup>
      </v-col>
    </v-row>
  </div>
  <!-- </v-col>
  </v-row> -->
  <!-- </v-col> -->
  <!-- </v-row> -->
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import routesgroup from "./routesgroup";
export default {
  components: {
    routesgroup,
  },
  props: {},
  data() {
    return {
      nrow: 0,
      ncol: 2,
    };
  },
  methods: {},
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  watch: {
    menus: function () {
      this.nrow = Math.floor(this.menus.length / this.ncol) + 1;
      // let nn = this.nrow
      // this.nrow =0;
      // this.nrow = nn
      // console.log(this.menus)
    },
  },
  computed: {
    ...mapState({
      menus: (state) => state.authm.menus,
      nogroup: (state) => state.authm.nogroup,
    }),
  },
  mounted() {
    this.nrow = Math.floor(this.menus.length / this.ncol) + 1;
    // console.log(this.nrow);
  },
};
</script>
