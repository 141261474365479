<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title> Выбор принтера </v-card-title>
          <v-card-text>
              <!-- item-value="hostid" -->
              <!-- {{printer}} -->
            <v-autocomplete
              v-model="printer"
              :items="$store.state.printers"
              item-text="name"
              return-object
              dense
              filled
              label="Принтеры"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer> </v-spacer>
            <v-btn @click="$emit('prnsel', printer)">OK</v-btn>
            <v-btn @click="$emit('prnsel', '')">Отмена</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      printer: "",
    };
  },
  methods: {},
  mounted() {},
};
</script>
