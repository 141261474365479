<template>
  <v-row class="justify-center text-center ma-1">
    <v-col cols="12">
      <v-row class="justify-center ma-2">
        <h1>Занятость аудиторий</h1>
        <!-- </v-col> -->
      </v-row>
      <!-- {{ sns }} -->
      <v-row class="justify-center align-center ma-1">
        <v-col xs="12" sm="8" md="4" lg="3">
          <v-row class="justify-center align-center ma-1">
            <v-btn small color="primary" class="mr-1" @click="start()"
              >Обновить</v-btn
            >
            <di
              :rdates="rdt"
              v-on:dateschange="
                sday = $event[0];
                sday2 = $event[1];
              "
            ></di>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col xs="12" sm="11" md="10" lg="7">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="justify-center ma-1">
        <v-col cols="12">
          <!-- show-expand -->
          <v-data-table
            v-if="true"
            ref="expandableTable"
            :headers="headers"
            :items="sensors"
            :expanded.sync="expanded"
            :single-expand="false"
            :search="search"
            group-by="Комната"
            :group-desc="false"
            item-key="nn"
            class="elevation-1"
            :mobile-breakpoint="100"
            :footer-props="{
              showFirstLastPage: true,
              itemsPerPageText: 'Записей на странице',
              itemsPerPageOptions: [
                { text: 'Все записи', value: -1 },
                15,
                20,
                50,
                100,
              ],
            }"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td style="text-align: start">{{ item.para }}</td>
                <td v-for="(cc, ii) in item.cols" :key="ii" :style="cc.style">
                  <!-- <span v-if="cc.val0 >=0"> {{ cc.val }}</span> -->
                  <span v-if="cc.show && cc.val0 >=0"> {{ cc.val }}</span>
                  <!-- <span v-else>???</span> -->
                  <!-- {{ cc.val }} -->
                </td>
                <!-- <td v-for="(cc,ii) in item.cols" :key="ii" >{{ cc }}</td> -->
              </tr>
              <!-- {{ index }} -->
              <!-- ItemZone датчика: {{item.sensor}} -->
              <!-- <span v-if="index > 2 && item[index] > 0 ">
              {{ item[index] }}
              </span>
              <span v-else>
              {{ item }}
              </span> -->
            </template>
            <!-- <template v-slot:group.header="{ group, headers, toggle, isOpen }">
              <td :colspan="headers.length">
                <v-btn @click="toggle" small icon :ref="group">
                  <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
                {{ group }}
              </td>
            </template> -->
            <template v-slot:item.nn="{ item }">
              <!-- <v-chip :color="item.tip == 'Влажность' ? 'info' : 'warning'"
                >{{ item.nn }}
              </v-chip> -->
              <v-chip :color="scolor(item.tip)">{{ item.nn }} </v-chip>
            </template>
            <template v-slot:item.dd="{ item }">
              {{ item.data[item.data.length - 1].date }}
            </template>
            <template v-slot:item.vv="{ item }">
              {{ item.data[item.data.length - 1].val }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <!-- {{item}} -->
                <v-row class="justify-center ma-2">
                  <v-col md="11" lg="8">
                    <values :values="item.data"></values>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- <v-row v-for="rm in sns" :key="rm.key">
        <v-col cols="12">
          <irroom :room="rm"></irroom>
          <br />
        </v-col>
      </v-row> -->
    </v-col>
    <wait :progress="progress"></wait>
    <v-snackbar v-model="sbok" :timeout="5000" color="green" top right>
      {{ strok }}
      <v-btn @click="sbok = false" class="ml-5" color="primary" fab>OK</v-btn>
    </v-snackbar>
    <v-snackbar v-model="sberr" :timeout="5000" color="red" top right>
      {{ strerr }}
      <v-btn @click="sberr = false" class="ml-5" fab>OK</v-btn>
    </v-snackbar>
  </v-row>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import values from "./values";
import di from "../dateinterval";
import wait from "../progress";
// import irroom from "./ir2_room";
export default {
  components: {
    values,
    di,
    wait,
    // irroom,
  },
  props: {},
  data() {
    return {
      sensors: [],
      sns: [],
      expanded: [],
      search: "",
      sday: new Date().toISOString().substring(0, 10),
      sday2: new Date().toISOString().substring(0, 10),
      rdt: [this.sday, this.sday2],
      progress: false,
      sbok: false,
      strok: "Успешно",
      sberr: false,
      strerr: "Ошибка",
      headers: [{ text: "Часы", value: "nn", align: "left" }],
      headers0: [
        { text: "№", value: "nn", align: "left" },
        {
          text: "Датчик",
          value: "sensor",
          align: "left",
          //  groupable: true,
        },
        { text: "Дата(последняя)", value: "dd", align: "left" },
        { text: "Значение", value: "vv.Avg", align: "left" },
      ],
    };
  },
  methods: {
    ...mapActions({
      loadSensors: "loadSensors",
    }),
    collapseAll() {
      this.$refs.expandableTable.collapseAll();
    },
    expandAll() {
      this.$refs.expandableTable.expandAll();
    },
    scolor(tip) {
      let rr = "white";
      switch (tip) {
        case "Влажность":
          rr = "info";
          break;
        case "Температура":
          rr = "warning";
          break;
        case "ИК":
        case "":
          rr = "secondary";
          break;
      }
      return rr;
    },
    async getSensors2() {
      this.progress = true;
      let url;
      let resp;
      //      url = this.urlschstat;
      // url = "us.kbsu.ru";
      // url = "http://" + url + ":2016/ir3";

      // url = "localhost";
      // url = "http://" + url + ":2020/ir3";
      url = this.urlsens;
      url += "/ir3";
      //      url += "/?d1=20201218";
      // url = url + "?d1=" + this.sday;
      // url = url + "&d2=" + this.sday2;
      // url = url + "?d1=" + this.sday.replaceAll("-", "");
      // url = url + "&d2=" + this.sday2.replaceAll("-", "");
      url = url + "?d1=" + this.sday; //.replace(/-/g, "");
      url = url + "&d2=" + this.sday2; //.replace(/-/g, "");
      // console.log(url);
      try {
        resp = await fetch(url);
      } catch (err) {
        this.strerr = "Ошибка соединения: " + err;
        this.sberr = true;
        this.progress = false;
        return;
      }
      this.sensors = [];
      let sen = await resp.json();
      sen = sen.sensors;
      // console.log(sen)
      //this.sns = sen;
      let nn = 1;
      let tmp = [];
      this.headers = [];
      // this.headers.push({ text: "N", value: "nn" });
      // this.headers.push({ text: "Комната", value: "room" });
      this.headers.push({ text: "Часы", value: "para", align: "left" });

      let d1 = new Date(this.sday);
      let d2 = new Date(this.sday2);
      // d1.setDate(this.sday.getDate());
      while (d1 <= d2) {
        this.headers.push({
          text: d1.toISOString().substr(0, 10),
          value: d1,
          align: "center",
        });
        d1.setDate(d1.getDate() + 1);
      }

      let hh = true;
      for (var key in sen) {
      if (key.indexOf("радиоканал") != -1){
        continue
      }
        let pr = {};
        // let qq = {nn: nn, "Комната": key, para: para };
        for (var para in sen[key]) {
          let qq = { nn: nn, Комната: key, para: para, cols: [] };
          pr["Комната"] = key;
          if (hh) {
            for (var dt0 in sen[key][para]) {
              //////              this.headers.push({ text: dt0, value: dt0, align: "center" });
            }
            hh = false;
          }
          /////          for (var dt in sen[key][para]) {
          for (var i = 1; i < this.headers.length; i++) {
            let dt = this.headers[i].text;
            // console.log(dt)
            // console.log(sen[key])
            if (!(dt in sen[key][para])) {
              sen[key][para][dt] = -1;
              // console.log(dt)
            }

            if (
              sen[key][para][dt] > 0 &&
              para != "До занятий" &&
              para != "После занятий"
            ) {
              if (pr[dt] == undefined) {
                pr[dt] = 0;
              }
              pr[dt] += 10;
            } else {
              if (pr[dt] == undefined) {
                pr[dt] = 0;
              }
            }
            qq[dt] = sen[key][para][dt].toFixed(2) + "%";

            let cc = "background-color: #FB8C00";
            if (sen[key][para][dt] > 0) {
              cc =
                "background-color: #7CB342; color: white; text-align: center;";
            }else {
              if (sen[key][para][dt] < 0) {
              cc = "background-color: white; color: black;";
              }
            }
            let sp = false
            if (para == "До занятий" || para == "После занятий") {
              // cc= "background-color: #757575; color: white;"
              cc = "background-color: white; color: black;";
              sp = true
            }
            qq.cols.push({
              val0: sen[key][para][dt],
              val: sen[key][para][dt].toFixed(2) + "%",
              style: cc,
              show: sp,
            });
            // qq.cols.push(sen[key][para][dt].toFixed(2) + "%")
            // qq[dt] = sen[key][para][dt].toFixed(2);
          }
          tmp.push(qq);
          nn++;
        }
        let pr3 = {};
        pr3.nn = nn;
        pr3["Комната"] = pr["Комната"];
        delete pr["Комната"];
        pr3.para = "% использования";
        pr3.cols = [];
        for (let pk0 in pr) {
          pr3.cols.push({
            key: pk0,
            val0: pr[pk0],
            val: pr[pk0] + "%",
            style: "background-color: #039BE5; color: white;",
            show: true,
          });
          pr3.cols.sort((a, b) => (a.key < b.key ? -1 : Number(a.key > b.key)));
          // zz.push({ key: pk, val: pr2[pk], style: "background-color: white; color: black;" });
        }
        tmp.push(pr3);
        nn++;
      }
      this.sensors = tmp;
      ///      this.sensors = [];
      //--------------------------------------
      let tmp2 = [];
      for (var key2 in sen) {
        let pr2 = {};
        let pp = [];
        let zz = [];
        for (var para2 in sen[key2]) {
          //let qq2 = {};
          //pr2["Комната"] = key2;
          let dd = [];
          for (var dt2 in sen[key2][para2]) {
            if (
              sen[key2][para2][dt2] > 0 &&
              para2 != "До занятий" &&
              para2 != "После занятий"
            ) {
              //  console.log(pr2[dt2])
              if (pr2[dt2] == undefined) {
                pr2[dt2] = 0;
              }
              pr2[dt2] += 10;
            } else {
              if (pr2[dt2] == undefined) {
                pr2[dt2] = 0;
              }
            }
            //qq2[dt2] = sen[key2][para2][dt2].toFixed(2) + "%";
            let cc = "background-color: #FB8C00";
            if (sen[key2][para2][dt2] > 0) {
              cc = "background-color: #7CB342; color: white;";
            }else {
              if (sen[key2][para2][dt2] < 0) {
              cc = "background-color: white; color: black;";
              }
            }

            if (para2 == "До занятий" || para2 == "После занятий") {
              // cc= "background-color: #757575; color: white;"
              cc = "background-color: white; color: black;";
            }
            dd.push({ key: dt2, val: sen[key2][para2][dt2], style: cc });
          }
          pp.push({ key: para2, val: dd });
          //console.log(pr2)
        }
        for (let pk in pr2) {
          zz.push({
            key: pk,
            val: pr2[pk],
            style: "background-color: #039BE5; color: white;",
          });
          // zz.push({ key: pk, val: pr2[pk], style: "background-color: white; color: black;" });
        }
        pp.push({ key: "% использования", val: zz });
        //console.log(zz);
        tmp2.push({ key: key2, val: pp });

        nn++;
        //pr2.nn = nn;
        //pr2.para = "Процент";
        // tmp2[key2]= pr2;
        nn++;
      }
      this.sns = tmp2;
      // let nn = 1;
      // for (var key in sen) {
      //   let dd = [];
      //   for (let k2 in sen[key]) {
      //     dd.push({ date: k2, val: sen[key][k2] });
      //   }
      //   let tmp;
      //   tmp = key.split("/");
      //   //        console.log(tmp)
      //   if (tmp.length == 4) {
      //     tmp[2] = tmp[2] + "(" + tmp[3] + ")";
      //   }
      //   if (tmp.length < 3) {
      //     tmp[0] = "";
      //     tmp[1] = "";
      //     tmp[2] = "";
      //   }

      // this.sensors.push({
      //   nn: nn,
      //   sensor: key,
      //   data: dd,
      //   tip: tmp[0],
      //   korpus: tmp[1],
      //   room: tmp[2],
      // });

      //   nn++;
      // }
      // console.log(this.sensors);
      this.strok = "Данные успешно загружены.";
      this.sbok = true;
      this.progress = false;
    },
    async start() {
      this.progress = true;
      // await this.loadSensors({ sday: this.sday, sday2: this.sday2 });
      await this.getSensors2();
      if (this.error == "") {
        this.strok = "Данные успешно загружены.";
        this.sbok = true;
        this.progress = false;
      } else {
        this.strerr = "Ошибка соединения: " + this.error;
        this.sberr = true;
        this.progress = false;
      }
    },
  },
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  computed: {
    ...mapGetters(["urlsens"]),
    ...mapState({
      // sensors: (state) => state.sensors,
      error: (state) => state.error,
      //      nogroup: (state) => state.authm.nogroup,
    }),
  },
  async mounted() {
    let d0 = new Date();
    d0.setDate(d0.getDate() - 4);
    this.sday = d0.toISOString().substring(0, 10);
    let d1 = new Date();
    //    d1.setDate(d1.getDate() - 2);
    d1.setDate(d1.getDate());
    this.sday2 = d1.toISOString().substring(0, 10);
    // this.sday2 = this.sday;
    this.rdt = [this.sday, this.sday2];
    await this.getSensors2();
    // this.collapseAll()
    // this.start();
  },
};
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px !important;
  padding: 2px;
  /* border: 1px solid black; */
}
th {
  background-color: #a5d6a7;
}
</style>
