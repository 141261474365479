<template>
  <v-container>
    <v-card>
      <!-- <v-row class="align-center">
        <v-col> -->
      <!-- <v-card-title > -->
      <v-toolbar color="primary" dark>
        <!-- <v-col cols="12"> -->
        <v-icon color="white" class="mr-2">mdi-home</v-icon>
        <v-toolbar-title> Структура объектов </v-toolbar-title>
        <v-toolbar-items>
          <v-checkbox
            v-model="fullstruct"
            label="Вся структура"
            class="ml-4 ma-5"
          ></v-checkbox>
        </v-toolbar-items>
        <!-- </v-col> -->
      </v-toolbar>
      <!-- </v-card-title> -->
      <!-- </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="text--primary"
              >Из 1C на {{ sgdate }}
              <v-switch
                v-model="addcnt1c"
                label="Количество"
                class="ml-2"
                dense
              ></v-switch>
            </v-card-title>
            <v-card-text>
              <v-row class="elevation-2">
                <v-breadcrumbs :items="bc1c">
                  <template v-slot:divider>
                    <v-icon>mdi-transfer-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-row>
              <v-sheet class="ma-2 pa-2 primary lighten-2">
                <v-text-field
                  v-model="search1c"
                  label="Поиск"
                  dark
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </v-sheet>
              <v-treeview
                :items="data"
                v-model="sel"
                :search="search1c"
                shaped
                hoverable
                activatable
              >
                <template v-slot:append="{ item }">
                  <badgemy
                    :vis="
                      addcnt1c && item != undefined && item.cnt != undefined
                    "
                    nameicon="mdi-account"
                    :num="item.cnt"
                    coloricon="brown"
                    color="primary"
                    tooltip="Сколько проживает"
                    :overlap="false"
                    class="mr-4"
                  ></badgemy>
                </template>
              </v-treeview>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>
              Из ORION
              <v-switch
                v-model="addcntorion"
                label="Количество"
                class="ml-2"
                dense
              ></v-switch>
              <v-switch
                v-model="addevent"
                label="События"
                class="ml-2"
                dense
              ></v-switch>
            </v-card-title>
            <v-card-text>
              <v-row class="elevation-2">
                <v-breadcrumbs :items="bcorion">
                  <template v-slot:divider>
                    <v-icon>mdi-transfer-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-row>
              <v-sheet class="ma-2 pa-2 primary lighten-2">
                <v-text-field
                  v-model="searchorion"
                  label="Поиск"
                  dark
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </v-sheet>
              <v-treeview
                :items="sensdata"
                :search="searchorion"
                shaped
                hoverable
                activatable
              >
                <template v-slot:append="{ item }">
                  <badgemy
                    :vis="addcntorion && item && item.cnt != undefined"
                    nameicon="mdi-account"
                    :num="item.cnt"
                    coloricon="brown"
                    color="primary"
                    tooltip="Сколько проживает"
                    :overlap="false"
                    class="mr-4"
                  ></badgemy>
                  <badgemy
                    :vis="item.fire > 0"
                    nameicon="mdi-fire"
                    :num="item.fire"
                    coloricon="red"
                    color="primary"
                    tooltip="Пожар"
                    :overlap="false"
                    class="mr-4"
                  ></badgemy>
                  <badgemy
                    :vis="item.alert > 0"
                    nameicon="mdi-alert-decagram"
                    :num="item.alert"
                    coloricon="red"
                    color="primary"
                    tooltip="Внимание! Опасность пожара"
                    :overlap="false"
                    class="mr-4"
                  ></badgemy>
                </template>
              </v-treeview>
              <!-- {{ sel }} -->
            </v-card-text>
          </v-card>
        </v-col>
        <!-- {{ data }} -->
      </v-row>
    </v-card>
    <wait :progress="progress" :message="waitmsg"></wait>
    <v-snackbar v-model="sberr" color="red" top right>
      {{ strerr }}
      <v-btn @click="sberr = false" class="ml-5" fab>OK</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import { strictEqual } from "assert";
import wait from "../progress2";
import badgemy from "../sensors/badge_my.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {
    wait,
    badgemy,
  },
  props: {},
  data() {
    return {
      data: [],
      sensdata: [],
      k1: "level4",
      k2: "people",
      sel: [],
      progress: false,
      fullstruct: false,
      addevent: false,
      addcnt1c: true,
      addcntorion: true,
      bc1c: [{ text: "Общежитие" }, { text: "Этаж" }, { text: "Комната" }],
      bcorion: [{ text: "Общежитие" }, { text: "Этаж" }, { text: "Комната" }],
      treeorion: ["corpus", "floor", "room"],
      tree1c: ["corpus", "floor", "room"],
      search1c: "",
      searchorion: "",
      map1c: {},
      sgdate: "",
      waitmsg: "Подождите, идет загрузка...",
      sberr: false,
      strerr: "Ошибка",
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
    ...mapActions({
      loadFireSens: "loadFireSens",
      loadFireSensDates: "loadFireSensDates",
      loadFireSensObj: "loadFireSensObj",
      loadpObjects: "loadpObjects",
      //   loadOtv: "loadOtv",
    }),
    parse0(ss, r0) {
      let s0 = ".*" + ss + `\\.([\\dа-яА-ЯёЁ]+).*`;
      let rg = new RegExp(s0, "");
      var match0 = rg.exec(r0);
      if (match0) {
        r0 = match0[1].toLowerCase();
        // console.log(match0);
      } else {
        r0 = "?"; //+r0;
        // console.log(r0["fl"]);
      }
      return r0;
    },
    parse(tt) {
      let r0 = {};
      //let tt = "Комната №11-(50) общ.7б МК эт.1 помещ.11"
      r0["corpus"] = "Общежитие " + this.parse0("общ", tt);
      r0["part"] = "Подъезд ?";
      r0["floor"] = "Этаж " + this.parse0("эт", tt);
      // r0["group"] = "Помещение " + this.parse0("помещ", tt);
      // var match0 = /Комната\s+.?([\dа-яА-ЯёЁ]+).?\((.*)\).*/.exec(tt);
      var match0 = /(Комната\s+.?([\dа-яА-ЯёЁ]+).?\((.*)\)).*/.exec(tt);
      // var match0 = /(Комната\s+.?[\dа-яА-ЯёЁ]+.?\(.*\)).*/.exec(tt);
      //var match0 = /Комната\s+.?(\d+).*/.exec(tt);
      if (match0) {
        // r0["room"] = "Комната " + match0[1];
        // r0["roomname"] = "(" + match0[2] + ")";
        //  r0["room"] = "Комната " + match0[2]+ " -- в 1С: "+ match0[1]+"-(" + match0[2] + ")";
        // r0["room"] = "Комната " + match0[3] + " или " + match0[1];
        r0["room"] = "Комната " + match0[3].toLowerCase();
        r0["roomname"] = match0[1];
        // console.log(match0);
      } else {
        r0["room"] = "Ошибка формата. Строка: " + tt;
        r0["roomname"] = "(" + tt + ")";
        // console.log(r0["fl"]);
      }
      return r0;
    },
    fillarr(ccc, mm, tmp, pp) {
      //let qqq = {}
      if (mm.length == 1) {
        if (!ccc[tmp[mm[0]]]) {
          ccc[tmp[mm[0]]] = {};
          ccc[tmp[mm[0]]].arr = [];
          ccc[tmp[mm[0]]].cnt = 0;
          ccc[tmp[mm[0]]].fire = 0;
          ccc[tmp[mm[0]]].alert = 0;
        }
        //console.log(pp)
        if (pp) {
          //console.log(tmp)
          ccc[tmp[mm[0]]].arr.push(tmp[pp]);
          ccc[tmp[mm[0]]].cnt++;
        }
        return;
      }
      if (!ccc[tmp[mm[0]]]) {
        ccc[tmp[mm[0]]] = {};
        ccc[tmp[mm[0]]].cnt = 0;
        ccc[tmp[mm[0]]].fire = 0;
        ccc[tmp[mm[0]]].alert = 0;
      }
      ccc[tmp[mm[0]]].cnt++;
      // if (tmp[mm[0]]["event"] == "fire"){
      //   ccc[tmp[mm[0]]].fire++;
      // }
      this.fillarr(ccc[tmp[mm[0]]], mm.slice(1, mm.length + 1), tmp, pp);
      // return ccc
    },
    fillarr1(ccc, mm, tmp) {
      //let qqq = {}
      if (mm.length == 1) {
        if (!ccc[mm[0]]) {
          ccc[mm[0]] = [];
        }
        ccc[mm[0]].push(mm[0]);
        return;
      }
      if (!ccc[mm[0]]) {
        ccc[mm[0]] = {};
      }
      this.fillarr(ccc[mm[0]], mm.slice(1, mm.length + 1), tmp);
      // return ccc
    },
    sensparse0(r0) {
      var match0 = /.*?(\d+).*/.exec(r0);
      if (match0) {
        r0 = match0[1];
        // console.log(match0);
      } else {
        r0 = "?";
        // console.log(r0["fl"]);
      }
      return r0;
    },
    sensparse(t0) {
      if (t0.Descr != "") {
        // "Общежитие №5/Блок 2/Подъезд 4/Этаж 3/Кухня левая
        let tmp = t0.Descr.split("/");
        let r0 = {};
        let m0;
        for (let i = 1; i < tmp.length; i++) {
          if (tmp[i].trim() == "") {
            tmp[i] = "?";
          }
        }
        //console.log(tmp[0])
        // if ( this.sensparse0(tmp[0]) == "0"){
        //   console.log(t0)
        // }
        r0["corpus"] = "Общежитие " + this.sensparse0(tmp[0]);
        r0["block"] = "Блок " + this.sensparse0(tmp[1]);
        r0["part"] = "Подъезд " + this.sensparse0(tmp[2]);
        r0["floor"] = "Этаж " + this.sensparse0(tmp[3]);
        // r0["group"] = "Помещение " + "?";
        //r0["room"] = "Комната "+tmp[4];
        r0["room"] = "" + tmp[4];
        return r0;
      }
      let t = t0.Name;
      let reg = {};
      reg["corpus"] = /.*(\d+).*/;
      reg["floor"] = /.*(\d+)\s*эт.*/;
      reg["part"] = /.*(\d+)\s*п.*/;
      reg["room"] = /.*(прав|лев|левая|правая).*/;
      // let t = "Общ №5 3п 2эт корид прав"
      let rr = {};
      for (var key in reg) {
        var match = reg[key].exec(t);
        if (match) {
          rr[key] = match[1];
        } else {
          rr[key] = "?";
        }
      }
      return rr;
    },
    sensparse2(t0) {
      if (t0.Descr != "") {
        // "Общежитие №5/Блок 2/Подъезд 4/Этаж 3/Кухня левая
        let tmp = t0.Descr.split("/");
        let r0 = {};
        let m0;
        for (let i = 1; i < tmp.length; i++) {
          if (tmp[i].trim() == "") {
            tmp[i] = "?";
          }
        }
        //console.log(tmp[0])
        // if ( this.sensparse0(tmp[0]) == "0"){
        //   console.log(t0)
        // }
        r0["corpus"] = "Общежитие " + this.sensparse0(tmp[0]);
        r0["block"] = "Блок " + this.sensparse0(tmp[1]);
        r0["part"] = "Подъезд " + this.sensparse0(tmp[2]);
        r0["floor"] = "Этаж " + this.sensparse0(tmp[3]);
        // r0["group"] = "Помещение " + "?";
        //r0["room"] = "Комната "+tmp[4];
        r0["room"] = "" + tmp[4];

        //console.log(t0.Event)
        switch (t0.Event) {
          case "37":
          case "40":
            r0["event"] = "fire";
            break;
          case "44":
            r0["event"] = "alert";
            break;
          case "360":
            r0["event"] = "mchs";
            break;
          default:
            // console.log(t);
            break;
        }
        return r0;
      }
      return null;
    },
    fillarr33(ccc, mm, tmp, pp) {
      //let qqq = {}
      if (mm.length == 1) {
        if (pp) {
          //console.log(tmp)
          ccc[tmp[mm[0]]].arr.push(tmp[pp]);
          ccc[tmp[mm[0]]][pp]++;
        }
        if (tmp["event"] == "fire") {
          ccc[tmp[mm[0]]].fire++;
        }
        if (tmp["event"] == "alert") {
          ccc[tmp[mm[0]]].alert++;
        }
        return;
      }
      //ccc[tmp[mm[0]]].cnt++;
      //console.log(tmp)
      if (tmp["event"] == "fire") {
        ccc[tmp[mm[0]]].fire++;
      }
      if (tmp["event"] == "alert") {
        ccc[tmp[mm[0]]].alert++;
      }
      this.fillarr33(ccc[tmp[mm[0]]], mm.slice(1, mm.length + 1), tmp, pp);
      // return ccc
    },

    async sens() {
      this.waitmsg = "Загрузка из ORION...";
      await this.loadpObjects();
      if (this.error != "") {
        this.strerr = "Ошибка загрузки из ORION: " + this.error;
        this.sberr = true;
      }
      // console.log(this.pobjects)
      let tmp;
      let sns = {};
      // let ss = this.firesens.forEach(s => {
      let ss = this.pobjects.forEach((s) => {
        // let mm = /Общежитие(.*)/.exec(s.Corpus)
        let mm = true;
        //console.log(mm)
        //if (mm && s.Descr !="") {
        if (mm) {
          tmp = this.sensparse(s);
          //console.log(s.Descr)
          // this.fillarr(sns, ["corpus", "block", "part", "floor", "group", "room"], tmp)
          // this.fillarr(sns, ["corpus", "floor", "room"], tmp);
          this.fillarr(sns, this.treeorion, tmp);
          // if (this.fullstruct) {
          //   this.fillarr(
          //     sns,
          //     // ["corpus", "block", "part", "floor", "group", "room"],
          //     ["corpus", "block", "part", "floor", "room"],
          //     tmp
          //   );
          // } else {
          //   this.fillarr(sns, ["corpus", "floor", "room"], tmp);
          // }
        }
      });
      //      console.log(sns)
      if (this.addevent) {
        this.waitmsg = "Загрузка событий...";
        await this.loadFireSens();
        if (this.error != "") {
          this.strerr = "Ошибка загрузки событий: " + this.error;
          this.sberr = true;
        }
        this.firesens.forEach((f) => {
          tmp = this.sensparse2(f);
          //console.log(tmp.event)
          if (tmp != null) {
            try {
              // this.fillarr33(sns, ["corpus", "floor", "room"], tmp);
              this.fillarr33(sns, this.treeorion, tmp);
            } catch (err) {
              //console.log(tmp)
            }
          }
        });
      }
      // console.log(sns);
      // console.log(this.map1c);
      // console.log("============================");
      // console.log(this.tree1c, " ----- ", this.treeorion);
      this.trans(this.map1c, sns, 0, 0);
      this.sensdata = this.totree("", [], sns);
    },
    trans(t1, t2, i1, i2) {
      if (i1 >= this.tree1c.length) return;
      if (i2 >= this.treeorion.length) return;
      // console.log( ">>", i1, "-", i2, " ", this.tree1c[i1], " -> ", this.treeorion[i2] );
      for (let k in t2) {
        if (k == "cnt" || k == "fire" || k == "alert" || k == "arr") continue;
        //if (typeof t2[k] !== "object") continue;
        //         while (this.tree1c[i1] != this.treeorion[i2] && i2 < this.treeorion.length) {
        //         i2++
        //         //if (i1 >= this.tree1c) continue
        // //        if (i2 >= this.treeorion) continue
        //         t2 = t2[k]
        //       }
        // if (t1[k] &&  t2[k] && t2[k].cnt && t1[k].cnt) {
        // t2[k].cnt = 0;
        t2[k].cnt = "?";
        if (t1[k] != undefined && t2[k] != undefined) {
          t2[k].cnt = t1[k].cnt;
          if (t1[k].arr != undefined || t2[k].arr != undefined) {
            t2[k].arr = t1[k].arr;
          }
          // console.log("  ??", i1,"-", i2, " ", this.tree1c[i1], " -> ", this.treeorion[i2] );
          if (this.tree1c[i1] == this.treeorion[i2]) {
            // console.log("  !!", i1, "-", i2, " ", this.tree1c[i1], " -> ", this.treeorion[i2]);
            let i11 = i1 + 1;
            let i22 = i2 + 1;
            this.trans(t1[k], t2[k], i11, i22);
          } else {
            let i22 = i2 + 1;
            // console.log(this.tree1c[i1], " -> ", this.treeorion[i2]);
            this.trans(t1[k], t2[k], i1, i22);
          }
        } else {
          let i22 = i2 + 1;
          // console.log("    ##",k,this.tree1c[i1], " -> ", this.treeorion[i2]);
          this.trans(t1, t2[k], i1, i22);
        }
      }
    },
    trans0(t1, t2) {
      for (let k in t2) {
        // if (k == "cnt" || k == "fire" || k == "alert" || k == "arr") continue;
        if (typeof t2[k] !== "object") continue;
        // console.log(k, " -> ", t1[k]);
        // if (t1[k] &&  t2[k] && t2[k].cnt && t1[k].cnt) {
        t2[k].cnt = 0;
        if (t1[k] != undefined && t2[k] != undefined) {
          t2[k].cnt = t1[k].cnt;
          if (t1[k].arr != undefined || t2[k].arr != undefined)
            t2[k].arr = t1[k].arr;
          this.trans(t1[k], t2[k]);
        }
      }
    },
    async start() {
      this.progress = true;
      // await this.sens();
      // let k1 = "level4";
      // let k2 = "people";
      let k1 = "roominfo";
      let k2 = "residinginfo";
      let url = "sg.json";
      let resp;
      let sg;
      this.waitmsg = "Загрузка из 1С...";
      resp = await fetch(url);
      sg = await resp.json();
      if (this.error != "") {
        this.strerr = "Ошибка загрузки из 1С: " + this.error;
        this.sberr = true;
      }
      let ddd = sg.datetime;
      //      ddd[11] = " "
      ddd.replace("T", "");
      // console.log(ddd)
      sg = sg.data;
      this.sgdate = new Date(ddd).toLocaleString("ru");
      // console.log(this.sgdate)
      let ccc = {};
      this.data = [];
      sg.forEach((s) => {
        let tmp = {};
        tmp[k1] = s[k1];
        tmp[k2] = s[k2];
        tmp = this.parse(s[k1]);
        tmp[k2] = s[k2];

        this.fillarr(ccc, this.tree1c, tmp, k2);
        // if (this.fullstruct) {
        //   this.fillarr(
        //     ccc,
        //     // ["corpus", "part", "floor", "group", "room", "roomname"],
        //     // ["corpus", "part", "floor", "group", "room"],
        //     ["corpus", "floor", "group", "room"],
        //     tmp,
        //     k2
        //   );
        // } else {
        //   this.fillarr(ccc, ["corpus", "floor", "room"], tmp, k2);
        // }

        //      this.fillarr(ccc,["corpus","room","part","floor","group"],tmp)
        // if (!ccc[tmp.corpus]) {
        //   ccc[tmp.corpus] = {};
        // }
        // if (!ccc[tmp.corpus][tmp.floor]) {
        //   ccc[tmp.corpus][tmp.floor] = {};
        // }
        // if (!ccc[tmp.corpus][tmp.floor][tmp.group]) {
        //   ccc[tmp.corpus][tmp.floor][tmp.group] = {};
        // }
        // if (!ccc[tmp.corpus][tmp.floor][tmp.group][tmp.room]) {
        //   ccc[tmp.corpus][tmp.floor][tmp.group][tmp.room] = [];
        // }
        // ccc[tmp.corpus][tmp.floor][tmp.group][tmp.room].push(tmp.people)
        // this.data.push(tmp);
      });
      // console.log(ccc)
      // console.log(this.totree("kbsu",[],ccc))
      this.map1c = ccc;
      this.data = this.totree("kbsu", [], ccc);
      await this.sens();
      this.progress = false;
    },
    totree(id, arr, ob) {
      //console.log(ob)
      if (ob.arr && Array.isArray(ob.arr)) {
        // return [{ id: id, name: "Жильцов: " + ob.length }]
        // return [{ id: id, name: "???"}]
        // return [{ id: id, name: "Жильцов: " + ob.arr.length, cnt: ob.cnt }];
        // return [{ id: id, name: "Жильцов: " + ob.arr.length }];
        // let str = "<ul>"
        // ob.arr.forEach(p => {
        //   str+="<li>"+p+"</li>"
        // })
        // str+="</ul>"
        let str = [];
        ob.arr.forEach((p) => {
          str.push({
            id: id + "," + p,
            cnt: ob.cnt,
            name: p,
          });
        });
        // let str = "Жильцов: " + ob.arr.length
        return [{ id: id, name: "Жильцов: " + ob.arr.length, children: str }];
      }
      //let tid = id
      for (let kk in ob) {
        if (kk == "cnt" || kk == "fire" || kk == "alert") continue;
        let nid = id + "," + kk;
        //nid = id+ (id*Object.keys(ob).length)+1
        arr.push({
          id: nid,
          name: kk,
          children: this.totree(nid, [], ob[kk]),
          cnt: ob[kk].cnt,
          fire: ob[kk].fire,
          alert: ob[kk].alert,
        });
        //id++
      }
      //return arr
      return arr.sort((a, b) => {
        return this.cstr(a.name, b.name);
        //return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      });
    },
    cstr(a, b) {
      let m1 = /(.*?)(\d+).*/.exec(a);
      let m2 = /(.*?)(\d+).*/.exec(b);
      let r1, r2;
      let z1, z2;
      //console.log(m1[1])
      if (m1 && m2) {
        let n1 = m1[2] + "";
        let n2 = m2[2] + "";
        //console.log(n1, " ",n1.length)
        z1 = n1;
        z2 = n2;
        //n1 = Number(n1)
        //n2 = Number(n2)
        //console.log(n1, " ", n2, " ",n1.length-n2.length)
        if (n1.length > n2.length) {
          for (let i = 0; i < n1.length - n2.length; i++) {
            z2 = "0" + z2;
          }
        } else {
          for (let i = 0; i < n2.length - n1.length; i++) {
            z1 = "0" + z1;
          }
        }
        r1 = m1[1] + z1;
        r2 = m2[1] + z2;
      } else {
        r1 = a;
        r2 = b;
      }
      // console.log(r1, " ", r2)
      return r1 > r2 ? 1 : r1 < r2 ? -1 : 0;
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
    ...mapState({
      firesens: (state) => state.firesens,
      // firesensdates: (state) => state.firesensdates,
      pobjects: (state) => state.pobjects,
      error: (state) => state.error,
      //   otv: (state) => state.otv,
      //      nogroup: (state) => state.authm.nogroup,
    }),
  },
  watch: {
    addevent: function () {
      this.start();
    },
    fullstruct: function () {
      if (this.fullstruct) {
        this.bc1c = [
          { text: "Общежитие" },
          { text: "Этаж" },
          // { text: "Помещение" },
          { text: "Комната" },
        ];
        this.bcorion = [
          { text: "Общежитие" },
          { text: "Блок" },
          { text: "Подъезд" },
          { text: "Этаж" },
          { text: "Комната" },
        ];

        this.treeorion = ["corpus", "block", "part", "floor", "room"];
        this.tree1c = ["corpus", "floor", "room"];

        // this.bc1c = ["","","","",""]
      } else {
        this.bc1c = [
          { text: "Общежитие" },
          { text: "Этаж" },
          { text: "Комната" },
        ];
        this.bcorion = [
          { text: "Общежитие" },
          { text: "Этаж" },
          { text: "Комната" },
        ];
        this.treeorion = ["corpus", "floor", "room"];
        this.tree1c = ["corpus", "floor", "room"];
      }
      this.start();
    },
  },
  mounted() {
    this.start();
  },
};
</script>
