<template>
  <!-- <div> -->
  <div class="">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="dates"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="dates2"
          label="Интервал"
          prepend-icon="mdi-calendar-range"
          readonly
          v-on="on"
          :disabled="dis"
          class="d-flex"
          style="width: 270px;"
        ></v-text-field>
      </template>
      <!-- <v-date-picker v-model="dates" range locale="ru-ru" :first-day-of-week="0" :selected-items-text="rangetext(dates)"> -->
      <v-date-picker
        v-model="dates"
        range
        locale="ru-ru"
        :first-day-of-week="1"
        selected-items-text="Интервал"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false">Отмена</v-btn>
        <v-btn
          text
          color="primary"
          @click="
            $refs.menu.save(dates);
            retdates(dates);
            $emit('dateschange', dates);
          "
          >OK</v-btn
        >
        <!-- <v-btn text color="primary" @click="rangetext(dates)">OK</v-btn> -->
      </v-date-picker>
    </v-menu>
    <!-- {{dates}} -->
    <!-- <v-date-picker v-model="dates" range locale="ru-ru" :first-day-of-week="0" :selected-items-text="rangetext(dates)"></v-date-picker> -->
    <!-- <v-date-picker  range></v-date-picker> -->
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    rdates: {
      type: Array,
      default() {
        return [
          new Date().toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10),
        ];
      },
    },
    dis: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      modal: false,
      dates2: "",
      dates: [],
      //      dates: ["2020-05-01", "2020-05-12"]
    };
  },
  methods: {
    retdates(dd) {
      this.rangetext(dd);
      //      console.log(dd);
    },
    dd2(d) {
      if (d == undefined) return "";
      return (
        d.substring(8, 10) + "-" + d.substring(5, 7) + "-" + d.substring(0, 4)
      );
    },
    rangetext(dd) {
      ///          console.log(dd)
      if (dd.length < 1) return "";
      if (dd[1] < dd[0]) {
        let tmp = dd[0];
        dd[0] = dd[1];
        dd[1] = tmp;
      }
      //   if(dd.length == 1 )
      //   {
      //       dd.push(dd[0])
      //   }
      if (dd.length == 1 || dd[1] == undefined) {
        dd[1] = dd[0];
        this.dates2 = "с " + this.dd2(dd[0]) + " по " + this.dd2(dd[0]);
      } else {
        this.dates2 = "с " + this.dd2(dd[0]) + " по " + this.dd2(dd[1]);
      }
      this.dates = [dd[0], dd[1]];
      return this.dates2;
    },
  },
  watch: {
    rdates: function () {
      //          console.log(this.rdates)
      this.rangetext(this.rdates);
      this.dates = this.rdates;
    },
  },
  computed: {},
  mounted() {
    this.dates = this.rdates;
    this.rangetext(this.dates);
  },
};
</script>