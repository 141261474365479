<template>
  <div class="ml-2 mr-2">
    <v-row class="justify-center" dense>
      <v-col xs="12" sm="11" md="10" lg="7">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-center" dense>
      <!-- <v-col xs="12" sm="11" md="10" lg="9"> -->
      <v-col>
        <v-data-table
          v-if="true"
          id="tbld"
          :headers="headers"
          :items="firesensfilter"
          :search="search"
          item-key="sensor"
          class="elevation-0"
          dense
          group-by="group"
          group-desc
          :mobile-breakpoint="100"
          :items-per-page="20"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageText: 'Записей на странице',
            itemsPerPageOptions: [
              { text: 'Все записи', value: -1 },
              10,
              15,
              20,
              25,
              50,
              100,
            ],
          }"
        >
          <template v-slot:group.header="{}">
            <!-- <tr style="background-color: red; color: white;"> -->
            <!-- <v-divider></v-divider> -->
            <div style="background-color: white; color: white; height: 1px;">-</div>
          </template>
          <template v-slot:group.summary="{}">
              <td colspan="8" style="background-color: #E8F5E9; color: white; height: 10px;"></td>
              <v-spacer></v-spacer>
              <v-divider></v-divider>
          </template>

          <template v-slot:item.DeviceTime="{ item }">
            <span class="red--text" v-if="item.DeviceTime && item.Event == 360">
              <!-- {{item.DeviceTime}} -->
              {{ new Date(item.DeviceTime).toLocaleString("ru") }}
            </span>
            <span class="black--text" v-else>
              <span v-if="item.DeviceTime">
                {{ new Date(item.DeviceTime).toLocaleString("ru") }}
              </span>
            </span>
          </template>
          <template v-slot:item.TimeVal="{ item }">
            <span class="red--text" v-if="item.Event == 360">
              {{ new Date(item.TimeVal).toLocaleString("ru") }}
            </span>
            <span class="black--text" v-else>
              {{ new Date(item.TimeVal).toLocaleString("ru") }}
            </span>
          </template>
          <template v-slot:item.Corpus="{ item }">
            <span class="red--text" v-if="item.Event == 360">{{
              item.Corpus
            }}</span>
            <span class="black--text" v-else>{{ item.Corpus }}</span>
          </template>
          <template v-slot:item.Name="{ item }">
            <span class="red--text" v-if="item.Event == 360">{{
              item.Name
            }}</span>
            <span class="black--text" v-else>{{ item.Name }}</span>
          </template>
          <template v-slot:item.Remark="{ item }">
            <span class="red--text" v-if="item.Event == 360">{{
              item.Remark
            }}</span>
            <span class="black--text" v-else>{{ item.Remark }}</span>
          </template>
          <template v-slot:item.Contents="{ item }">
            <span class="red--text" v-if="item.Event == 360">
              Сигнал в МЧС
              <!-- {{item.Contents}} -->
            </span>
            <span class="black--text" v-else>
              {{ item.Contents }}
            </span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              {{ item }}
            </td>
          </template></v-data-table
        ></v-col
      ></v-row
    >
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    firesensfilter: Array,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Время", value: "DeviceTime", align: "left" },
        // { text: "Время записи в базу", value: "TimeVal", align: "left" },
        { text: "Корпус", value: "Corpus", align: "left" },
        { text: "Объект", value: "Name", align: "left" },
        // { text: "Устройство", value: "Remark", align: "left" },
        { text: "Событие", value: "Contents", align: "left" },
        { text: "Причина срабатывания", value: "t1", align: "left" },
        { text: "Ответственный", value: "t4", align: "left" },
        { text: "Принятые меры", value: "t2", align: "left" },
        { text: "Примечание", value: "t3", align: "left", width:"20%" },
        // { text: "Адрес", value: "adr", align: "left" },
        // { text: "Comment", value: "Comment", align: "left" },
        // { text: "Раздел", value: "RazdIndex", align: "left" },
        // { text: "IndexZone", value: "IndexZone", align: "left" },
      ],
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
  },
  mounted() {},
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 18px !important;
  /* border: 5px solid red; */
  /* padding-top: 0 px;
  padding-bottom: 0 px;
  margin-top: 0 px; */
  /* padding: 2px;
  margin: 1px; */
}
/* border: 1px solid black; */
th {
  background-color: #a5d6a7;
}
</style>
