var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"justify-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"xs":"12","sm":"11","md":"10","lg":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"justify-center",attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"single-expand":true,"show-expand":"","id":"tbld","headers":_vm.headers,"items":_vm.firesensstat.stat,"search":_vm.search,"item-key":"name","mobile-breakpoint":100,"items-per-page":-1,"dense":"","sort-by":"name","footer-props":{
          showFirstLastPage: true,
          itemsPerPageText: 'Записей на странице',
          itemsPerPageOptions: [
            { text: 'Все записи', value: -1 },
            10,
            15,
            20,
            25,
            50,
            100 ],
        }},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('fsall',{attrs:{"firesensfilter":item.vals}})],1)]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td'),_c('td',{staticClass:"font-weight-black"},[_vm._v("Итого")]),_c('td'),_c('td',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.firesensstat.sum["Пожар"]))]),_c('td',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.firesensstat.sum["Внимание! Опасность пожара"])+" ")]),_c('td',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.firesensstat.sum["Запуск сценария управления"])+" ")])])]},proxy:true},{key:"body.append",fn:function(){return [_c('tr',[_c('td'),_c('td',{staticClass:"font-weight-black"},[_vm._v("Итого")]),_c('td'),_c('td',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.firesensstat.sum["Пожар"]))]),_c('td',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.firesensstat.sum["Внимание! Опасность пожара"])+" ")]),_c('td',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.firesensstat.sum["Запуск сценария управления"])+" ")])])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }