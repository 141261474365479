<template>
  <div>
    <!-- <v-row justify="center" align="center" wrap> -->
    <v-row class="justify-center align-center text-center">
      <v-col class="title" cols="10">Датчики.</v-col>
    </v-row>
    <v-row class="align-center ml-1">
      <v-col cols="2">
        <v-btn small color="primary" @click="start()">Обновить</v-btn>
      </v-col>
      <v-col cols="6">
        <di
          :rdates="rdt"
          v-on:dateschange="
            sday = $event[0];
            sday2 = $event[1];
          "
        ></di>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="hidden-md-and-down">
        <d3tree :treeData="data"></d3tree>
      </v-col>
      <v-col cols="12" class="hidden-lg-and-up">
        <v-treeview
          :items="treedata"
          open-on-click
          :open.sync="opened1"
        ></v-treeview>
      </v-col>
    </v-row>
    <wait :progress="progress"></wait>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import d3tree from "./tree";
import { fDATE0, fDATE1, l2t, l2t2 } from "../lib1.js";
import di from "../dateinterval";
import wait from "../progress";
export default {
  components: {
    d3tree,
    di,
    wait,
  },
  props: {
    prg: {
      type: String,
      default: "Датчики",
    },
  },
  data() {
    return {
      dataall: null,
      servers: [],
      server: "",
      data: {},
      treedata: [],
      opened1: [0],
      d1: fDATE0(new Date().toISOString()),
      d0: new Date().toISOString().substring(0, 10),
      sday: new Date().toISOString().substr(0, 10),
      sday2: new Date().toISOString().substr(0, 10),
      rdt: [this.sday, this.sday2],
      // menu1: null,
      // radios: "r1",
      ord: [
        ["korpus", "room", "tip", "date"],
        //        ["arhdate", "Name", "Group", "Name2"]
      ],
      progress: false,
    };
  },
  methods: {
    ...mapActions({
      loadSensors: "loadSensors",
    }),
    loadcancel() {
      this.progress = false;
      this.showalert = true;
      this.erroralert =
        "Ошибка: отмена соединения. Для повтора обновите страницу.";
    },
    async start() {
      this.progress = true;
      await this.loadSensors({ sday: this.sday, sday2: this.sday2 });
      this.showtree();
      this.progress = false;
    },
    showtree() {
      let n0 = 0;
      let d2 = this.senstree;
      if (d2 == null) {
        //  console.log(this.server)
        this.data = {};
        this.treedata = [];
        return;
      }
      this.data = l2t("Датчики", d2, this.ord[n0], "val");
      this.treedata = l2t2("Датчики", d2, this.ord[n0], "val");
      // let tmp = l2t("Датчики", d2,  ["korpus", "room", "tip", "date"] , "val");
      // tmp = tmp.children
      // console.log(tmp)
      this.opened1 = [0];
    },
  },
  watch: {
    radios: function () {
      this.start();
    },
    d0: function () {
      //     this.d1 = fDATE0(this.d0.toISOString())
      this.start();
    },
  },
  computed: {
    //    ...mapGetters(["urlmdlstat"]),
    ...mapState({
      sensors: (state) => state.sensors,
      senstree: (state) => state.senstree,
      //      nogroup: (state) => state.authm.nogroup,
    }),
  },
  mounted() {
    let d0 = new Date();
    d0.setDate(d0.getDate() - 0);
    this.sday = d0.toISOString().substr(0, 10);
    this.rdt = [this.sday, this.sday2];
    this.start();
  },
};
</script>
