<template>
  <div>
    <!-- {{ sns }} -->
    <v-simple-table v-if="room">
      <thead>
        <th width="30px">Кабинет</th>
        <th>Часы</th>
        <!-- <th>20210320</th> -->
        <th v-for="dd in room.val[0].val" :key="dd.key">{{ dd.key }}</th>
      </thead>
      <tbody>
        <tr>
          <td rowspan="15" width="30px">
            <p class="vert">{{ room.key }}</p>
          </td>
        </tr>
        <tr v-for="pp in room.val" :key="pp.key">
          <td>{{ pp.key }}</td>
          <!-- <td v-for="dd in pp.val" :key="dd.key">
              <span v-if="dd.val > 0" style="background-color: green;" >{{ dd.val.toFixed(2) }}%</span>
              </td> -->
          <template v-for="dd in pp.val">
            <!-- <td :key="dd.key" v-if="dd.val > 0" style="background-color: #7CB342; color: white;">
              <span>{{ dd.val.toFixed(2) }}%</span>
            </td>
            <td :key="dd.key" v-else style="background-color: #FB8C00"></td> -->
            <!-- <td :key="dd.key"  :style="dd.style" v-if="dd.val >=0"><span>{{ dd.val.toFixed(2) }}%</span></td> -->
            <td :key="dd.key"  :style="dd.style" v-if="dd.val >=0 && dd.show"><span>{{dd.val.toFixed(2) }}%</span></td>
            <td :key="dd.key"  :style="dd.style" v-else><span></span></td>
          </template>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
      room: {
          type: Object,
          default: undefined
      }
  },
  data() {
    return {
      room2: {
        key: "ИК радиокан/ФМФ/каб. 111 верхний",
        val: [
          {
            key: "До занятий",
            val: [
              { key: "2021-03-17", val: 9.62963 },
              { key: "2021-03-18", val: 0.37037036 },
              { key: "2021-03-19", val: 0 },
            ],
          },
          {
            key: "Пара 1, час 1",
            val: [
              { key: "2021-03-17", val: 63.04348 },
              { key: "2021-03-18", val: 0 },
              { key: "2021-03-19", val: 10.869565 },
            ],
          },
          {
            key: "Пара 1, час 2",
            val: [
              { key: "2021-03-17", val: 0 },
              { key: "2021-03-18", val: 0 },
              { key: "2021-03-19", val: 0 },
            ],
          },
          {
            key: "Пара 2, час 1",
            val: [
              { key: "2021-03-17", val: 26.086956 },
              { key: "2021-03-18", val: 0 },
              { key: "2021-03-19", val: 0 },
            ],
          },
          {
            key: "Пара 2, час 2",
            val: [
              { key: "2021-03-17", val: 0 },
              { key: "2021-03-18", val: 0 },
              { key: "2021-03-19", val: 0 },
            ],
          },
          {
            key: "Пара 3, час 1",
            val: [
              { key: "2021-03-17", val: 50 },
              { key: "2021-03-18", val: 0 },
              { key: "2021-03-19", val: 0 },
            ],
          },
          {
            key: "Пара 3, час 2",
            val: [
              { key: "2021-03-17", val: 45.652172 },
              { key: "2021-03-18", val: 26.086956 },
              { key: "2021-03-19", val: 28.26087 },
            ],
          },
          {
            key: "Пара 4, час 1",
            val: [
              { key: "2021-03-17", val: 0 },
              { key: "2021-03-18", val: 28.26087 },
              { key: "2021-03-19", val: 0 },
            ],
          },
          {
            key: "Пара 4, час 2",
            val: [
              { key: "2021-03-17", val: 0 },
              { key: "2021-03-18", val: 0 },
              { key: "2021-03-19", val: 0 },
            ],
          },
          {
            key: "Пара 5, час 1",
            val: [
              { key: "2021-03-17", val: 6.521739 },
              { key: "2021-03-18", val: 0 },
              { key: "2021-03-19", val: 0 },
            ],
          },
          {
            key: "Пара 5, час 2",
            val: [
              { key: "2021-03-17", val: 0 },
              { key: "2021-03-18", val: 0 },
              { key: "2021-03-19", val: 0 },
            ],
          },
          {
            key: "После занятий",
            val: [
              { key: "2021-03-17", val: 2.9535866 },
              { key: "2021-03-18", val: 2.3206751 },
              { key: "2021-03-19", val: 0 },
            ],
          },
        ],
      },
      room1: [
        {
          nn: 1,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "До занятий",
          "2021-03-21": "1.11%",
        },
        {
          nn: 2,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "Пара 1, час 1",
          "2021-03-21": "0.00%",
        },
        {
          nn: 3,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "Пара 1, час 2",
          "2021-03-21": "4.35%",
        },
        {
          nn: 4,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "Пара 2, час 1",
          "2021-03-21": "0.00%",
        },
        {
          nn: 5,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "Пара 2, час 2",
          "2021-03-21": "0.00%",
        },
        {
          nn: 6,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "Пара 3, час 1",
          "2021-03-21": "0.00%",
        },
        {
          nn: 7,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "Пара 3, час 2",
          "2021-03-21": "0.00%",
        },
        {
          nn: 8,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "Пара 4, час 1",
          "2021-03-21": "2.17%",
        },
        {
          nn: 9,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "Пара 4, час 2",
          "2021-03-21": "2.17%",
        },
        {
          nn: 10,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "Пара 5, час 1",
          "2021-03-21": "2.17%",
        },
        {
          nn: 11,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "Пара 5, час 2",
          "2021-03-21": "0.00%",
        },
        {
          nn: 12,
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          para: "После занятий",
          "2021-03-21": "0.42%",
        },
        {
          Комната: "ИК/Бизнес-инубатор/комп.класс",
          "2021-03-21": 40,
          nn: 13,
          para: "Процент",
        },
      ],
      room0: {
        name: "ИК/Бизнес-инубатор/комп.класс",
        para: {
          "До занятий": { "2021-03-21": 1.1111112 },
          "Пара 1, час 1": { "2021-03-21": 0 },
          "Пара 1, час 2": { "2021-03-21": 4.347826 },
          "Пара 2, час 1": { "2021-03-21": 0 },
          "Пара 2, час 2": { "2021-03-21": 0 },
          "Пара 3, час 1": { "2021-03-21": 0 },
          "Пара 3, час 2": { "2021-03-21": 0 },
          "Пара 4, час 1": { "2021-03-21": 2.173913 },
          "Пара 4, час 2": { "2021-03-21": 2.173913 },
          "Пара 5, час 1": { "2021-03-21": 2.173913 },
          "Пара 5, час 2": { "2021-03-21": 0 },
          "После занятий": { "2021-03-21": 0.42194092 },
        },
      },
    };
  },
  methods: {},
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  mounted() {},
};
</script>
<style scoped>
table {
  text-align: center;
  border-spacing: 0px;
  border-collapse: collapse;
}
.prl {
  text-align: left;
}
tr {
  margin: 0px;
}
td,
th {
  border: 1px solid black;
  padding: 4px;
  margin: 0px;
  text-align: center;
}

.vert {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  padding: 10px;
}
</style>