<template>
  <v-container>
    <v-row class="justify-center align-center text-center" dense>
      <div class="text-h5 font-weight-black">Анализ происшествий</div>
      <v-btn small fab class="ml-4 mb-0">
        <v-progress-circular
          size="25"
          indeterminate
          color="primary"
          class=""
          v-if="progress"
        >
        </v-progress-circular>
        <v-progress-circular
          size="25"
          value="0"
          color="white"
          class=""
          v-else
          @click="start()"
        ></v-progress-circular>
      </v-btn>
    </v-row>
    <v-row class="justify-center align-center ma-1">
      <!-- <v-col cols="11">
        <v-row class="justify-center align-center"> -->
      <!-- <v-btn small color="primary" class="mr-1" @click="start()"
        >Обновить</v-btn
      > -->
      <v-checkbox v-model="allrec" label="Все записи" class="mr-2"></v-checkbox>
      <di
        :rdates="rdt"
        v-on:dateschange="
          sday = $event[0];
          sday2 = $event[1];
          show();
        "
        :dis="allrec"
      ></di>
      <!-- </v-row>
      </v-col> -->
    </v-row>
    <v-row class="align-center justify-center">
      <!-- <v-col cols="6"> -->
      <v-btn
        fab
        @click="show1c = true"
        color="yellow"
        class="mr-2 text-red"
        small
        ><span class="text-red">1c</span></v-btn
      >
      <v-btn fab @click="showsens = true" color="" class="mr-2 text-red" small
        ><span class="text-red">Orion</span></v-btn
      >
      <div><v-icon color="primary">mdi-domain</v-icon></div>
      <div>
        <v-autocomplete
          :items="dormitory"
          v-model="objsel"
          class="d-flex ml-2 mr-2"
          style="width: 300px"
        >
        </v-autocomplete>
      </div>
      <!-- </v-col> -->
      <!-- <v-col> -->
      <badgemy
        v-if="senstree[objsel] != undefined"
        :vis="senstree[objsel].cnt > 0"
        nameicon="mdi-account"
        :num="senstree[objsel].cnt"
        coloricon="brown"
        color="primary"
        tooltip="Сколько проживает"
        :overlap="false"
        class="mr-4"
      ></badgemy>
      <badgemy
        :vis="true"
        nameicon="mdi-fire"
        :num="fire"
        coloricon="red"
        color="primary"
        tooltip="Пожар"
        class="mr-2"
      ></badgemy>
      <badgemy
        :vis="true"
        nameicon="mdi-alert-decagram"
        :num="alert"
        coloricon="red"
        color="primary"
        tooltip="Внимание! Опасность пожара"
        class="mr-2"
      ></badgemy>
      <v-tooltip top>
        <template v-slot:activator="{ on2 }">
          <v-menu offset-x>
            <template v-slot:activator="{ on }">
              <!-- <badgemy
              :vis="true"
              nameicon="mdi-fire-truck"
              :num="truck"
              coloricon="red"
              color="blue"
              tooltip="Сигнал в МЧС"
              class="mr-2"
              :fun = "on"
              v-on="on"
            ></badgemy> -->
              <v-badge :overlap="false" :content="'' + truck">
                <v-icon color="red" v-on="on || on2">mdi-fire-truck</v-icon>
              </v-badge>
            </template>
            <v-list v-if="truck >= 0">
              <v-list-item v-for="ii in truckitem" :key="ii.GUID">
                <v-list-item-icon>
                  <v-icon color="red">mdi-fire-truck</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ new Date(ii.DeviceTime).toLocaleString("ru") }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        Сигнал в МЧС
      </v-tooltip>
      <!-- <badgemy
          :vis="true"
          nameicon="mdi-fire-truck"
          :num="truck"
          coloricon="red"
          color="blue"
          tooltip="Сигнал в МЧС"
          class="mr-2"
        ></badgemy> -->
      <!-- </v-col> -->
    </v-row>
    <v-row class="align-center justify-center">
      <v-simple-table style="border: 2px solid black">
        <thead>
          <th style="border: 1px solid black"></th>
          <template v-for="(pp, pn) in parts" style="border: 1px solid black">
            <th :key="pn" style="border: 1px solid black">
              <v-row class="align-end justify-center" style="height: 40px">
                {{ pp }}
                <badgemy
                  :vis="senstree[objsel][pp].fire > 0"
                  nameicon="mdi-fire"
                  :num="senstree[objsel][pp].fire"
                  coloricon="red"
                  color="primary"
                  tooltip="Пожар"
                  class="ml-1"
                ></badgemy>
                <badgemy
                  :vis="senstree[objsel][pp].alert > 0"
                  nameicon="mdi-alert-decagram"
                  :num="senstree[objsel][pp].alert"
                  coloricon="red"
                  color="primary"
                  tooltip="Внимание! Опасность пожара"
                  class="ml-1"
                ></badgemy>
              </v-row>
            </th>
          </template>
        </thead>
        <tbody v-if="senstree && senstree[objsel]">
          <!-- <th style="border: 1px solid black"></th> -->
          <tr v-for="fl in floors" :key="fl" style="border: 1px solid black">
            <td style="background-color: #a5d6a7; border: 1px solid black">
              <div class="text-h7" style="font-size: 16px; font-weight: bold">
                {{ fl }}
                <badgemy
                  v-if="map1c && map1c[objsel] && map1c[objsel][fl]"
                  :vis="map1c[objsel][fl].cnt > 0"
                  nameicon="mdi-account"
                  :num="map1c[objsel][fl].cnt"
                  coloricon="brown"
                  color="primary"
                  tooltip="Сколько проживает"
                  :overlap="false"
                  class="mr-4"
                ></badgemy>
              </div>
            </td>
            <td v-for="pt in parts" :key="pt" style="border: 1px solid black">
              <template v-for="(v, k) in senstree[objsel][pt][fl]" dense>
                <!-- {{ senstree[objsel][pt][fl]}} -->
                <v-card
                  dense
                  elevation="2"
                  class="mt-1 mb-3"
                  color="#E0F2F1"
                  :key="k"
                  v-if="
                    k != 'cnt' &&
                    k != 'fire' &&
                    k != 'alert' &&
                    k != 'arr' &&
                    k != 'razdel' &&
                    v.razdel != '-1'
                  "
                >
                  <!-- {{ senstree[objsel][pt][fl] }} -->
                  <!-- <v-card-title> {{ k }}</v-card-title> -->
                  <v-toolbar color="green lighten-4" elevation="1">
                    <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                    <v-toolbar-title>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{ k }}</span>
                        </template>
                        <span> Раздел: {{ v.razdel }}</span>
                      </v-tooltip>
                    </v-toolbar-title>
                    <!-- {{ senstree[objsel][pt][fl][k] }} -->
                    <v-menu
                      offset-x
                      v-if="
                        senstree[objsel][pt][fl][k] != undefined &&
                        senstree[objsel][pt][fl][k].cnt > 0
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-badge
                          :overlap="false"
                          :content="senstree[objsel][pt][fl][k].cnt"
                          class="ml-2"
                        >
                          <v-icon color="brown" v-on="on">mdi-account</v-icon>
                        </v-badge>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="ii in senstree[objsel][pt][fl][k].arr"
                          :key="ii.GUID"
                        >
                          <v-list-item-icon>
                            <v-icon color="brown">mdi-account</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>
                              {{ ii }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <!-- <badgemy
                      :vis="senstree[objsel][pt][fl][k].cnt > 0"
                      nameicon="mdi-account"
                      :num="senstree[objsel][pt][fl][k].cnt"
                      coloricon="brown"
                      color="primary"
                      tooltip="Сколько проживает"
                      :overlap="false"
                      class="ml-0"
                    ></badgemy> -->
                    <v-menu
                      offset-x
                      v-if="senstree[objsel][pt][fl][k].fire > 0"
                    >
                      <template v-slot:activator="{ on }">
                        <v-badge
                          :overlap="false"
                          :content="senstree[objsel][pt][fl][k].fire"
                          class="ml-2"
                        >
                          <v-icon color="red" v-on="on">mdi-fire</v-icon>
                        </v-badge>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="ii in senstree[objsel][pt][fl][k].fireitem"
                          :key="ii.GUID"
                        >
                          <v-list-item-icon>
                            <v-icon color="red">mdi-fire</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            {{ new Date(ii.DeviceTime).toLocaleString("ru") }}
                            <v-list-item-subtitle>
                              {{ ii.Name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              {{ ii.Descr }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              Причина: {{ ii.T1 }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              Примечание: {{ ii.Txt }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <!-- <badgemy :vis="senstree[objsel][pt][fl][k].fire > 0" nameicon="mdi-fire"
                      :num="senstree[objsel][pt][fl][k].fire" coloricon="red" color="primary" tooltip="Пожар"
                      :overlap="false" class="ml-2"></badgemy> -->
                    <v-menu
                      offset-x
                      v-if="senstree[objsel][pt][fl][k].alert > 0"
                    >
                      <template v-slot:activator="{ on }">
                        <v-badge
                          :overlap="false"
                          :content="senstree[objsel][pt][fl][k].alert"
                          class="ml-2"
                        >
                          <v-icon color="red" v-on="on"
                            >mdi-alert-decagram</v-icon
                          >
                        </v-badge>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="ii in senstree[objsel][pt][fl][k].alertitem"
                          :key="ii.GUID"
                        >
                          <v-list-item-icon>
                            <v-icon color="red">mdi-alert-decagram</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            {{ new Date(ii.DeviceTime).toLocaleString("ru") }}
                            <v-list-item-subtitle>
                              {{ ii.Name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              {{ ii.Descr }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              Причина: {{ ii.T1 }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              Примечание: {{ ii.Txt }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <!-- <badgemy
                      :vis="senstree[objsel][pt][fl][k].alert > 0"
                      nameicon="mdi-alert-decagram"
                      :num="senstree[objsel][pt][fl][k].alert"
                      coloricon="red"
                      color="primary"
                      tooltip="Внимание! Опасность пожара"
                      :overlap="false"
                      class="ml-2"
                    ></badgemy> -->
                  </v-toolbar>
                  <v-list
                    shaped
                    v-if="parents[v.razdel]"
                    nav
                    dense
                    color="green lighten-5"
                  >
                    <!-- <v-list shaped > -->
                    <!-- <v-subheader>{{ k }}</v-subheader> -->
                    <v-list-item-group color="primary">
                      <v-list-item
                        v-for="raz in parents[v.razdel]"
                        :key="raz.ID"
                        dense
                      >
                        <!-- <v-list-item-content> -->
                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <span v-on="on">{{ raz.room }}</span>
                                </template>
                                {{ raz.Descr }}
                              </v-tooltip>
                              <v-spacer></v-spacer>
                              <div v-if="senstree[objsel][pt][fl][raz.room]">
                                <badgemy
                                  :vis="
                                    senstree[objsel][pt][fl][raz.room] !=
                                      undefined &&
                                    senstree[objsel][pt][fl][raz.room].cnt > 0
                                  "
                                  nameicon="mdi-account"
                                  :num="senstree[objsel][pt][fl][raz.room].cnt"
                                  coloricon="brown"
                                  color="primary"
                                  tooltip="Сколько проживает"
                                  :overlap="false"
                                  class="ml-0"
                                ></badgemy>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <!-- {{senstree[objsel][pt][fl]}} -->
                              <ul v-if="senstree[objsel][pt][fl][raz.room]">
                                <li
                                  v-for="f in senstree[objsel][pt][fl][raz.room]
                                    .arr"
                                  :key="f"
                                >
                                  <v-icon>mdi-account</v-icon> {{ f }}
                                </li>
                              </ul>
                              <!-- {{ senstree[objsel][pt][fl][raz.room].arr }} -->
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <!-- </v-list-item-content> -->
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </template>
            </td>
          </tr>
        </tbody>
        <!-- <tbody>
            <tr v-for="(v, i) in sensdata[0].children.children" :key="i" dense>
              <td style="border: 1px solid black" class="text-center">
                {{ v.name }}
              </td>
            </tr>
          </tbody> -->
        <!-- v-if="obj[v] &&  obj[v][v2] &&  Object.keys(obj[v][v2]).length != 0"       -->
        <!-- <tbody  v-if="v2 != undefined">
        <tr v-for="(v, i) in v2.children" :key="i" dense >
          <td style="border: 1px solid black" class="text-center">
            {{ v }}
          </td>
</tr> 
</tbody> -->
      </v-simple-table>
      <!-- {{ sensdata[0].children }} -->
    </v-row>
    <v-card v-if="false">
      <!-- <v-row class="align-center">
        <v-col> -->
      <!-- <v-card-title > -->
      <v-toolbar color="primary" dark>
        <!-- <v-col cols="12"> -->
        <v-icon color="white" class="mr-2">mdi-home</v-icon>
        <v-toolbar-title> Структура объектов </v-toolbar-title>
        <v-toolbar-items>
          <v-checkbox
            v-model="fullstruct"
            label="Вся структура"
            class="ml-4 ma-5"
          ></v-checkbox>
        </v-toolbar-items>
        <!-- </v-col> -->
      </v-toolbar>
      <!-- </v-card-title> -->
      <!-- </v-col>
      </v-row> -->
      <v-row>
        <v-col v-if="true">
          <v-card>
            <v-card-title class="text--primary"
              >Из 1C
              <v-switch
                v-model="addcnt1c"
                label="Количество"
                class="ml-2"
                dense
              ></v-switch>
            </v-card-title>
            <v-card-text>
              <v-row class="elevation-2">
                <v-breadcrumbs :items="bc1c">
                  <template v-slot:divider>
                    <v-icon>mdi-transfer-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-row>
              <v-sheet class="ma-2 pa-2 primary lighten-2">
                <v-text-field
                  v-model="search1c"
                  label="Поиск"
                  dark
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </v-sheet>
              <v-treeview
                :items="data"
                v-model="sel"
                :search="search1c"
                shaped
                hoverable
                activatable
              >
                <template v-slot:append="{ item }">
                  <badgemy
                    :vis="
                      addcnt1c && item != undefined && item.cnt != undefined
                    "
                    nameicon="mdi-account"
                    :num="item.cnt"
                    coloricon="brown"
                    color="primary"
                    tooltip="Сколько проживает"
                    :overlap="false"
                    class="mr-4"
                  >
                  </badgemy>
                </template>
              </v-treeview>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>
              Из ORION
              <v-switch
                v-model="addcntorion"
                label="Количество"
                class="ml-2"
                dense
              ></v-switch>
              <v-switch
                v-model="addevent"
                label="События"
                class="ml-2"
                dense
              ></v-switch>
            </v-card-title>
            <v-card-text>
              <v-row class="elevation-2">
                <v-breadcrumbs :items="bcorion">
                  <template v-slot:divider>
                    <v-icon>mdi-transfer-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-row>
              <v-sheet class="ma-2 pa-2 primary lighten-2">
                <v-text-field
                  v-model="searchorion"
                  label="Поиск"
                  dark
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </v-sheet>
              <v-treeview
                :items="sensdata"
                :search="searchorion"
                shaped
                hoverable
                activatable
              >
                <template v-slot:append="{ item }">
                  <badgemy
                    :vis="
                      addcntorion && item != undefined && item.cnt != undefined
                    "
                    nameicon="mdi-account"
                    :num="item.cnt"
                    coloricon="brown"
                    color="primary"
                    tooltip="Сколько проживает"
                    :overlap="false"
                    class="mr-4"
                  >
                  </badgemy>
                  <badgemy
                    :vis="item.fire > 0"
                    nameicon="mdi-fire"
                    :num="item.fire"
                    coloricon="red"
                    color="primary"
                    tooltip="Пожар"
                    :overlap="false"
                    class="mr-4"
                  ></badgemy>
                  <badgemy
                    :vis="item.alert > 0"
                    nameicon="mdi-alert-decagram"
                    :num="item.alert"
                    coloricon="red"
                    color="primary"
                    tooltip="Внимание! Опасность пожара"
                    :overlap="false"
                    class="mr-4"
                  ></badgemy>
                </template>
              </v-treeview>
              <!-- {{ sel }} -->
            </v-card-text>
          </v-card>
        </v-col>
        <!-- {{ data }} -->
      </v-row>
    </v-card>
    <v-dialog v-model="show1c" width="700" v-if="show1c">
      <v-card>
        <v-card-actions align-center justify-center>
          <v-spacer></v-spacer>
          <v-btn @click="show1c = false" color="primary" class=""
            >Закрыть</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <show1ctree :data1c="data" :objsel="objsel" :bc1c="bc1c"></show1ctree>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showsens" width="700" v-if="showsens">
      <v-card>
        <v-card-actions align-center justify-center>
          <v-spacer></v-spacer>
          <v-btn @click="showsens = false" color="primary" class=""
            >Закрыть</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <show1ctree
            :data1c="sensdata"
            :objsel="objsel"
            :bc1c="[
              { text: 'Общежитие' },
              { text: 'Подъезд' },
              { text: 'Этаж' },
              { text: 'Комната' },
            ]"
            :upd="upd"
          ></show1ctree>
        </v-card-text>
      </v-card>
    </v-dialog>
    <wait :progress="progress" :message="waitmsg"></wait>
    <v-snackbar v-model="sberr" color="red" top right>
      {{ strerr }}
      <v-btn @click="sberr = false" class="ml-5" fab>OK</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import { strictEqual } from "assert";
import wait from "../progress2";
import di from "../dateinterval";
import badgemy from "../sensors/badge_my.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import show1ctree from "./1c_tree.vue";
export default {
  components: {
    wait,
    badgemy,
    di,
    show1ctree,
  },
  props: {},
  data() {
    return {
      show1c: false,
      showsens: false,
      data: [],
      sensdata: [],
      k1: "level4",
      k2: "people",
      sel: [],
      progress: false,
      fullstruct: true,
      addevent: true,
      addcnt1c: true,
      addcntorion: true,
      bc1c: [{ text: "Общежитие" }, { text: "Этаж" }, { text: "Комната" }],
      bcorion: [{ text: "Общежитие" }, { text: "Этаж" }, { text: "Комната" }],
      // treeorion: ["corpus", "floor", "room"],
      treeorion: ["corpus", "part", "floor", "room"],
      tree1c: ["corpus", "floor", "room"],
      search1c: "",
      searchorion: "",
      map1c: {},
      objsel: "Общежитие 5",
      dormitory: [],
      parts: [],
      floors: [],
      senstree: {},
      parents: {},
      allrec: false,
      sday: new Date().toISOString().substring(0, 10),
      sday2: new Date().toISOString().substring(0, 10),
      rdt: [this.sday, this.sday2],
      fire: 0,
      alert: 0,
      truck: 0,
      truckitem: [],
      sgdate: "",
      waitmsg: "Подождите, идет загрузка...",
      sberr: false,
      strerr: "Ошибка",
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
    ...mapActions({
      loadFireSens: "loadFireSens",
      loadFireSensDates: "loadFireSensDates",
      loadFireSensObj: "loadFireSensObj",
      loadpObjects: "loadpObjects",
      //   loadOtv: "loadOtv",
    }),
    parse0(ss, r0) {
      let s0 = ".*" + ss + `\\.([\\dа-яА-ЯёЁ]+).*`;
      let rg = new RegExp(s0, "");
      var match0 = rg.exec(r0);
      if (match0) {
        r0 = match0[1].toLowerCase();
        // console.log(match0);
      } else {
        r0 = "?"; //+r0;
        // console.log(r0["fl"]);
      }
      return r0;
    },
    parse(tt) {
      let r0 = {};
      //let tt = "Комната №11-(50) общ.7б МК эт.1 помещ.11"
      r0["corpus"] = "Общежитие " + this.parse0("общ", tt);
      r0["part"] = "Подъезд ?";
      r0["floor"] = "Этаж " + this.parse0("эт", tt);
      // r0["group"] = "Помещение " + this.parse0("помещ", tt);
      // var match0 = /Комната\s+.?([\dа-яА-ЯёЁ]+).?\((.*)\).*/.exec(tt);
      var match0 = /(Комната\s+.?([\dа-яА-ЯёЁ]+).?\((.*)\)).*/.exec(tt);
      // var match0 = /(Комната\s+.?[\dа-яА-ЯёЁ]+.?\(.*\)).*/.exec(tt);
      //var match0 = /Комната\s+.?(\d+).*/.exec(tt);
      if (match0) {
        // r0["room"] = "Комната " + match0[1];
        // r0["roomname"] = "(" + match0[2] + ")";
        //  r0["room"] = "Комната " + match0[2]+ " -- в 1С: "+ match0[1]+"-(" + match0[2] + ")";
        // r0["room"] = "Комната " + match0[3] + " или " + match0[1];
        r0["room"] = "Комната " + match0[3].toLowerCase();
        r0["roomname"] = match0[1];
        // console.log(match0);
      } else {
        r0["room"] = "Ошибка формата. Строка: " + tt;
        r0["roomname"] = "(" + tt + ")";
        // console.log(r0["fl"]);
      }
      return r0;
    },
    fillarr(ccc, mm, tmp, pp) {
      //let qqq = {}
      // if (tmp.parents != "undefined") return;
      // if (tmp.razdel == "0") {
      //   console.log(mm[0])
      // }
      if (mm.length == 1) {
        if (!ccc[tmp[mm[0]]]) {
          ccc[tmp[mm[0]]] = {};
          ccc[tmp[mm[0]]].arr = [];
          ccc[tmp[mm[0]]].cnt = 0;
          ccc[tmp[mm[0]]].fire = 0;
          ccc[tmp[mm[0]]].alert = 0;
          ccc[tmp[mm[0]]].fireitem = [];
          ccc[tmp[mm[0]]].alertitem = [];
        }
        //console.log(pp)
        if (tmp.parents != "undefined") {
          ccc[tmp[mm[0]]].razdel = "-1";
        } else {
          ccc[tmp[mm[0]]].razdel = tmp.razdel;
        }
        // ccc[tmp[mm[0]]].razdel = tmp.razdel;

        if (pp) {
          //console.log(tmp)
          ccc[tmp[mm[0]]].arr.push(tmp[pp]);
          ccc[tmp[mm[0]]].cnt++;
        }
        return;
      }
      if (!ccc[tmp[mm[0]]]) {
        ccc[tmp[mm[0]]] = {};
        ccc[tmp[mm[0]]].cnt = 0;
        ccc[tmp[mm[0]]].fire = 0;
        ccc[tmp[mm[0]]].alert = 0;
      }
      ccc[tmp[mm[0]]].cnt++;
      if (tmp.parents != "undefined") {
        ccc[tmp[mm[0]]].razdel = "-1";
      } else {
        ccc[tmp[mm[0]]].razdel = tmp.razdel;
      }
      // if (ccc[tmp[mm[0]]].razdel == "0") {
      //   console.log(tmp)
      // }
      this.fillarr(ccc[tmp[mm[0]]], mm.slice(1, mm.length + 1), tmp, pp);
      // return ccc
    },
    fillarr1(ccc, mm, tmp) {
      //let qqq = {}
      if (mm.length == 1) {
        if (!ccc[mm[0]]) {
          ccc[mm[0]] = [];
        }
        ccc[mm[0]].push(mm[0]);
        return;
      }
      if (!ccc[mm[0]]) {
        ccc[mm[0]] = {};
      }
      this.fillarr(ccc[mm[0]], mm.slice(1, mm.length + 1), tmp);
      // return ccc
    },
    sensparse0(r0) {
      var match0 = /.*?(\d+).*/.exec(r0);
      if (match0) {
        r0 = match0[1];
        // console.log(match0);
      } else {
        r0 = "?";
        // console.log(r0["fl"]);
      }
      return r0;
    },
    sensparse(t0) {
      if (t0.Descr != "") {
        // "Общежитие №5/Блок 2/Подъезд 4/Этаж 3/Кухня левая
        let tmp = t0.Descr.split("/");
        let r0 = {};
        let m0;
        for (let i = 1; i < tmp.length; i++) {
          if (tmp[i].trim() == "") {
            tmp[i] = "?";
          }
        }
        //console.log(tmp[0])
        // if ( this.sensparse0(tmp[0]) == "0"){
        //   console.log(t0)
        // }
        r0["corpus"] = "Общежитие " + this.sensparse0(tmp[0]);
        r0["block"] = "Блок " + this.sensparse0(tmp[1]);
        r0["part"] = "Подъезд " + this.sensparse0(tmp[2]);
        r0["floor"] = "Этаж " + this.sensparse0(tmp[3]);
        // r0["group"] = "Помещение " + "?";
        //r0["room"] = "Комната "+tmp[4];
        r0["room"] = "" + tmp[4];
        r0["parents"] = "" + tmp[5];
        // r0["razdel"] = "" + t0.RazdIndex;
////        r0["razdel"] = "" + t0.GIndex;
        return r0;
      }
      let t = t0.Name;
      let reg = {};
      reg["corpus"] = /.*(\d+).*/;
      reg["floor"] = /.*(\d+)\s*эт.*/;
      reg["part"] = /.*(\d+)\s*п.*/;
      reg["room"] = /.*(прав|лев|левая|правая).*/;
      // let t = "Общ №5 3п 2эт корид прав"
      let rr = {};
      for (var key in reg) {
        var match = reg[key].exec(t);
        if (match) {
          rr[key] = match[1];
        } else {
          rr[key] = "?";
        }
      }
      return rr;
    },
    sensparse2(t0) {
      if (t0.Descr != "") {
        // "Общежитие №5/Блок 2/Подъезд 4/Этаж 3/Кухня левая
        let tmp = t0.Descr.split("/");
        let r0 = {};
        let m0;
        for (let i = 1; i < tmp.length; i++) {
          if (tmp[i].trim() == "") {
            tmp[i] = "?";
          }
        }
        // console.log(tmp.length)
        // if ( this.sensparse0(tmp[0]) == "0"){
        //   console.log(t0)
        // }
        r0["corpus"] = "Общежитие " + this.sensparse0(tmp[0]);
        r0["block"] = "Блок " + this.sensparse0(tmp[1]);
        r0["part"] = "Подъезд " + this.sensparse0(tmp[2]);
        r0["floor"] = "Этаж " + this.sensparse0(tmp[3]);
        // r0["group"] = "Помещение " + "?";
        //r0["room"] = "Комната "+tmp[4];
        if (tmp[4]) {
          r0["room"] = "" + tmp[4].trim();
        } else {
          r0["room"] = "" + tmp[4];
        }
        r0["parents"] = "" + tmp[5];
        // if (tmp[5] != "") {
          r0["razdel"] = "" + t0.RazdIndex;
        // }
        //console.log(t0.Event)
        switch (t0.Event) {
          case "37":
          case "40":
            r0["event"] = "fire";
            r0["item"] = t0;
            // this.fire++;
            break;
          case "44":
            r0["event"] = "alert";
            r0["item"] = t0;
            break;
          case "360":
            r0["event"] = "mchs";
            r0["item"] = t0;
            // console.log(t0);
            break;
          default:
            // console.log(t);
            break;
        }
        return r0;
      }
      // switch (t0.Event) {
      //   case "360":
      //     this.truck++;
      //     this.truckitem.push(t0);
      //     break;
      // }
      return null;
    },
    fillarr33(ccc, mm, tmp, pp) {
      //let qqq = {}
      if (!ccc[tmp[mm[0]]].razdel) {
        ccc[tmp[mm[0]]].razdel = tmp.razdel;
      }
      // if (!tmp.isadd) return
      if (mm.length == 1) {
        // if (pp) {
        //   //console.log(tmp)
        //   ccc[tmp[mm[0]]].arr.push(tmp[pp]);
        //   ccc[tmp[mm[0]]][pp]++;
        // }
        if (!tmp.isadd) return;
        if (tmp["event"] == "fire") {
          ccc[tmp[mm[0]]].fire++;
          if (pp) {
            ccc[tmp[mm[0]]]["fireitem"].push(tmp[pp]);
          }
          this.fire++;
        }
        if (tmp["event"] == "alert") {
          ccc[tmp[mm[0]]].alert++;
          if (pp) {
            ccc[tmp[mm[0]]]["alertitem"].push(tmp[pp]);
          }
          this.alert++;
        }
        if (tmp["event"] == "mchs") {
          this.truck++;
          this.truckitem.push(tmp[pp]);
        }
        return;
      }
      //ccc[tmp[mm[0]]].cnt++;
      //console.log(tmp)
      if (tmp.isadd) {
        if (tmp["event"] == "fire") {
          ccc[tmp[mm[0]]].fire++;
        }
        if (tmp["event"] == "alert") {
          ccc[tmp[mm[0]]].alert++;
        }
      }
      this.fillarr33(ccc[tmp[mm[0]]], mm.slice(1, mm.length + 1), tmp, pp);
      // return ccc
    },

    async sens() {
      // await this.loadpObjects();
      // console.log(this.pobjects)
      let tmp;
      let sns = {};
      let parent = {};
      // let ss = this.firesens.forEach(s => {
      this.fire = 0;
      this.alert = 0;
      this.truck = 0;
      this.truckitem = [];
      let ss = this.pobjects.forEach((s) => {
        // let mm = /Общежитие(.*)/.exec(s.Corpus)
        let mm = true;
        //console.log(mm)
        if (mm) {
          tmp = this.sensparse(s);
          tmp.razdel = s.GIndex
          // if (s.GIndex == 3920) {
          //   console.log(tmp)
          // }
          if (tmp.parents != "undefined") {
            let tmp2 = tmp.parents.split(",");
            // console.log(tmp2)
            for (let i = 0; i < tmp2.length; i++) {
              if (!parent[tmp2[i].trim()]) {
                parent[tmp2[i].trim()] = [];
              }
              //console.log(s)
              s.room = tmp.room;
              parent[tmp2[i].trim()].push(s);
              // console.log(s)
              parent[tmp2[i].trim()].sort();
              parent[tmp2[i].trim()] = parent[tmp2[i].trim()].sort((a, b) => {
                return this.cstr(a.room, b.room);
              });
            }
          }
          if (this.objsel == tmp.corpus) {
            // console.log(tmp)
            this.fillarr(sns, this.treeorion, tmp);
          }
        }
      });
      // console.log(parent);
      this.parents = parent;
      // let a = true
      // if (a) return
      if (this.addevent) {
        // await this.loadFireSens();
        // );
        // });
        // fs.forEach((f) => {
        let ppp = this;
        this.firesens.forEach((f) => {
          tmp = this.sensparse2(f);
          if (tmp != null) {
            try {
              let d1 = new Date(this.sday + " 00:00:00");
              let d2 = new Date(this.sday2 + " 23:59:59");
              let dd = new Date(f.DeviceTime);
              let ff = "Общежитие " + this.sensparse0(f.Corpus);
              tmp["isadd"] =
                ff == this.objsel && ((dd >= d1 && dd <= d2) || this.allrec);
              // this.fillarr33(sns, ["corpus", "floor", "room"], tmp);
              if (tmp["isadd"]) {
                switch (f.Event) {
                  case "360":
                    ppp.truck++;
                    ppp.truckitem.push(t0);
                    break;
                }
              }
              this.fillarr33(sns, this.treeorion, tmp, "item");
            } catch (err) {
              //console.log(tmp)
            }
          } else {
            let d1 = new Date(this.sday + " 00:00:00");
            let d2 = new Date(this.sday2 + " 23:59:59");
            let dd = new Date(f.DeviceTime);
            let ff = "Общежитие " + this.sensparse0(f.Corpus);
            // ff == this.objsel && ((dd >= d1 && dd <= d2) || this.allrec);
            // this.fillarr33(sns, ["corpus", "floor", "room"], tmp);
            if (ff == this.objsel && ((dd >= d1 && dd <= d2) || this.allrec)) {
              switch (f.Event) {
                case "360":
                  ppp.truck++;
                  ppp.truckitem.push(f);
                  break;
              }
            }
          }
        });
      }
      // console.log(sns);
      // console.log(this.tree1c, " ----- ", this.treeorion);
      this.trans(this.map1c, sns, 0, 0);
      // this.senstree = sns;
      this.parts = this.getParts(this.objsel, sns);
      this.floors = this.getFloors(this.objsel, sns);
      // console.log(sns);
      this.senstree = sns;
      this.sensdata = this.totree("", [], sns);

      // let a = true
      // if (a) { this.progress = false; return }

      // this.firesens.forEach((f) => {
      //     tmp = this.sensparse2(f);
      //     // if (tmp.parents != "?") {
      //      console.log(tmp)
      //     // }
      // })
      //  console.log(this.firesens);
    },
    getFloors(objsel, sns) {
      let floors = [];
      for (let part in sns[objsel]) {
        if (
          part == "cnt" ||
          part == "fire" ||
          part == "alert" ||
          part == "arr" ||
          part == "razdel"
        )
          continue;
        for (let floor in sns[objsel][part]) {
          if (
            floor == "cnt" ||
            floor == "fire" ||
            floor == "alert" ||
            floor == "arr" ||
            floor == "razdel"
          )
            continue;
          //   console.log(floor);
          // console.log(floors.indexOf(floor));
          if (floors.indexOf(floor) == -1) {
            floors.push(floor);
          }
          // console.log(floors);
        }
      }
      floors = floors.sort((a, b) => {
        return this.cstr(a, b);
      });
      return floors;
    },
    getParts(objsel, sns) {
      let parts = [];
      // console.log(sns[objsel])
      // if(!sns) return []
      // if(sns[objsel] == undefined) return []
      for (let part in sns[objsel]) {
        if (
          part == "cnt" ||
          part == "fire" ||
          part == "alert" ||
          part == "arr" ||
          part == "razdel"
        )
          continue;
        parts.push(part);
      }
      parts = parts.sort((a, b) => {
        return this.cstr(a, b);
      });
      return parts;
    },
    trans(t1, t2, i1, i2) {
      if (i1 >= this.tree1c.length) return;
      if (i2 >= this.treeorion.length) return;
      // console.log( ">>", i1, "-", i2, " ", this.tree1c[i1], " -> ", this.treeorion[i2] );
      for (let k in t2) {
        if (
          k == "cnt" ||
          k == "fire" ||
          k == "alert" ||
          k == "arr" ||
          k == "razdel"
        )
          continue;
        //if (typeof t2[k] !== "object") continue;
        //         while (this.tree1c[i1] != this.treeorion[i2] && i2 < this.treeorion.length) {
        //         i2++
        //         //if (i1 >= this.tree1c) continue
        // //        if (i2 >= this.treeorion) continue
        //         t2 = t2[k]
        //       }
        // if (t1[k] &&  t2[k] && t2[k].cnt && t1[k].cnt) {
        // t2[k].cnt = 0;
        // if (!t2[k]) {
        // }
        t2[k].cnt = "?";
        if (t1[k] != undefined && t2[k] != undefined) {
          //         console.log(t1[k])
          t2[k].cnt = t1[k].cnt;
          if (t1[k].arr != undefined || t2[k].arr != undefined) {
            t2[k].arr = t1[k].arr;
          }
          // console.log(k, ":", t1[k], "--", t2[k]);
          // console.log("  ??", i1,"-", i2, " ", this.tree1c[i1], " -> ", this.treeorion[i2] );
          if (this.tree1c[i1] == this.treeorion[i2]) {
            // console.log("  !!", i1, "-", i2, " ", this.tree1c[i1], " -> ", this.treeorion[i2]);
            let i11 = i1 + 1;
            let i22 = i2 + 1;
            this.trans(t1[k], t2[k], i11, i22);
          } else {
            let i22 = i2 + 1;
            // console.log(this.tree1c[i1], " -> ", this.treeorion[i2]);
            this.trans(t1[k], t2[k], i1, i22);
          }
        } else {
          let i22 = i2 + 1;
          // console.log("    ##",k,this.tree1c[i1], " -> ", this.treeorion[i2]);
          this.trans(t1, t2[k], i1, i22);
        }
      }
    },
    trans0(t1, t2) {
      for (let k in t2) {
        // if (k == "cnt" || k == "fire" || k == "alert" || k == "arr") continue;
        if (typeof t2[k] !== "object") continue;
        // console.log(k, " -> ", t1[k]);
        // if (t1[k] &&  t2[k] && t2[k].cnt && t1[k].cnt) {
        t2[k].cnt = 0;
        if (t1[k] != undefined && t2[k] != undefined) {
          t2[k].cnt = t1[k].cnt;
          if (t1[k].arr != undefined || t2[k].arr != undefined)
            t2[k].arr = t1[k].arr;
          this.trans(t1[k], t2[k]);
        }
      }
    },
    async start() {
      this.progress = true;
      // await this.sens();
      // let k1 = "level4";
      // let k2 = "people";
      let k1 = "roominfo";
      let k2 = "residinginfo";
      let url = "sg.json";
      let resp;
      let sg;
      this.waitmsg = "Загрузка списка объектов...";
      await this.loadFireSensObj();
      if (this.error != "") {
        this.strerr = "Ошибка загрузки списка объектов: " + this.error;
        this.sberr = true;
        //        this.progress = false;
      }
      this.waitmsg = "Загрузка информации об объектах...";
      await this.loadpObjects();
      if (this.error != "") {
        this.strerr = "Ошибка загрузки информации об объектах: " + this.error;
        this.sberr = true;
        //        this.progress = false;
      }
      this.waitmsg = "Загрузка событий...";
      await this.loadFireSens();
      if (this.error != "") {
        this.strerr = "Ошибка загрузки событий: " + this.error;
        this.sberr = true;
      }

      //console.log(this.firesensobj)
      this.dormitory = this.firesensobj.filter((d) => {
        return this.sensparse0(d) != "?";
      });
      this.dormitory = this.dormitory.map((f) => {
        return "Общежитие " + this.sensparse0(f);
        // console.log(f, "   ",this.sensparse0(f))
      });
      this.dormitory = this.dormitory.sort((a, b) => {
        return this.cstr(a, b);
      });

      // console.log(this.dormitory)
      this.waitmsg = "Загрузка из 1С...";
      try {
        resp = await fetch(url);
        sg = await resp.json();
      } catch (err) {
        this.strerr = "Ошибка загрузки из 1С: " + err;
        this.sberr = true;
      }
      // console.log(sg)
      sg = sg.data;
      let ccc = {};
      this.data = [];
      sg.forEach((s) => {
        let tmp = {};
        tmp[k1] = s[k1];
        tmp[k2] = s[k2];
        tmp = this.parse(s[k1]);
        tmp[k2] = s[k2];

        this.fillarr(ccc, this.tree1c, tmp, k2);
      });
      // console.log(ccc)
      // console.log(this.totree("kbsu",[],ccc))
      // this.waitmsg = "Отображение данных...";
      this.map1c = ccc;
      this.data = this.totree("kbsu", [], ccc);
      // await this.sens();
      await this.show();
      this.progress = false;
    },
    async show() {
      this.waitmsg = "Отображение данных...";
      // console.log(this.bcorion)
      this.progress = true;
      // this.waitmsg = "Обновление информации...";
      await this.sens();
      this.progress = false;
    },
    totree(id, arr, ob) {
      // console.log(ob)
      if (ob && ob.arr && Array.isArray(ob.arr)) {
        let str = [];
        ob.arr.forEach((p) => {
          str.push({
            id: id + "," + p,
            cnt: ob.cnt,
            name: p,
          });
        });
        // let str = "Жильцов: " + ob.arr.length
        return [{ id: id, name: "Жильцов: " + ob.arr.length, children: str }];
      }
      //let tid = id
      for (let kk in ob) {
        if (kk == "cnt" || kk == "fire" || kk == "alert" || kk == "razdel")
          continue;
        let nid = id + "," + kk;
        //nid = id+ (id*Object.keys(ob).length)+1
        arr.push({
          id: nid,
          name: kk,
          children: this.totree(nid, [], ob[kk]),
          cnt: ob[kk].cnt,
          fire: ob[kk].fire,
          alert: ob[kk].alert,
        });
      }
      //return arr
      return arr.sort((a, b) => {
        return this.cstr(a.name, b.name);
        //return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      });
    },
    cstr(a, b) {
      let m1 = /(.*?)(\d+).*/.exec(a);
      let m2 = /(.*?)(\d+).*/.exec(b);
      let r1, r2;
      let z1, z2;
      //console.log(m1[1])
      if (m1 && m2) {
        let n1 = m1[2] + "";
        let n2 = m2[2] + "";
        //console.log(n1, " ",n1.length)
        z1 = n1;
        z2 = n2;
        //n1 = Number(n1)
        //n2 = Number(n2)
        //console.log(n1, " ", n2, " ",n1.length-n2.length)
        if (n1.length > n2.length) {
          for (let i = 0; i < n1.length - n2.length; i++) {
            z2 = "0" + z2;
          }
        } else {
          for (let i = 0; i < n2.length - n1.length; i++) {
            z1 = "0" + z1;
          }
        }
        r1 = m1[1] + z1;
        r2 = m2[1] + z2;
      } else {
        r1 = a;
        r2 = b;
      }
      // console.log(r1, " ", r2)
      return r1 > r2 ? 1 : r1 < r2 ? -1 : 0;
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
    ...mapState({
      firesens: (state) => state.firesens,
      // firesensdates: (state) => state.firesensdates,
      pobjects: (state) => state.pobjects,
      error: (state) => state.error,
      firesensobj: (state) => state.firesensobj,
      //   otv: (state) => state.otv,
      //      nogroup: (state) => state.authm.nogroup,
    }),
  },
  watch: {
    addevent: function () {
      this.start();
    },
    fullstruct: function () {
      if (this.fullstruct) {
        this.bc1c = [
          { text: "Общежитие" },
          { text: "Этаж" },
          // { text: "Помещение" },
          { text: "Комната" },
        ];
        this.bcorion = [
          { text: "Общежитие" },
          // { text: "Блок" },
          { text: "Подъезд" },
          { text: "Этаж" },
          { text: "Комната" },
        ];

        // this.treeorion = ["corpus", "block", "part", "floor", "room"];
        this.treeorion = ["corpus", "part", "floor", "room"];
        this.tree1c = ["corpus", "floor", "room"];

        // this.bc1c = ["","","","",""]
      } else {
        this.bc1c = [
          { text: "Общежитие" },
          { text: "Этаж" },
          { text: "Комната" },
        ];
        this.bcorion = [
          { text: "Общежитие" },
          { text: "Этаж" },
          { text: "Комната" },
        ];
        this.treeorion = ["corpus", "floor", "room"];
        this.tree1c = ["corpus", "floor", "room"];
      }
      this.show();
    },
    objsel: function () {
      this.show();
    },
    allrec: function () {
      this.show();
    },
  },
  mounted() {
    let d0 = new Date();
    d0.setDate(d0.getDate() - 30);
    this.sday = d0.toISOString().substring(0, 10);
    let d1 = new Date();
    //    d1.setDate(d1.getDate() - 2);
    d1.setDate(d1.getDate());
    this.sday2 = d1.toISOString().substring(0, 10);
    // this.sday2 = this.sday;
    this.rdt = [this.sday, this.sday2];
    this.start();
  },
};
</script>
