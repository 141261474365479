<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card>
      <v-card-title> Границы </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-row>
                <v-text-field
                  v-model="b.tmin"
                  hint="Температура (нижняя граница)"
                  label="Температура (нижняя граница)"
                ></v-text-field>
                <v-text-field
                  v-model="b.tmax"
                  hint="Температура (верхняя граница)"
                  label="Температура (верхняя граница)"
                  class="ml-2"
                ></v-text-field>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-text-field
                  v-model="b.hmin"
                  hint="Влажность (нижняя граница)"
                  label="Влажность (нижняя граница)"
                ></v-text-field>
                <v-text-field
                  v-model="b.hmax"
                  hint="Влажность (верхняя граница)"
                  label="Влажность (верхняя граница)"
                  class="ml-2"
                ></v-text-field>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-text-field
                  v-model="b.irmin"
                  hint="ИК (нижняя граница)"
                  label="ИК (нижняя граница)"
                ></v-text-field>
                <v-text-field
                  v-model="b.irmax"
                  hint="ИК (верхняя граница)"
                  label="ИК (верхняя граница)"
                  class="ml-2"
                ></v-text-field>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" @click="save()">Сохранить</v-btn>
        <v-btn color="" @click="cancel()">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    bounds: Object,
    show: Boolean,
  },
  data() {
    return {
      b: {},
    };
  },
  methods: {
    // ...mapActions(["loadSettings", "LoadObjects"]),
    save() {
      this.$emit("dsave",this.b);
    },
    cancel() {
      this.$emit("dcancel");
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
  },
  mounted() {
    this.b = JSON.parse(JSON.stringify(this.bounds));
  },
};
</script>
