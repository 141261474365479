<template>
  <v-container>
    <!-- {{ismodif}} -->
    <v-row>
      <v-col cols="12">
        <v-btn @click="fsave()" color="primary" :disabled="!ismodif"
          >Сохранить</v-btn
        >
        <v-btn @click="refresh()" class="ml-3" :disabled="!ismodif"
          >Отмена</v-btn
        >
        <v-btn fab small class="ml-4" color="primary" @click="refresh()">
          <v-progress-circular
            size="24"
            indeterminate
            color="white"
            class=""
            v-if="progress"
          >
          </v-progress-circular>
          <v-progress-circular
            size="24"
            value="0"
            color="white"
            class=""
            v-else
          ></v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="iserr" class="align-center justify-center">
      <v-alert type="error">
        <v-progress-circular
          indeterminate
          size="24"
          class="ml-2 mr-2"
          color="white"
        ></v-progress-circular>
        Данные не загружены, обновите страницу...
      </v-alert>
    </v-row>
    <v-row>
      <v-btn
        @click="boundsall('all')"
        color="primary"
        :disabled="iserr"
        v-if="kortexall"
      >
        <v-icon class="mr-2">mdi-arrow-expand-horizontal</v-icon>
        Границы для всех объектов</v-btn
      >
    </v-row>
    <v-row v-if="kortexall" dense>
      <v-col cols="12">
        <template v-for="(krt, nn) in kortexall.kortexdevs">
          <v-row :key="krt.ip" v-if="nn != 'all'" dense>
            <v-col cols="12">
              <v-card
                class="elevation-2"
                color="light-green lighten-5"
                :disabled="iserr"
              >
                <v-speed-dial
                  top
                  left
                  direction="right"
                  :open-on-hover="false"
                  transition="slide-x-transition"
                  dark
                  absolute
                  fab
                  x-small
                  style="top: 0; left: 0"
                  dense
                >
                  <template v-slot:activator>
                    <v-btn v-model="fab" fab x-small>
                      <!-- <v-icon v-if="fab"> {{ picon() }} </v-icon> -->
                      <iconmy
                        nameicon="mdi-card-bulleted-settings"
                        coloricon="brown"
                        tooltip="Дополнительные функции"
                      ></iconmy>
                    </v-btn>
                  </template>
                  <v-btn
                    fab
                    x-small
                    color="green"
                    :disabled="krt.bounds != undefined"
                    @click="boundsset(krt)"
                  >
                    <iconmy
                      nameicon="mdi-table-column-plus-after"
                      coloricon="white"
                      tooltip="Переключить на индивидуальные настройки"
                    ></iconmy>
                  </v-btn>
                  <v-btn
                    fab
                    x-small
                    color="red"
                    :disabled="krt.bounds == undefined"
                    @click="boundsdel(krt)"
                  >
                    <iconmy
                      nameicon="mdi-table-column-remove"
                      coloricon="white"
                      tooltip="Удалить индивидуальные настройки"
                    ></iconmy>
                  </v-btn>
                </v-speed-dial>
                <v-card-title class="ml-7">{{ nn }} </v-card-title>
                <!-- <v-card-subtitle>{{ krt.ip }}</v-card-subtitle> -->
                <v-card-subtitle class="mb-4">
                  <v-row class="align-center text-center " dense>
                  {{ krt.ip }}, {{ krt.room }}
                  </v-row>
                  <v-row class="align-center" dense>
                    <v-switch
                      v-model="krt.manual"
                      class="ml-3"
                      @click="ismodif = true"
                    >
                      <template v-slot:label>Ручной режим</template>
                    </v-switch>
                    <v-switch
                      v-model="krt.holidaynow"
                      class="ml-3"
                      color="red"
                      @click="ismodif = true"
                    >
                      <template v-slot:label color="text--red"
                        >Выходной</template
                      >
                    </v-switch>
                  </v-row>
                  <!-- </v-card-subtitle>
                <v-card-subtitle> -->
                  <v-row class="align-center" dense>
                    <v-btn
                      fab
                      color="blue"
                      class="ml-4 white--text"
                      small
                      @click="bounds(nn, krt)"
                      v-if="krt.bounds"
                    >
                      <!-- <v-icon>mdi-arrow-expand-horizontal</v-icon> -->
                      <iconmy
                        nameicon="mdi-arrow-expand-horizontal"
                        coloricon="white"
                        tooltip="Границы режимов работы"
                      ></iconmy>
                    </v-btn>
                    <v-btn
                      fab
                      color="black"
                      class="ml-2 orange--text"
                      small
                      @click="graf(nn, krt)"
                    >
                      <!-- <v-icon>mdi-chart-line</v-icon> -->
                      <iconmy
                        nameicon="mdi-chart-line"
                        coloricon="orange"
                        tooltip="Графики"
                      ></iconmy>
                    </v-btn>
                  </v-row>
                </v-card-subtitle>
                <v-card-actions>
                  <!-- <pipe
                    :pstate="{
                      data: kortexall.kortexs[nn].oids,
                      param: kortexall.kortexs[nn].kortex,
                      state: kortexall.kortexs[nn].state,
                    }"
                    :name="nn"
                  ></pipe> -->
                </v-card-actions>
                <pipe :pstate="kortexall.kortexs[nn]" :name="nn"></pipe>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <!-- {{ JSON.stringify(kortexall, "", 4)}} -->
        <!-- <v-textarea
          :value="JSON.stringify(kortexall, '', 4)"
          auto-grow
          @change="ch($event)"
        ></v-textarea> -->
      </v-col>
    </v-row>
    <v-snackbar v-model="sbok" right top color="green" :timeout="5000">
      <v-icon>mdi-information-outline</v-icon>
      {{ sboktext }}
      <v-spacer></v-spacer>
      <template v-slot:action="{}">
        <v-icon @click="sbok = false">mdi-close-circle</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar v-model="sberr" right top color="red" :timeout="5000">
      <v-icon>mdi-information-outline</v-icon>
      {{ sberrtext }}
      <v-spacer></v-spacer>
      <template v-slot:action="{}">
        <v-icon @click="sberr = false">mdi-close-circle</v-icon>
      </template>
    </v-snackbar>
    <v-dialog v-model="dbounds" persistent>
      <v-card>
        <v-card-text>
          <v-row class="">
            <v-col cols="12">
              <cbounds
                :kdev="kortexall.kortexdevs[boundsnow]"
                :all="kortexall.kortexdevs.all"
                v-if="dbounds"
              ></cbounds>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions align-center justify-center>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              dbounds = false;
              fsave();
              ismodif = false;
            "
            color="green"
            class="white--text"
            >Сохранить</v-btn
          >
          <v-btn
            @click="
              dbounds = false;
              refresh();
            "
            color="red"
            class="white--text"
            >Отмена</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import pipe from "../kortex/kortex.vue";
import cbounds from "./param.vue";
import iconmy from "../sensors/icon_my.vue";
export default {
  components: {
    pipe,
    cbounds,
    iconmy,
  },
  props: {},
  data() {
    return {
      fname: "kortexdevs.json",
      sbok: false,
      sboktext: "Файл сохранен.",
      sberr: false,
      sberrtext: "Ошибка сохранения файла.",
      progress: false,
      ismodif: false,
      dbounds: false,
      boundsnow: "",
      iserr: false,
      fab: null,
    };
  },
  methods: {
    ...mapActions(["loadKortexall"]),
    boundsset(kk) {
      // console.log(kk);
      // console.log(this.kortexall);
      if (confirm("Установить индивидуальные настройки?")) {
        kk.bounds = this.kortexall.kortexdevs.all.bounds;
        kk.boundsnow = true;
        this.ismodif = true;
        // console.log("Да!");
      }
    },
    boundsdel(kk) {
      if (confirm("Удалить индивидуальные настройки?")) {
        delete kk.bounds;
        kk.boundsnow = false;
        this.ismodif = true;
        // console.log("Да!");
      }
    },
    bounds(nn, kk) {
      this.boundsnow = nn;
      this.dbounds = true;
    },
    boundsall(nn, kk) {
      this.boundsnow = nn;
      this.dbounds = true;
    },
    graf(nn, krt) {
      let url;
      // if (this.name == "ИТФ") {
      url =
        // "http://us.kbsu.ru:3000/d/JmIsv9Dnk3/datchiki-v-kabinete-plius-zadvizhka?orgId=1&refresh=1m";
        "http://us.kbsu.ru:3000/d/JmIsv9Dnk3/datchiki-v-kabinete-plius-zadvizhka?orgId=1&var-corpus=" +
        nn +
        "&var-room=" +
        krt.room +
        "&var-kortexip=" +
        krt.ip;
      ("&refresh=1m&from=now-3h&to=now");
      // "172.16.15.801";
      // console.log(this.name)
      // console.log(url)
      window.open(url, "_blank");
    },
    async refresh() {
      this.progress = true;
      // console.log("Start")
      await this.loadKortexall();
      // console.log(this.error)
      if (this.error != "") {
        this.sberr = true;
        this.ismodif = false;
        this.sberrtext = this.error;
        this.iserr = true;
      } else {
        this.sbok = true;
        this.sboktext = "Данные загружены!";
        this.ismodif = false;
        this.iserr = false;
      }
      // console.log("End")
      this.progress = false;
    },
    async fsave() {
      let snd;
      snd = {
        filename: this.fname,
        data: JSON.stringify(this.kortexall.kortexdevs),
      };
      let url;
      url = this.sensors_url + this.sensors_port; // + "/";
      url += "/saveparam";
      // url = this.urlcsv+"/save";
      console.log(url);
      // console.log(snd);
      // console.log(JSON.stringify(snd));
      let res;
      try {
        res = await fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=utf-8",
            // Accept: "text/csv",
            // "Content-Type": "text/csv",
          },
          method: "POST",
          body: JSON.stringify(snd),
          // body: csv,
        });
      } catch (err) {
        this.sberr = true;
        console.log(err);
      }
      // console.log(res);
      // .then(function (res) {
      // ppp.saveok = true;
      // console.log(res);
      // ppp.resval = res;
      if (res.ok) {
        let dd = await res.json();
        if (dd.status == "success") {
          this.sbok = true;
          this.sboktext = "Настройки сохранены.";
          this.ismodif = false;
          // this.$emit("chng", false);
        } else {
          this.sberr = true;
          this.ismodif = false;
          this.sberrtext = dd.error;
          // this.$emit("chng", false);
        }
        // console.log(dd);
      }
      // })
    },
    kinfo(ip, nn) {
      this.$router.push({
        name: "kortexcontrol",
        params: { ip: ip, cname: nn },
      });
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    ...mapState(["kortexall", "sensors_url", "sensors_port", "error"]),
  },
  // watch:{
  // },
  async mounted() {
    this.ismodif = false;
    this.refresh();
    // await this.loadKortexall();
  },
};
</script>
