<template>
  <v-container>
    <!-- <v-row> -->
    <!-- <v-col cols="12"> -->
    <v-row>
      <v-btn @click="fsave()" color="primary">Сохранить</v-btn>
      <v-btn @click="init()" class="ml-3">Отмена</v-btn>
    </v-row>
    <v-row>
      <v-tabs>
        <v-tab>JSON</v-tab>
        <v-tab-item>
          <!-- <v-textarea :value="JSON.stringify(survey)" auto-grow></v-textarea> -->
          <!-- <v-textarea v-model="JSON.stringify(survey)" auto-grow></v-textarea> -->
          <v-textarea
            :value="JSON.stringify(survey,'',4)"
            auto-grow
            @change="ch($event)"
          ></v-textarea>
        </v-tab-item>
      </v-tabs>
    </v-row>
    <v-snackbar v-model="sbok" right top color="green" :timeout="5000">
      <v-icon>mdi-information-outline</v-icon>
      {{ sboktext }}
      <v-spacer></v-spacer>
      <template v-slot:action="{}">
        <v-icon @click="sbok = false">mdi-close-circle</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar v-model="sberr" right top color="red" :timeout="5000">
      <v-icon>mdi-information-outline</v-icon>
      {{ sberrtext }}
      <v-spacer></v-spacer>
      <template v-slot:action="{}">
        <v-icon @click="sberr = false">mdi-close-circle</v-icon>
      </template>
    </v-snackbar>
    <!-- <v-icon @click="addPage()">mdi-plus</v-icon> -->
    <!-- <v-row>
          {{ survey }}
        </v-row> -->
    <!-- </v-col> -->
    <!-- </v-row> -->
  </v-container>
</template>
<script>
 import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {
  },
  props: {
    survey1: {
      type: Object,
      default: function () {
        return {
          pages: [
            {
              name: "Страница1",
              elements: [{ name: "Вопрос1", title: "", choices: [] }],
            },
          ],
        };
      },
    },
    // upd: Number,
  },
  data() {
    return {
      tip: ["radiogroup", "checkbox", "rating"],
      survey: {},
      tmpsurvey: {},
      upd: 0,
      fname: "kortexdevs.json",
      sbok: false,
      sboktext: "Файл сохранен.",
      sberr: false,
      sberrtext: "Ошибка сохранения файла.",
    };
  },
  methods: {
    ch(vv) {
      //  console.log(vv)
      this.survey = JSON.parse(vv);
      //  this.survey = vv
      this.upd++;
      //  this.$emit('eupd')
    },
    async fsave() {
      let snd;
      snd = { filename: this.fname, data: JSON.stringify(this.survey) };
      let url;
      url = this.sensors_url + this.sensors_port // + "/";
      url += "/saveparam";
      // url = this.urlcsv+"/save";
      console.log(url);
      // console.log(snd);
      // console.log(JSON.stringify(snd));
      let res;
      try {
        res = await fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=utf-8",
            // Accept: "text/csv",
            // "Content-Type": "text/csv",
          },
          method: "POST",
          body: JSON.stringify(snd),
          // body: csv,
        });
      } catch (err) {
        this.sberr = true;
        console.log(err);
      }
      console.log(res);
      // .then(function (res) {
      // ppp.saveok = true;
      // console.log(res);
      // ppp.resval = res;
      if (res.ok) {
        let dd = await res.json();
        if (dd.status == "success") {
          this.sbok = true;
          this.sboktext = "Файл сохранен.";
          this.ismodif = false;
          // this.$emit("chng", false);
        } else {
          this.sberr = true;
          this.ismodif = false;
          // this.$emit("chng", false);
        }
        console.log(dd);
      }
      // })
    },
    async init() {
      if (this.fname == undefined || this.fname == null) {
        console.log(this.fname);
        return;
      }
      //var Papa = require("papaparse");
      let url;
      // url = "http://localhost:2021/getcsv?fn=" + this.fname;
      url = this.sensors_url + this.sensors_port // + "/";
//      url="http://localhost:2021"
      url += "/getparam?fn=" + this.fname;
       console.log(url)
      let ff;
      ff = await fetch(url);
      let file;
      // = "csv/gaz.csv";
      file = await ff.text();
      // console.log(file)
      this.ch(file)
    },
  },
    computed: {
      ...mapState(["sensors_url", "sensors_port"]),
    },
  async mounted() {
    await this.init()
    // this.survey = this.survey1;
    // console.log(this.survey);
  },
};
</script>