<template>
  <div :id="id">Нет данных</div>
</template>
<script>
export default {
  components: {},
  props: {
    height: Number,
    colorgr: String,
    //      width: Number,
    grd: Array,
    id: String,
    marks: {
      type: Boolean,
      default: false,
    },
    razm: {
      type: String,
      default: "м3",
    },
  },
  data() {
    return {
      //        id: ""
      width: 500,
      //      height: 400,
      //   grd: [
      //     { x: 1, y: 50, xlabel: "H1" },
      //     { x: 2, y: 60 },
      //     { x: 3, y: 30, xlabel: "H4" },
      //     { x: 4, y: 40 }
      //   ],
      //      colorgr: "red",
      fsize: 16,
      lsize: 12,
    };
  },
  methods: {
    draw() {
      if(this.grd.length < 1) {
        return
      }
      let ppp = this;
      let height = this.height;
      let width = this.width;
      let margin = 60;
      width = d3
        .select("#" + this.id)
        .node()
        .getBoundingClientRect().width;
      //  height = d3
      //     .select("#gr")
      //     .node()
      //     .getBoundingClientRect().height;

      d3.select("#" + this.id).text("");
      var svg = d3
        .select("#" + this.id)
        .append("svg")
        .attr("class", "axis")
        .attr("width", width)
        .attr("height", height);

      // длина оси X= ширина контейнера svg - отступ слева и справа
      var xAxisLength = width - 2 * margin;

      // длина оси Y = высота контейнера svg - отступ сверху и снизу
      var yAxisLength = height - 2 * margin;
      // находим максимальное значение для оси x
      // var maxValue = d3.max(this.grd, function(s) {
      //   return d3.max(s, function(d) {
      //     return d.y;
      //   });
      // });
      // находим минимальное значение для оси Y
      // var minValue = d3.min(this.grd, function(s) {
      //   return d3.min(s, function(d) {
      //     return d.y;
      //   });
      // });

      // функция интерполяции значений на ось Х
      // var scaleX0 = d3.scale
      //   .linear()
      //   .domain([
      //     d3.min(this.grd, function(d) {
      //       return d.x;
      //     }),
      //     d3.max(this.grd, function(d) {
      //       return d.x;
      //     })
      //   ])
      //   .range([0, xAxisLength]);

      // var scaleX = d3.scale
      //   .ordinal()
      //   // .linear()
      //   .domain(
      //     ppp.grd.map(function (d) {
      //       if (d.xlabel != undefined) {
      //         return d.x;
      //       } else {
      //         return d.x;
      //       }
      //     })
      //   )
      //   .rangeRoundPoints([0, xAxisLength]);
      //                 console.log(this.grd[0].x)
      var scaleX = d3.time
        .scale()
        // .domain([new Date(2021, 3, 9), new Date(2021, 3, 10)])
        .domain([this.grd[0].x, this.grd[this.grd.length - 1].x])
        .range([0, xAxisLength]);

      let ylow = 0;
      // if( ppp.razm.indexOf("гр.С") != -1)
      // {
      //   ylow= -50
      // }
      // функция интерполяции значений на ось Y
      var scaleY = d3.scale
        .linear()
        .domain([
          d3.max(this.grd, function (d) {
            return d.y;
          }),
          ylow,
          //   d3.min(this.grd, function(d) {
          //     return d.y;
          //   })
        ])
        .range([0, yAxisLength]);

      // создаем ось X
      var xAxis = d3.svg
        .axis()
        .scale(scaleX)
        .orient("bottom")
        // .ticks(this.grd.length)
        .ticks(24);
      // .tickFormat(function (d) {
      //   console.log(d.toISOString().substr(0, 16))
      //   return d.toISOString().substr(0, 16);
      // });
      //       .attr("font-size","40")
      // .text(function(d){
      //     return d
      // })
      // xAxis.ticks(24)

      // создаем ось Y
      var yAxis = d3.svg.axis().scale(scaleY).orient("left").ticks(2);

      // отрисовка оси Х
      svg
        .append("g")
        .attr("class", "x-axis0")
        .attr(
          "transform", // сдвиг оси вниз и вправо
          "translate(" + margin + "," + (height - margin) + ")"
        )
        .call(xAxis)
        .style("stroke", "black")
        .style("fill", "none")
        .style("stroke-width", 1)
        .selectAll("text")
        .text(function (tt) {
          // if (tt.length > 2) {
          //   return tt.substr(0, 5);
          // } else {
          // return tt.toISOString().substr(0, 10)+" "+tt.toISOString().substr(11, 5);
          //return tt
          return tt.toISOString().substr(2, 8) + " " + tt.toLocaleString().substr(11, 6);
          // }
        })
        .attr("transform", "translate(-10,50)rotate(-90)")
        .style("text-anchor", "start")
        .style("font-size", "12")
        .style("stroke", "none")
        .style("fill", "black")

      // .style("fill", function (d) {
      //   if (d.length > 2) {
      //     let ss =
      //       d.substr(6, 4) + "-" + d.substr(3, 2) + "-" + d.substr(0, 2);
      //     let dt = new Date(ss);
      //     let dw = dt.getDay();
      //     //         console.log(ss+"->"+dw)
      //     if (dw == 0 || dw == 6) {
      //       return "red";
      //     } else {
      //       return "black";
      //     }
      //   } else {
      //     return "black";
      //   }
      // });

      // отрисовка оси Y
      svg
        .append("g")
        //        .attr("class", "y-axis")
        .attr(
          "transform", // сдвиг оси вниз и вправо на margin
          "translate(" + margin + "," + margin + ")"
        )
        .call(yAxis)
        .style("stroke", "black")
        .style("fill", "none")
        .style("stroke-width", 1)
        .selectAll("text")
        .style("font-size", ppp.fsize);

      // создаем набор вертикальных линий для сетки
      d3.selectAll("g.x-axis g.tick")
        .append("line") // добавляем линию
        // .style("stroke", "grey")
        // .style("fill", "none")
        // .style("stroke-width", 1)
        .classed("grid-line", true) // добавляем класс
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", 0)
        .attr("y2", -(height - 2 * margin));

      // рисуем горизонтальные линии
      d3.selectAll("g.y-axis g.tick")
        .append("line")
        .classed("grid-line", true)
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", xAxisLength)
        .attr("y2", 0);
      // createChart(usdData, "blue", "usd");
      //      let mm = ["p3", "p2", "p3", "p4", "p5"];
      //      for (var i = 0; i < this.colorgr.length; i++) {
      createChart(this.grd, this.colorgr);

      // общая функция для создания графиков
      function createChart(data, colorStroke, label) {
        let sm = 0;
        //        let mlen = data.length;
        //        console.log(mlen)
        // функция, создающая по массиву точек линии
        var line = d3.svg
          .line()
          .x(function (d, i) {
            if (i != 0) {
              return scaleX(d.x) + margin - sm;
            } else {
              return scaleX(d.x) + margin;
            }
          })
          .y(function (d) {
            return scaleY(d.y) + margin;
          });

        var g = svg.append("g");
        g.append("path")
          .attr("d", line(data))
          .style("stroke", colorStroke)
          .style("fill", "none")
          .style("stroke-width", 3);

        svg
          .append("text")
          .attr("x", margin / 2 + 5)
          .attr("y", margin / 2)
          .style("text-anchor", "middle")
          .style("fill", "black")
          .style("font-size", ppp.fsize)
          .text(ppp.razm);

        if (!ppp.marks) return;
        // добавляем отметки к точкам
        svg
          .selectAll(".dot " + label)
          .data(data)
          .enter()
          .append("circle")
          .style("stroke", colorStroke)
          .style("fill", "white")
          .attr("class", "dot " + label)
          .attr("r", 5)
          .attr("cx", function (d, i) {
            //            if(i !=0 && i != mlen-1)
            if (i != 0) {
              return scaleX(d.x) + margin - sm;
            } else {
              return scaleX(d.x) + margin;
            }
          })
          .attr("cy", function (d) {
            return scaleY(d.y) + margin;
          });

        svg
          .selectAll(".dot " + label)
          .data(data)
          .enter()
          .append("text")
          .attr("x", function (d, i) {
            if (i != 0) {
              return scaleX(d.x) + margin - sm;
            } else {
              return scaleX(d.x) + margin;
            }
          })
          .attr("y", function (d) {
            return scaleY(d.y) + margin - 10;
          })
          .style("text-anchor", "middle")
          //          .style("text-anchor", "right")
          ////          .style("fill", "white")
          .style("fill", "black")
          //          .style("font-size", ppp.fsize)
          .style("font-size", ppp.lsize)
          //        .style("fill", "yellow")
          .text(function (d) {
            return d.y;
          });
      }
    },
  },
  mounted() {
    this.draw();
  },
  watch: {
    grd: function () {
      this.draw();
    },
  },
};
</script>
