<template>
  <div>
    <v-card dense color="grey lighten-4">
      <!-- <v-toolbar dense floating absolute class="justify-end" >
            <v-btn icon>
              <v-icon>mdi-crosshairs-gps</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar> -->
      <v-speed-dial
        v-if="false"
        right
        top
        absolute
        style="z-index: 999; margin-top: 50px; margin-right: 0px"
        direction="bottom"
      >
        <template v-slot:activator>
          <v-btn color="primary" fab><v-icon>mdi-printer</v-icon></v-btn>
        </template>
        <!-- <v-icon>mdi-floor-plan</v-icon> -->
        <v-btn @click="dlgsel = true" color="indigo" dark
          ><v-icon>mdi-plus</v-icon> Добавить</v-btn
        >
        <!-- <template v-for="(dd, ii) in data">
        <v-btn :key="ii" dark small color="indigo" @click="$emit('floornum', ii)">
          <v-icon>mdi-numeric-{{ii+1}}-box</v-icon>
          <v-icon>mdi-floor-plan</v-icon>
          {{ getFloor(dd.name) }}
        </v-btn>
      </template> -->
      </v-speed-dial>
      <v-card-text @click.stop="show" id="cardtext">
        <!-- <v-navigation-drawer
        permanent
        expand-on-hover
        right
      >
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </v-navigation-drawer> -->
        <plan
          ref="pln2"
          :www="www"
          :hhh="hhh"
          :zoom="zm"
          :data="data"
          :floors="floors"
          divid="divflr0"
          :key2="key2"
          :key3="fUpd"
          v-on:markerclick="mclick($event)"
          v-on:markerdblclick="mdblclick($event)"
          v-on:prnmoved="prnmove()"
          v-on:pickfloor="pickfloor($event)"
        >
        </plan>
      </v-card-text>
      <v-card-actions>
        <!-- {{data}} -->
        <!-- <v-btn @click="dlgsel = true">Добавить</v-btn> -->
        <v-spacer></v-spacer>
        <v-btn
          @click="
            $emit('prncancel');
            ismodified = false;
            fUpd += 1;
          "
          v-if="mustsave"
          >Отмена</v-btn
        >
        <!-- prnupd(); -->
        <v-btn
          @click="
            $emit('prnsave');
            ismodified = false;
            dlgsend = false;
          "
          v-if="mustsave"
          color="primary"
          >Сохранить</v-btn
        >
        <!-- <v-btn
          color="primary"
          @click.stop="
            dialog = false;
            showMenu = false;
            $emit('dlgclose');
          "
        >
          OK
        </v-btn> -->
      </v-card-actions>
    </v-card>
    <v-dialog persistent v-model="dlgsel" width="500px">
      <prnselect
        v-on:prnsel="
          dlgsel = false;
          if ($event != '') {
            printer = $event;
            prnadd($event);
          }
        "
      ></prnselect>
    </v-dialog>
    <v-dialog persistent v-model="dlgsend">
      <v-card>
        <v-card-title>Данные этажа</v-card-title>
        <v-card-text class="text-h5">
          {{ data }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dlgsend = false">OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
      <v-list>
        <v-list-item @click="dlgsel = true">
          <v-list-item-title>Добавить</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu v-model="showMenuMarker" :position-x="x" :position-y="y" absolute offset-y>
      <v-list>
        <!-- <v-list-item @click="prninfo()">
          <v-list-item-title>PrintInfo</v-list-item-title>
        </v-list-item> -->
        <v-list-item @click="prndel()">
          <v-list-item-title>Удалить</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapState } from "vuex";
import plan from "./plan";
import prnselect from "./prnselect";
export default {
  components: {
    plan,
    prnselect,
  },
  props: {
    www: { type: Number, default: 300 },
    hhh: { type: Number, default: 300 },
    // dlg: { type: Boolean, default: false },
    data: {
      type: Object,
      default: undefined,
    },
    ismod: {
      type: Boolean,
      default: false,
    },
    zm: {
      type: Number,
      default: 0,
    },
    key2: Number,
    floors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      // urlbase: "http://localhost:2021",
      ismodified: false,
      floor: null,
      // hhh: 400,
      // www: 400,
      dialog: false,
      dlgsend: false,
      dlgsel: false,
      printer: "",
      //      zm: -1,
      fUpd: 0,
      showMenu: false,
      showMenuMarker: false,
      x: 0,
      y: 0,
      todel: -1,
      curname: "",
    };
  },
  methods: {
    pickfloor(ev) {
      // console.log("Floor2 " + ev);
      this.$emit("pickfloor", ev);
    },
    prninfo() {
      let url = "http://kpp:9090/#/prninfo/?h=" + this.curname; //prn0603-0016"
      // console.log(url);
      //  document.location.href = url
      window.open(url, "_blank");
    },
    prnmove() {
      this.ismodified = true;
      this.$nextTick(() => {
        this.showMenu = false;
        this.$emit("prnmoved");
      });
    },
    mclick(ee) {
      // console.log("-------------")
      // console.log(ee);
      this.x = ee.originalEvent.clientX;
      this.y = ee.originalEvent.clientY;
      // ee.sourceTarget._icon.innerHTML =
      //        "<div class='pin' style='background:red'></div><div class='pulse'></div> <br><div class='office'><b>" +
      //                    "bbbbbbbbbbb" +
      //       "</b></div>",
      // this.$emit("prndel", ee.target.number)
      this.todel = ee.target.number;
      this.curname = ee.target.prnname;
      this.showMenuMarker = true;
      //      this.showMenu = false;
    },
    mdblclick(ee) {
      // this.x = ee.originalEvent.clientX;
      // this.y = ee.originalEvent.clientY;
      // this.todel = ee.target.number;
      // this.curname = ee.target.prnname;
      //  console.log(ee)
      this.showMenuMarker = false;
      this.$emit("dblclick", ee.sourceTarget.prnname);
      //      this.showMenu = false;
    },
    show(e) {
      e.preventDefault();
      this.showMenuMarker = false;
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        ///        this.showMenu = true;
      });
    },
    async prndel() {
      //      console.log(this.todel)
      this.$emit("prndel", this.todel);
      this.ismodified = true;
      this.fUpd += 1;
    },
    async prnadd(pr) {
      console.log(pr);
      let t0;
      let t2 = pr.name;
      try {
        t2 = t2.split(".")[0];
      } catch {
        t2 = "???";
      }

      t0 = {
        x: "200",
        y: "200",
        width: "200",
        height: "200",
        prnname: t2, //pr.name,
        elements: [{ hostid: pr.hostid }],
        label:
          "{HOST.DNS}\r\n\t\t{{HOST.HOST}:shortModelName.last()}\r\n\t\tSerialNumber: {{HOST.HOST}:prtGeneralSerialNumber.last()}\r\n\t\tBlack: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Black].last()}\r\n\t\tCyan: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Cyan].last()}\r\n\t\tMagenta: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Magenta].last()}\r\n\t\tYellow: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Yellow].last()}",
        elementtype: "0",
        iconid_off: "83",
        iconid_on: "196",
      };
      //      this.zm = 0;
      //      console.log(t0)
      this.$emit("prnadd", t0);
      this.ismodified = true;
      this.fUpd += 1;
    },
    async prnupd() {
      let url;
      // url = "http://" + this.$store.state.urlmdlctl + ":2024/del";
      url = this.urlbase + "/upd";
      let rawResponse = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: this.data.sysmapid, selements: this.data.selements }),
      });
      //      this.refresh()
      let ret = await rawResponse.json();
      console.log(ret);
      this.ismodified = false;
    },
  },
  watch: {
    // dlg: function () {
    //   this.dialog = this.dlg;
    //   if (this.dialog) {
    //     //          this.setmap()
    //     this.$nextTick(() => {
    //       // this.setmap();
    //       //          this.rr();
    //     });
    //   } else {
    //     //console.log("deactivated");
    //     // this.mapf.remove();
    //     // this.mapf = null;
    //   }
    // },
  },
  computed: {
    ...mapState(["printers", "urlbase"]),
    mustsave: function () {
      return this.ismodified || this.ismod;
    },
  },
  mounted() {
    // this.hhh = window.innerHeight - 100;
    // this.www = window.innerWidth - 100;

    this.$nextTick(() => {
      //      this.setmap()
    });
  },
};
</script>
