import Vue from "vue";
import VueRouter from "vue-router";
import sensors from "../components/sensors/sensors";
import sensorstree from "../components/sensors/sensors_tree";
import ir from "../components/sensors/ir";
import usagerooms from "../components/sensors/usagerooms";
import usagerooms2 from "../components/sensors/usagerooms2";
import irr from "../components/sensors/ir2_room";
import hoststree from "../components/hosts/hosts_tree";
import hoststbl from "../components/hosts/hosts_tbl";
import upload from "../components/hosts/upload";

import maplight2 from "../components/maplight/maplight2";

import buildings from "../components/buildings/buildings";

import sensorscorp from "../components/sensors/sensors_corpuses";
import ipanel from "../components/sensors/infopanel";
import ipc from "../components/sensors/ipc";

import kortexcontrol from "../components/kortex/kortexcontrol";
import kortexparam from "../components/kortex/param";
import kortexeditparam from "../components/kortex/editparam";
import kortexall from "../components/kortex/kortexall";

import firesens from "../components/firesens/firesens";
import fsview from "../components/firesens/fs_view";
import fsview2 from "../components/firesens/fs_view2";
import fsanaliz from "../components/firesens/fs_analiz";
import dormitory from "../components/firesens/1c_dormitory";
import waterleak from "../components/waterleak/waterleak";

import kbsuobj from "../components/dynamic/objs";
//import kbsuobj2 from "../components/dynamic/objs";

import timeline from "../components/timeline/timeline";
import timeline2 from "../components/timeline/timeline2";
import timelineall from "../components/timeline/timelineall";

import netcam from "../components/netcam/netcam";

import index from "../components/index";

import { USEKEYCLOAK } from "../main";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      // group: "Датчики",
      requiresAuth: false,
      // roles: ["Orion_admin", "Chief_res"],
      icon: "mdi-home",
      text: "Главная",
    },
  },
  {
    path: "/tbl",
    name: "sensors",
    component: sensors,
    meta: {
      group: "Датчики",
      requiresAuth: true,
      roles: ["Orion_admin", "Chief_res"],
      icon: "mdi-table-large",
      text: "Таблица",
    },
  },
  {
    path: "/netcam",
    name: "netcam",
    component: netcam,
  },
  {
    path: "/ko",
    name: "kbsuobj",
    component: kbsuobj,
  },
  {
    path: "/timeline",
    name: "timeline",
    component: timeline,
    meta: {
      group: "События",
      requiresAuth: true,
      roles: ["kbsu_admin"],
      // roles: ["kbsu_sens_switch"],
      icon: "mdi-clock-outline",
      text: "Лента событий",
    },
  },
  {
    path: "/timeline2",
    name: "timeline2",
    component: timeline2,
    meta: {
      group: "nogroup",
      requiresAuth: true,
      roles: ["kbsu_admin"],
      // roles: ["kbsu_sens_switch"],
      icon: "mdi-clock-outline",
      text: "Лента событий 2",
    },
  },
  {
    path: "/timelineall",
    name: "timelineall",
    component: timelineall,
    meta: {
      group: "nogroup",
      requiresAuth: true,
      roles: ["kbsu_admin"],
      // roles: ["kbsu_sens_switch"],
      icon: "mdi-clock-outline",
      text: "Лента последних 10 событий",
    },
  },
  {
    path: "/firesens",
    name: "firesens",
    component: firesens,
    meta: {
      group: "События",
      requiresAuth: true,
      // roles: ["kbsu_sens_switch"],
      roles: [
        // "kbsu_sens_switch",
        // "kbsu_sens_fire_fsview",
        "Orion_admin",
        "Chief_fire",
        "Chief_campus",
      ],
      icon: "mdi-fire",
      text: "Пожарная сигнализация",
    },
  },
  {
    path: "/fsview",
    name: "fsview",
    component: fsview,
    meta: {
      group: "События",
      requiresAuth: true,
      roles: [
        // "kbsu_sens_switch",
        // "kbsu_sens_fire_fsview",
        "Orion_admin",
        "Chief_fire",
        "Chief_campus",
      ],
      icon: "mdi-help",
      text: "Анализ происшествий",
    },
  },
  {
    path: "/fsanaliz",
    name: "fsanaliz",
    component: fsanaliz,
    meta: {
      group: "События",
      requiresAuth: true,
      roles: ["Orion_admin", "Chief_fire", "Chief_campus"],
      // roles: ["kbsu_sens_switch"],
      icon: "mdi-help",
      text: "Анализ происшествий 2",
    },
  },
  {
    path: "/fsview2",
    name: "fsview2",
    component: fsview2,
    meta: {
      group: "События",
      requiresAuth: true,
      roles: ["kbsu_admin"],
      // roles: ["kbsu_sens_switch"],
      icon: "mdi-animation-outline",
      text: "Структура объектов",
    },
  },
  {
    path: "/dormitory",
    name: "dormitory",
    component: dormitory,
    meta: {
      group: "nogroup",
      requiresAuth: true,
      roles: ["kbsu_admin"],
      // roles: ["kbsu_sens_switch"],
      icon: "mdi-animation-outline",
      text: "Структура объектов из 1С",
    },
  },
  {
    path: "/waterleak",
    name: "waterleak",
    component: waterleak,
    meta: {
      group: "События",
      requiresAuth: true,
      // roles: ["kbsu_sens_switch"],
      roles: [
        // "kbsu_sens_switch",
        // "kbsu_sens_fire_fsview",
        // "Orion_admin",
        // "Chief_fire",
        // "Chief_campus",
        ["kbsu_admin", "Orion_admin"],
      ],
      icon: "mdi-water-pump",
      text: "Протечки воды",
    },
  },
  {
    path: "/kortexs",
    name: "kortexall",
    component: kortexall,
    meta: {
      group: "Kortex",
      requiresAuth: true,
      roles: ["kbsu_sens_switch"],
      icon: "mdi-expand-all",
      text: "Все задвижки",
    },
  },
  {
    path: "/editparam",
    name: "kortexeditparam",
    component: kortexeditparam,
    meta: {
      group: "Kortex",
      requiresAuth: true,
      roles: ["kbsu_sens_switch"],
      icon: "mdi-table-large",
      text: "Редактировать параметры",
    },
  },
  {
    path: "/kp",
    name: "kortexparam",
    component: kortexparam,
  },
  {
    path: "/k",
    name: "kortexcontrol",
    component: kortexcontrol,
    props: true,
    meta: {
      group: "nogroup",
      requiresAuth: true,
      roles: ["kbsu_sens_switch"],
      icon: "mdi-table-large",
      text: "Kortex",
    },
  },
  {
    path: "/ipanel",
    name: "ipanel",
    component: ipanel,
  },
  {
    path: "/ipreal",
    name: "sensorscorp",
    component: sensorscorp,
  },
  {
    path: "/ip",
    name: "ipc",
    component: ipc,
    meta: {
      group: "Датчики",
      requiresAuth: true,
      roles: ["Orion_admin"],
      icon: "mdi-table",
      text: "Информационная панель",
    },
  },
  {
    path: "/buildings",
    name: "buildings",
    component: buildings,
  },
  {
    path: "/mapzbx",
    name: "maplight2",
    component: maplight2,
  },
  {
    path: "/sensorstree",
    name: "sensorstree",
    component: sensorstree,
    meta: {
      group: "Датчики",
      requiresAuth: true,
      roles: ["Orion_admin", "Chief_res"],
      icon: "mdi-file-tree",
      text: "Дерево",
    },
  },
  {
    path: "/ir",
    name: "ir",
    component: ir,
    meta: {
      group: "Датчики",
      requiresAuth: true,
      roles: ["Orion_admin", "Chief_res"],
      icon: "mdi-motion-sensor",
      text: "ИК",
    },
  },
  {
    path: "/irr",
    name: "irr",
    component: irr,
    meta: {
      group: "nogroup",
      requiresAuth: false,
      roles: ["kbsu_admin"],
      icon: "mdi-motion-sensor",
      text: "ИК 3",
    },
  },
  {
    path: "/usagerooms",
    name: "usagerooms",
    component: usagerooms,
    meta: {
      group: "Датчики",
      requiresAuth: true,
      roles: ["kbsu_admin"],
      icon: "mdi-motion-sensor",
      text: "% использования аудиторий",
    },
  },
  {
    path: "/usagerooms2",
    name: "usagerooms2",
    component: usagerooms2,
    meta: {
      group: "Датчики",
      requiresAuth: true,
      roles: ["kbsu_admin"],
      icon: "mdi-motion-sensor",
      text: "% использования аудиторий 2",
    },
  },
  {
    path: "/hoststree",
    name: "hoststree",
    component: hoststree,
    meta: {
      group: "nogroup",
      requiresAuth: true,
      roles: ["kbsu_admin"],
      icon: "mdi-file-tree",
      text: "Компьютеры",
    },
  },
  {
    path: "/hoststbl",
    name: "hoststbl",
    component: hoststbl,
    meta: {
      group: "nogroup",
      requiresAuth: true,
      roles: ["kbsu_admin"],
      icon: "mdi-file-tree",
      text: "Компьютеры tbl",
    },
  },
  {
    path: "/upload",
    name: "upload",
    component: upload,
    meta: {
      group: "nogroup",
      requiresAuth: false,
      roles: ["kbsu_admin"],
      icon: "mdi-file-tree",
      text: "Загрузить",
    },
  },
];

// const router = new VueRouter({
//   routes,
//   groupicon
const groupicon = {
  События: "mdi-fire",
  Компьютеры: "mdi-alpha-b-box",
  Датчики: "mdi-gauge",
  Kortex: "mdi-alpha-k-box",
  Информация: "mdi-alpha-i-box",
  Статистика: "mdi-alpha-c-box",
  Посещаемость: "mdi-alpha-a-box",
  Управление: "mdi-alpha-y-box",
  "OpenMeetings 5": "mdi-alpha-o-box",
};

// })

const router = new VueRouter({
  routes,
  groupicon,
});
function checkrole(dd, pp) {
  if (!USEKEYCLOAK) return true
  if (dd == undefined) return true;
  //  if(this == undefined) return false
  for (let i = 0; i < dd.length; i++) {
    if (pp.$keycloak.realmAccess.roles.indexOf(dd[i]) != -1) {
      return true;
    }
  }
  // return true
  return false;
}
router.beforeEach((to, from, next) => {
  if (!USEKEYCLOAK) { //&& router.app.$keycloak=="undefined") {
    // console.log(USEKEYCLOAK)
    next()
    return
  }
  // console.log(router.app.$keycloak)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (router.app.$keycloak.authenticated) {
      if (
        checkrole(to.meta.roles, router.app) ||
        checkrole(["kbsu_admin"], router.app)
      ) {
        next();
      }
    } else {
      const loginUrl = router.app.$keycloak.createLoginUrl();
      window.location.replace(loginUrl);
    }
  } else {
    next();
  }
});
export default router;
