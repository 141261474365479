<template>
  <!-- <div style="width: 100%; height: 80%"> -->
  <!-- <div> -->
  <v-container fluid>
    <v-row
      class="align-center justify-center text-center mb-2"
      no-gutters
      id="title"
    >
      <v-col cols="12" class="text-h5 font-weight-black">
        Устройства{{ dragprn.name }}
      </v-col>
      <!-- {{officesfloor}} -->
    </v-row>
    <v-row v-resize="onResize" no-gutters class="justify-center flex-nowrap">
      <v-col>
        <v-row no-gutters class="ml-0 mr-2">
          <v-col cols="12">
            <v-row>
              <v-autocomplete
                v-if="false"
                v-model="moffice"
                :items="officesfloor"
                item-text="office"
                dense
                label="Здания"
              ></v-autocomplete>
            </v-row>
            <v-row>
              <v-col cols="12">
                <prnsmask
                  :prns="printers"
                  :hhh="hhh+20"
                  v-on:prnadd="prnadd2($event)"
                  v-on:prnstart="prnstart($event)"
                  v-on:prnend="prnend($event)"
                  v-on:prnonplan="prnonplan($event)"
                ></prnsmask>
                <!-- <v-spacer></v-spacer> -->
                <!-- href="mailto:ers@mts.ru?subject=Размещение принтеров&body=Нет принтера или офиса" -->
                <!-- <v-sheet class="elevation-3 pa-2 black--text" color="amber lighten-4">
                  При отсутствии офиса или принтера сообщить <br />
                  <a href="mailto:ers@mts.ru?subject=Размещение принтеров на планах"
                    >Ивану Ершову</a
                  >
                </v-sheet> -->
                <!-- <v-autocomplete
                  v-model="printer"
                  :items="printersfree"
                  item-text="name"
                  dense
                  label="Принтеры"
                ></v-autocomplete> -->
              </v-col>
            </v-row>
            <!-- <v-row no-gutters>
              <v-col cols="12">
                <office
                  :office="{ name: moffice }"
                  :floors="office"
                  v-on:floornum="
                    curfloor = $event;
                    dt = office[$event];
                    dialog = true;
                  "
                  v-on:menuclick="
                    mapoffice = false;
                    curfloor = $event;
                    dt = office[$event];
                    floorupd += 1;
                  "
                  v-on:showonmap="mapoffice = true"
                  v-if="vismenu"
                >
                </office>
              </v-col>
            </v-row> -->
            <!-- <v-row class="mt-2 justify-center" no-gutters>
              <plan
                :www="300"
                :hhh="250"
                :zoom="-3"
                :data="dt"
                v-if="vismenu"
                divid="mapfloor_tmp0"
                :key="floorupd"
              >
              </plan>
            </v-row> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="9" id="mmm" >
        <v-row class="align-center justify-center">
          <!-- =={{moffice}}-- -->
          <!-- {{ zoom }} -->
          <!-- <v-btn @click="test()">Test</v-btn> -->
          <v-icon
            v-if="moffice"
            class="mr-2 mb-2"
            color="red"
            @click="openzabbix(dt.sysmapid)"
            >mdi-alpha-z-box</v-icon
          >
          <v-icon v-if="!showmap" class="mr-2 mb-2" @click="showmap = true"
            >mdi-alpha-m-box</v-icon
          >
          <v-autocomplete
            clearable
            v-model="moffice"
            :items="officesfloor"
            item-text="office"
            dense
            label="Планы этажей"
          ></v-autocomplete>
        </v-row>
        <v-row>
        <mapoffice
          v-if="showmap"
          :www="www"
          :hhh="hhh+155"
          divid="myMapOffice"
          :curOffice="offices00[0]"
          v-on:selectobj="
            showmap = false;
            moffice = 'г. Нальчик, ул. Чернышевского, д. 173, Ректорат, 1 этаж';
          "
        ></mapoffice>
</v-row>        <!-- <v-row
          id="idmap"
          ref="rmap"
          @dragover="prnover($event)"
          @drop="prndrop($event)"
          v-if="moffice != undefined && moffice != ''"
        >
        </v-row> -->
        <v-row>
          <floor2
            v-if="!showmap"
            ref="rpln"
            :www="www"
            :hhh="hhh"
            :zm="zoom"
            :data="dt"
            :floors="curFloors"
            :ismod="ismodified"
            v-on:prndel="prndel($event)"
            v-on:prncancel="refresh()"
            v-on:prnsave="prnupd()"
            v-on:prnmoved="prnmoved()"
            v-on:dblclick="dblclick($event)"
            v-on:pickfloor="pickfloor($event)"
            :key2="floorupd"
            :key3="floorupd"
          ></floor2>
        </v-row>
        <!-- <v-row v-if="showmap && (moffice != undefined && moffice != '')" class="align-center justify-center fill-height" ref="test">
          <h1>{{ mapmsg }}</h1>
        </v-row> -->
      </v-col>
    </v-row>
    <!-- <floor
      :data="dt"
      :dlg="dialog"
      v-on:dlgclose="dialog = false"
      v-on:prnadd="prnadd($event)"
      v-on:prndel="prndel($event)"
    ></floor> -->
    <wait :progress="progress" :message="waittext"></wait>
    <v-dialog v-model="qmod" persistent width="700">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row class="">
            <v-col cols="12">
              <!-- <p class="text-xs-center mt-3"></p> -->
              <!-- <p class="text-xs-center text-h4"> Что делать с изменениями? </p>  </v-alert> -->
              <v-alert prominent>
                <v-row class="align-center justify-center">
                  <v-icon large class="mr-5">mdi-help-circle-outline</v-icon>
                  <span class="text-h4"> Что сделать с изменениями?</span>
                </v-row>
              </v-alert>
              <!-- <p class="text-xs-center">Получаю данные с сервера:&nbsp;{{curserver}}</p> -->
            </v-col>
          </v-row>
          <!-- <v-progress-linear indeterminate color="blue" class="mb-0"></v-progress-linear> -->
        </v-card-text>
        <v-card-actions class="align-center justify-center">
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            @click="
              moffice = prevoffice;
              qmod = false;
            "
            >Вернуться</v-btn
          >
          <v-btn
            @click="
              qmod = false;
              floorupd = -1;
              refresh();
            "
            >Отменить</v-btn
          >
          <v-btn
            @click="
              qmod = false;
              prnupd();
              floorupd = -1;
              refresh();
            "
            color="primary"
            >Сохранить</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-snackbar v-model="sbok" :timeout="5000" color="green" top right>
      {{ strok }}
      <v-btn @click="sbok = false" class="ml-5" color="primary" fab>OK</v-btn>
    </v-snackbar> -->
    <v-snackbar v-model="sbok" right top color="green" :timeout="5000">
      <v-icon>mdi-information-outline</v-icon>
      {{ sboktext }}
      <v-spacer></v-spacer>
      <template v-slot:action="{}">
        <v-icon @click="sbok = false">mdi-close-circle</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar v-model="sberr" right top color="red" :timeout="5000">
      <v-icon>mdi-information-outline</v-icon>
      {{ sberrtext }}
      <v-spacer></v-spacer>
      <template v-slot:action="{}">
        <v-icon @click="sberr = false">mdi-close-circle</v-icon>
      </template>
    </v-snackbar>
    <!-- <template v-for="(sb,si) in sbs">
    <v-snackbar v-model="sb.ok" right top color="green" :timeout="5000" :key="si" app>
      <v-icon>mdi-information-outline</v-icon>
      {{ sb.text }}
      <v-spacer></v-spacer>
      <template v-slot:action="{}">
        <v-icon @click="sb.ok = false">mdi-close-circle</v-icon>
      </template>
    </v-snackbar> 
    </template> -->
    <v-dialog v-model="dlgprn" persistent width="500">
      <v-card>
        <v-card-text>
          <printercard :item="prninfo"></printercard>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dlgprn = false" color="primary">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <!-- </div> -->
</template>

<script>
import L from "leaflet";
import { mapState, mapActions } from "vuex";
// import floor from "./floor";
import floor2 from "./floor2";
// import officemenu from "./officemenu";
// import office from "./office";
// import plan from "./plan";
import mapoffice from "./mapoffice";
import prnsmask from "./prnsmask";
import wait from "../progress2";
import printercard from "./printercard";
export default {
  components: {
    //    floor,
    floor2,
    //    officemenu,
    // office,
    //    plan,
    mapoffice,
    prnsmask,
    wait,
    printercard,
  },
  props: {},
  data() {
    return {
      map: null,
      map2: null,
      ismap: true,
      crsm: null,
      marker: null,
      hhh: 600,
      www: 600,
      zoom: 0,
      dialog: false,
      vismenu: true,
      // urlbase: "http://localhost:2021",
      // printers: [],
      moffice: undefined,
      prevoffice: undefined,
      curOffice: null,
      curFloors: null,
      office: null,
      offices00: [
        {
          Lat: "43.494980888243205",
          Lng: "43.59463980611124",
          Tooltip: "Ректорат",
          Popup:
            "<b>Россия, Кабардино-Балкарская Республика, Нальчик, улица Чернышевского, 173, Ректорат</b>",
          // planimg: "floor2.svg",
        },
      ],
      tmp: {},
      dt: undefined, //{},
      data: [undefined],
      printer: "",
      // forceUpd: 1,
      ttt: "1",
      floorupd: -1,
      curfloor: 0,
      showmap: false,
      dragprn: "",
      progress: false,
      ismodified: false,
      mapmsg: "Не выбран план этажа.",
      qmod: false,
      sbok: false,
      sboktext: "Успешно",
      sberr: false,
      sberrtext: "Ошибка",
      waittext: "Подождите...",
      dlgprn: false,
      prninfo: {},
    };
  },
  methods: {
    ...mapActions([
      "loadIcons",
      "loadOfficesfloor",
      "loadPrintersfree",
      "loadWifi",
      "loadHosts",
    ]),
    pickfloor(ev) {
      //  console.log(ev)
      this.moffice = ev;
    },
    openzabbix(id) {
      window.open(
        "http://82.179.94.22:8093/zabbix.php?action=map.view&sysmapid=" + id,
        "_blank"
      );
    },
    dblclick(nn) {
      // console.log(nn);
      //      this.printers.forEach(p=>)
      for (let i = 0; i < this.printers.length; i++) {
        if (this.printers[i].name == nn) {
          this.prninfo = this.printers[i];
          // console.log(this.printers[i]);
          this.dlgprn = true;
          return;
        }
      }
      this.prninfo = null;
      // console.log(this.dt.selements[nn])
    },
    test() {
      // let layerBounds = this.mapf.getBounds();
      //   let layerBounds = this.$refs.pln.mapf.getBounds()
      console.log(this.dt);
      // console.log(this.$refs.pln.$refs.pln2.mapf.getBounds());
      // let bnd = this.$refs.rpln.$refs.pln2.mapf.getBounds()._southWest;
      // console.log(bnd.lat + " " + bnd.lng);
    },
    // prndrop(evt, list) {
    prnmoved() {
      this.ismodified = true;
    },
    prnstart(evt) {
      // console.log(evt.printer.name);
      // console.log(evt);
      this.dragprn = evt.printer;
    },
    prnover(evt) {
      if (this.moffice != undefined) {
        evt.preventDefault();
        this.convert(evt);
      }
      // console.log(evt.printer);
    },
    convert(evt) {
      var rr = this.$refs.rpln; //document.getElementsByTagName("rmap");
      rr = document.getElementById("idmap");
      let x, y, dx, dxm;
      // let dy,dym
      dxm = rr.clientWidth;
      // dym = rr.clientHeight;

      let bnd = this.$refs.rpln.$refs.pln2.mapf.getBounds()._southWest;
      let bnd2 = this.$refs.rpln.$refs.pln2.mapf.getBounds()._northEast;
      // dx = bnd2.lng - Math.abs(bnd.lng);
      // dy = bnd2.lat - Math.abs(bnd.lat);
      dx = bnd2.lng - bnd.lng;
      dx += 100;
      // dy = bnd2.lat - bnd.lat;

      // x = (evt.offsetX - Math.abs(bnd.lng))  / (dxm / dx);
      x = bnd.lng + evt.offsetX * (dx / dxm);
      // y = (bnd2.lat - (evt.offsetY * (dy / dym)));
      y = bnd2.lat - evt.offsetY * (dx / dxm);
      y = this.dt.height - y;
      // x -= Math.abs(bnd.lng) / (dxm / dx)
      //       y -= bnd.lat
      //      y = bnd.lat - y
      let zoomlevel = this.$refs.rpln.$refs.pln2.mapf.getZoom();
      this.zoom = zoomlevel;

      // console.log(bnd.lng + " " + bnd.lat + " " + zoomlevel);
      // console.log(bnd2.lng + " " + bnd2.lat + " " + zoomlevel);
      // // console.log(dx + " " + dy + " " + (dx / dxm));
      // console.log(x + " " + y + " " + dy / dym + " " + this.dt.height);
      // console.log(evt.offsetX + " " + evt.offsetY);
      // // console.log(rr);
      // console.log(" ");
      return { x: x, y: y };
    },
    prnend() {
      this.dragprn = "";
      // console.log("End");
      // console.log(evt);
      // const itemID = evt.dataTransfer.getData('itemID')
      // const item = this.items.find(item => item.id == itemID)
      // item.list = list
    },
    async prndrop(evt) {
      this.progress = true;
      // let bnd = this.$refs.rpln.$refs.pln2.mapf.getBounds()._southWest;
      let xx, yy;
      // yy = evt.offsetY - bnd.lng;
      // xx = evt.offsetX - bnd.lat;
      let xy = this.convert(evt);
      xx = Math.floor(xy.x);
      yy = Math.floor(xy.y);

      let icn = "179";
      switch (this.dragprn.tip) {
        case "Принтер":
          icn = "83";
          break;
        case "wifi":
          icn = "124";
          break;
      }
      // console.log(xx)
      let t0 = {
        // x: 200,
        // y: 200,
        x: xx,
        y: yy,
        width: "200",
        height: "200",
        prnname: this.dragprn.name,
        elements: [{ hostid: this.dragprn.hostid }],
        label: "{HOST.NAME}",
        // "{HOST.DNS}\r\n\t\t{{HOST.HOST}:shortModelName.last()}\r\n\t\tSerialNumber: {{HOST.HOST}:prtGeneralSerialNumber.last()}\r\n\t\tBlack: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Black].last()}\r\n\t\tCyan: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Cyan].last()}\r\n\t\tMagenta: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Magenta].last()}\r\n\t\tYellow: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Yellow].last()}",
        elementtype: "0",
        iconid_off: icn,
        iconid_on: icn,
      };
      // console.log(t0);
      this.dt.selements.push(t0);
      ///      this.ismodified = true;
      // await this.prnupd();
      // await this.$store.dispatch("loadPrintersfree");
      this.ismodified = true;
      this.floorupd += 1;
      this.progress = false;
      //  this.prnadd2(this.dragprn)
    },
    async prnupd() {
      this.waittext = "Сохранение изменений...";
      this.progress = true;
      let url;
      url = this.urlbase + "/upd";
      let rawResponse = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: this.dt.sysmapid,
          selements: this.dt.selements,
        }),
      });
      //      this.refresh()
      // console.log({ id: this.dt.sysmapid, selements: this.dt.selements });
      let ret = await rawResponse.json();
      // console.log(ret);
      if (ret.response == "success") {
        this.sbok = true;
        this.sboktext = "Изменения успешно сохранены.";
      } else {
        this.sberr = true;
        this.sberrtext = "Ошибка сохранения данных: " + ret.error;
      }
      // console.log(ret);
      this.waittext = "Загрузка списка устройств...";
      /////      await this.$store.dispatch("loadPrintersfree");
      await this.loadDevs();
      this.sbok = true;
      this.sboktext = "Список устройств загружен.";
      this.ismodified = false;
      this.floorupd = 0;
      this.progress = false;
    },
    showfloor() {},
    async prndel(el) {
      /////      this.office[0].selements.push(el);
      //////      this.dt = this.office[0];
      ///        this.dt.selements.push(el);
      //  this.forceUpd += 1
      ///       this.office[0].selements[0].prnname = "333333333"
      //       this.ttt +="1"
      // console.log(el);
      //  delete this.dt.selements[el]
      this.progress = true;
      this.dt.selements.splice(el, 1);
      // await this.prnupd();
      // await this.$store.dispatch("loadPrintersfree");
      this.ismodified = true;
      this.floorupd += 1;
      this.progress = false;

      //  this.office[0] = this.dt
      //  this.dialog = false
      //  this.dialog = true
    },
    async prnadd2(pr) {
      //      console.log(pr);
      this.progress = true;
      let t0;
      let t2 = pr.name;
      // try {
      //   t2 = t2.split(".")[0];
      // } catch {
      //   t2 = "???";
      // }
      t0 = {
        x: "200",
        y: "200",
        width: "200",
        height: "200",
        prnname: t2, //pr.name,
        elements: [{ hostid: pr.hostid }],
        label:
          "{HOST.DNS}\r\n\t\t{{HOST.HOST}:shortModelName.last()}\r\n\t\tSerialNumber: {{HOST.HOST}:prtGeneralSerialNumber.last()}\r\n\t\tBlack: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Black].last()}\r\n\t\tCyan: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Cyan].last()}\r\n\t\tMagenta: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Magenta].last()}\r\n\t\tYellow: {{HOST.HOST}:prtSuppliesCapacity[Cartridge Yellow].last()}",
        elementtype: "0",
        iconid_off: "83",
        iconid_on: "196",
      };
      //      this.zm = 0;
      // console.log(t0);
      // this.$emit("prnadd", t0);
      this.dt.selements.push(t0);
      // await this.prnupd();
      // await this.$store.dispatch("loadPrintersfree");
      this.ismodified = false;
      this.floorupd += 1;
      this.progress = false;
      ///      this.ismodified = true;
    },
    prnadd(el) {
      /////      this.office[0].selements.push(el);
      //////      this.dt = this.office[0];
      this.dt.selements.push(el);
      //  this.forceUpd += 1
      ///       this.office[0].selements[0].prnname = "333333333"
      //       this.ttt +="1"
      ///       console.log(this.office[0].selements)
      //  this.office[0] = this.dt
      //  this.dialog = false
      //  this.dialog = true
    },
    async prnonplan(pr) {
      // console.log(pr);
      // this.getOfficeid(pr.sysmapid)
      this.office = await this.getOfficeid(pr.sysmapid);
      this.curfloor = 0;
      //      console.log( this.office[0])
      // console.log( this.office[0].selements)
      //        this.dt = undefined;
      this.dt = this.office[this.curfloor];
      if (this.dt == undefined) {
        this.moffice = undefined;
        // this.showmap = false;
        this.mapmsg = "Устройство " + pr.name + " не размещено на карте.";
        return;
      }
      this.mapmsg = "Не выбран план этажа.";
      this.moffice = this.dt.name;
      // this.showmap = false;
      this.ismodified = false;
      this.floorupd += 1;
    },
    onResize() {
      ///      this.hhh = window.innerHeight - 80;
      var div0 = document.getElementById("mmm");
      var div = document.getElementById("title");
      //  console.log(div.clientHeight);
      //  console.log(window.innerHeight);
      // if (div.clientHeight > window.innerHeight) {
      //   this.hhh = window.innerHeight - 100;
      //   return;
      // }
      //      this.hhh = div.clientHeight - 150;
      this.hhh = window.innerHeight - div.clientHeight - 240 - 50;
      this.www = div0.clientWidth - 20;
      //  console.log(div.clientHeight)
    },
    async getPrinters() {
      let url;
      url = this.urlbase + "/printers";
      let rez;
      rez = await fetch(url);
      rez = await rez.json();
      //      rez = "data:image/jpg;base64," + rez[0].image;
      return rez;
    },
    async getImg(id) {
      let url;
      url = this.urlbase + "/img?id=" + id;
      let rez;
      rez = await fetch(url);
      rez = await rez.json();
      rez = "data:image/jpg;base64," + rez[0].image;
      return rez;
    },
    async getMap(id) {
      let url;
      url = this.urlbase + "/map?id=" + id;
      let rez;
      rez = await fetch(url);
      rez = await rez.json();
      rez = rez[0];
      // console.log(rez)
      rez.selements.map((s) => {
        //         console.log(s.elements[0].hostid);
        s.prnname = this.printers.find(
          (f) =>
            // console.log(f.hostid);
            f.hostid == s.elements[0].hostid
        ).name;
        //        console.log(s.prnname);
        return s;
      });
      rez.image = await this.getImg(rez.backgroundid);
      return rez;
    },
    async getOffice(sss) {
      let url;
      url = this.urlbase + "/office?name=" + sss;
      let rez;
      rez = await fetch(url);
      rez = await rez.json();
      await Promise.all(
        rez.map(async (r) => {
          r.image = await this.getImg(r.backgroundid);
          r.selements.map((s) => {
            try {
              s.prnname = this.printers.find(
                (f) => f.hostid == s.elements[0].hostid
              ).name;
              // s.prnname = s.prnname.split(".")[0];
            } catch {
              s.prnname = "???";
            }
            return s;
          });
          return r;
        })
      );
      // console.log(rez)
      rez.sort((a, b) => (a.name < b.name ? -1 : Number(a.name > b.name)));
      return rez;
    },
    async getOfficeid(id) {
      let url;
      url = this.urlbase + "/office?id=" + id;
      let rez;
      rez = await fetch(url);
      rez = await rez.json();
      await Promise.all(
        rez.map(async (r) => {
          r.image = await this.getImg(r.backgroundid);
          r.selements.map((s) => {
            s.prnname = this.printers.find(
              (f) => f.hostid == s.elements[0].hostid
            ).name;
            // try {
            //   s.prnname = s.prnname.split(".")[0];
            // } catch {
            //   s.prnname = "???";
            // }
            return s;
          });
          return r;
        })
      );
      rez.sort((a, b) => (a.name < b.name ? -1 : Number(a.name > b.name)));
      return rez;
    },
    HideMarkers(map) {
      map.eachLayer((m) => {
        if ("icon" in m.options) {
          map.removeLayer(m);
        }
      });
    },
    SetMarkers(map, mm) {
      mm.forEach((m) => {
        var okIcon = new L.divIcon({
          html:
            "<div class='pin' style='background:#2aa043'></div><div class='pulse'></div> <br><div class='office'><b>" +
            m.Tooltip +
            "</b></div>",
          iconSize: 0,
          shadowSize: 0,
          popupAnchor: [-4, -33],
          iconAnchor: [0, 15],
        });
        let marker = L.marker([m.Lat, m.Lng], {
          draggable: true,
          icon: okIcon,
        });
        let ppp = this;
        marker.bindPopup(m.Popup);
        marker.on("dblclick", this.gooffice);
        marker.on("click", this.vmenu);
        marker.on("dragend", function () {
          ppp.tmp = {
            lat: marker.getLatLng().lat,
            lng: marker.getLatLng().lng,
          };
        });
        marker.addTo(map);
      });
    },
    gooffice() {
      var latlng = L.latLng(this.curOffice.Lat, this.curOffice.Lng);
      this.map.setView(latlng, 18);
      this.vismenu = true;
    },
    vmenu() {
      //      this.vismenu = !this.vismenu; //true;
    },
    setmap() {
      var latlng = L.latLng(this.curOffice.Lat, this.curOffice.Lng);
      this.map = L.map("myMap", { attributionControl: false }); //.setView(latlng, 17);
      this.map.setView(latlng, 18);
      this.lmap = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution: "МТС карта",
          maxZoom: 20,
        }
      ).addTo(this.map);
      console.log("map");
      // this.map.on("zoom", this.zoommap);
      this.HideMarkers(this.map);
      this.SetMarkers(this.map, this.offices00);
      L.control.scale({ metric: true, imperial: false }).addTo(this.map);
    },
    doSomethingOnReady() {
      console.log("Ready");
      this.map = this.$refs.myMap.mapObject;
      this.setmap();
    },
    cdr(ss) {
      ss = ss.split(",");
      return ss[ss.length - 1];
    },
    async refresh() {
      // console.log(this.moffice)
      // console.log("->>>> " + this.moffice);
      if (this.moffice == undefined) {
        return;
      }
      this.progress = true;
      this.waittext = "Загрузка плана этажа...";

      this.office = await this.getOffice(this.moffice);
      if (this.moffice != "") {
        let ss = this.moffice.split(",");
        ss.pop().trim(); //  delele (ss.length-1)
        ss = ss.join(",").trim();
        let ff = await this.getOffice(ss);
        // console.log(ff);
        ff.sort((a, b) => {
          let a1, b1;
          if (a.name != undefined && b.name != undefined) {
            a1 = a.name.split(",");
            a1 = a1.pop().trim();
            a1 = /(\d+).*/.exec(a1);
            if (a1) {
              a1 = a1[1];
            }
            // b1 = this.getFloorName(b.office);
            b1 = b.name.split(",");
            b1 = b1.pop().trim();
            b1 = /(\d+).*/.exec(b1);
            if (b1) {
              b1 = b1[1];
            }
            // b1 = b.office
          } else {
            a1 = a.name;
            b1 = b.name;
          }
          return Number(a1) < Number(b1) ? -1 : Number(a1 > b1);
        });

        this.curFloors = ff;
      }
      // this.curFloors = this.curFloors.filter((f) => {
      //   return f.name != this.moffice;
      // });
      // console.log(this.curFloors);

      // console.log(this.office[0]);
      // console.log( this.office[0].selements)
      //        this.dt = undefined;
      this.dt = this.office[this.curfloor];
      this.ismodified = false;
      this.curfloor = 0;
      this.zoom = 0;
      this.floorupd += 1;
      this.prevoffice = this.dt.name;
      this.showmap = false;
      this.progress = false;
      // this.$refs.rpln.$refs.pln2.mapf.setView([this.dt.height / 2, this.dt.width / 2], this.zoom);
      // //       console.log( this.office.length)
    },
    async loadDevs() {
      // await this.$store.dispatch("loadPrinters");
      this.waittext = "Загрузка списка устройств...";
      //await this.$store.dispatch("loadPrintersfree");
      await this.loadPrintersfree()
      // console.log("!!!")
      // console.log(this.printers)
      this.sbok = true;
      this.sboktext = "Список устройств загружен.";

      this.waittext = "Загрузка списка точек WiFi...";
      await this.$store.dispatch("loadWifi");
      // console.log(this.wifi)
      let ppp = this;
      this.wifi.forEach((w) => {
        for (let p = 0; p < this.printers.length; p++) {
          if (w.ip == this.printers[p].name) {
            w.hostid = this.printers[p].hostid;
            w.prnbusy = this.printers[p].prnbusy;
            w.address = this.printers[p].address;
            w.sysmapid = this.printers[p].sysmapid;
            //  console.log(this.printers[p])
            break;
          }
        }
        // console.log(w.ip+" "+w.name)
        ppp.printers.push({
          name: w.ip,
          "Model Name 2": w.name,
          inventory: {
            model: w.name,
            serialno_a: w.serial,
            site_address_a: w.name,
          },
          address: w.address,
          SerialNumber: w.serial,
          tip: "wifi",
          hostid: w.hostid,
          prnbusy: w.prnbusy,
          sysmapid: w.sysmapid,
        });
      });
      // console.log(this.printers)
      this.sbok = true;
      this.sboktext = "Список точек WiFi загружен.";

      this.waittext = "Загрузка из Excel...";
      // await this.$store.dispatch("loadHosts");
      await this.loadHosts();
      //     console.log(this.hosts)
      for (let h = 0; h < this.hosts.length; h++) {
        for (let p = 0; p < this.printers.length; p++) {
          if (this.hosts[h].Ip == this.printers[p].name) {
            this.printers[p]["Model Name 2"] =
              this.hosts[h].Build +
              ", " +
              this.hosts[h].Floor +
              ", " +
              this.hosts[h].Room;
            //  console.log(this.printers[p]["Model Name 2"])
            break;
          }
        }
      }
      this.sbok = true;
      this.sboktext = "Список Excel загружен.";
    },
  },
  watch: {
    moffice: async function () {
      // if (this.office == null) {
      //   return;
      // }
      if (this.moffice == this.prevoffice) {
        return;
      }
      if (this.ismodified) {
        // this.moffice = this.prevoffice
        this.qmod = true;
      } else {
        this.$nextTick(async () => {
          // this.curfloor = 0;
          // this.zoom = -1;
          this.floorupd = -1;
          this.refresh();
        });
      }
    },
  },
  computed: {
    ...mapState([
      "printers",
      "printersfree",
      "urlbase",
      "offices",
      "officesfloor",
      "icons",
      "wifi",
      "hosts",
    ]),
  },
  async mounted() {
    this.progress = true;
    await this.loadDevs();
    // this.sbs.push({ok: true,text: "Список принтеров загружен."})
    this.waittext = "Загрузка списка планов...";
    await this.$store.dispatch("loadOfficesfloor");
    // this.sbs.push({ok: true,text: "Список офисов загружен."})
    this.sbok = true;
    this.sboktext = "Список планов загружен.";
    this.waittext = "Загрузка иконок...";
    await this.$store.dispatch("loadIcons");
    this.sbok = true;
    this.sboktext = "Список иконок загружен.";
    this.waittext = "Подождите...";
    this.curOffice = this.offices00[0];
    this.showmap = true;
    ///    this.printers = await this.getPrinters();
    // this.data = await this.getMap(301);
    // this.dt = this.data;
    // console.log(this.dt)
    this.floorupd = -1;
    this.curfloor = 0;
    // this.moffice = "г Нальчик, ул Головко, д 8, 2 этаж";
    // this.moffice = "г Орёл, ул Октябрьская, влд 27А, 1 этаж"
    // this.moffice = "г Москва, пр-кт Андропова, д 18 к 9, 15 этаж";
    this.progress = false;
    ///    this.setmap();
    //  this.office = await this.getOffice("г Нальчик, ул Головко");
  },
  destroyed() {
    if (this.ismodified) {
      // this.qmod = true;
    }
    // if (this.ismodified) {
    //   alert("Изменения не сохранены!");
    // }
  },
};
</script>
<style>
.btn {
  width: 50px;
  height: 50px;
  background-color: rgb(245, 238, 238);
  border: 1px solid black;
}
.office {
  /* display: block; */
  width: 120px;
  /* margin-top: -10px; */
  /* display: inline-block; */
  /* display: block; */
  /* float: left; */
  /* color: red; */
  color: blue;
}

.cluster {
  border-radius: 40px;

  height: 5px;
  margin: 15px;
  width: 5px;

  text-align: center;
  padding-top: 7px;
  font-size: 24px;
  color: white;
}
.cluster-ok {
  background: #2aa043;
}
.cluster-info {
  background: #00bfff;
}
.cluster-warning {
  background: #d16e0a;
}
.cluster-average {
  background: #ff6800;
}
.cluster-high {
  background: #eb6e63;
}
.cluster-disaster {
  background: #6f001f;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: grey;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px grey;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
</style>
