<template>
  <v-container class="fill-height" id="main1" v-resize="rrr">
    <v-row class="justify-center align-center text-center" dense>
      <div class="text-h5 font-weight-black">
        Состояние камер на
        {{ new Date().toLocaleString("ru") }}
      </div>
      <v-btn small fab class="ml-4 mb-0">
        <v-progress-circular
          size="25"
          indeterminate
          color="primary"
          class=""
          v-if="progress"
        >
        </v-progress-circular>
        <v-progress-circular
          size="25"
          value="0"
          color="white"
          class=""
          v-else
          @click="start()"
        ></v-progress-circular>
      </v-btn>
    </v-row>
    <v-row
      id="tl0"
      class="justify-center mt-0"
      :style="'overflow: auto; height:' + hhh + 'px;'"
    >
      <v-col cols="9">
        <v-card v-if="netcam" color="light-green lighten-4" class="elevation-5">
          <v-card-title>
            <v-icon @click="gocam()" color="green" class="mr-2"
              >mdi-cctv</v-icon
            >
            {{ cam }}
          </v-card-title>
          <v-card-text>
            <v-row class="text-center justify-center align-center">
              <img
                :src="getImg(upd)"
                height="300"
                class="elevation-4"
                @click="overlay = true"
              >
              <v-progress-circular
                indeterminate
                color="blue"
                v-if="progress"
                style="position: absolute;"
              ></v-progress-circular>
              <!-- <v-img
                :src="getImg(upd)"
                height="300"
                width="300"
                contain
                class="elevation-4"
                @click="overlay = true"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img> -->
            </v-row>
            <!-- <v-img :src="getImg()" contain> </v-img> -->
          </v-card-text>
          <v-card-text class="text-h5">
            <v-row>
            <v-icon color="green" class="mr-2">mdi-run</v-icon> Вход: {{ netcam.entertoday }}
            <v-spacer></v-spacer>
             Выход:
            {{ netcam.exittoday }}<v-icon color="green" class="ml-2">mdi-exit-run</v-icon>
            </v-row>
          </v-card-text>
          <!-- <v-card-text class="text-h5">
            <v-icon color="green">mdi-exit-run</v-icon> Выход:
            {{ netcam.exittoday }}
          </v-card-text> -->
        </v-card>
        <v-overlay :absolute="true" :value="overlay">
            <v-row class="text-center justify-center align-center">
              <img
                :src="getImg(upd)"
                class="elevation-4"
                @click="overlay = false"
              >
              <v-progress-circular
                indeterminate
                color="blue"
                v-if="progress"
                style="position: absolute;"
              ></v-progress-circular>
            </v-row>
          <!-- <v-img
            :src="getImg(upd)"
            :height="hhh + 120"
            contain
            @click="overlay = false"
          >
          </v-img> -->
        </v-overlay>

        <!-- {{ netcam }} -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { setInterval } from "timers";
import { clearInterval } from "timers";
var i1;
export default {
  components: {},
  props: {
    //    data: Array,
  },
  data() {
    return {
      reverse: false,
      data: [],
      progress: false,
      cnt: 10,
      hhh: 300,
      netcam: null,
      cam: "172.16.0.36",
      upd: 0,
      overlay: false,
    };
  },
  methods: {
    ...mapActions({
      // loadFireSens: "loadFireSens",
      // loadWlSens: "loadWlSens",
      // loadFireSensDates: "loadFireSensDates",
      // loadFireSensObj: "loadFireSensObj",
    }),
    gocam() {
      window.open("http://" + this.cam);
    },
    getImg(uu) {
      let url; // = "kortexsrv.json";
      let cam = this.cam; // "172.16.0.36";
      url = "https://sens.kbsu.ru:3011/ncjpg?cam=" + cam + "&upd=" + uu;
      // console.log(url);
      return url;
    },
    async start() {
      this.progress = true;
      let url; // = "kortexsrv.json";
      let resp; // = await fetch(url);
      let krt;
      let cam = this.cam; // "172.16.0.36";
      // console.log(tmp)
      // console.log(tmp[kk].ip)
      //url = state.firesens_url + state.firesens_port + "/";
      url = "https://sens.kbsu.ru:3011/nc?cam=" + cam;
      //url += "wldates";
      // console.log(url);
      try {
        resp = await fetch(url);
        krt = await resp.json();
      } catch (err) {
        console.log(err);
        return;
      }
      // if (krt.status != "success") {
      //   console.log(krt.error);
      //   return;
      // }
      // console.log(krt);
      this.netcam = krt;
      this.upd++;
      this.progress = false;
    },
    rrr() {
      this.hhh = window.innerHeight - 120;
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
    ...mapState({
      // firesens: (state) => state.firesens,
      // waterleak: (state) => state.wlsens,
    }),
    // hh: function() {
    //   console.log(document.getElementById("main1"))
    //   return window.innerHeight-120
    // }
  },
  async mounted() {
    this.start();
    i1 = setInterval(this.start, 1000 * this.cnt);
  },
  destroyed() {
    clearInterval(i1);
    i1 = null;
  },
};
</script>
