export function fDATE0(dr)
{
  return dr.substr(8, 2) + "." + dr.substr(5, 2) + "." + dr.substr(0, 4);
}
  ///  ->>  28.10.2019 00:00:00
export function fDATE1(dr, printtime = true) {
  let dd = dr.substr(8, 2) + "." + dr.substr(5, 2) + "." + dr.substr(0, 4);
  if (printtime) {
    dd = dd + " " + dr.substr(11, 8);
  }
  return dd;
}
function fDATEt(dr) {
  let dd // = dr.substr(8, 2) + "." + dr.substr(5, 2) + "." + dr.substr(0, 4);
  dd = dr.substr(11, 8);
  return dd;
}

////// список в дерево  (вызов l2t)  start
function find0(mm, val) {
  for (let t in mm) {
    if (mm[t]["name"] == val) {
      return true;
    }
  }
  return false;
}

function roots(obj, fld, root) {
  let rr = true;
  root.forEach((ff, ii) => {
    if (obj[fld[ii]] != null && typeof obj[fld[ii]] != "undefined") {
      if (obj[fld[ii]] != ff) {
        rr = false;
        return false;
      }
    } else {
      rr = false;
      return false;
    }
  });
  return rr;
}
// исходный_список, список_полей_группировок, уровень (0), вспомогательный_список([]), печатать_хначение (true), имя_поля-значения
// data = list2tree(data, ["arhdate","Name"], 0, [], true,"DataValue");
function list2tree(list, fld, nn, root, obj, valname) {
  if (fld == null) return null;
  if (fld.length < nn || list == null) {
    return null;
  }

  let prev = "";
  let cur = "";
  if (nn > 0) {
    prev = fld[nn - 1];
  }
  cur = fld[nn];
  let tr0 = [];

  if (fld.length == nn && obj) {
    let qq = [];
    list.forEach(ii => {
      if (roots(ii, fld, root)) {
        //            qq.push({ name: JSON.stringify(ii), parent: ii[prev] });
        qq.push({ cnt: 1, level: nn + 1, name: ii[valname], parent: ii[prev] });
      }
    });
    return qq;
  }

  list.forEach(ii => {
    if (ii[cur] == null || ii[cur] == " ") {
      ii[cur] = "???";
    }

    if (roots(ii, fld, root)) {
      let rec = null;
      if (!find0(tr0, ii[cur])) {
        let rn = root.slice();
        rn.push(ii[cur]);
        rec = list2tree(list, fld, nn + 1, rn, obj, valname);
        if (rec != null) {
          //              let rec0 = JSON.parse(JSON.stringify(rec));
          //              tr0.push({ cnt: rec.length,name: ii[cur], children: rec });
          let cnt0 = rec.length;
          tr0.push({ cnt: cnt0, level: nn + 1, name: ii[cur], parent: ii[prev], _children: rec });
        }
      }
    }
  });
  return tr0;
}

// исходный_список, список_полей_группировок, уровень (0), вспомогательный_список([]), печатать_хначение (true), имя_поля-значения
// data = list2tree(data, ["arhdate","Name"], 0, [], true,"DataValue");
function list2tree2(list, fld, nn, root, obj, valname, id0) {
//  console.log(list)
  if (fld == null) return null;
  if (fld.length < nn || list == null) {
    return null;
  }

  let prev = "";
  let cur = "";
  if (nn > 0) {
    prev = fld[nn - 1];
  }
  cur = fld[nn];
  let tr0 = [];

  if (fld.length == nn && obj) {
    let qq = [];
    list.forEach(ii => {
      if (roots(ii, fld, root)) {
        //            qq.push({ name: JSON.stringify(ii), parent: ii[prev] });
//        qq.push({ id: ii[cur], cnt: 1, level: nn + 1, name: ii[valname], parent: ii[prev] });
        id0++
        qq.push({ id: id0, cnt: 1, level: nn + 1, name: ii[valname], parent: ii[prev] });
      }
    });
    return qq;
  }

  list.forEach(ii => {
    if (ii[cur] == null || ii[cur] == " ") {
      ii[cur] = "???";
    }

    if (roots(ii, fld, root)) {
      let rec = null;
      if (!find0(tr0, ii[cur])) {
        let rn = root.slice();
        rn.push(ii[cur]);
        id0++
        rec = list2tree2(list, fld, nn + 1, rn, obj, valname, id0);
        if (rec != null) {
          //              let rec0 = JSON.parse(JSON.stringify(rec));
          //              tr0.push({ cnt: rec.length,name: ii[cur], children: rec });
          let cnt0 = rec.length;
//          tr0.push({ id: ii[cur], cnt: cnt0, level: nn + 1, name: ii[cur], parent: ii[prev], children: rec });
          tr0.push({ id: id0, cnt: cnt0, level: nn + 1, name: ii[cur], parent: ii[prev], children: rec });
        }
      }
    }
  });
  return tr0;
}

/// Пример: l2t("Водоснабжение", flatlist, ["arhdate", "Name"], "DataValue");
export function l2t(rootname, list, fld, val) {
  // console.log(list)
  let t0 = list2tree(list, fld, 0, [], true, val)
  return { level: 0, name: rootname, parent: "null", children: t0 }
  // let aa =[]
  // aa.push({level: 0, name: rootname, parent:"null", children: t0 })
  // return aa;
}
/// Пример: l2t("Водоснабжение", flatlist, ["arhdate", "Name"], "DataValue");
export function l2t2(rootname, list, fld, val) {
  let t0 = list2tree2(list, fld, 0, [], true, val,1)
  return [{ id: 0, level: 0, name: rootname, parent: "null", children: t0 }]
  // let aa =[]
  // aa.push({level: 0, name: rootname, parent:"null", children: t0 })
  // return aa;
}
////// список в дерево end

