<template>
      <v-dialog v-model="dd" transition="fab-transition">
        <v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small fab @click="dd = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-actions>
          <v-card-text>
            <component :is="dyncomp"> </component>
          </v-card-text>
        </v-card>
      </v-dialog>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    name: String,
    dd: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
    dyncomp() {
      // return () => import("./firesens/firesens.vue");
      let tmp = this.name
       console.log(`${tmp}`)
      return () => import(`${tmp}`);
    },
  },
  mounted() {},
};
</script>
