<template>
    <v-container>
        <v-row class="justify-center text-center">
        <h1>Лента событий</h1>
        </v-row>
      <v-row> <v-divider></v-divider></v-row>
      <routestopage></routestopage>
      <v-row> <v-divider></v-divider></v-row>
    </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import routestopage from "./authvuex/routestopage"
export default {
  components: {routestopage,},
  props: {},
  data() {
    return {};
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
  },
  mounted() {},
};
</script>
