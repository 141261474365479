<template>
  <v-container>
    <v-row class="justify-center align-center text-center" dense>

      <div class="text-h5 font-weight-black">Из 1С</div>
      <v-btn small fab class="ml-4 mb-0">
        <v-progress-circular
          size="25"
          indeterminate
          color="primary"
          class=""
          v-if="progress"
        >
        </v-progress-circular>
        <v-progress-circular
          size="25"
          value="0"
          color="white"
          class=""
          v-else
          @click="start()"
        ></v-progress-circular>
      </v-btn>
      <v-switch
        v-model="addcnt1c"
        label="Количество"
        class="ml-2"
        dense
      ></v-switch>
    </v-row>
    <show1ctree :data1c="data" objsel="Общежитие 5"></show1ctree>
    <v-card v-if="false">
      <v-row>
        <v-col v-if="true">
          <v-card>
            <v-card-text>
              <v-row class="elevation-2">
                <v-breadcrumbs :items="bc1c">
                  <template v-slot:divider>
                    <v-icon>mdi-transfer-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-row>
              <v-sheet class="ma-2 pa-2 primary lighten-2">
                <v-text-field
                  v-model="search1c"
                  label="Поиск"
                  dark
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </v-sheet>
              <v-treeview
                :items="data"
                v-model="sel"
                :search="search1c"
                shaped
                hoverable
                activatable
              >
                <template v-slot:append="{ item }">
                  <badgemy
                    :vis="
                      addcnt1c && item != undefined && item.cnt != undefined
                    "
                    nameicon="mdi-account"
                    :num="item.cnt"
                    coloricon="brown"
                    color="primary"
                    tooltip="Сколько проживает"
                    :overlap="false"
                    class="mr-4"
                  >
                  </badgemy>
                </template>
              </v-treeview>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- {{ data }} -->
      </v-row>
    </v-card>
    <wait :progress="progress" :message="waitmsg"></wait>
    <v-snackbar v-model="sberr" color="red" top right>
      {{ strerr }}
      <v-btn @click="sberr = false" class="ml-5" fab>OK</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import show1ctree from "./1c_tree.vue";
import wait from "../progress2";
import badgemy from "../sensors/badge_my.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {
    wait,
    badgemy,
    show1ctree,
  },
  props: {},
  data() {
    return {
      data: [],
      sensdata: [],
      k1: "level4",
      k2: "people",
      sel: [],
      progress: false,
      fullstruct: true,
      addcnt1c: true,
      addcntorion: true,
      bc1c: [{ text: "Общежитие" }, { text: "Этаж" }, { text: "Комната" }],
      tree1c: ["corpus", "floor", "room"],
      search1c: "",
      searchorion: "",
      map1c: {},
      objsel: "Общежитие 5",
      dormitory: [],
      senstree: {},
      parents: {},
      allrec: false,
      sgdate: "",
      waitmsg: "Подождите, идет загрузка...",
      sberr: false,
      strerr: "Ошибка",
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
    ...mapActions({
      // loadFireSens: "loadFireSens",
      // loadFireSensDates: "loadFireSensDates",
      // loadFireSensObj: "loadFireSensObj",
      // loadpObjects: "loadpObjects",
      //   loadOtv: "loadOtv",
    }),
    parse0(ss, r0) {
      let s0 = ".*" + ss + `\\.([\\dа-яА-ЯёЁ]+).*`;
      let rg = new RegExp(s0, "");
      var match0 = rg.exec(r0);
      if (match0) {
        r0 = match0[1].toLowerCase();
        // console.log(match0);
      } else {
        r0 = "?"; //+r0;
        // console.log(r0["fl"]);
      }
      return r0;
    },
    parse(tt) {
      let r0 = {};
      //let tt = "Комната №11-(50) общ.7б МК эт.1 помещ.11"
      r0["corpus"] = "Общежитие " + this.parse0("общ", tt);
      r0["part"] = "Подъезд ?";
      r0["floor"] = "Этаж " + this.parse0("эт", tt);
      // r0["group"] = "Помещение " + this.parse0("помещ", tt);
      // var match0 = /Комната\s+.?([\dа-яА-ЯёЁ]+).?\((.*)\).*/.exec(tt);
      var match0 = /(Комната\s+.?([\dа-яА-ЯёЁ]+).?\((.*)\)).*/.exec(tt);
      // var match0 = /(Комната\s+.?[\dа-яА-ЯёЁ]+.?\(.*\)).*/.exec(tt);
      //var match0 = /Комната\s+.?(\d+).*/.exec(tt);
      if (match0) {
        // r0["room"] = "Комната " + match0[1];
        // r0["roomname"] = "(" + match0[2] + ")";
        //  r0["room"] = "Комната " + match0[2]+ " -- в 1С: "+ match0[1]+"-(" + match0[2] + ")";
        // r0["room"] = "Комната " + match0[3] + " или " + match0[1];
        r0["room"] = "Комната " + match0[3].toLowerCase();
        r0["roomname"] = match0[1];
        // console.log(match0);
      } else {
        r0["room"] = "Ошибка формата. Строка: " + tt;
        r0["roomname"] = "(" + tt + ")";
        // console.log(r0["fl"]);
      }
      return r0;
    },
    fillarr(ccc, mm, tmp, pp) {
      //let qqq = {}
      // if (tmp.parents != "undefined") return;
      if (mm.length == 1) {
        if (!ccc[tmp[mm[0]]]) {
          ccc[tmp[mm[0]]] = {};
          ccc[tmp[mm[0]]].arr = [];
          ccc[tmp[mm[0]]].cnt = 0;
          ccc[tmp[mm[0]]].fire = 0;
          ccc[tmp[mm[0]]].alert = 0;
          ccc[tmp[mm[0]]].fireitem = [];
          ccc[tmp[mm[0]]].alertitem = [];
        }
        //console.log(pp)
        if (tmp.parents != "undefined") {
          ccc[tmp[mm[0]]].razdel = "-1";
        } else {
          ccc[tmp[mm[0]]].razdel = tmp.razdel;
        }
        // ccc[tmp[mm[0]]].razdel = tmp.razdel;

        if (pp) {
          //console.log(tmp)
          ccc[tmp[mm[0]]].arr.push(tmp[pp]);
          ccc[tmp[mm[0]]].cnt++;
        }
        return;
      }
      if (!ccc[tmp[mm[0]]]) {
        ccc[tmp[mm[0]]] = {};
        ccc[tmp[mm[0]]].cnt = 0;
        ccc[tmp[mm[0]]].fire = 0;
        ccc[tmp[mm[0]]].alert = 0;
      }
      ccc[tmp[mm[0]]].cnt++;
      if (tmp.parents != "undefined") {
        ccc[tmp[mm[0]]].razdel = "-1";
      } else {
        ccc[tmp[mm[0]]].razdel = tmp.razdel;
      }
      // if (tmp[mm[0]]["event"] == "fire"){
      //   ccc[tmp[mm[0]]].fire++;
      // }
      this.fillarr(ccc[tmp[mm[0]]], mm.slice(1, mm.length + 1), tmp, pp);
      // return ccc
    },
    trans(t1, t2, i1, i2) {
      if (i1 >= this.tree1c.length) return;
      if (i2 >= this.treeorion.length) return;
      // console.log( ">>", i1, "-", i2, " ", this.tree1c[i1], " -> ", this.treeorion[i2] );
      for (let k in t2) {
        if (
          k == "cnt" ||
          k == "fire" ||
          k == "alert" ||
          k == "arr" ||
          k == "razdel"
        )
          continue;
        //if (typeof t2[k] !== "object") continue;
        //         while (this.tree1c[i1] != this.treeorion[i2] && i2 < this.treeorion.length) {
        //         i2++
        //         //if (i1 >= this.tree1c) continue
        // //        if (i2 >= this.treeorion) continue
        //         t2 = t2[k]
        //       }
        // if (t1[k] &&  t2[k] && t2[k].cnt && t1[k].cnt) {
        // t2[k].cnt = 0;
        // if (!t2[k]) {
        // }
        t2[k].cnt = "?";
        if (t1[k] != undefined && t2[k] != undefined) {
          //         console.log(t1[k])
          t2[k].cnt = t1[k].cnt;
          if (t1[k].arr != undefined || t2[k].arr != undefined) {
            t2[k].arr = t1[k].arr;
          }
          // console.log(k, ":", t1[k], "--", t2[k]);
          // console.log("  ??", i1,"-", i2, " ", this.tree1c[i1], " -> ", this.treeorion[i2] );
          if (this.tree1c[i1] == this.treeorion[i2]) {
            // console.log("  !!", i1, "-", i2, " ", this.tree1c[i1], " -> ", this.treeorion[i2]);
            let i11 = i1 + 1;
            let i22 = i2 + 1;
            this.trans(t1[k], t2[k], i11, i22);
          } else {
            let i22 = i2 + 1;
            // console.log(this.tree1c[i1], " -> ", this.treeorion[i2]);
            this.trans(t1[k], t2[k], i1, i22);
          }
        } else {
          let i22 = i2 + 1;
          // console.log("    ##",k,this.tree1c[i1], " -> ", this.treeorion[i2]);
          this.trans(t1, t2[k], i1, i22);
        }
      }
    },
    trans0(t1, t2) {
      for (let k in t2) {
        // if (k == "cnt" || k == "fire" || k == "alert" || k == "arr") continue;
        if (typeof t2[k] !== "object") continue;
        // console.log(k, " -> ", t1[k]);
        // if (t1[k] &&  t2[k] && t2[k].cnt && t1[k].cnt) {
        t2[k].cnt = 0;
        if (t1[k] != undefined && t2[k] != undefined) {
          t2[k].cnt = t1[k].cnt;
          if (t1[k].arr != undefined || t2[k].arr != undefined)
            t2[k].arr = t1[k].arr;
          this.trans(t1[k], t2[k]);
        }
      }
    },
    async start() {
      this.progress = true;
      // await this.sens();
      // let k1 = "level4";
      // let k2 = "people";
      let k1 = "roominfo";
      let k2 = "residinginfo";
      let url = "sg.json";
      let resp;
      let sg;
      this.waitmsg = "Загрузка из 1С...";
      resp = await fetch(url);
      sg = await resp.json();
      if (this.error != "") {
        this.strerr = "Ошибка загрузки из 1С: " + this.error;
        this.sberr = true;
      }
      // console.log(sg)
      sg = sg.data;
      let ccc = {};
      this.data = [];
      sg.forEach((s) => {
        let tmp = {};
        tmp[k1] = s[k1];
        tmp[k2] = s[k2];
        tmp = this.parse(s[k1]);
        tmp[k2] = s[k2];

        this.fillarr(ccc, this.tree1c, tmp, k2);
      });
      // console.log(ccc)
      // console.log(this.totree("kbsu",[],ccc))
      // this.waitmsg = "Отображение данных...";
      this.map1c = ccc;
      this.data = this.totree("kbsu", [], ccc);
      // console.log(this.data)
      // await this.sens();
      // await this.show();
      this.progress = false;
    },
    totree(id, arr, ob) {
      // console.log(ob)
      if (ob && ob.arr && Array.isArray(ob.arr)) {
        let str = [];
        ob.arr.forEach((p) => {
          str.push({
            id: id + "," + p,
            cnt: ob.cnt,
            name: p,
          });
        });
        // let str = "Жильцов: " + ob.arr.length
        return [{ id: id, name: "Жильцов: " + ob.arr.length, children: str }];
      }
      //let tid = id
      for (let kk in ob) {
        if (kk == "cnt" || kk == "fire" || kk == "alert" || kk == "razdel")
          continue;
        let nid = id + "," + kk;
        //nid = id+ (id*Object.keys(ob).length)+1
        arr.push({
          id: nid,
          name: kk,
          children: this.totree(nid, [], ob[kk]),
          cnt: ob[kk].cnt,
          fire: ob[kk].fire,
          alert: ob[kk].alert,
        });
      }
      //return arr
      return arr.sort((a, b) => {
        return this.cstr(a.name, b.name);
        //return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      });
    },
    cstr(a, b) {
      let m1 = /(.*?)(\d+).*/.exec(a);
      let m2 = /(.*?)(\d+).*/.exec(b);
      let r1, r2;
      let z1, z2;
      //console.log(m1[1])
      if (m1 && m2) {
        let n1 = m1[2] + "";
        let n2 = m2[2] + "";
        //console.log(n1, " ",n1.length)
        z1 = n1;
        z2 = n2;
        //n1 = Number(n1)
        //n2 = Number(n2)
        //console.log(n1, " ", n2, " ",n1.length-n2.length)
        if (n1.length > n2.length) {
          for (let i = 0; i < n1.length - n2.length; i++) {
            z2 = "0" + z2;
          }
        } else {
          for (let i = 0; i < n2.length - n1.length; i++) {
            z1 = "0" + z1;
          }
        }
        r1 = m1[1] + z1;
        r2 = m2[1] + z2;
      } else {
        r1 = a;
        r2 = b;
      }
      // console.log(r1, " ", r2)
      return r1 > r2 ? 1 : r1 < r2 ? -1 : 0;
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
    ...mapState({
      firesens: (state) => state.firesens,
      // firesensdates: (state) => state.firesensdates,
      pobjects: (state) => state.pobjects,
      error: (state) => state.error,
      firesensobj: (state) => state.firesensobj,
      //   otv: (state) => state.otv,
      //      nogroup: (state) => state.authm.nogroup,
    }),
  },
  watch: {
    fullstruct: function () {
      if (this.fullstruct) {
        this.bc1c = [
          { text: "Общежитие" },
          { text: "Этаж" },
          // { text: "Помещение" },
          { text: "Комната" },
        ];
        this.bcorion = [
          { text: "Общежитие" },
          // { text: "Блок" },
          { text: "Подъезд" },
          { text: "Этаж" },
          { text: "Комната" },
        ];

        // this.treeorion = ["corpus", "block", "part", "floor", "room"];
        this.treeorion = ["corpus", "part", "floor", "room"];
        this.tree1c = ["corpus", "floor", "room"];

        // this.bc1c = ["","","","",""]
      } else {
        this.bc1c = [
          { text: "Общежитие" },
          { text: "Этаж" },
          { text: "Комната" },
        ];
        this.bcorion = [
          { text: "Общежитие" },
          { text: "Этаж" },
          { text: "Комната" },
        ];
        this.treeorion = ["corpus", "floor", "room"];
        this.tree1c = ["corpus", "floor", "room"];
      }
      this.show();
    },
    objsel: function () {
      this.show();
    },
    allrec: function () {
      this.show();
    },
  },
  mounted() {
    this.start();
  },
};
</script>
