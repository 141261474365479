<template>
  <v-container fluid>
    <v-row class="align-center ma-0" dense>
      <v-col cols="10">
        <v-text-field v-model="mask" label="Поиск устройств"> </v-text-field>
        <!-- <v-autocomplete
          v-model="printer"
          :items="prns"
          item-text="name"
          dense
          label="Принтеры"
        ></v-autocomplete> -->
      </v-col>
      <v-col cols="1">
        {{ printers.length }}
      </v-col>
    </v-row>
    <!-- <v-row dense>
      <v-checkbox v-model="prnall" label="Показывать занятые"></v-checkbox>
    </v-row> -->
    <v-row dense>
      <v-select
        v-model="flt"
        :items="filters"
        attach
        chips
        label="Фильтр"
        multiple
      ></v-select>
    </v-row>
    <v-row dense>
      <v-virtual-scroll
        :items="printers"
        :item-height="55"
        :height="hhh"
        width="270px"
        class="pa-0 ma-0"
      >
        <template v-slot:default="{ item }">
          <!-- <v-list-item dense draggable="true" style="cursor: move; border: 1px solid black;"> -->
          <v-divider></v-divider>
          <v-list-item
            :draggable="!item.prnbusy"
            @dragstart="prnstart($event, item)"
            @dragend="prnend($event, item)"
            :style="(item.prnbusy || !item.hostid) ? 'cursor: default;' : 'cursor: move;'"
            class="align-center"
          >
            <!-- <v-row>
              <v-col cols="12"> -->
            <v-list-item-content>
              <v-list-item-title>
                <v-tooltip top v-if="prncolor(item).msg != ''">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                      :color="prncolor(item).color"
                      @click="banner = true"
                      >{{ hosticon(item) }}</v-icon
                    >
                  </template>
                  {{ prncolor(item).msg }}
                </v-tooltip>
                <v-icon v-else color="green">{{ hosticon(item) }}</v-icon>
                <span>{{ item.name }}</span>
              </v-list-item-title>
              <v-list-item-subtitle v-text="item['Model Name 2']"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" color="blue" class="mr-0"
                      >mdi-information</v-icon
                    >
                  </template>
                  {{ item.inventory.model }} <br />
                  S/N: {{ item.inventory.serialno_a }} <br />
                  {{ item.inventory.site_address_a }}<br />
                  <div v-for="cc in item.items" :key="cc.itemid">
                    {{ cc.name }}: {{ cc.lastvalue }}% <br />
                  </div>
                </v-tooltip>
                <v-tooltip top v-if="item.prnbusy">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                      color="brown"
                      class="mr-0"
                      @click="prnonplan(item)"
                      >mdi-map-marker</v-icon
                    >
                  </template>
                  Показать место установки <br />
                  {{ item.address }}
                </v-tooltip>
                <v-tooltip top v-if="!item.prnbusy && item.hostid">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                      color="green"
                      class="mr-0"
                      @click="prnadd(item)"
                      >mdi-arrow-right-circle</v-icon
                    >
                  </template>
                  Добавить принтер на план этажа
                </v-tooltip>
                <div class="text-center">
                  <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="brown"
                        class="mr-0"
                        @click="banner = true"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-chevron-down</v-icon
                      >
                    </template>
                    <printercard :item="item"></printercard>
                    <!-- <v-card class="mt-1">
                      <v-card-title>{{ item.name }}</v-card-title>
                      <v-card-subtitle>{{ item.inventory.model }}</v-card-subtitle>
                      <v-card-text>
                        S/N: {{ item.inventory.serialno_a }} <br />
                        Адрес: {{ item.inventory.site_address_a }}<br />
                        <br />
                        <div
                          v-for="cc in item.items"
                          :key="cc.itemid"
                          :color="cc.name.split(' ')[2]"
                        >
                          {{ cc.name }}: {{ cc.lastvalue }}% <br />
                        </div>
                      </v-card-text>
                    </v-card> -->
                    <!-- {{cc.name.split(' ')[2].}} -->
                  </v-menu>
                </div>
              </v-row>
            </v-list-item-action>
            <!-- </v-col>
            </v-row> -->
            <!-- <v-row>
              <v-col cols="12"> -->
            <!-- <v-banner v-model="banner">
            <template v-slot:actions="{ dismiss }">
              <v-btn text color="primary" @click="dismiss"> Dismiss </v-btn>
            </template>
            {{ item }}
          </v-banner> -->
            <!-- </v-col>
          </v-row> -->
          </v-list-item>
          <!-- <v-divider></v-divider> -->
          <!-- <v-list-item dense draggable="true" class="elevation-1" style="cursor: move">
            <v-row class="align-center">
              {{ item.name }} <v-spacer></v-spacer>
              <v-icon color="green" class="mr-0" @click="prnadd(item)"
                >mdi-arrow-right-circle</v-icon
              >
              <v-icon color="blue" class="mr-0" @click="prnadd(item)"
                >mdi-information</v-icon
              >
            </v-row>
          </v-list-item> -->
          <!-- <v-card
                    elevation="1"
                    outlined
                    draggable="true"
                    @dragstart="prnstart($event, item)"
                    @dragend="prnend($event, item)"
                  >
                    <v-card-title>
                      <v-icon large class="mr-3">mdi-printer</v-icon>
                      {{ item.name }}
                    </v-card-title>
                    <v-card-subtitle>
                      {{ item.inventory.model }},
                      {{ item.inventory.site_address_a }} S/N:{{
                        item.inventory.serialno_a
                      }}
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-btn @click="prnadd(item)">Добавить</v-btn>
                    </v-card-actions>
                  </v-card> -->
          <!-- </v-col> -->
        </template>
      </v-virtual-scroll>
    </v-row>
  </v-container>
</template>
<script>
import printercard from "./printercard";
export default {
  components: {
    printercard,
  },
  props: {
    prns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    hhh: { type: Number, default: 300 },
  },
  data() {
    return {
      printer: "",
      mask: "",
      prnall: true,
      banner: false,
      filters: ["Принтеры","Компьютеры","wifi"],
      // flt: ["Показывать занятые","Принтеры","Компьютеры"],
      flt: ["Принтеры","Компьютеры","wifi"],
    };
  },
  methods: {
    hosticon(item) {
      let ii = "mdi-desktop-classic";
      switch (item.tip) {
        case "Принтер":
          ii = "mdi-printer";
          break;
        case "Компьютер":
          ii = "mdi-desktop-classic";
          break;
        case "wifi":
          ii = "mdi-wifi";
          break;
      }
      return ii;
    },
    prncolor(item) {
      let color = "green";
      let msg = "";
      // console.log(item);
      if (item.kbsu_hosts_ping == "0") {
        color = "orange";
        msg += "Недоступен по сети";
      }
      if (item.items == undefined) {
        return { color: color, msg: msg };
      }
      if (
        !item.items.every((p) => {
          return Number(p.lastvalue) > 3;
        })
      ) {
        color = "red";
        msg = "Необходима замена картриджа";
      }
      return { color: color, msg: msg };
    },
    prnstart($event, prn) {
      $event.printer = prn;
      this.$emit("prnstart", $event);
    },
    prnend($event, prn) {
      $event.printer = prn;
      this.$emit("prnend", $event);
    },
    prnadd(prn) {
      this.$emit("prnadd", prn);
    },
    prnonplan(prn) {
      this.$emit("prnonplan", prn);
    },
    myfilter(prns) {
        return prns.filter((p) => {
          let rr = false
          this.flt.forEach(f => {
          switch(f) {
            case "Принтеры":
              rr = rr || (p.tip == "Принтер")
              break
            case "Компьютеры":
              rr = rr || (p.tip == "Компьютер")
              break
            case "wifi":
              rr = rr || (p.tip == "wifi")
              break
            // case "Показывать занятые":
            //   rr = rr || p.prnbusy
            //   break
          }
          });
          return rr 
          // return this.prnall || !p.prnbusy;
        });
    },
  },
  computed: {
    printers: function () {
      if (this.mask == "") {
        return this.myfilter(this.prns);
      }
      let pp = this.prns.filter((p) => {
        // console.log(p.name + " "+ this.mask)
        return p.name.toLowerCase().indexOf(this.mask.toLowerCase()) != -1  || ( p["Model Name 2"] && p["Model Name 2"].toLowerCase().indexOf(this.mask.toLowerCase()) != -1) 
        // return p.name.indexOf(this.mask) != -1  || ( p.["Model Name 2"] && p.["Model Name 2"].indexOf(this.mask) != -1) 
         // (this.prnall || !p.prnbusy);
        // return p.name.indexOf(this.mask) != -1 && this.myfilter() // (this.prnall || !p.prnbusy);
      });
       pp =  pp && this.myfilter(pp)
      //    console.log(pp)
      return pp;
    },
  },
  mounted() {},
};
</script>
