<template>
  <v-container>
    <!-- {{ firesens }} -->
    <v-row class="justify-center align-center text-center" dense>
      <div class="text-h5 font-weight-black">
        Анализ происшествий
      </div>
    </v-row>
    <v-row class="justify-center align-center ma-1">
      <!-- <v-col> -->
      <!-- <v-row class="justify-center align-center ma-0">
                    <v-col> -->
      <v-btn small color="primary" class="mr-1" @click="start()"
        >Обновить</v-btn
      >
      <di
        :rdates="rdt"
        v-on:dateschange="
          sday = $event[0];
          sday2 = $event[1];
          show();
        "
        :dis="allrec"
      ></di>
      <v-checkbox v-model="allrec" label="Все записи"></v-checkbox>
      <!-- </v-col>
                </v-row> -->
      <!-- </v-col> -->
    </v-row>
    <v-row class="align-center justify-start">
      <v-col cols="6">
        <v-autocomplete :items="firesensobj" v-model="objsel"> </v-autocomplete>
      </v-col>
      <v-col>
        <badgemy
          :vis="true"
          nameicon="mdi-fire"
          :num="fire"
          coloricon="red"
          color="primary"
          tooltip="Пожар"
          class="mr-2"
        ></badgemy>
        <badgemy
          :vis="true"
          nameicon="mdi-alert-decagram"
          :num="alert"
          coloricon="red"
          color="primary"
          tooltip="Внимание! Опасность пожара"
          class="mr-2"
        ></badgemy>
        <v-tooltip top>
          <template v-slot:activator="{ on2 }">
            <v-menu offset-x>
              <template v-slot:activator="{ on }">
                <!-- <badgemy
              :vis="true"
              nameicon="mdi-fire-truck"
              :num="truck"
              coloricon="red"
              color="blue"
              tooltip="Сигнал в МЧС"
              class="mr-2"
              :fun = "on"
              v-on="on"
            ></badgemy> -->
                <v-badge :overlap="false" :content="truck">
                  <v-icon color="red" v-on="on || on2">mdi-fire-truck</v-icon>
                </v-badge>
              </template>
              <v-list v-if="truck > 0">
                <v-list-item v-for="ii in truckitem" :key="ii.GUID">
                  <v-list-item-icon>
                    <v-icon color="red">mdi-fire-truck</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ new Date(ii.DeviceTime).toLocaleString("ru") }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          Сигнал в МЧС
        </v-tooltip>
        <!-- <badgemy
          :vis="true"
          nameicon="mdi-fire-truck"
          :num="truck"
          coloricon="red"
          color="blue"
          tooltip="Сигнал в МЧС"
          class="mr-2"
        ></badgemy> -->
      </v-col>
    </v-row>
    <v-simple-table dense style="border: 2px solid black">
      <thead>
        <th style="border: 1px solid black"></th>
        <th v-for="(v2, j1) in pode" :key="j1" style="border: 1px solid black">
          Подъезд {{ v2 }}
        </th>
      </thead>
      <!-- v-if="obj[v] &&  obj[v][v2] &&  Object.keys(obj[v][v2]).length != 0"       -->
      <tbody v-if="obj">
        <tr v-for="(v, i) in etazh" :key="i" dense>
          <td style="border: 1px solid black" class="text-center">
            {{ v }}&nbsp;этаж
          </td>
          <template v-for="(v2, j) in pode">
            <td :key="j" style="border: 1px solid black">
              <!-- <v-row dense class="align-center justify-space-around"> -->
              <!-- <v-row dense class="align-center justify-space-between"> -->
              <v-row dense class="align-center justify-space-around">
                <!-- <v-row dense class="align-center justify-center"> -->
                <template v-for="(v3, k) in levy">
                  <!-- <template > -->
                  <v-card
                  :key="k"
                    flat
                    outlined
                    class="text-center align-center justify-center"
                    elevation="2"
                    color=""
                    v-if="
                      obj[v] &&
                      obj[v][v2] &&
                      obj[v][v2][v3] &&
                      Object.keys(obj[v][v2][v3]).length != 0
                    "
                  >
                    <v-card-subtitle class="text-center"
                      >{{ v3 }}
                    </v-card-subtitle>
                    <v-card-text
                      class="text-center align-center justify-center"
                    >
                      <v-row
                        dense
                        class="text-center align-center justify-center"
                      >
                        <v-col
                          v-if="
                            obj[v] &&
                            obj[v][v2] &&
                            obj[v][v2][v3] &&
                            obj[v][v2][v3][1] &&
                            obj[v][v2][v3][1].n > 0
                          "
                        >
                          <v-menu offset-x>
                            <template v-slot:activator="{ on }">
                              <v-badge
                                :overlap="false"
                                :content="obj[v][v2][v3][1].n"
                              >
                                <v-icon color="red" v-on="on">mdi-fire</v-icon>
                              </v-badge>
                            </template>
                            <!-- <v-card> -->
                            <v-list
                              v-if="
                                obj[v] &&
                                obj[v][v2] &&
                                obj[v][v2][v3] &&
                                obj[v][v2][v3][1]
                              "
                            >
                              <v-list-item
                                v-for="ii in obj[v][v2][v3][1].items"
                                :key="ii.GUID"
                              >
                                <v-list-item-icon>
                                  <v-icon color="red">mdi-fire</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  {{
                                    new Date(ii.DeviceTime).toLocaleString("ru")
                                  }}
                                  <v-list-item-subtitle>
                                    {{ ii.Name }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle>
                                    {{ ii.Descr }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle>
                                    Причина: {{ ii.T1 }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle>
                                    Примечание: {{ ii.Txt }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <!-- </v-card> -->
                          </v-menu>
                        </v-col>
                        <v-col
                          v-if="
                            obj[v] &&
                            obj[v][v2] &&
                            obj[v][v2][v3] &&
                            obj[v][v2][v3][2] &&
                            obj[v][v2][v3][2].n > 0
                          "
                        >
                          <v-menu offset-x>
                            <template v-slot:activator="{ on }">
                              <v-badge
                                :overlap="false"
                                :content="obj[v][v2][v3][2].n"
                              >
                                <v-icon color="red" v-on="on"
                                  >mdi-alert-decagram</v-icon
                                >
                              </v-badge>
                            </template>
                            <!-- <v-card> -->
                            <v-list
                              v-if="
                                obj[v] &&
                                obj[v][v2] &&
                                obj[v][v2][v3] &&
                                obj[v][v2][v3][2]
                              "
                            >
                              <v-list-item
                                v-for="ii2 in obj[v][v2][v3][2].items"
                                :key="ii2.GUID"
                              >
                                <v-list-item-icon>
                                  <v-icon color="red"
                                    >mdi-alert-decagram</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-content>
                                  {{
                                    new Date(ii2.DeviceTime).toLocaleString(
                                      "ru"
                                    )
                                  }}
                                  <v-list-item-subtitle>
                                    {{ ii2.Name }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle>
                                    {{ ii2.Descr }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle>
                                    Причина: {{ ii2.T1 }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle>
                                    Примечание: {{ ii2.Txt }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <!-- </v-card> -->
                          </v-menu>
                          <!-- <v-badge overlap :content="obj[v][v2][v3][2].n"
                                                    v-if="obj[v][v2][v3] && obj[v][v2][v3][2].n > 0">
                                                    <v-icon color="red">mdi-alert-decagram</v-icon>
                                                </v-badge> -->
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </template>
              </v-row>
            </td>
          </template>
        </tr>
      </tbody>
    </v-simple-table>
    <wait :progress="progress"></wait>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import wait from "../progress";
import di from "../dateinterval";
import badgemy from "../sensors/badge_my.vue";
export default {
  components: {
    wait,
    di,
    badgemy,
  },
  props: {},
  data() {
    return {
      obj: null,
      progress: false,
      etazh: null,
      pode: null,
      levy: null,
      objsel: "Общежитие №5",
      sday: new Date().toISOString().substring(0, 10),
      sday2: new Date().toISOString().substring(0, 10),
      rdt: [this.sday, this.sday2],
      allrec: false,
      fire: 0,
      alert: 0,
      truck: 0,
      truckitem: [],
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
    ...mapActions({
      loadFireSens: "loadFireSens",
      loadFireSensDates: "loadFireSensDates",
      loadFireSensObj: "loadFireSensObj",
      loadpObjects: "loadpObjects",
      //   loadOtv: "loadOtv",
    }),
    async start() {
      this.progress = true;
      //   await this.loadFireSensDates();
      //   if (this.error != "") {
      //     this.strerr = "Ошибка загрузки списка дат: " + this.error;
      //     this.sberr = true;
      //     //        this.progress = false;
      //   }
      await this.loadFireSensObj();
      if (this.error != "") {
        this.strerr = "Ошибка загрузки списка объектов: " + this.error;
        this.sberr = true;
        //        this.progress = false;
      }
      //console.log(this.firesensobj)
      // console.log(this.objsel)
      let cur;
      await this.loadFireSens();
      // this.firesens = JSON.parse(jj);

      if (this.error == "") {
        //this.obj = []
        // cur = this.firesens.filter(f => {
        //     return f.Corpus == this.objsel
        // })
      }
      await this.rooms(this.firesens);
      // await this.loadpObjects();
      // console.log(this.pobjects)
      // await this.rooms(this.pobjects);
      await this.show();
      this.progress = false;
    },
    async rooms(tmp) {
      //let floor = {}
      this.etazh = [];
      this.pode = [];
      this.levy = [];
      let floors = [];
      let ppp = [];
      let dir = [];
      // let reg = /(.*?)\s+(\d+)?\s*п?\s*(\d+)\s*эт\s*([\wа-яА-ЯёЁ\d]*)\s*([\wа-яА-ЯёЁ\d]*)\s*/
      tmp.forEach((t) => {
        if (this.objsel == t.Corpus) {
          // var match = reg.exec(t.Name);
          // if (t.Name == "") {
          //     match = ["",]
          // }
          // if (match) {
          //     let fl = match[3]
          //     if (fl == undefined) {
          //         fl = "?"
          //     }
          //     let pp = match[2]
          //     if (pp == undefined) {
          //         pp = "???"
          //     }
          //     let lr = match[5]
          //     if (lr == undefined) {
          //         lr = "???"
          //     }
          // if (t.Name != "" || t.Descr != "" || t.Event == "360") {
          let t0 = this.parse(t);
          let fl, pp, lr;
          fl = t0["fl"];
          pp = t0["pod"];
          lr = t0["lr"];

          if (t.Event != "360") {
            if (floors.indexOf(fl) == -1) {
              floors.push(fl);
            }
            if (ppp.indexOf(pp) == -1) {
              ppp.push(pp);
            }
            if (dir.indexOf(lr) == -1) {
              dir.push(lr);
            }
          }
        }
      });
      // this.obj = JSON.stringify(floor, "", 2)
      floors.sort();
      ppp.sort();
      dir.sort();
      // console.log(floors)
      // console.log(ppp)
      // console.log(dir)
      this.etazh = floors;
      this.pode = ppp;
      this.levy = dir; //["лев", "прав", "?"] //dir
    },
    async show() {
      this.progress = true;
      await this.rooms(this.firesens);
      let d1 = new Date(this.sday + " 00:00:00");
      let d2 = new Date(this.sday2 + " 23:59:59");
      let tmp = this.firesens.filter((f) => {
        let dd = new Date(f.DeviceTime);
        return (
          f.Corpus == this.objsel && ((dd >= d1 && dd <= d2) || this.allrec)
        );
        //  {
        //     return true
        // }
      });
      // let rr = await fetch("t.json")
      // let tmp = await rr.json();
      // let t = "Общ №5 3п 2эт корид прав"
      // let reg = /(.*)(\d+).*п\s*(\d+)\s* эт.*\s*(.*)/
      // let reg = /(.*)\s+(\d+)\s*п\s*(\d+)\s*эт\s*(.*)\s*(.*)/
      // let reg = /(.*?)\s+(\d+\s*п)?\s*(\d+)\s*эт\s*(.*)\s*(.*)/
      //let reg = /(.*?)\s+(\d+)?\s*п?\s*(\d+)\s*эт\s*([\wа-яА-ЯёЁ\d]*)\s*([\wа-яА-ЯёЁ\d]*)\s*/
      this.obj = {};
      let floor = {};
      // let floors = []
      // let ppp = []
      // let dir = []
      this.fire = 0;
      this.alert = 0;
      this.truck = 0;
      this.truckitem = [];
      let fl, pp, lr;
      tmp.forEach((t) => {
        // var match = reg.exec(t.Name);
        // if (t.Name == "") {
        //     match = ["",]
        // }
        // if (match) {
        //     let fl = match[3]
        //     if (fl == undefined) {
        //         fl = "?"
        //     }
        //     let pp = match[2]
        //     if (pp == undefined) {
        //         pp = "???"
        //     }
        //     let lr = match[5]
        //     if (lr == undefined) {
        //         lr = "???"
        //     }
        // if (t.Name != "" || t.Descr != "" || t.Event == "360") {
        let t0 = this.parse(t);
        fl = t0["fl"];
        pp = t0["pod"];
        lr = t0["lr"];

        // if (fl == "?") {
        //   console.log(t);
        // }
        // if (floors.indexOf(fl) == -1) {
        //     floors.push(fl)
        // }
        // if (ppp.indexOf(pp) == -1) {
        //     ppp.push(pp)
        // }
        // if (dir.indexOf(lr) == -1) {
        //     dir.push(lr)
        // }
        //console.log(match)
        if (!floor[fl]) {
          floor[fl] = {};
        }
        if (!floor[fl][pp]) {
          floor[fl][pp] = {};
        }
        if (!floor[fl][pp][lr]) {
          floor[fl][pp][lr] = {};
        }
        // if (!floor[fl][pp][lr][1]) {
        //   floor[fl][pp][lr][1] = {};
        //   floor[fl][pp][lr][1].n = 0;
        //   floor[fl][pp][lr][1]["items"] = [];
        // }
        // if (!floor[fl][pp][lr][2]) {
        //   floor[fl][pp][lr][2] = {};
        //   floor[fl][pp][lr][2].n = 0;
        //   floor[fl][pp][lr][2]["items"] = [];
        // }
        // if (!floor[fl][pp][lr][3]) {
        //     floor[fl][pp][lr][3] = 0
        //     floor[fl][pp][lr][3]["items"] = []
        // }
        // if (!floor[fl][pp][lr]["items"]) {
        //     floor[fl][pp][lr]["items"] = []
        // }
        switch (t.Event) {
          case "37":
          case "40":
            if (!floor[fl][pp][lr][1]) {
              floor[fl][pp][lr][1] = {};
              floor[fl][pp][lr][1].n = 0;
              floor[fl][pp][lr][1]["items"] = [];
            }
            floor[fl][pp][lr][1].n++;
            floor[fl][pp][lr][1]["items"].push(t);
            this.fire++;
            break;
          case "44":
            if (!floor[fl][pp][lr][2]) {
              floor[fl][pp][lr][2] = {};
              floor[fl][pp][lr][2].n = 0;
              floor[fl][pp][lr][2]["items"] = [];
            }
            floor[fl][pp][lr][2].n++;
            floor[fl][pp][lr][2]["items"].push(t);
            // console.log(t)
            this.alert++;
            break;
          case "360":
            //console.log(t)
            // floor[fl][pp][lr][3]++
            this.truck++;
            this.truckitem.push(t);
            break;
          default:
            // console.log(t);
            break;
        }
        // } else {
        //     console.log("--" + JSON.stringify(t.Name) + "==" + t.Corpus)
        // }
      });
      // this.obj = JSON.stringify(floor, "", 2)
      // for (let k0 in floor[fl][pp]) {
      //   console.log(k0)
      //   if (Object.keys(floor[fl][pp][k0]).length == 0) {
      //     delete floor[fl][pp][k0]
      //   }
      // }
      this.obj = floor;
      // floors.sort()
      // ppp.sort()
      // dir.sort()
      //console.log(floor)
      // console.log(ppp)
      // console.log(this.obj)
      // this.etazh = floors
      // this.pode = ppp
      // this.levy = dir

      // console.log(this.obj)
      //console.log(tmp)
      // this.strok = "Данные успешно загружены.";
      // this.sbok = true;
      // // console.log(this.firesens)
      // // this.fsfilter();
      // this.upd += 1;
      //     this.progress = false;
      //   } else {
      //     // this.strerr = "Ошибка соединения: " + this.error;
      //     // this.sberr = true;
      this.progress = false;
      //   }
    },
    parse0(r0) {
      var match0 = /.*(\d+).*/.exec(r0);
      if (match0) {
        r0 = match0[1];
        // console.log(match0);
      } else {
        r0 = "?";
        // console.log(r0["fl"]);
      }
      return r0;
    },
    parse(t0) {
      if (t0.Descr != "") {
        // "Общежитие №5/Блок 2/Подъезд 4/Этаж 3/Кухня левая
        let tmp = t0.Descr.split("/");
        let r0 = {};
        let m0;
        for (let i = 1; i < tmp.length; i++) {
          if (tmp[i].trim() == "") {
            tmp[i] = "?";
          }
          //  else {
          //   m0 = /(.*)(\d+)\s*/.exec(tmp[i].toUpperCase());
          //   if (m0) {
          //     r0[m0[1]] = m0[2];
          //     console.log(m0);
          //   } else {
          //     r0["?"] = tmp[i];
          //   }
          // }
        }
        // return r0;
        // r0["fl"] = tmp[3];
        // var match0 = /.*(\d+).*/.exec(r0["fl"]);
        // if (match0) {
        //   r0["fl"] = match0[1];
        //   console.log(match0);
        // } else {
        //   r0["fl"] = "?";
        //   console.log(r0["fl"]);
        // }
        r0["fl"] = this.parse0(tmp[3]);
        r0["pod"] = this.parse0(tmp[2]);
        r0["lr"] = tmp[4];
        return r0;
      }
      // return null;

      let t = t0.Name;
      let reg = {};
      reg["fl"] = /.*(\d+)\s*эт.*/;
      reg["pod"] = /.*(\d+)\s*п.*/;
      reg["lr"] = /.*(прав|лев|левая|правая).*/;
      // let t = "Общ №5 3п 2эт корид прав"
      let rr = {};
      for (var key in reg) {
        var match = reg[key].exec(t);
        if (match) {
          rr[key] = match[1];
        } else {
          rr[key] = "?";
        }
      }
      // console.log(rr)
      // rr = {}
      // rr.fl="fl"
      // rr.pod="pod"
      // rr.lr="lr"
      return rr;
    },
  },
  watch: {
    objsel: function () {
      this.show(this.objsel);
    },
    allrec: function () {
      this.show(this.objsel);
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
    ...mapState({
      firesens: (state) => state.firesens,
      firesensdates: (state) => state.firesensdates,
      firesensobj: (state) => state.firesensobj,
      pobjects: (state) => state.pobjects,
      error: (state) => state.error,
      //   otv: (state) => state.otv,
      //      nogroup: (state) => state.authm.nogroup,
    }),
  },
  mounted() {
    let d0 = new Date();
    d0.setDate(d0.getDate() - 30);
    this.sday = d0.toISOString().substring(0, 10);
    let d1 = new Date();
    //    d1.setDate(d1.getDate() - 2);
    d1.setDate(d1.getDate());
    this.sday2 = d1.toISOString().substring(0, 10);
    // this.sday2 = this.sday;
    this.rdt = [this.sday, this.sday2];
    //   this.parse()
    this.truck = 0;
    this.start();
  },
};
</script>
  