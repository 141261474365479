<template>
  <!-- <v-row class="justify-center text-center ma-1" v-resize="draw"> -->
  <v-row class="justify-center align-center text-center">
    <v-col cols="12">
      <v-row class="justify-center">
        <h3>
          Рапорт по системе защиты от протечек на
          {{ new Date().toLocaleString("ru").substr(0, 10) }}
        </h3>
        <v-btn small color="primary" class="ml-2" @click="start()"
          >Обновить</v-btn
        >
      </v-row>
      <!-- <v-row class="justify-center align-center">
        <v-col xs="12" sm="8" md="4" lg="3">
          <v-row class="justify-center align-center ma-1">
            <v-btn small color="primary" class="mr-1" @click="start()"
              >Обновить</v-btn
            >
          </v-row>
        </v-col>
      </v-row> -->
      <v-row class="justify-center align-center">
        <v-col cols="12">
          <v-tabs v-model="tab" @change="fsfilter()">
            <v-row class="justify-center align-center">
              <v-tab>
                Все события
                <badgemy
                  :vis="tab == 0"
                  nameicon="mdi-water-pump"
                  :num="wlsens.length"
                  coloricon="blue"
                  color="blue"
                  tooltip="Всего событий"
                  class="mt-2"
                ></badgemy>
              </v-tab>
              <v-tab
                >За сутки
                <badgemy
                  :vis="tab == 1"
                  nameicon="mdi-water-pump"
                  :num="firesensfilter.length"
                  coloricon="blue"
                  color="blue"
                  :tooltip="
                    'Всего событий за ' + new Date(sday).toLocaleDateString()
                  "
                  class="mt-2"
                ></badgemy>
              </v-tab>
              <v-tab
                >За месяц
                <badgemy
                  :vis="tab == 2"
                  nameicon="mdi-water-pump"
                  :num="calcstat(firesensfilter).sum.all"
                  coloricon="blue"
                  color="blue"
                  :tooltip="
                    'Всего событий за ' +
                    new Date(dtm).toLocaleDateString('ru-RU', {
                      month: 'long',
                      year: 'numeric',
                    })
                  "
                  class="mt-2"
                ></badgemy>
              </v-tab>
            </v-row>
            <v-tab-item>
              <wlall :firesensfilter="firesensfilter" :key="upd"></wlall>
            </v-tab-item>
            <v-tab-item>
              <!-- <v-col cols="6" class="ml-5"> -->
              <v-row class="align-center justify-center" dense>
                <v-col cols="4">
                  <dateselect
                    :dis="true"
                    :day="sday"
                    :endates="wlsensdates"
                    @changed="sday = $event"
                  ></dateselect>
                </v-col>
              </v-row>
              <!-- <v-row class="align-center justify-center"> -->
                <wlall :firesensfilter="firesensfilter" :key="upd"></wlall>
              <!-- </v-row> -->

              <!-- </v-col> -->
            </v-tab-item>
            <v-tab-item>
              <v-row class="align-center justify-center" dense>
                <v-col cols="4">
                  <monthselect
                    :dis="true"
                    :day="dtm"
                    @changed="dtm = $event"
                  ></monthselect>
                </v-col>
              </v-row>
              <!-- <v-row class="align-center justify-center"> -->
                <wlmonth
                  :firesensstat="calcstat(firesensfilter)"
                  :key="upd"
                ></wlmonth>
              <!-- </v-row> -->
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>

      <!-- <v-row v-show="false">
        <v-col cols="12">
        <daterange :rmin="0" :rmax="dates.length-1" :dates="dates" :key="upd"> </daterange>
        </v-col>
      </v-row> -->

      <!-- :expanded.sync="expanded"
            :single-expand="false" 
            show-expand
            -->
      <!-- {{ firesens }} -->
    </v-col>
    <wait :progress="progress"></wait>
    <v-snackbar v-model="sbok" :timeout="5000" color="green" top right>
      {{ strok }}
      <v-btn @click="sbok = false" class="ml-5" color="primary" fab>OK</v-btn>
    </v-snackbar>
    <v-snackbar v-model="sberr" :timeout="5000" color="red" top right>
      {{ strerr }}
      <v-btn @click="sberr = false" class="ml-5" fab>OK</v-btn>
    </v-snackbar>
  </v-row>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
// import values from "./values";
// import graphmany from "./graphmany.vue";
import wlall from "./wl_all";
import wlmonth from "./wl_month";
import dateselect from "../dateselect.vue";
import monthselect from "../monthselect.vue";
import badgemy from "../sensors/badge_my.vue";
// import daterange from "../daterange.vue";
import wait from "../progress";
//import Fsall from "./fs_all.vue";
export default {
  components: {
    // values,
    // graphmany,
    wlall,
    wlmonth,
    dateselect,
    monthselect,
    // daterange,
    wait,
    badgemy,
  },
  props: {},
  data() {
    return {
      //      sensors: [],
      expanded: [],
      sday: new Date().toISOString().substr(0, 10),
      sday2: new Date().toISOString().substr(0, 10),
      rdt: [this.sday, this.sday2],
      hhh: 300,
      www: 500,
      upd: 0,
      dsel: false,
      firesensfilter: [],
      dates: [],
      tab: null,
      dtm: null,
      marks: true,
      progress: false,
      sbok: false,
      strok: "Успешно",
      sberr: false,
      strerr: "Ошибка",
    };
  },
  methods: {
    ...mapActions({
      loadWlSens: "loadWlSens",
      loadWlSensDates: "loadWlSensDates",
      loadFireSensObj: "loadFireSensObj",
    }),
    draw() {
      this.www = document.getElementById("tbld").clientWidth - 20;
      //  console.log(this.www)
      this.upd += 1;
    },
    scolor(tip) {
      let rr = "white";
      switch (tip) {
        case "Влажность":
          rr = "info";
          break;
        case "Температура":
          rr = "warning";
          break;
        case "ИК":
        case "":
          rr = "secondary";
          break;
      }
      return rr;
    },
    scolor2(tip) {
      let rr = "#1976D2";
      switch (tip) {
        case "Влажность":
          rr = "#2196F3";
          break;
        case "Температура":
          rr = "#FFC107";
          break;
        case "ИК":
        case "":
          rr = "#424242";
          break;
      }
      return rr;
    },
    async start() {
      this.progress = true;
      // await this.loadFireSens({ sday: this.sday, sday2: this.sday2 });
      await this.loadWlSensDates();
      if (this.error != "") {
        this.strerr = "Ошибка загрузки списка дат: " + this.error;
        this.sberr = true;
        //        this.progress = false;
      }
      await this.loadFireSensObj();
      if (this.error != "") {
        this.strerr = "Ошибка загрузки списка объектов: " + this.error;
        this.sberr = true;
        //        this.progress = false;
      }
      await this.loadWlSens();
      if (this.error == "") {
        this.strok = "Данные успешно загружены.";
        this.sbok = true;
        // console.log(this.firesens)
        this.fsfilter();
        this.upd += 1;
        this.progress = false;
      } else {
        this.strerr = "Ошибка соединения: " + this.error;
        this.sberr = true;
        this.progress = false;
      }
    },
    fsfilter() {
      // console.log(this.firesensdates)
      let ppp = this;
      this.dates = [];
      this.firesensfilter = this.wlsens.filter((f) => {
        ppp.dates.push(
          new Date(f.DeviceTime).toLocaleString("ru").substr(0, 10)
        );
        if (ppp.tab == 0) {
          return true;
        }
        if (ppp.tab == 1) {
          // return true
          // console.log(
          //   new Date(f.DeviceTime).toLocaleString("ru") + " == " +  new Date(this.sday).toLocaleString("ru")
          // );
          // console.log(ppp.dsel);
          return (
            new Date(f.DeviceTime).toLocaleString("ru").substr(0, 10) ==
            new Date(ppp.sday).toLocaleString("ru").substr(0, 10)
          );
        }
        // break;
        if (ppp.tab == 2) {
          return ppp.dtm == f.DeviceTime.substr(0, 7);
        }
        // break;
      });
      // this.firesensfilter.forEach((f) => {
      //   if (stat[f.Corpus]) {
      //     if (stat[f.Corpus][f.Contents]) {
      //       stat[f.Corpus][f.Contents]++;
      //     } else {
      //       stat[f.Corpus][f.Contents] = 0;
      //       stat[f.Corpus][f.Contents]++;
      //     }
      //   } else {
      //     stat[f.Corpus] = {};
      //     stat[f.Corpus][f.Contents] = 0;
      //     stat[f.Corpus][f.Contents]++;
      //   }
      // });
      // console.log(stat);
      this.calcstat(this.firesensfilter);
      this.dates.reverse();
      this.upd++;
      // console.log(this.firesensfilter)
    },
    calcstat(fs) {
      let stat = {};
      let sum = { all: 0 };
      let evt = "evt";
      fs.forEach((f) => {
        f.Corpus = f.Corpus.trim();
        if (stat[f.Corpus]) {
          if (stat[f.Corpus][evt]) {
            stat[f.Corpus][evt]++;
            if (sum[evt]) {
              sum[evt]++;
              sum.all++;
            } else {
              sum[evt] = 1;
              sum.all++;
            }
          } else {
            stat[f.Corpus][evt] = 1;
            if (sum[evt]) {
              sum[evt]++;
              sum.all++;
            } else {
              sum[evt] = 1;
              sum.all++;
            }
            // stat.sum[f.Contents] = 1;
            // stat[f.Corpus][f.Contents]++;
          }
        } else {
          stat[f.Corpus] = {};
          stat[f.Corpus][evt] = 1;
          stat[f.Corpus].vals = [];
          if (sum[evt]) {
            sum[evt]++;
            sum.all++;
          } else {
            sum[evt] = 1;
            sum.all++;
          }
          // stat[f.Corpus][f.Contents]++;
        }
        stat[f.Corpus].vals.push(f);
      });
      let stat2 = [];
      for (let key in stat) {
        let val = stat[key];
        val.name = key;
        val.otv = this.otv[key]
        stat2.push(val);
      }
      //  console.log(stat);
      this.firesensobj.forEach((s) => {
        if (!stat[s]) {
          // console.log(s);
          let val = {};
          val.name = s;
          val.otv = this.otv[s]
          stat2.push(val);
        }
      });
      // console.log("--------------------");
      // console.log(sum);
      return { stat: stat2, sum: sum };
    },
  },
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  computed: {
    ...mapState({
      wlsens: (state) => state.wlsens,
      wlsensdates: (state) => state.wlsensdates,
      firesensobj: (state) => state.firesensobj,
      error: (state) => state.error,
      otv: (state) => state.otv,
      //      nogroup: (state) => state.authm.nogroup,
    }),
  },
  watch: {
    sday: function () {
      this.fsfilter();
    },
    dsel: function () {
      this.fsfilter();
    },
    dtm: function () {
      // console.log(this.dtm)
      this.fsfilter();
    },
  },
  mounted() {
    let d0 = new Date();
    d0.setDate(d0.getDate() - 0);
    this.sday = d0.toISOString().substr(0, 10);
    this.rdt = [this.sday, this.sday2];
    this.dtm = d0.toISOString().substr(0, 7);
    //    this.getSensors();
    this.start();
  },
};
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px !important;
  padding: 2px;
  /* border: 1px solid black; */
}
th {
  background-color: #a5d6a7;
}
</style>
