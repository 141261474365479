<template>
  <!-- <div> -->
    <v-bottom-navigation app fixed grow v-model="activeItem">
      <!-- <v-btn value="top" :to="{ name: gg.menus.name }" v-for="gg in  menus" :key="gg.name"> -->
      <!-- <v-btn value="top" v-for="gg in menus" :key="gg.name" v-bind="attrs" v-on="on"> -->
        <dix v-if="$keycloak">
      <v-btn icon @click="$keycloak.logoutFn()" v-if="$keycloak.authenticated">
         Выход<v-icon  color="primary">mdi-exit-to-app</v-icon>
      </v-btn>
      <v-btn icon @click="$keycloak.login()" v-else>
        Авторизоваться<v-icon color="primary">mdi-account-box</v-icon>
      </v-btn>
</dix>
      <!-- <v-btn :to="{ name: 'sensors' }">
        <v-icon color="primary">mdi-table-large</v-icon>Таблица</v-btn
      >
      <v-btn :to="{ name: 'sensorstree' }">
        <v-icon color="primary">mdi-file-tree</v-icon>Дерево</v-btn
      >
      <v-btn :to="{ name: 'ir' }">
        <v-icon color="primary">mdi-motion-sensor</v-icon>ИК</v-btn
      > -->
      <!-- <v-btn :to="{ name: 'usagerooms' }">
        <v-icon color="primary">mdi-calendar-month</v-icon>%
        использования</v-btn
      >
      <v-btn :to="{ name: 'usagerooms2' }">
        <v-icon color="primary">mdi-calendar-month</v-icon>% использования
        2</v-btn
      > -->
      <!-- <v-btn :to="{ name: 'sensorscorp' }"> <v-icon color="primary">mdi-office-building</v-icon>Корпуса</v-btn> -->
      <template value="top" v-for="(gg, ii) in menus">
        <v-menu v-bind:key="ii" top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <span>{{ gg.name }}</span>
              <v-icon color="primary">{{ gg.icon }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in gg.menus"
              :key="index"
              :to="{ name: item.name }"
            >
              <v-list-item-icon
                ><v-icon color="primary">{{ item.meta.icon }}</v-icon></v-list-item-icon
              >
              <v-list-item-title>{{ item.meta.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-bottom-navigation>
  <!-- </div> -->
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      groups: [],
      activeItem: [],
    };
  },
  computed: {
    ...mapState({
      menus: (state) => state.authm.menus,
      nogroup: (state) => state.authm.nogroup,
    }),
  },
  methods: {
    ...mapActions({
      routestomenu: "authm/routestomenu",
    }),
  },
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  async mounted() {
       await this.routestomenu();
      // console.log(this.menus)
  },
};
</script>
