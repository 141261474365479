var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.val,"footer-props":{
    showFirstLastPage: true,
    itemsPerPageText: 'Записей на странице',
    itemsPerPageOptions: [12, 24, 48, 96, { text: 'Все записи', value: -1 }],
  },"mobile-breakpoint":100},scopedSlots:_vm._u([{key:"item.val.Avg",fn:function(ref){
  var item = ref.item;
return [(item.val)?_c('span',{class:_vm.color(item.val.Avg)+'--text'},[_vm._v(" "+_vm._s(item.val.Avg.toFixed(2))+" ")]):_vm._e()]}},{key:"item.val.Min",fn:function(ref){
  var item = ref.item;
return [(item.val)?_c('span',{class:_vm.colormin(item.val.Min)+'--text'},[_vm._v(" "+_vm._s(item.val.Min.toFixed(2))+" ")]):_vm._e()]}},{key:"item.val.Max",fn:function(ref){
  var item = ref.item;
return [(item.val)?_c('span',{class:_vm.colormax(item.val.Max)+'--text'},[_vm._v(" "+_vm._s(item.val.Max.toFixed(2))+" ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }