<template>
<v-container>
  <v-row  v-for="(b,i) in objs" :key="i">
    <v-col>
       <obj :title="b.title" :subtitle="b.subtitle" :act="b.act"></obj>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import obj from "./obj.vue"
export default {
  components: {obj},
  props: {},
  data() {
    return {
      objs: [
        {
          title: "Корпус 1",
          subtitle: "Доп. информация 1",
          act: [{
            vis: true,
            icon: "mdi-fire",
            num: 0,
            coloricon: "red",
            color: "red",
            tooltip: "Тестовая",
            component: "firesens",
          },]
        },
        {
          title: "Корпус 2",
          subtitle: "Доп. информация 1",
          act: [{
            vis: true,
            icon: "mdi-water-pump",
            num: 0,
            coloricon: "blue",
            color: "blue",
            tooltip: "Тестовая",
            component: "firesens",
          },]
        },
      ],
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
  },
  mounted() {},
};
</script>
