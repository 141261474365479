<template>
  <div>
    <v-row class="justify-center ma-2">
      <h1>ИК</h1>
      <!-- </v-col> -->
      <!-- <span v-if="ir.length > 0">{{ irdate(ir[0]) }}</span> -->
    </v-row>
    <!-- {{ itvs2data(ir)}} -->
    <v-row class="justify-center align-center ma-1">
      <v-col xs="12" sm="8" md="4" lg="3">
        <v-row class="justify-center align-center ma-1">
          <v-btn small color="primary" class="mr-1" @click="start()"
            >Обновить</v-btn
          >
          <di
            :rdates="rdt"
            v-on:dateschange="
              sday = $event[0];
              sday2 = $event[1];
            "
          ></di>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="ml-1 mr-1">
      <analizall
        :headers="itvs2headers(intervals)"
        :values="itvs2data(ir)"
      ></analizall>
    </v-row>
    <v-expansion-panels>
      <v-expansion-panel v-for="dd in ir" :key="dd.nn" class="ma-2 pa-0">
        <v-expansion-panel-header>
          {{ dd.sensor }}
        </v-expansion-panel-header>
        <v-expansion-panel-content color="grey">
          <v-row class="justify-center ma-2">
            <v-col md="11" lg="8">
              <analiz :values="irintervals(dd)"></analiz>
            </v-col>
          </v-row>
          <graph :dd="dd"></graph>
          <!-- <v-card class="elevation-5">
            <v-card-title>{{ dd.sensor }}</v-card-title>
            <v-card-text>
              <v-sparkline
                :value="irval(dd)"
                line-width="0.3"
                :gradient="[
                  'red',
                  'red',
                  'red',
                  'red',
                  'green',
                  'red',
                  'red',
                  'red',
                  'red',
                ]"
                stroke-linecap="round"
                auto-draw
                :labels="irdate(dd)"
                label-size="2"
              >
              </v-sparkline>
            </v-card-text>
          </v-card> -->
          <v-row class="justify-center ma-2">
            <v-col md="11" lg="8">
              <values :values="dd.data"></values>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- {{val}} -->
    <wait :progress="progress"></wait>
    <v-snackbar v-model="sbok" :timeout="5000" color="green" top right>
      {{ strok }}
      <v-btn @click="sbok = false" class="ml-5" color="primary" fab>OK</v-btn>
    </v-snackbar>
    <v-snackbar v-model="sberr" :timeout="5000" color="red" top right>
      {{ strerr }}
      <v-btn @click="sberr = false" class="ml-5" fab>OK</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import wait from "../progress";
import di from "../dateinterval";
import values from "./values";
import analiz from "./ir_analiz";
import analizall from "./ir_all";
import graph from "./ir_graph";
export default {
  components: {
    wait,
    di,
    values,
    analiz,
    analizall,
    graph,
  },
  props: {},
  data() {
    return {
      sday: new Date().toISOString().substr(0, 10),
      sday2: new Date().toISOString().substr(0, 10),
      rdt: [this.sday, this.sday2],
      progress: false,
      sbok: false,
      strok: "Успешно",
      sberr: false,
      strerr: "Ошибка",
      intervals: [
        // {
        //   descr: "для теста",
        //   d1: "21:00",
        //   d2: "22:00",
        // },
        {
          descr: "Пара 1, час 1",
          d1: "09:00",
          d2: "09:45",
        },
        {
          descr: "Пара 1, час 2",
          d1: "09:50",
          d2: "10:35",
        },
        {
          descr: "Пара 2, час 1",
          d1: "10:45",
          d2: "11:30",
        },
        {
          descr: "Пара 2, час 2",
          d1: "11:35",
          d2: "12:20",
        },
        {
          descr: "Пара 3, час 1",
          d1: "13:00",
          d2: "13:45",
        },
        {
          descr: "Пара 3, час 2",
          d1: "13:50",
          d2: "14:35",
        },
        {
          descr: "Пара 4, час 1",
          d1: "14:45",
          d2: "15:30",
        },
        {
          descr: "Пара 4, час 2",
          d1: "15:35",
          d2: "16:20",
        },
        {
          descr: "Пара 5, час 1",
          d1: "16:30",
          d2: "17:15",
        },
        {
          descr: "Пара 5, час 2",
          d1: "17:20",
          d2: "18:05",
        },
        // {
        //   descr: "Пара 6, час 1",
        //   d1: "18:15",
        //   d2: "19:00",
        // },
        // {
        //   descr: "Пара 6, час 2",
        //   d1: "19:05",
        //   d2: "19:50",
        // },
      ],
    };
  },
  methods: {
    ...mapActions({
      loadIR: "loadIR",
    }),
    detect(val) {
      let bb = 127;
      let sl = 10;
      let sr = 10;
      // if (val > bb - sl && val < bb + sr) {
      if (val.Min <= 117 || val.Max >= 137) {
        return 1;
      } else {
        return 0;
      }
    },
    analiz0(dd, itv) {
      let rr = {};
      //      console.log(itv);
      // let nn =0
      // let yy =0
      rr.nn = 0;
      rr.yy = 0;
      rr.data = [];
      dd.filter((m) => {
        let tt = m.date.substr(11, 5);
        if (tt >= itv.d1 && tt <= itv.d2) {
          rr.nn++;
          rr.data.push(m);
          if (m.val == 1) {
            rr.yy++;
          }
        }
      });
      if (rr.nn == 0) {
        rr.proc = -1;
      } else {
        rr.proc = (rr.yy * 100) / rr.nn;
      }
      rr.interval = itv;
      return rr;
    },
    analiz(dd, itv) {
      if (itv.times == undefined) return;
      let rr = {};
      //            console.log(itv);
      // let nn =0
      // let yy =0
      rr.rez = "???";
      rr.nn = itv.times.length;
      rr.yy = 0;
      rr.data = [];
      dd.filter((m) => {
        let tt = m.date.substr(11, 5);
        if (itv.times.indexOf(tt) != -1) {
          // console.log(tt)
          //          rr.nn++;
          rr.data.push(m);
          if (m.val == 1) {
            rr.yy++;
          }
        }
      });
      rr.rez = "Нет данных";
      //      if (rr.nn == 0) {
      if (dd.length == 0) {
        rr.proc = -1;
        rr.rez = "Нет данных";
      } else {
        rr.proc = (rr.yy * 100) / rr.nn;
        if (rr.proc > 0) {
          rr.rez = "Да";
        } else {
          //          if (rr.yy > 0) {
          rr.rez = "Нет";
          //   } else {
          //     rr.rez = "Нет данных";
          //   }
        }
      }
      //      console.log(dd.length)
      if (rr.data.length <= 0) {
        rr.rez = "???";
      }
      rr.interval = itv;
      return rr;
    },
    irintervals(dd) {
      if (dd == undefined || dd.data == undefined || dd.data.length <= 0) {
        return [];
      }
      // if( dd.data.length <= 0)
      // {
      //   return [{rez: "Нет данных"}];
      // }
      let vv = dd.data;
      let rr = [];
      let tt = {};
      vv.forEach((ee) => {
        //  console.log(ee.date.substr(0,16))
        // let d = ee.date.substr(0, 16);
        let d = ee.date;
        if (d in tt) {
          if (tt[d] == 0) {
            tt[d] = this.detect(ee.val);
          }
        } else {
          tt[d] = this.detect(ee.val);
        }
      });
      let mm = [];
      for (let kk in tt) {
        mm.push({ date: kk, val: tt[kk] });
      }
      // let m2 =mm.filter(m => {
      //   return m.val == 1
      // })
      // let m2 = mm.filter((m) => {
      //   return m.val == 1;
      // });
      ///      let m2 = this.analiz(mm, intervals[0]);
      let m2 = [];
      this.intervals.forEach((ii) => {
        let aa = this.analiz(mm, ii);
        m2.push(aa);
      });
      //      console.log(m2);
      return m2;
      // vv.forEach((ee) => {
      //   rr.push(Number(ee.val));
      // });
      //      return rr;
    },
    fillinterval(itv) {
      let d1 = new Date("2020-01-01 " + itv.d1);
      let d2 = new Date("2020-01-01 " + itv.d2);
      // console.log(d1)
      //        console.log(d1.toISOString().substr(11, 5))
      // console.log(d1.toLocaleString("ru"))
      // console.log(d1.toLocaleString("ru").substr(12, 5))
      let tt = [];
      while (d1 <= d2) {
        let t0 = d1.toLocaleString("ru").substr(12, 5);
        //        console.log(t0)
        tt.push(t0);
        d1.setMinutes(d1.getMinutes() + 1);
        // d1.setMinutes(d1.getMinutes());
      }
      return tt;
      //        let d2 = new Time(itv.d2)
      // console.log(tt.length)
      // console.log(tt)
    },
    fillintervals(itvs) {
      itvs.map((ii) => {
        ii.times = this.fillinterval(ii);
      });
    },
    itvs2headers(itvs) {
      let hh = [];
      hh.push({ text: "Кабинет", value: "room", align: "left" });
      itvs.forEach((ii, nn) => {
        hh.push({
          text: ii.descr + ", " + ii.d1 + "-" + ii.d2,
          value: "i" + nn,
          align: "left",
        });
      });
      hh.push({ text: "Процент использования", value: "proc", align: "left" });
      //      console.log(hh)
      return hh;
    },
    itvs2data(data) {
      let dd = [];
      data.forEach((ii, nn) => {
        let t0 = this.irintervals(ii);
        let t2 = {};
        //                console.log(t0)
        let p = 0;
        t0.forEach((qq, nn) => {
          if (qq && qq.rez == "Да") {
            t2["i" + nn] = qq.rez + " (" + qq.proc.toFixed(2) + "%)";
            p++;
          } else {
            if(qq) {
            t2["i" + nn] = qq.rez;
            } else {
              t2["i" + nn] ="?????"
            }
          }
        });
        t2.proc = 0;
        if (t0.length != 0) {
          t2.proc = (p * 100) / t0.length;
        }
        t2.room = ii.sensor;
        t2.data = ii.data;
        t2.all = ii;
        t2.irintervals = t0;
        dd.push(t2);
      });
      return dd;
    },
    irval(dd) {
      if (dd.data == undefined || dd.data.length <= 0) return [];
      let vv = dd.data;
      let rr = [0, 255];
      if (dd.sensor.indexOf("радио") != -1) {
        rr = [];
      }
      vv.forEach((ee) => {
        rr.push(Number(ee.val));
      });
      return rr;
    },
    irdate(dd) {
      if (dd.data == undefined || dd.data.length <= 0) return [];
      let vv = dd.data;
      let rr = [" ", " "];
      let nn;
      nn = vv[0].date.substr(11, 2);
      vv.forEach((ee) => {
        //nn = ee.date.substr(11, 2);
        if (ee.date.substr(11, 2) == nn) {
          rr.push(ee.date.substr(11, 5));
          nn++;
        } else {
          rr.push(" ");
        }
        if (ee.date.substr(11, 2) > nn) {
          nn++;
        }
        //         nn++
      });
      return rr;
    },
    async start() {
      this.progress = true;
      await this.loadIR({ sday: this.sday, sday2: this.sday2 });
      if (this.irerror == "") {
        //        this.irintervals(this.ir[1]);
        this.strok = "Данные успешно загружены.";
        this.sbok = true;
        this.progress = false;
      } else {
        this.strerr = "Ошибка соединения: " + this.error;
        this.sberr = true;
        this.progress = false;
      }
    },
  },
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  computed: {
    ...mapState({
      ir: (state) => state.ir,
      irerror: (state) => state.irerror,
      //      nogroup: (state) => state.authm.nogroup,
    }),
  },
  mounted() {
    let d0 = new Date();
    d0.setDate(d0.getDate() - 0);
    this.sday = d0.toISOString().substr(0, 10);
    this.rdt = [this.sday, this.sday2];
    //    this.getSensors();
    this.fillintervals(this.intervals);
    // console.log(this.intervals)
    this.start();
  },
};
</script>
