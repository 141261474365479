var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.draw),expression:"draw"}],staticClass:"justify-center text-center ma-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-center ma-2"},[_c('h1',[_vm._v("Температура и влажность")])]),_c('v-row',{staticClass:"justify-center align-center ma-1"},[_c('v-col',{attrs:{"xs":"12","sm":"8","md":"4","lg":"3"}},[_c('v-row',{staticClass:"justify-center align-center ma-1"},[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.start()}}},[_vm._v("Обновить")]),_c('di',{attrs:{"rdates":_vm.rdt},on:{"dateschange":function($event){_vm.sday = $event[0];
              _vm.sday2 = $event[1];}}})],1)],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"xs":"12","sm":"11","md":"10","lg":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"justify-center ma-1"},[_c('v-col',{attrs:{"xs":"12","sm":"11","md":"10","lg":"9"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"tbld","headers":_vm.headers,"items":_vm.sensors,"expanded":_vm.expanded,"single-expand":false,"search":_vm.search,"show-expand":"","group-by":"Корпус","item-key":"sensor","mobile-breakpoint":100,"footer-props":{
            showFirstLastPage: true,
            itemsPerPageText: 'Записей на странице',
            itemsPerPageOptions: [10, 20, 50, 100, { text: 'Все записи', value: -1 }],
          }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.sensor",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.sensor)+" ")]}},{key:"item.nn",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.scolor(item.tip)}},[_vm._v(_vm._s(item.nn)+" ")])]}},{key:"item.dd",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[item.data.length - 1].date)+" ")]}},{key:"item.vv",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[item.data.length - 1].val.Avg.toFixed(2))+" ")]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('v-row',[_c('v-checkbox',{attrs:{"label":"Показывать значения"},on:{"change":function($event){_vm.upd += 1}},model:{value:(_vm.marks),callback:function ($$v) {_vm.marks=$$v},expression:"marks"}})],1),_c('v-row',[_c('graphmany',{key:_vm.upd,attrs:{"id":'gr' + item.nn,"stat":item.data,"title":"","hdr":[item.tip],"colorgr":[_vm.scolor2(item.tip)],"xx":"dt","yy":['yy'],"hhh":_vm.hhh,"www":_vm.www,"marks":_vm.marks}})],1),_c('v-row',{staticClass:"justify-center ma-2"},[_c('v-col',{attrs:{"md":"11","lg":"8"}},[_c('values',{attrs:{"values":item.data}})],1)],1)],1)],1)]}}])})],1)],1)],1),_c('wait',{attrs:{"progress":_vm.progress}}),_c('v-snackbar',{attrs:{"timeout":5000,"color":"green","top":"","right":""},model:{value:(_vm.sbok),callback:function ($$v) {_vm.sbok=$$v},expression:"sbok"}},[_vm._v(" "+_vm._s(_vm.strok)+" "),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","fab":""},on:{"click":function($event){_vm.sbok = false}}},[_vm._v("OK")])],1),_c('v-snackbar',{attrs:{"timeout":5000,"color":"red","top":"","right":""},model:{value:(_vm.sberr),callback:function ($$v) {_vm.sberr=$$v},expression:"sberr"}},[_vm._v(" "+_vm._s(_vm.strerr)+" "),_c('v-btn',{staticClass:"ml-5",attrs:{"fab":""},on:{"click":function($event){_vm.sberr = false}}},[_vm._v("OK")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }