var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.values,"footer-props":{
    showFirstLastPage: true,
    itemsPerPageText: 'Записей на странице',
    itemsPerPageOptions: [24,48,96,{'text':'Все записи','value':-1}]
  },"mobile-breakpoint":100},scopedSlots:_vm._u([{key:"item.proc",fn:function(ref){
  var item = ref.item;
return [(item.proc >= 0 )?_c('span',[_vm._v(_vm._s(item.proc.toFixed(2))+"% ")]):_vm._e()]}},{key:"item.dd",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.interval.d1)+" - "+_vm._s(item.interval.d2)+" ")]}},{key:"item.rez",fn:function(ref){
  var item = ref.item;
return [(item.rez.indexOf('Да') != -1)?_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(item.rez))]):_vm._e(),(item.rez == 'Нет')?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.rez))]):_vm._e(),(item.rez == '???')?_c('span',[_vm._v(_vm._s(item.rez))]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }