<template>
          <v-card
            max-width="500"
            width="600"
            v-if="true && mm"
          >
            <v-toolbar color="primary" dark>
              <v-icon
              large
              v-if="$router.options.groupicon && $router.options.groupicon[mm.name]"
              class="mr-2"
              >{{ $router.options.groupicon[mm.name] }}</v-icon 
            >
              <v-toolbar-title>{{
                mm.name
              }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <routesitems :mm="mm"></routesitems>
            <!-- <v-list subheader two-line>
            <v-list-item v-for="m in mm.menus" :key="m.name" link :to="m.path">
              <v-list-item-avatar>
                <v-icon class="blue lighten-1" dark> {{ m.meta.icon }} </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="m.meta.text"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="blue lighten-1">mdi-arrow-right-box</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list> -->
          </v-card>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
// import routesgroup from "./routesgroup";
import routesitems from "./routesitems";

export default {
  components: {
      routesitems
  },
  props: {
    mm: Object,
  },
  data() {
    return {};
  },
  methods: {},
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  mounted() {},
};
</script>
