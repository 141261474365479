import Vue from "vue";
import Vuex from "vuex";
import authm from "../components/authvuex/authvuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sensors: [],
    senstree: [],
    error: "",
    ir: [],
    irtree: [],
    irerror: "",
    hosts: [],
    hoststree: [],
    hostserror: "",
    zbx: [],
    zbxtree: [],
    zbxerror: "",
    firesens: [],
    firesensdates: [],
    firesensobj: [],
    wlsens: [],
    wlsensdates: [],
    pobjects: [],
    // urlbase: "http://localhost:2021",
    // urlbase: "http://0400prnmon02:2021",
    // urlbase: "http://82.179.94.22:2027",
    //    urlbase: "http://us.kbsu.ru:2027",
    urlbase: "https://sensrv.kbsu.ru:3002",

    // sensors_url: "http://localhost",
    // sensors_port: ":2021",

    // sens_ch_srv
    sensors_url: "https://sensrv.kbsu.ru",
    sensors_port: ":3001",

    firesens_url: "https://sensrv.kbsu.ru",
    firesens_port: ":3010",

    //    urlbasewifi: "http://us.kbsu.ru:2023",
    urlbasewifi: "https://sensrv.kbsu.ru:3004",

    printers: [],
    printersfree: [],
    offices: [],
    officesfloor: [],
    icons: {},
    wifi: [],
    objects: null,
    groups: null,
    bounds: null,
    kortex: null,
    kortexall: null,
    otv: {},
    senslastevent: null,
    welcome: null,
  },
  mutations: {
    setsensors(state, ss) {
      state.sensors = ss;
    },
    setsensorstree(state, st) {
      state.senstree = st;
    },
    seterror(state, ee) {
      state.error = ee;
    },
    setir(state, ss) {
      state.ir = ss;
    },
    setirtree(state, st) {
      state.irtree = st;
    },
    setirerror(state, ee) {
      state.irerror = ee;
    },
    sethosts(state, ss) {
      state.hosts = ss;
    },
    sethoststree(state, st) {
      state.hoststree = st;
    },
    sethostserror(state, ee) {
      state.hostserror = ee;
    },
    setzbx(state, ss) {
      state.zbx = ss;
    },
    setzbxtree(state, st) {
      state.zbxtree = st;
    },
    setzbxerror(state, ee) {
      state.zbxerror = ee;
    },
    setofficesfloor(state, obj) {
      state.officesfloor = obj;
    },
    setprn(state, obj) {
      state.printers = obj;
    },
    seticons(state, obj) {
      state.icons = obj;
    },
    setwifi(state, obj) {
      state.wifi = obj;
    },
    setobjects(state, obj) {
      state.objects = obj;
    },
    setgroups(state, obj) {
      state.groups = obj;
    },
    setbounds(state, obj) {
      state.bounds = obj;
    },
    setkortex(state, obj) {
      state.kortex = obj;
    },
    setkortexall(state, obj) {
      state.kortexall = obj;
    },
    setfiresens(state, ss) {
      state.firesens = ss;
    },
    setfiresensdates(state, ss) {
      state.firesensdates = ss;
    },
    setfiresensobj(state, ss) {
      state.firesensobj = ss;
    },
    setwlsens(state, ss) {
      state.wlsens = ss;
    },
    setwlsensdates(state, ss) {
      state.wlsensdates = ss;
    },
    setotv(state, ss) {
      state.otv = ss;
    },
    setsenslastevent(state, ss) {
      state.senslastevent = ss;
    },
    setwelcome(state, ss) {
      state.welcome = ss;
    },
    setpobjects(state, ss) {
      state.pobjects = ss;
    },
  },
  actions: {
    loadSensors: async ({ commit, state }, { sday, sday2 }) => {
      let url;
      let resp;
      let sen;
      //      console.log(sday)
      // url = "us.kbsu.ru";
      // url = "localhost";
      // url = "http://" + url + ":2020/";
      url = state.sensors_url + state.sensors_port + "/";

      url = url + "?d1=" + sday; //.replace(/-/g, "");
      url = url + "&d2=" + sday2; //.replace(/-/g, "");
      // console.log(url);
      try {
        resp = await fetch(url);
        sen = await resp.json();
      } catch (err) {
        commit("seterror", err);
        // this.strerr = "Ошибка соединения: " + err;
        // this.sberr = true;
        // this.progress = false;
        return;
      }
      sen = sen.sensors;
      // console.log(sen)
      let ss = [];
      let st = [];
      let nn = 1;
      for (var key in sen) {
        let dd = [];
          // console.log(key)
          for (let k2 in sen[key]) {
          // console.log(k2)
          // dd.push({ date: k2.substr(0, 10)+" "+k2.substr(11, 2), val: sen[key][k2], dt: k2.substr(0, 10)+" "+k2.substr(11, 2)+":00" });
          // dd.push({ date: k2.substr(0, 10)+" "+k2.substr(11, 2), val: sen[key][k2], dt: new Date(k2.substr(0, 10)+" "+k2.substr(11, 2)+":00") });
          // dd.push({ date: k2, val: sen[key][k2].toFixed(2), dt: new Date(k2 + ":00") });        }
          dd.push({
            date: k2,
            val: sen[key][k2],
            yy: sen[key][k2].Avg.toFixed(2),
            dt: new Date(k2 + ":00"),
          });
        }
        let tmp;
        tmp = key.split("/");
        if (tmp.length == 4) {
          tmp[2] = tmp[2] + "(" + tmp[3] + ")";
        }
        if (tmp.length == 2) {
          tmp[2] = "";
        }
        if (tmp.length < 2) {
          tmp[0] = "";
          tmp[1] = "";
          tmp[2] = "";
        }
        ss.push({
          nn: nn,
          sensor: key,
          data: dd,
          tip: tmp[0],
          Корпус: tmp[1],
          room: tmp[2],
        });

        dd.forEach((d) => {
          st.push({
            nn: nn,
            sensor: key,
            date: d.date,
            val: d.val.Avg.toFixed(2),
            tip: tmp[0],
            korpus: tmp[1],
            room: tmp[2],
          });
        });
        nn++;
      }
      // console.log(ss);
      // ss = ss.shift();
      ss.sort((a, b) =>
        a["Корпус"] < b["Корпус"] ? -1 : a["Корпус"] > b["Корпус"]
      );
      commit("seterror", "");
      commit("setsensors", ss);
      commit("setsensorstree", st);
    },
    loadIR: async ({ commit, state }, { sday, sday2 }) => {
      let url;
      let resp;
      let sen;
      //      console.log(sday)
      // url = "us.kbsu.ru";
      // url = "http://" + url + ":2016/ir";
      // url = "localhost";
      // url = "http://" + url + ":2020/ir";
      url = state.sensors_url + state.sensors_port + "/ir";
      url = url + "?d1=" + sday; //.replace(/-/g, "");
      url = url + "&d2=" + sday2; //.replace(/-/g, "");
      // console.log(url);
      try {
        resp = await fetch(url);
        sen = await resp.json();
      } catch (err) {
        commit("setirerror", err);
        // this.strerr = "Ошибка соединения: " + err;
        // this.sberr = true;
        // this.progress = false;
        return;
      }
      sen = sen.sensors;
      let ss = [];
      let st = [];
      let nn = 1;
      for (var key in sen) {
        let dd = [];
        for (let k2 in sen[key]) {
          dd.push({ date: k2, val: sen[key][k2] });
          // dd.push({ date: k2, min: sen[key][k2].Min, max: sen[key][k2].Max });
        }
        let tmp;
        tmp = key.split("/");
        if (tmp.length == 4) {
          tmp[2] = tmp[2] + "(" + tmp[3] + ")";
        }
        if (tmp.length < 3) {
          tmp[0] = "";
          tmp[1] = "";
          tmp[2] = "";
        }
        ss.push({
          nn: nn,
          sensor: key,
          data: dd,
          tip: tmp[0],
          Корпус: tmp[1],
          room: tmp[2],
        });

        dd.forEach((d) => {
          st.push({
            nn: nn,
            sensor: key,
            date: d.date,
            val: d.val,
            tip: tmp[0],
            korpus: tmp[1],
            room: tmp[2],
          });
        });
        nn++;
      }
      // console.log(st)
      commit("setirerror", "");
      commit("setir", ss);
      commit("setirtree", st);
    },
    loadHosts: async ({ commit }) => {
      let url;
      let resp;
      let hst;
      url = "us.kbsu.ru";
      url = "https://" + url + ":2002/hosts";
      // url = "82.179.94.22";
      // url = "http://" + url + ":2002/hosts";
      // url = url + "?d1=" + sday.replace(/-/g, "");
      // url = url + "&d2=" + sday2.replace(/-/g, "");
      // console.log(url);
      try {
        resp = await fetch(url);
        hst = await resp.json();
      } catch (err) {
        commit("sethostserror", err);
        return;
      }
      hst.shift();
      hst = hst.map((h) => {
        h.Floor = "Этаж " + h.Floor;
        h.Room = "Комната " + h.Room;
        return h;
      });
      // console.log(hst)
      commit("sethostserror", "");
      commit("sethosts", hst);
      commit("sethoststree", hst);
    },
    loadZabbix: async ({ commit }, { sday, sday2 }) => {
      let url;
      let resp;
      let hst;
      //      console.log(sday)
      url = "us.kbsu.ru";
      url = "82.179.94.22";
      url = "http://" + url + ":2027/items2";
      // url = "http://localhost:2021/items2";
      url = url + "?d1=" + sday;
      url = url + "&d2=" + sday2;
      // console.log(url);
      try {
        resp = await fetch(url);
        hst = await resp.json();
      } catch (err) {
        commit("setzbxerror", err);
        // this.strerr = "Ошибка соединения: " + err;
        // this.sberr = true;
        // this.progress = false;
        return;
      }
      // hst.shift()
      // hst = hst.map(h=> {
      //   h.Floor = "Этаж "+ h.Floor
      //   h.Room = "Комната "+ h.Room
      //   return h
      // })
      // console.log(hst)
      commit("setzbxerror", "");
      commit("setzbx", hst);
      commit("setzbxtree", hst);
    },
    loadPrintersfree: async ({ commit, state }) => {
      let url;
      url = state.urlbase + "/prnfree";
      // url = state.urlbase + "/hosts";
      // console.log(url)
      let rez;
      rez = await fetch(url);
      rez = await rez.json();
      // console.log("Список принтеров загружен.")
      // console.log(rez)
      // rez.forEach(p => {
      //      console.log(p)
      // })
      // rez.map(s => {
      //   try {
      //     s.name = s.name.split(".")[0];
      //     s.items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      //   } catch {
      //     //        s.name = ;
      //   }
      // })
      rez.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      commit("setprn", rez);
      // console.log("Список принтеров загружен.")
      // commit('setprnfree', rez)
    },
    loadOfficesfloor: async ({ commit, state }) => {
      let url;
      url = state.urlbase + "/offices";
      let rez;
      rez = await fetch(url);
      rez = await rez.json();
      rez.map((m) => {
        m.office = m.name;
        return m;
      });
      //  console.log(rez)
      rez.sort((a, b) =>
        a.office < b.office ? -1 : Number(a.office > b.office)
      );
      // console.log("Список офисов загружен.")
      commit("setofficesfloor", rez);
    },
    loadIcons: async ({ commit, state }) => {
      let url;
      url = state.urlbase + "/icons";
      let rez;
      rez = await fetch(url);
      rez = await rez.json();
      // console.log(rez)
      // rez.sort((a, b) => a.office < b.office ? - 1 : Number(a.office > b.office))
      // console.log("Список офисов загружен.")
      commit("seticons", rez);
    },
    loadWifi: async ({ commit, state }) => {
      let url;
      url = state.urlbasewifi + "/devs/";
      //      url = "http://us.kbsu.ru:2023/devs/"
      // console.log(url)
      let rez;
      rez = await fetch(url);
      rez = await rez.json();
      // console.log(rez)
      rez.sort((a, b) =>
        a.office < b.office ? -1 : Number(a.office > b.office)
      );
      // console.log("Список офисов загружен.")
      commit("setwifi", rez);
    },
    loadObjects: async ({ commit }) => {
      let url = "objects.json";
      let resp = await fetch(url);
      let tmp = await resp.json();
      // console.log(tmp)
      commit("setobjects", tmp);

      let grp = {
        "ИНСТИТУТЫ, ФАКУЛЬТЕТЫ": [],
        "КОЛЛЕДЖИ, ШКОЛЫ": [],
        "АДМИНИСТРАТИВНЫЕ КОРПУСА": [],
        //       "СПОРТИВНЫЕ ЗАЛЫ, АКТОВЫЕ ЗАЛЫ": [], "ОБЩЕЖИТИЯ КБГУ": [], "ПУНКТЫ ПИТАНИЯ": [], "": [] }
        "СПОРТИВНЫЕ ЗАЛЫ, АКТОВЫЕ ЗАЛЫ": [],
        "ОБЩЕЖИТИЯ КБГУ": [],
        "ПУНКТЫ ПИТАНИЯ": [],
      };

      for (let m in tmp) {
        // console.log(m)
        if (tmp[m].group != undefined) {
          grp[tmp[m].group].push(m);
        } else {
          grp[""].push(m);
        }
      }
      let tmp2 = [];
      for (let key in grp) {
        tmp2.push({ name: key, member: grp[key] });
      }
      commit("setgroups", tmp2);
    },
    loadBounds: async ({ commit }) => {
      let url = "bounds.json";
      let resp = await fetch(url);
      let tmp = await resp.json();
      // console.log(tmp)
      commit("setbounds", tmp);
    },
    loadKortex0: async ({ commit, state }) => {
      let url = "kortexsrv.json";
      let resp = await fetch(url);
      let tmp = await resp.json();
      // console.log(tmp)
      for (var kk in tmp) {
        // console.log(tmp[kk].ip)
        url = state.sensors_url + state.sensors_port + "/";
        url += "kortexinfo?ip=" + tmp[kk].ip;
        let resp = await fetch(url);
        let tmp2 = await resp.json();
        // console.log(tmp2)
        if (tmp2.status == "success") {
          tmp[kk].state = tmp2.state;
          // tmp[kk].state = "upstart";
          tmp[kk].data = tmp2.data;
          tmp[kk].param = tmp2.param;
          tmp[kk].param.ip = tmp[kk].ip;
        } else {
          tmp[kk].state = "?";
          tmp[kk].data = [];
          tmp[kk].param = [];
        }
      }
      commit("setkortex", tmp);
    },
    loadKortex: async ({ commit, state }) => {
      let url = "kortexsrv.json";
      let resp; //= await fetch(url);
      let tmp0; //= await resp.json();
      let tmp; //= await resp.json();
      url = state.sensors_url + state.sensors_port + "/";
      url += "kortexinfoall";
      // console.log(url)
      try {
        resp = await fetch(url);
        tmp0 = await resp.json();
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      // console.log(tmp)
      if (tmp0.status != "success") {
        console.log(tmp0.error);
        commit("seterror", tmp0.error);
        // commit("setkortex", null);
        return;
      }
      // console.log(tmp0)
      tmp = tmp0.kortexs;
      // for (var kk in tmp0.kortexdevs) {
      //   // console.log(kk)
      //   if (kk == "all") {
      //     continue
      //   }
      //   let tmp2 = tmp0.kortexs[kk];
      //   tmp[kk].state = tmp2.state;
      //   // tmp[kk].state = "upstart";
      //   tmp[kk].data = tmp2.oids;
      //   tmp[kk].param = tmp2.kortex;
      //   tmp[kk].param.ip = tmp[kk].ip;
      //   // } else {
      //   //   tmp[kk].state = "?";
      //   //   tmp[kk].data = [];
      //   //   tmp[kk].param = [];
      //   // }
      // }
      commit("setkortex", tmp);
    },
    loadKortexall: async ({ commit, state }) => {
      let url; // = "kortexsrv.json";
      let resp; // = await fetch(url);
      let tmp; //  = await resp.json();
      let krt;
      // console.log(tmp)
      // console.log(tmp[kk].ip)
      url = state.sensors_url + state.sensors_port + "/";
      url += "kortexinfoall";
      // console.log(url)
      try {
        resp = await fetch(url);
        krt = await resp.json();
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      if (krt.status != "success") {
        console.log(krt.error);
        commit("seterror", krt.error);
        return;
      }
      // console.log(krt);
      commit("seterror", "");
      commit("setkortexall", krt);
    },
    kortexUp: async ({ commit, state }, host) => {
      let url;
      let resp;
      let sen;
      url = state.sensors_url + state.sensors_port + "/";
      url += "kortexup";
      url += "?ip=" + host;
      // url = url + "?d1=" + sday//.replace(/-/g, "");
      // url = url + "&d2=" + sday2//.replace(/-/g, "");
      console.log(url);
      try {
        resp = await fetch(url);
        sen = await resp.json();
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      if (sen.status != "success") {
        console.log(sen.error);
        commit("seterror", sen.error);
        return;
      }
      console.log(sen);
      commit("seterror", "");
    },
    kortexDown: async ({ commit, state }, host) => {
      let url;
      let resp;
      let sen;
      url = state.sensors_url + state.sensors_port + "/";
      url += "kortexdown";
      url += "?ip=" + host;
      // url = url + "?d1=" + sday//.replace(/-/g, "");
      // url = url + "&d2=" + sday2//.replace(/-/g, "");
      console.log(url);
      try {
        resp = await fetch(url);
        sen = await resp.json();
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      if (sen.status != "success") {
        console.log(sen.error);
        commit("seterror", sen.error);
        return;
      }
      console.log(sen);
      commit("seterror", "");
    },
    loadFireSens: async ({ commit, state, dispatch }) => {
      let url; // = "kortexsrv.json";
      let resp; // = await fetch(url);
      let tmp; //  = await resp.json();
      let krt;
      //  console.log()
      // console.log(tmp[kk].ip)
      // await actions.loadOtv()
      dispatch("loadOtv");
      url = state.firesens_url + state.firesens_port + "/";
      // url = "http://85.172.174.18:2022/"
      url += "fsall";
      // https://sensrv.kbsu.ru:3010/fsall
      // console.log(url);
      try {
        // url = "sensors.json"
        resp = await fetch(url);
        krt = await resp.json();
        // resp = await fetch("/sensors.json");
        // krt = await resp.json();
        // console.log(krt)
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      if (krt.status != "success") {
        console.log(krt.error);
        commit("seterror", krt.error);
        return;
      }
      // console.log(krt);
      krt.sensors.forEach((s) => {
        let rr = /(.*)\/(.*)\/(.*)/;
        let mm = rr.exec(s.Txt);
        s.t4 = "";
        if (state.otv[s.Corpus]) {
          s.t4 = state.otv[s.Corpus];
        }
        s.t1 = s.T1;
        s.t2 = s.T2;
        if (mm) {
          // s.t1 = mm[1];
          // s.t2 = mm[2];
          if (s.T1) {
            s.t1 = s.T1 + " [" + mm[1] + "]";
          } else {
            s.t1 = mm[1];
          }
          if (s.T2) {
            s.t2 = s.T2 + " [" + mm[2] + "]";
          } else {
            s.t2 = mm[2];
          }
          s.t3 = mm[3];
        } else {
          s.t3 = s.Txt;
        }
        let dd = new Date(s.DeviceTime).toLocaleString("ru").substring(0, 10);
        s.group = dd.substring(5, 10) + dd.substring(3, 5) + dd.substring(0, 2);
      });

      // krt.sensors.sort((a, b) => {
      //   if (a.DeviceTime < b.DeviceTime) {
      //     return 1;
      //   }
      //   if (a.DeviceTime > b.DeviceTime) {
      //     return -1;
      //   }
      //   return 0;
      // });
      let sns = [];
      krt.sensors.forEach((f) => {
        if (f.Event == 250 || f.Event == 251) {
          if (f.Par3 == 0 && f.Par4 == 0) {
            f.Name = "";
            sns.push(f);
          }
        } else {
          sns.push(f);
        }
      });
      // sns.push(krt.sensors[0]);
      // for (let i = 1; i < krt.sensors.length; i++) {
      //   // console.log(
      //   //   new Date(krt.sensors[i - 1].DeviceTime)
      //   //     .toLocaleString("ru")
      //   //     .substr(0, 10)
      //   // );
      //   if (
      //     new Date(krt.sensors[i - 1].DeviceTime)
      //       .toLocaleString("ru")
      //       .substr(0, 10) !=
      //     new Date(krt.sensors[i].DeviceTime).toLocaleString("ru").substr(0, 10)
      //     // krt.sensors[i].DeviceTime.substr(0, 10)
      //   ) {
      //     sns.push({ Name: "", Corpus: "" });
      //   }
      //   sns.push(krt.sensors[i]);
      // }
      commit("seterror", "");
      // commit("setfiresens", krt.sensors);
      commit("setfiresens", sns);
    },
    loadFireSensDates: async ({ commit, state }) => {
      let url; // = "kortexsrv.json";
      let resp; // = await fetch(url);
      let tmp; //  = await resp.json();
      let krt;
      // console.log(tmp)
      // console.log(tmp[kk].ip)
      url = state.firesens_url + state.firesens_port + "/";
      // url = "http://85.172.174.18:2022/"
      url += "fsdates";
      // console.log(url);
      try {
        resp = await fetch(url);
        krt = await resp.json();
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      if (krt.status != "success") {
        console.log(krt.error);
        commit("seterror", krt.error);
        return;
      }
      // console.log(krt);
      commit("seterror", "");
      commit("setfiresensdates", krt.dates);
    },
    loadFireSensObj: async ({ commit, state }) => {
      let url; // = "kortexsrv.json";
      let resp; // = await fetch(url);
      let tmp; //  = await resp.json();
      let krt;
      // console.log(tmp)
      // console.log(tmp[kk].ip)
      url = state.firesens_url + state.firesens_port + "/";
      // url = "http://85.172.174.18:2022/"
      url += "fsobj";
      // console.log(url);
      try {
        resp = await fetch(url);
        krt = await resp.json();
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      if (krt.status != "success") {
        console.log(krt.error);
        commit("seterror", krt.error);
        return;
      }
      // console.log(krt);
      commit("seterror", "");
      commit("setfiresensobj", krt.obj);
    },
    loadpObjects: async ({ commit, state }) => {
      let url; // = "kortexsrv.json";
      let resp; // = await fetch(url);
      let krt;
      url = state.firesens_url + state.firesens_port + "/";
      // url = "http://85.172.174.18:2022/"
      url += "pobjects";
      // console.log(url);
      try {
        resp = await fetch(url);
        krt = await resp.json();
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      if (krt.res && krt.res == "error") {
        console.log(krt.error);
        commit("seterror", krt.error);
        return;
      }
      // console.log(krt);
      commit("seterror", "");
      commit("setpobjects", krt);
    },
    loadWlSens: async ({ commit, state, dispatch }) => {
      let url; // = "kortexsrv.json";
      let resp; // = await fetch(url);
      let tmp; //  = await resp.json();
      let krt;
      // console.log(tmp)
      // console.log(tmp[kk].ip)
      dispatch("loadOtv");
      url = state.firesens_url + state.firesens_port + "/";
      // url = "http://85.172.174.18:2022/"
      url += "wlall";
      // console.log(url);
      try {
        resp = await fetch(url);
        krt = await resp.json();
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      if (krt.status != "success") {
        console.log(krt.error);
        commit("seterror", krt.error);
        return;
      }
      // console.log(krt);
      if (!krt.sensors) {
        krt.sensors = []
       }
        krt.sensors.forEach((s) => {
          let rr = /(.*)\/(.*)\/(.*)/;
          s.t4 = "??";
          if (state.otv[s.Corpus]) {
            s.t4 = state.otv[s.Corpus];
          }
          let mm = rr.exec(s.Txt);
          if (mm) {
            s.t1 = mm[1];
            s.t2 = mm[2];
            s.t3 = mm[3];
          } else {
            s.t3 = s.Txt;
          }
          s.Contents = "Утечка воды";
        });
      commit("seterror", "");
      commit("setwlsens", krt.sensors);
    },
    loadWlSensDates: async ({ commit, state }) => {
      let url; // = "kortexsrv.json";
      let resp; // = await fetch(url);
      let tmp; //  = await resp.json();
      let krt;
      // console.log(tmp)
      // console.log(tmp[kk].ip)
      url = state.firesens_url + state.firesens_port + "/";
      // url = "http://85.172.174.18:2022/"
      url += "wldates";
      // console.log(url);
      try {
        resp = await fetch(url);
        krt = await resp.json();
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      if (krt.status != "success") {
        console.log(krt.error);
        commit("seterror", krt.error);
        return;
      }
      // console.log(krt);
      commit("seterror", "");
      commit("setwlsensdates", krt.dates);
    },
    loadOtv: async ({ commit }) => {
      let url;
      url = "/otv.json";
      // url = state.urlbase + "/hosts";
      // console.log(url)
      let rez;
      rez = await fetch(url);
      rez = await rez.json();
      commit("seterror", "");
      commit("setotv", rez);
    },
    loadSensLastEvent: async ({ commit, state }) => {
      let url; // = "kortexsrv.json";
      let resp; // = await fetch(url);
      let tmp; //  = await resp.json();
      let krt;
      // console.log(tmp)
      // console.log(tmp[kk].ip)
      url = state.firesens_url + state.firesens_port + "/";
      // url = "http://85.172.174.18:2022/"
      url += "lastev";
      // console.log(url);
      try {
        resp = await fetch(url);
        krt = await resp.json();
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      if (krt.status != "success") {
        console.log(krt.error);
        commit("seterror", krt.error);
        return;
      }
      // console.log(krt);
      commit("seterror", "");
      commit("setsenslastevent", krt.sensors);
    },
    loadWelcome: async ({ commit, state }) => {
      let url; // = "kortexsrv.json";
      let resp; // = await fetch(url);
      let tmp; //  = await resp.json();
      let krt;
      // console.log(tmp)
      // console.log(tmp[kk].ip)
      url =
        "http://inform.kbsu.ru/welcome/proxy.php?url=http://172.16.40.132/w_disp.php&sw=getQueue&tm=" + new Date();
      // url = "http://localhost:2021/queue/";
      url = "us.kbsu.ru";
      url = "https://" + url + ":2003/queue/";
      // console.log(url);
       try {
        resp = await fetch(url);
        krt = await resp.json();
      } catch (err) {
        console.log(err);
        commit("seterror", err);
        return;
      }
      // console.log(krt);
      commit("seterror", "");
      commit("setwelcome", krt);
    },
  },
  getters: {
    urlsens: (state) => {
      return state.sensors_url + state.sensors_port;
    },
  },
  modules: {
    authm,
  },
});
