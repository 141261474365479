<template>
  <!-- <div> -->
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dt1"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="d2"
        v-on="on"
        append-icon="mdi-calendar-range"
        color="primary"
        :disabled="!dis"
        single-line
        hide-details
        dense
      >
        <!-- <template v-slot:message>
       jgkjfgjflgjdflj
    </template> -->
      </v-text-field>
    </template>
    <v-container fluid>
      <v-card color="white" class="">
        <!-- <v-sheet color="blue"> -->
        <v-card-text>
          <!-- <v-row clas8s="ma-1"> -->
          <v-container>
            <!-- <v-date-picker
              v-model="dt1"
              locale="ru-RU"
              first-day-of-week="1"
              show-week
              show-current
              :allowed-dates="adt"
              :events="adt"
              event-color="green"
            >
            </v-date-picker> -->
            <v-date-picker
              v-model="dt1"
              locale="ru-RU"
              first-day-of-week="1"
              show-week
              show-current
              event-color="green"
              type="month"
            >
              <!-- <v-btn>1111</v-btn> -->
              <!-- <div class="flex-grow-1"> -->
            </v-date-picker>
          </v-container>
          <!-- <v-divider></v-divider> -->
          <!-- </v-row> -->
        </v-card-text>
        <v-card-actions class="justify-end">
          <!-- <v-row class="align-end justify-end"> -->
          <v-btn color="primary" @click="menu = false">Отмена</v-btn>
          <v-btn color="primary" @click="datechange()">Выбрать</v-btn>
          <!-- </v-row> -->
        </v-card-actions>
        <!-- </v-sheet> -->
      </v-card>
    </v-container>
  </v-menu>
  <!-- </div> -->
</template>
<script>
export default {
  components: {},
  props: {
    day: {
      type: String,
    },
    endates: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dis: Boolean,
  },
  data() {
    return {
      dt1: null,
      d1: null,
      //      dt1: new Date().toISOString().substr(0, 10),
      //      d1: new Date().toISOString().substr(0, 10),
      menu: false,
    };
  },
  methods: {
    adt(val) {
      if (this.endates.length < 1) return true;
      //      console.log(val)
      //      return false
      return this.endates.indexOf(val) != -1;
    },
    datechange() {
      this.menu = false;
      //     this.showalert = false
      this.d1 = this.dt1;
      this.$emit("changed", this.d1);
      //     this.dayobj()
    },
  },
  watch: {
    day: function () {
      // console.log(this.day)
      this.d1 = this.day;
      this.dt1 = this.day;
    },
  },
  computed: {
    d2: function () {
      return new Date(this.d1).toLocaleDateString("ru-RU", {
        month: "long",
        year: "numeric",
      });
      // return new Date(this.d1).toLocaleDateString().substring(2,10);
    },
  },
  mounted() {
    this.d1 = this.day;
    this.dt1 = this.day;
    // this.$date-picker-table-height = 500
  },
};
</script>
<style>
.v-date-picker-table td,
.v-date-picker-table th {
  border: none;
}
</style>