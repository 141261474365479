<template>
  <!-- <div> -->
  <!-- <v-row justify="center"> -->
  <!-- <v-row justify="start">
      <v-col cols="12"> -->

  <v-layout row justify-end>
    <v-flex class="overflow-x-auto">
      <!-- <div id="ttt" v-resize="start"></div> -->
      <div id="ttt"></div>
      <!-- <div id="ttt" v-resize="start"></div> -->
    </v-flex>
  </v-layout>
  <!-- </v-col>
    </v-row> -->
  <!-- </div> -->
</template>
<script>
let svg;
let diagonal;
let tree;
let root;
let i;
let duration;

export default {
  props: {
    treeData: Object,
  },
  data() {
    return {};
  },
  methods: {
    start() {
      //      console.log(this.treeData)
      if (this.treeData.level == undefined) {
       d3.selectAll("#ttt").style("font-size", "32px").style("margin-left","50px").text("Нет данных");
//        d3.selectAll("#ttt").style("font-size", "32px").text("Нет данных");
        // d3.selectAll("#ttt").text("   Нет данных");
        return;
      }
      //      if(this.treeData.length == 0) return;
      //      var treeData = this.treeData;
      var margin = { top: 5, right: 10, bottom: 10, left: 70 },
        //        width = 1960 - margin.right - margin.left,
        width = 1960 - margin.right - margin.left,
        height = 860 - margin.top - margin.bottom;
      i = 0;
      duration = 750;
      //  width = d3
      //     .select("#ttt")
      //     .node()
      //     .getBoundingClientRect().width;
      //        width = width - margin.right - margin.left

      tree = d3.layout.tree().size([height, width]);

      diagonal = d3.svg.diagonal().projection(function (d) {
        return [d.y, d.x];
      });
      d3.selectAll("#ttt").style("margin-left","0px").text("");
      svg = d3
        .select("#ttt")
        .append("svg")
        .attr("width", width + margin.right + margin.left)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      root = this.treeData;
      //console.log(this.treeData)
      root.x0 = height / 2;
      root.y0 = 0;
      this.update(root);

      d3.select(self.frameElement).style("height", "700px");
    },

    update(source) {
      //console.log(source)
      //        if(this.svg==null) return;
      // Compute the new tree layout.
      var nodes = tree.nodes(root).reverse(),
        links = tree.links(nodes);

      // Normalize for fixed-depth.
      let sme =[10, 200,400,550,700,900]
      nodes.forEach(function (d) {
         d.y = sme[d.depth];
        // let shir = 350;
        //   d.y = d.depth * shir;
        // let shir = 100;
        // if (d.depth > 1) {
        //   shir = 300;
        //   d.y = d.depth * shir;
        //   d.y += 200;
        // } else {
        //   d.y = d.depth * shir;
        // }
      });

      // Update the nodes…
      var node = svg.selectAll("g.node").data(nodes, function (d) {
        return d.id || (d.id = ++i);
      });

      //      console.log(this.svg)
      // Enter any new nodes at the parent's previous position.
      var nodeEnter = node
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("transform", function (d) {
          return "translate(" + source.y0 + "," + source.x0 + ")";
        })
        .on("click", this.click);

      nodeEnter
        .append("circle")
        .attr("r", 1e-6)
        .style("fill", function (d) {
          return d._children ? "lightsteelblue" : "#fff";
        });

      nodeEnter
        .append("text")
        .attr("x", function (d) {
          return d.children || d._children ? -13 : 13;
        })
        .attr("dy", ".35em")
        .attr("text-anchor", function (d) {
          return d.children || d._children ? "end" : "start";
        })
        .text(function (d) {
          return d.name;
        })
        .style("fill-opacity", 1e-6);

      // Transition nodes to their new position.
      var nodeUpdate = node
        .transition()
        .duration(duration)
        .attr("transform", function (d) {
          return "translate(" + d.y + "," + d.x + ")";
        });

      nodeUpdate
        .select("circle")
        .attr("r", 10)
        .style("fill", function (d) {
          return d._children ? "lightsteelblue" : "#fff";
        });

      nodeUpdate.select("text").style("fill-opacity", 1);

      // Transition exiting nodes to the parent's new position.
      var nodeExit = node
        .exit()
        .transition()
        .duration(duration)
        .attr("transform", function (d) {
          return "translate(" + source.y + "," + source.x + ")";
        })
        .remove();

      nodeExit.select("circle").attr("r", 1e-6);

      nodeExit.select("text").style("fill-opacity", 1e-6);

      // Update the links…
      var link = svg.selectAll("path.link").data(links, function (d) {
        return d.target.id;
      });

      // Enter any new links at the parent's previous position.
      link
        .enter()
        .insert("path", "g")
        .attr("class", "link")
        .attr("d", function (d) {
          var o = { x: source.x0, y: source.y0 };
          return diagonal({ source: o, target: o });
        });

      // Transition links to their new position.
      link.transition().duration(duration).attr("d", diagonal);

      // Transition exiting nodes to the parent's new position.
      link
        .exit()
        .transition()
        .duration(duration)
        .attr("d", function (d) {
          var o = { x: source.x, y: source.y };
          return diagonal({ source: o, target: o });
        })
        .remove();

      // Stash the old positions for transition.
      nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    },
    click(d) {
      //      console.log(d)
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
      this.update(d);
    },
  },
  watch: {
    treeData: function () {
      this.start();
    },
  },
  mounted() {
    this.start();
  },
};
</script>
<style>
.node {
  cursor: pointer;
}
.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}
.node text {
  font: 12px sans-serif;
}
.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}
</style>