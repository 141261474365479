var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-2 mr-2"},[_c('v-row',{staticClass:"justify-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"xs":"12","sm":"11","md":"10","lg":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"justify-center",attrs:{"dense":""}},[_c('v-col',[(true)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"id":"tbld","headers":_vm.headers,"items":_vm.firesensfilter,"search":_vm.search,"item-key":"sensor","dense":"","items-per-page":20,"mobile-breakpoint":100,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageText: 'Записей на странице',
          itemsPerPageOptions: [
            10,
            15,
            20,
            25,
            50,
            100,
            { text: 'Все записи', value: -1 } ],
        }},scopedSlots:_vm._u([{key:"item.DeviceTime",fn:function(ref){
        var item = ref.item;
return [(item.Event == 360)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(new Date(item.DeviceTime).toLocaleString("ru"))+" ")]):_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(new Date(item.DeviceTime).toLocaleString("ru"))+" ")])]}},{key:"item.Corpus",fn:function(ref){
        var item = ref.item;
return [(item.Event == 360)?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.Corpus))]):_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.Corpus))])]}},{key:"item.Name",fn:function(ref){
        var item = ref.item;
return [(item.Event == 360)?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.Name))]):_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.Name))])]}},{key:"item.Remark",fn:function(ref){
        var item = ref.item;
return [(item.Event == 360)?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.Remark))]):_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.Remark))])]}},{key:"item.Contents",fn:function(ref){
        var item = ref.item;
return [(item.Event == 360)?_c('span',{staticClass:"red--text"},[_vm._v(" Сигнал в МЧС ")]):_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.Contents)+" ")])]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,1433575796)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }