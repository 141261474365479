<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <!-- <span v-bind="attrs" v-on="on"> {{ disk }}</span> -->
      <v-icon :color="coloricon" @click="fun()" v-bind="attrs" v-on="on" v-if="fun" :dark="isdark">{{
        nameicon
      }}</v-icon>
      <v-icon :color="coloricon" v-bind="attrs" v-on="on"  :dark="isdark" v-else>{{
        nameicon
      }}</v-icon>
      <!-- <v-icon :color="coloricon" @click="fun()">{{ nameicon }}</v-icon> -->
    </template>
    <span> {{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    nameicon: String,
    coloricon: String,
    tooltip: String,
    fun: Function,
    isdark: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {};
  },
  methods: {
    // ...mapActions(["loadSettings", "LoadObjects"]),
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
  },
  mounted() {},
};
</script>
