<template>
  <!-- <div> -->
    <v-data-table
      :headers="headers"
      :items="val"
      :footer-props="{
      showFirstLastPage: true,
      itemsPerPageText: 'Записей на странице',
      itemsPerPageOptions: [12,24,48,96,{'text':'Все записи','value':-1}]
    }"
      class="elevation-1"
      :mobile-breakpoint="100"
    >
      <template v-slot:item.val.Avg="{ item }">
        <span v-if="item.val"> {{item.val.Avg.toFixed(2)}} </span>
      </template>
      <!-- <template v-slot:item.isPresenter="{ item }">
          <span v-if="item.isPresenter=='true'">Да</span>
          <span v-else>Нет</span>
      </template> -->
    </v-data-table>
  <!-- </div> -->
</template>
<script>
export default {
  components: {
  },
  props: {
    values: Array
  },
  data() {
    return {
      headers: [
        { text: "Дата", value: "date", align: "left" },
        { text: "Значение min", value: "val.Min", align: "center" },
        { text: "Значение max", value: "val.Max", align: "center" },
        { text: "Значение среднее", value: "val.Avg", align: "center" },
      ]
    };
  },
  methods: {},
  computed:{
    val : function(){
      let rr = [... this.values]
      return rr.reverse()
    },
  },
  mounted() {
//    console.log(this.users)
  }
};
</script>