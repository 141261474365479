<template>
  <!-- <div> -->
  <v-data-table
    :headers="headers"
    :items="values"
    :expanded.sync="expanded"
    :single-expand="false"
    show-expand
    item-key="room"
    :footer-props="{
      showFirstLastPage: true,
      itemsPerPageText: 'Записей на странице',
      itemsPerPageOptions: [24, 48, 96, { text: 'Все записи', value: -1 }],
    }"
    class="elevation-1"
    :mobile-breakpoint="100"
  >
    <template v-slot:item.proc="{ item }"> {{ item.proc.toFixed(2) }}% </template>
    <!-- <template v-slot:item.i4 = "{ item }">
          <span v-if="item.i4.indexOf('Да') != -1" class="green--text">{{item.i4}}</span>
          <span v-if="item.i4 == 'Нет'" class="red--text">{{item.i4}}</span>
          <span v-if="item.i4 == '???'">{{item.i4}}</span>
      </template> -->
    <!-- <template v-slot:item="{ item }">
          <span v-if="item.rez == 'Да'" class="green--text">Да</span>
          <span v-if="item.rez= 0 && item.proc < 1" class="red--text">Нет</span>
          <span v-if="item.proc < 0" >Нет данных</span>
      </template> -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <!-- {{item}} -->
        <v-row class="justify-center ma-2">
          <v-col cols="11" md="11" lg="11">
            <graph :dd="item.all" class="mb-2"></graph>
          </v-col>
        </v-row>
          <v-row class="justify-center ma-2">
            <v-col md="11" lg="8">
              <analiz :values="item.irintervals"></analiz>
            </v-col>
          </v-row>
        <v-row class="justify-center ma-2">
          <v-col cols="11" md="11" lg="8">
            <values :values="item.data"></values>
          </v-col>
        </v-row>
      </td>
    </template>
  </v-data-table>
  <!-- </div> -->
</template>
<script>
import values from "./values";
import analiz from "./ir_analiz";
import graph from "./ir_graph";
export default {
  components: {
    values,
    graph,
    analiz,
  },
  props: {
    values: Array,
    headers: {
      type: Array,
      default: (ee) => {
        [];
      },
    },
  },
  data() {
    return {
      expanded: [],
    };
  },
  methods: {},
  mounted() {
    //    console.log(this.values);
  },
};
</script>
