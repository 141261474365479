<template>
  <!-- <div> -->
    <v-data-table
      :headers="headers"
      :items="values"
      :footer-props="{
      showFirstLastPage: true,
      itemsPerPageText: 'Записей на странице',
      itemsPerPageOptions: [24,48,96,{'text':'Все записи','value':-1}]
    }"
      class="elevation-1"
      :mobile-breakpoint="100"
    >
      <!-- <template v-slot:item.isPresenter="{ item }">
          <span v-if="item.isPresenter=='true'">Да</span>
          <span v-else>Нет</span>
      </template> -->
    </v-data-table>
  <!-- </div> -->
</template>
<script>
export default {
  components: {
  },
  props: {
    values: Array
  },
  data() {
    return {
      headers: [
        { text: "Дата", value: "clock", align: "left" },
        { text: "Значение", value: "value", align: "center" },
      ]
    };
  },
  methods: {},
  mounted() {
    // console.log(this.values)
  }
};
</script>