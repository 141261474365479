<template>
  <!-- <div> -->
  <v-list-item dense>
    <!-- <v-list-item-icon> -->
    <v-list-item-avatar tile>
      <!-- <v-icon color="green" v-if="tcheck() && wcheck()" @click="graf()"
          >mdi-home-analytics</v-icon
        > -->
      <v-icon color="green" v-if="tcheck() && wcheck()" @click="graf()"
        >mdi-door</v-icon
      >
      <v-icon color="red" v-else @click="graf()">mdi-door</v-icon>
    </v-list-item-avatar>
    <!-- </v-list-item-icon> -->
    <v-list-item-content>
      <!-- <v-list-item-title v-text="disk" class="text-subtitle-2"> -->
      <v-list-item-title class="text-subtitle-2">
        <!-- <v-textarea
      :value="disk"
    ></v-textarea> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> {{ disk }}</span>
          </template>
          <span> {{ disk }}</span>
        </v-tooltip>
      </v-list-item-title>
      <!-- {{unit.name}} -->
    </v-list-item-content>
    <v-list-item-action>
      <!-- <v-icon color="amber">mdi-temperature-celsius</v-icon> -->
      <v-row class="ml-0">
        <!-- <v-icon color="orange" v-if="tnodata > 0">mdi-clock-alert</v-icon> -->
        <iconmy
          nameicon="mdi-clock-alert"
          coloricon="orange"
          tooltip="Нет данных по температуре за текущий час"
          v-if="tnodata > 0"
        ></iconmy>
        <!-- <v-icon color="blue" v-if="hnodata > 0">mdi-clock-alert</v-icon> -->
        <iconmy
          nameicon="mdi-clock-alert"
          coloricon="blue"
          tooltip="Нет данных по влажности за текущий час"
          v-if="hnodata > 0"
        ></iconmy>
        <!-- <v-badge color="red" class="" v-if="(tnodata+hnodata) > 0">
            <v-icon color="">mdi-clock-alert</v-icon>
            <template v-slot:badge> {{ tnodata+hnodata }} </template>
          </v-badge> -->
        <v-btn icon v-if="tcheck()">
          <!-- <v-icon color="orange" @click="temperature()">mdi-thermometer</v-icon> -->
          <div v-if="iskortex">
            <v-badge
              color="orange"
              class="mr-0"
              overlap
              bottom
              style="z-index: 1"
            >
              <iconmy
                nameicon="mdi-thermometer"
                coloricon="orange"
                :fun="temperature"
                tooltip="Температура за сутки (таблица)"
              ></iconmy>
              <template v-slot:badge>
                {{
                  unit["Температура"][
                    unit["Температура"].length - 1
                  ].val.Avg.toFixed(1)
                }}
              </template>
            </v-badge>
          </div>
          <div v-else>
            <iconmy
              nameicon="mdi-thermometer"
              coloricon="orange"
              :fun="temperature"
              tooltip="Температура за сутки (таблица)"
            ></iconmy>
          </div>
        </v-btn>
        <div v-else>
          <!-- <v-chip color="red lighten-4">
            <iconmy
              nameicon="mdi-thermometer-alert"
              coloricon="orange"
              :fun="temperature"
              tooltip="Температура за сутки (таблица)"
            ></iconmy>
          </v-chip> -->

          <v-badge
            color="orange"
            class="mr-0"
            overlap
            bottom
            style="z-index: 1"
            v-if="iskortex"
          >
            <v-chip color="red lighten-4">
              <iconmy
                nameicon="mdi-thermometer-alert"
                coloricon="orange"
                :fun="temperature"
                tooltip="Температура за сутки (таблица)"
              ></iconmy>
            </v-chip>
            <template v-slot:badge>
              {{
                unit["Температура"][
                  unit["Температура"].length - 1
                ].val.Avg.toFixed(1)
              }}
            </template>
          </v-badge>

          <v-chip v-else color="red lighten-4">
            <iconmy
              nameicon="mdi-thermometer-alert"
              coloricon="orange"
              :fun="temperature"
              tooltip="Температура за сутки (таблица)"
            ></iconmy>
          </v-chip>
        </div>

        <v-btn icon v-if="wcheck()">
          <!-- <v-icon color="blue" @click="water()">mdi-water-percent</v-icon> -->
          <iconmy
            nameicon="mdi-weather-rainy"
            coloricon="blue"
            :fun="water"
            tooltip="Влажность за сутки (таблица)"
          ></iconmy>
        </v-btn>
        <v-chip v-else color="red lighten-4">
          <!-- <v-icon color="blue" @click="water()">mdi-water-percent</v-icon> -->
          <iconmy
            nameicon="mdi-weather-cloudy-alert"
            coloricon="blue"
            :fun="water"
            tooltip="Влажность за сутки (таблица)"
          ></iconmy>
        </v-chip>

        <span v-if="unit['ИК']">
          <v-btn icon v-if="ircheck()">
            <!-- <v-icon color="blue" @click="water()">mdi-water-percent</v-icon> -->
            <iconmy
              nameicon="mdi-motion-sensor"
              coloricon="grey"
              :fun="ir"
              tooltip="Не зафиксировано движения за последний час"
            ></iconmy>
          </v-btn>
          <!-- <v-chip v-else color="red"> -->
          <!-- <v-icon color="blue" @click="water()">mdi-water-percent</v-icon> -->
          <v-btn icon v-else>
            <iconmy
              nameicon="mdi-motion-sensor"
              coloricon="green"
              :fun="ir"
              tooltip="Зафиксировано движение в течении последнего часа"
            ></iconmy>
          </v-btn>
          <!-- </v-chip> -->
        </span>
      </v-row>
    </v-list-item-action>
    <v-dialog v-model="dtemper" persistent max-width="900px">
      <v-card>
        <v-card-title> Температура ({{ disk }}) </v-card-title>
        <v-card-text>
          <values
            :values="unit['Температура']"
            :key="upd"
            :vmin="Number(bounds.tmin)"
            :vmax="Number(bounds.tmax)"
          ></values>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="dtemper = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dwater" persistent max-width="900px">
      <v-card>
        <v-card-title> Влажность ({{ disk }}) </v-card-title>
        <v-card-text>
          <values
            :values="unit['Влажность']"
            :key="upd"
            :vmin="Number(bounds.hmin)"
            :vmax="Number(bounds.hmax)"
          ></values>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="dwater = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dir" persistent max-width="900px">
      <v-card>
        <v-card-title> ИК ({{ disk }}) </v-card-title>
        <v-card-text>
          <values
            :values="unit['ИК']"
            :key="upd"
            :vmin="Number(bounds.irmin)"
            :vmax="Number(bounds.irmax)"
          ></values>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="dir = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
  <!-- </div> -->
  <!-- :style="item.prnbusy ? 'cursor: default;' : 'cursor: move;'" -->
  <!-- <v-card
    draggable="true"
    @dragstart="dstart($event)"
    @click="select()"
    :color="setcolor()"
    :key="upd"
  >
    <v-card-subtitle>{{ unit.name }}</v-card-subtitle>
  </v-card> -->
</template>
<script>
import { mapState, mapMutations } from "vuex";
import values from "./valuesbounds";
import iconmy from "./icon_my.vue";
export default {
  components: {
    values,
    iconmy,
  },
  props: {
    name: String,
    disk: String,
    unit: Object,
    bounds: Object,
    iskortex: Boolean,
  },
  data() {
    return {
      selected: false,
      upd: 0,
      dtemper: false,
      dwater: false,
      dir: false,
      tnodata: 0,
      hnodata: 0,
      irnodata: 0,
      diffhours: 0,
    };
  },
  methods: {
    graf() {
      let url;
      // if (this.name == "ИТФ") {
      if (this.kortex[this.name]) {
        url =
          // "http://us.kbsu.ru:3000/d/JmIsv9Dnk3/datchiki-v-kabinete-plius-zadvizhka?orgId=1&refresh=1m";
          "http://us.kbsu.ru:3000/d/JmIsv9Dnk3/datchiki-v-kabinete-plius-zadvizhka?orgId=1&var-corpus=" +
          this.name +
          "&var-room=" +
          this.disk +
          "&var-kortexip=" +
          this.kortex[this.name].ip;
        ("&refresh=1m&from=now-3h&to=now");
        // "172.16.15.801";
        // console.log(this.name)
      } else {
        url =
          "http://us.kbsu.ru:3000/d/JmIsv9Dnk/datchik-v-kabinete?orgId=1&var-corpus=" +
          this.name +
          "&var-room=" +
          this.disk +
          "&refresh=1m&from=now-3h&to=now";
        // console.log(this.name)
      }
      // console.log(url)
      window.open(url, "_blank");
    },
    tcheck() {
      let bb = true;
      let tnodata0 = 0;
      let hour = Number(new Date().toLocaleString("ru").substr(12, 2));
      if (this.unit["Температура"]) {
        bb =
          bb &&
          this.unit["Температура"][this.unit["Температура"].length - 1].val
            .Avg >= this.bounds.tmin &&
          this.unit["Температура"][this.unit["Температура"].length - 1].val
            .Avg <= this.bounds.tmax;
        // проверка на данные
        let lasth =
          this.unit["Температура"][this.unit["Температура"].length - 1].date;
        lasth = Number(lasth.substr(11, 2));
        // console.log(hour + " " + lasth);
        if (hour - lasth > this.diffhours) {
          tnodata0++;
          // console.log(hour+" "+lasth)
        }
      } else {
        tnodata0++;
        // bb=false
      }
      this.tnodata = tnodata0;
      return bb;
    },
    wcheck() {
      let bb = true;
      let hnodata0 = 0;
      let hour = Number(new Date().toLocaleString("ru").substr(12, 2));
      if (this.unit["Влажность"]) {
        bb =
          bb &&
          this.unit["Влажность"][this.unit["Влажность"].length - 1].val.Avg >=
            this.bounds.hmin &&
          this.unit["Влажность"][this.unit["Влажность"].length - 1].val.Avg <=
            this.bounds.hmax;
        // проверка на данные
        let lasth =
          this.unit["Влажность"][this.unit["Влажность"].length - 1].date;
        lasth = Number(lasth.substr(11, 2));
        if (hour - lasth > this.diffhours) {
          hnodata0++;
        }
      } else {
        hnodata0++;
        // bb=false
      }
      this.hnodata = hnodata0;
      return bb;
    },
    ircheck() {
      let bb = true;
      let irnodata0 = 0;
      let hour = Number(new Date().toLocaleString("ru").substr(12, 2));
      if (this.unit["ИК"]) {
        for (let ii in this.unit["ИК"]) {
          // console.log(n)
          // console.log(ii)
          if (this.unit["ИК"][ii].date.substr(11, 2) == hour) {
            bb =
              bb &&
              // this.unit["ИК"][ii].val.Max >=
              //   this.bounds.irmin &&
              this.unit["ИК"][ii].val.Max <= this.bounds.irmax &&
              this.unit["ИК"][ii].val.Min >= this.bounds.irmin;
          }
          // this.unit["ИК"][ii].val.Min <=
          //   this.bounds.irmax;
        }
        // this.unit["ИК"][this.unit["ИК"].length - 1].val.Max >=
        //   this.bounds.irmin &&
        // this.unit["ИК"][this.unit["ИК"].length - 1].val.Max <=
        //   this.bounds.irmax;
        // проверка на данные
        let lasth = this.unit["ИК"][this.unit["ИК"].length - 1].date;
        lasth = Number(lasth.substr(11, 2));
        // console.log(hour + " " + lasth);
        if (hour - lasth > this.diffhours) {
          irnodata0++;
          // console.log(hour+" "+lasth)
        }
      } else {
        irnodata0++;
        // bb=false
      }
      this.irnodata = irnodata0;
      return bb;
    },
    temperature() {
      this.upd++;
      this.dtemper = true;
      // console.log(this.unit["Температура"]);
    },
    water() {
      this.upd++;
      this.dwater = true;
    },
    ir() {
      this.upd++;
      this.dir = true;
    },
    select() {
      // console.log(this.unit.selected)
      if (this.unit.selected == undefined) {
        this.unit.selected = false;
      }
      this.unit.selected = !this.unit.selected;
      this.upd++;
    },
    setcolor() {
      if (this.unit.selected) {
        return "green lighten-3";
      } else {
        return "white";
      }
    },
    // ...mapActions(["loadSettings", "LoadObjects"]),
    dover() {
      //    console.log("Drag over.");
    },
    dend() {
      //      console.log("Drag end.");
    },
    dstart(evt) {
      //      console.log("Drag end.");
      //   evt.unit = this.unit;
      //   evt.dataTransfer.effectAllowed="add"
      // evt.dataTransfer.setData("unit", JSON.stringify(this.unit));
      // evt.dataTransfer.setData("disk", this.disk);
      //  console.log(evt);
    },
  },
  computed: {
    //    ...mapGetters(["urlmdlstat"]),
    ...mapState({
      kortex: (state) => state.kortex,
    }),
  },
  mounted() {
    // console.log(this.kortex)
  },
};
</script>