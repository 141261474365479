import '@babel/polyfill'
import 'whatwg-fetch'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'leaflet/dist/leaflet.css';
import  "leaflet-easyprint"
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";

Vue.config.productionTip = false

var USEKEYCLOAK = true
export { USEKEYCLOAK }
if (USEKEYCLOAK) {
  Vue.use(VueKeyCloak, {
    init: {
      // onLoad: "login-required",
      // checkLoginIframe: false,
      onLoad: 'check-sso',
    },
    logout: {
      //    redirectUri: 'http://us.kbsu.ru:8050'
      redirectUri: 'https://sens.kbsu.ru/#/'
    },
    config: {
      realm: "kbsu",
      url: "https://auth.kbsu.ru:8443/auth",
      // url: "",
      //    url: 'https://open01.kbsu.ru:8443/auth/realms/kbsu',
      clientId: "kbsu",
      "enable-cors": false,
      enableCors: false,
      corsAllowedMethods: "*",
      "Access-Control-Allow-Origin": "*",
      accessControlAllowOrigin: "*",
      "cors-allowed-headers": "*",
      "cors-origins": "*",
      webOrigins: "*",
      "cors-exposed-headers": "*",
      corsExposedHeaders: "*",
      //    credentials: {
      //      secret: '0a9a15d9-6562-49c6-b21e-e942e815a02c' // clientSecret
      //  },
      //    flow: 'implicit',
    },
    onReady: () => {
      //    this.$keycloak.corsOrigin="*"
      //      onReady: () => {
      new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount("#app");
    },
  });
} else {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}