var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"Поиск устройств"},model:{value:(_vm.mask),callback:function ($$v) {_vm.mask=$$v},expression:"mask"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.printers.length)+" ")])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-select',{attrs:{"items":_vm.filters,"attach":"","chips":"","label":"Фильтр","multiple":""},model:{value:(_vm.flt),callback:function ($$v) {_vm.flt=$$v},expression:"flt"}})],1),_c('v-row',{attrs:{"dense":""}},[_c('v-virtual-scroll',{staticClass:"pa-0 ma-0",attrs:{"items":_vm.printers,"item-height":55,"height":_vm.hhh,"width":"270px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-divider'),_c('v-list-item',{staticClass:"align-center",style:((item.prnbusy || !item.hostid) ? 'cursor: default;' : 'cursor: move;'),attrs:{"draggable":!item.prnbusy},on:{"dragstart":function($event){return _vm.prnstart($event, item)},"dragend":function($event){return _vm.prnend($event, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[(_vm.prncolor(item).msg != '')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.prncolor(item).color},on:{"click":function($event){_vm.banner = true}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.hosticon(item)))])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.prncolor(item).msg)+" ")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v(_vm._s(_vm.hosticon(item)))]),_c('span',[_vm._v(_vm._s(item.name))])],1),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item['Model Name 2'])}})],1),_c('v-list-item-action',[_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_vm._v(" "+_vm._s(item.inventory.model)+" "),_c('br'),_vm._v(" S/N: "+_vm._s(item.inventory.serialno_a)+" "),_c('br'),_vm._v(" "+_vm._s(item.inventory.site_address_a)),_c('br'),_vm._l((item.items),function(cc){return _c('div',{key:cc.itemid},[_vm._v(" "+_vm._s(cc.name)+": "+_vm._s(cc.lastvalue)+"% "),_c('br')])})],2),(item.prnbusy)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"color":"brown"},on:{"click":function($event){return _vm.prnonplan(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-map-marker")])]}}],null,true)},[_vm._v(" Показать место установки "),_c('br'),_vm._v(" "+_vm._s(item.address)+" ")]):_vm._e(),(!item.prnbusy && item.hostid)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"color":"green"},on:{"click":function($event){return _vm.prnadd(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-right-circle")])]}}],null,true)},[_vm._v(" Добавить принтер на план этажа ")]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"color":"brown"},on:{"click":function($event){_vm.banner = true}}},'v-icon',attrs,false),on),[_vm._v("mdi-chevron-down")])]}}],null,true)},[_c('printercard',{attrs:{"item":item}})],1)],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }