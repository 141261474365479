<template>
  <div v-if="pstate">
    <v-speed-dial
      v-model="fab"
      bottom
      left
      direction="right"
      :open-on-hover="false"
      transition="slide-x-transition"
      :color="vstate().color"
      dark
      absolute
      fab
      small
      style="bottom: 0; left: 0"
      dense
    >
      <template v-slot:activator>
        <v-btn v-model="fab" :color="vstate().color" dark fab small :loading="pstate.state=='up' || pstate.state=='down'">
          <!-- <v-icon v-if="fab"> {{ picon() }} </v-icon> -->
          <iconmy
            v-if="fab"
            :nameicon="vstate().icon"
            coloricon="white"
            :tooltip="vstate().text"
          ></iconmy>
          <!-- <v-icon v-else>
          {{ picon() }}
        </v-icon> -->
          <iconmy
            v-else
            :nameicon="vstate().icon"
            coloricon="white"
            :tooltip="vstate().text"
          ></iconmy>
        </v-btn>
      </template>
      <!-- <v-btn fab dark x-small color="green" :disabled="pstate == 'on'">
      <v-icon>mdi-water-pump</v-icon>
    </v-btn> -->
      <v-btn
        fab
        dark
        x-small
        color="green"
        :disabled="pstate.state != 'off'"
        @click="pipeon = true"
      >
        <iconmy
          :isdark="false"
          nameicon="mdi-water-pump"
          coloricon="white"
          tooltip="Открыть задвижку"
        ></iconmy>
      </v-btn>
      <!-- <v-btn fab dark x-small color="red" :disabled="pstate == 'off'">
      <v-icon>mdi-water-pump-off</v-icon>
    </v-btn> -->
      <v-btn
        fab
        dark
        x-small
        color="red"
        :disabled="pstate.state != 'on'"
        @click="pipeoff = true"
      >
        <iconmy
          :isdark="false"
          nameicon="mdi-water-pump-off"
          coloricon="white"
          tooltip="Закрыть задвижку"
        ></iconmy>
      </v-btn>
      <!-- <v-btn fab dark x-small color="blue">
      <v-icon>mdi-information</v-icon>
    </v-btn> -->
      <v-btn fab dark x-small color="blue" @click="pipeinfo = true">
        <iconmy
          :isdark="false"
          nameicon="mdi-information-outline"
          coloricon="white"
          tooltip="Информация о состоянии задвижки"
        ></iconmy>
      </v-btn>
    </v-speed-dial>
    <!-- <v-banner
      v-model="pipeinfo"
      single-line
      transition="slide-y-transition"
    >
      Информация о состоянии задвижки
      <template v-slot:actions="{ dismiss }">
        <v-btn
          text
          color="primary"
          @click="dismiss"
        >
          OK
        </v-btn>
      </template>
    </v-banner>   -->
    <v-dialog v-model="pipeon" persistent width="500">
      <v-card>
        <v-card-text>
          <v-row class="">
            <v-col cols="12">
              <!-- <p class="text-xs-center mt-3"></p> -->
              <!-- <p class="text-xs-center text-h4"> Что делать с изменениями? </p>  </v-alert> -->
              <v-alert prominent>
                <v-row class="align-center justify-center">
                  <v-icon large class="mr-5" color="blue"
                    >mdi-help-circle-outline</v-icon
                  >
                  <span class="text-h4"> Открыть задвижку?</span>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions align-center justify-center>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="pipeon = false" color="red" class="white--text"  :to="{ name: 'kortexcontrol', params: {krt: pstate} }" -->
          <v-btn @click="pipeon = false" color="red" class="white--text"  :to="{ name: 'kortexcontrol', params: {ip: pstate.ip, cname: name} }"
            >Да</v-btn
          >
          <v-btn @click="pipeon = false" color="green" class="white--text"
            >Нет</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pipeoff" persistent width="500">
      <v-card>
        <v-card-text>
          <v-row class="">
            <v-col cols="12">
              <!-- <p class="text-xs-center mt-3"></p> -->
              <!-- <p class="text-xs-center text-h4"> Что делать с изменениями? </p>  </v-alert> -->
              <v-alert prominent>
                <v-row class="align-center justify-center">
                  <v-icon large class="mr-5" color="blue"
                    >mdi-help-circle-outline</v-icon
                  >
                  <span class="text-h4"> Закрыть задвижку?</span>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions align-center justify-center>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="pipeoff = false" color="red" class="white--text" :to="{ name: 'kortexcontrol', params: {krt: pstate} }" -->
          <v-btn @click="pipeoff = false" color="red" class="white--text" :to="{ name: 'kortexcontrol', params: {ip: pstate.ip, cname: name} }"
            >Да</v-btn
          >
          <v-btn @click="pipeoff = false" color="green" class="white--text"
            >Нет</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pipeinfo" persistent width="800">
      <v-card>
        <v-card-text>
          <v-row class="">
            <v-col cols="12">
              <!-- <p class="text-xs-center mt-3"></p> -->
              <!-- <p class="text-xs-center text-h4"> Что делать с изменениями? </p>  </v-alert> -->
              <v-alert prominent>
                <v-row class="align-center justify-center">
                  <v-icon large class="mr-5" color="blue"
                    >mdi-information</v-icon
                  >
                  <span class="text-h6">Информация о состоянии задвижки:</span>
                </v-row>
                <v-row class="align-center justify-center">
                  <!-- <v-row justify="center" v-if="pstate.state == 'on'"> -->
                  <v-row justify="center">
                    <v-icon large :color="vstate().color" class="mr-4">{{
                      vstate().icon
                    }}</v-icon>
                    <span
                      :class="'text-h4 '+vstate().textcolor"
                      >{{vstate().text}}</span
                    >
                  </v-row>
                  <!-- <v-row justify="center" v-if="pstate.state == 'off'">
                    <v-icon large :color="pcolor()" class="mr-4">{{
                      picon()
                    }}</v-icon>
                    <span class="text-h4 red--text" v-if="pstate.state == 'off'"
                      >Закрыта</span
                    ></v-row
                  > -->
                  <!-- <v-row justify="center" v-if="pstate.state == '?'">
                    <v-icon large :color="pcolor()" class="mr-4">{{
                      picon()
                    }}</v-icon>
                    <span
                      class="text-h4 orange--text"
                      v-if="pstate.state == '?'"
                      >Нет информации</span
                    ></v-row
                  > -->
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  Корпус: {{name}}
                </v-row>
                <v-row>
                  IP: {{pstate.ip}}
                </v-row>
                <v-row v-for="vv in pstate.oids" :key="vv.oid">
                  {{ vv.name }}: {{ vv.value }}
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions align-center justify-center>
          <v-spacer></v-spacer>
          <v-btn @click="pipeinfo = false" color="blue" class="white--text"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <!-- <v-btn
    v-show="false"
    :color="pcolor()"
    dark
    absolute
    bottom
    left
    fab
    small
    style="bottom: 0; left: 0"
  >
    <v-icon>{{picon()}}</v-icon>
  </v-btn> -->
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import iconmy from "../sensors/icon_my.vue";
export default {
  components: {
    iconmy,
  },
  props: {
    pstate: {
      type: Object,
      // default: "?",
    },
    name: String,
  },
  data() {
    return {
      fab: false,
      pipeon: false,
      pipeoff: false,
      pipeinfo: false,
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
    vstate() {
      let rr = {
        color: "",
        text: "",
        icon: "",
        textcolor: "",
      };
      // this.pstate.state = "up"
      switch (this.pstate.state) {
        case "on":
          rr.text = "Задвижка открыта";
          rr.color = "green darken-2";
          rr.textcolor="green--text"
          rr.icon = "mdi-play-network";
          break;
        case "off":
          rr.text = "Задвижка закрыта";
          rr.color = "red darken-2";
          rr.textcolor="red--text"
          rr.icon = "mdi-close-network";
          break;
        case "up":
          rr.text = "Задвижка открывается";
          rr.color = "green lighten-2";
          rr.textcolor="green--text"
          rr.icon = "mdi-play-network";
          break;
        case "down":
          rr.text = "Задвижка закрывается";
          rr.color = "red lighten-2";
          rr.textcolor="red--text"
          rr.icon = "mdi-close-network";
          break;
        case "upstart":
          rr.text = "Задвижка начинает открываться";
          rr.color = "green lighten-2";
          rr.textcolor = "green--text";
          rr.icon = "mdi-upload-network";
          break;
        case "downstart":
          rr.text = "Задвижка начинает закрываться";
          rr.color = "red lighten-2";
          rr.textcolor = "red--text";
          rr.icon = "mdi-download-network";
          break;
        case "upend":
          rr.text = "Процесс открытия завершен";
          rr.color = "green lighten-2";
          rr.textcolor = "green--text";
          rr.icon = "mdi-upload-network";
          break;
        case "downend":
          rr.text = "Процесс закрытия завершен";
          rr.color = "red lighten-2";
          rr.textcolor = "red--text";
          rr.icon = "mdi-download-network";
          break;
        case "err":
          rr.text = "Ошибка устройства!!!!!";
          rr.color = "red darken-2";
          rr.textcolor="red--text"
          rr.icon = "mdi-block-helper";
         break;
        case "?":
          rr.text = "Нет информации о состоянии задвижки";
          rr.color = "orange darken-2";
          rr.textcolor="orange--text"
          rr.icon = "mdi-help-network";
         break;
      }
      return rr;
    },
    ptext() {
      switch (this.pstate.state) {
        case "on":
          return "Задвижка открыта";
        // break;
        case "off":
          return "Задвижка закрыта";
        // break;
        case "?":
          return "Нет информации о состоянии задвижки";
        // break;
      }
    },
    pcolor() {
      // this.pstate.state = "?"
      switch (this.pstate.state) {
        case "on":
          return "green darken-2";
        // break;
        case "off":
          return "red darken-2";
        // break;
        case "?":
          return "orange darken-2";
        // break;
      }
    },
    picon() {
      switch (this.pstate.state) {
        case "on":
          return "mdi-play-network";
        //   return "mdi-pipe";
        // break;
        case "off":
          return "mdi-close-network";
        //   return "mdi-pipe-disconnect";
        // break;
        case "?":
          return "mdi-help-network";
        // break;
      }
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["kortex"]),
  },
  mounted() {
    // console.log("---------------")
    //  console.log(this.pstate)
  },
};
</script>
