<template>
  <v-list subheader two-line>
    <v-list-item v-for="m in mm.menus" :key="m.name" link :to="m.path">
      <v-list-item-avatar>
        <v-icon class="blue lighten-1" dark> {{ m.meta.icon }} </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="m.meta.text"></v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn icon>
          <v-icon color="blue lighten-1">mdi-arrow-right-box</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    mm: Object,
  },
  data() {
    return {};
  },
  methods: {},
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  mounted() {},
};
</script>
