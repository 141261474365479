<template>
  <v-container>
    <!-- {{ groups }}
    <br>
    <br> -->
    <h1>Buildings</h1>
    <v-row justify="center">
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(val, name) in objects" :key="name">
          <v-expansion-panel-header>{{ name }}</v-expansion-panel-header>
          <v-expansion-panel-content>
              <buildingcard :obj="val"></buildingcard>
            <!-- <mtbl :arh="cnttodiff(item)" :showexpand="false"></mtbl> -->
            <!-- {{cnttodiff(item)}} -->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    {{ objects }}

  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import buildingcard from "./buildingcard.vue"
export default {
  components: {
      buildingcard,
  },
  props: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions(["loadObjects"]),
  },
  //   computed: {
  //     ...mapGetters(["urlmdlstat"]),
  //   },
  computed: {
    ...mapState(["objects", "groups"]),
  },
  async mounted() {
     await this.loadObjects();
//    await this.$store.dispatch("loadObjects");
  },
};
</script>
