var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-5"},[_c('v-card-title',[_vm._v(_vm._s(_vm.dd.sensor))]),_c('v-card-text',[_c('v-sparkline',{attrs:{"value":_vm.irval(_vm.dd),"line-width":"0.3","gradient":[
        'red',
        'red',
        'red',
        'red',
        'green',
        'red',
        'red',
        'red',
        'red' ],"stroke-linecap":"round","auto-draw":"","labels":_vm.irdate(_vm.dd),"label-size":"3"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }