var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.values,"expanded":_vm.expanded,"single-expand":false,"show-expand":"","item-key":"room","footer-props":{
    showFirstLastPage: true,
    itemsPerPageText: 'Записей на странице',
    itemsPerPageOptions: [24, 48, 96, { text: 'Все записи', value: -1 }],
  },"mobile-breakpoint":100},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.proc",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.proc.toFixed(2))+"% ")]}},{key:"expanded-item",fn:function(ref){
  var headers = ref.headers;
  var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"justify-center ma-2"},[_c('v-col',{attrs:{"cols":"11","md":"11","lg":"11"}},[_c('graph',{staticClass:"mb-2",attrs:{"dd":item.all}})],1)],1),_c('v-row',{staticClass:"justify-center ma-2"},[_c('v-col',{attrs:{"md":"11","lg":"8"}},[_c('analiz',{attrs:{"values":item.irintervals}})],1)],1),_c('v-row',{staticClass:"justify-center ma-2"},[_c('v-col',{attrs:{"cols":"11","md":"11","lg":"8"}},[_c('values',{attrs:{"values":item.data}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }