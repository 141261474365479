<template>
  <v-container>
    <v-row>
      <v-timeline v-if="false">
        <v-timeline-item>timeline item</v-timeline-item>
        <v-timeline-item class="text-right"> timeline item </v-timeline-item>
        <v-timeline-item>timeline item</v-timeline-item>
      </v-timeline>
    </v-row>
    <v-row class="mt-11">
      <v-col cols="12">
        <v-range-slider
          :tick-labels="filllabel(4)"
          v-model="kvalue"
          min="0"
          :max="kmax"
          thumb-label="always"
          thumb-size="50"
          thumb-color="orange"
          track-color="black"
          track-fill-color="orange"
          color="orange"
          tick-size="4"
          step="1"
          class="text-caption text-center"
          @change="range()"
        >
          <template v-slot:thumb-label="props">
            {{ ktime[props.value] }}
          </template>
        </v-range-slider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card color="orange lighten-5">
          <v-card-title class="orange--text"> День</v-card-title>
          <v-card-subtitle>
            {{ ktime[kvalue[0]] }}-{{ ktime[kvalue[1]] }}
          </v-card-subtitle>
          <v-card-text>
            <v-row v-if="kdev && kdev.bounds">
              <v-text-field
                v-model="kdev.bounds.day.smin"
                hint="Температура (нижняя граница)"
                label="Температура (нижняя граница)"
                color="orange"
              ></v-text-field>
              <v-text-field
                v-model="kdev.bounds.day.smax"
                hint="Температура (верхняя граница)"
                label="Температура (верхняя граница)"
                color="orange"
                class="ml-2"
              ></v-text-field>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card color="deep-purple lighten-5" class="mt-1">
          <v-card-title> Ночь</v-card-title>
          <v-card-subtitle>
            {{ ktime[0] }}-{{ ktime[kvalue[0]] }}, {{ ktime[kvalue[1]] }}-{{
              ktime[kmax - 1]
            }}
          </v-card-subtitle>
          <v-card-text>
            <v-row v-if="kdev && kdev.bounds">
              <v-text-field
                v-model="kdev.bounds.night.smin"
                hint="Температура (нижняя граница)"
                label="Температура (нижняя граница)"
                color="purple"
              ></v-text-field>
              <v-text-field
                v-model="kdev.bounds.night.smax"
                hint="Температура (верхняя граница)"
                label="Температура (верхняя граница)"
                color="purple"
                class="ml-2"
              ></v-text-field>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card color="red lighten-4" class="mt-1">
          <v-card-title class="red--text">Выходные</v-card-title>
          <v-card-text>
            <v-row v-if="kdev && kdev.bounds">
              <v-text-field
                v-model="kdev.bounds.holiday.smin"
                hint="Температура (нижняя граница)"
                label="Температура (нижняя граница)"
                color="red"
              ></v-text-field>
              <v-text-field
                v-model="kdev.bounds.holiday.smax"
                hint="Температура (верхняя граница)"
                label="Температура (верхняя граница)"
                color="red"
                class="ml-2"
              ></v-text-field>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    kdev: Object,
    all: Object,
  },
  data() {
    return {
      kvalue: [14, 38],
      ktime: [],
      klabel: null,
      kmax: 0,
      // kdevs: null,
      // kdev: null,
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
    range(){
      this.kdev.bounds.range1 = this.ktime[this.kvalue[0]]
      this.kdev.bounds.range2 = this.ktime[this.kvalue[1]]
      // console.log(this.kdev.range1)
    },
    filltime() {
      let tt;
      let tms = [];
      tt = new Date("2000-01-01 00:00:00");
      tms.push(tt.toLocaleString("ru").substring(12, 17));
      for (let i = 0; i < 48; i++) {
        let t2 = new Date(tt);
        t2.setMinutes(t2.getMinutes() + 30);
        tms.push(t2.toLocaleString("ru").substring(12, 17));
        tt = t2;
      }
      //   let t3;
      //   t3 = "";
      //   tms.forEach((t) => {
      //     if (t3 != "") {
      //       t3 += ",";
      //     }
      //     t3 += t;
      //   });
      return tms;
    },
    filllabel(nn) {
      let tt;
      let tms = [];
      tt = new Date("2000-01-01 00:00:00");
      tms.push(tt.toLocaleString("ru").substring(12, 17));
      // tms.push("")
      for (let i = 1; i < 48; i++) {
        let t2 = new Date(tt);
        t2.setMinutes(t2.getMinutes() + 30);
        if (i % nn == 0) {
          tms.push(t2.toLocaleString("ru").substring(12, 17));
        } else {
          tms.push("");
        }
        tt = t2;
      }
      return tms;
    },
    async loadKortexDev() {
      let url = "kortexdevs.json";
      // console.log(url)
      let resp = await fetch(url);
      let tmp = await resp.json();
      // console.log(tmp)
      return tmp;
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
  },
  async mounted() {
    // this.kdevs = await this.loadKortexDev();
    // this.kdev = this.kdevs.all;
    // if (!this.kdev.bounds){
    //   this.kdev.bounds = this.all.bounds
    // }
    // console.log(this.kdev)
    
    this.ktime = this.filltime();
    this.kvalue = [];
    for (let i = 0; i < this.ktime.length; i++) {
      if (this.ktime[i] == this.kdev.bounds.range1) {
        // this.kvalue[0]= i
        this.kvalue.push(i);
      }
      if (this.ktime[i] == this.kdev.bounds.range2) {
        // this.kvalue[1]= i
        this.kvalue.push(i);
      }
    }
    //console.log(this.kvalue);
    this.kmax = this.ktime.length;
    //console.log(this.kmax);
  },
};
</script>
