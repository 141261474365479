<template>
  <div v-resize="draw">
    <!-- <div> -->
    <div :id="'gr' + id" height="400"></div>
    <!-- {{ stat }} -->
  </div>
</template>
<script>
import * as d3 from "d3";
// import wait from "../progress";
export default {
  components: {},
  props: {
    id: String,
    title: String,
    stat: Array,
    marks: { type: Boolean, default: true },
    xx: String,
    yy: Array,
    hdr: Array,
    colorgr: Array,
    hhh: Number,
    www: Number,
  },
  data() {
    return {
      // stat: [],
      // title: "Приемная кампания",
      // hdr: ["Заявителей", "Контрактников", "Оплачено", "Подано заявлений"], // "Ошибки","Вход без пропуска", "Выход без пропуска",],
      // colorgr: ["#6A1B9A", "#01579B", "#1B5E20", "#D50000"], //,"#FFEA00","black", "grey"],
      // xx: "date",
      // yy: ["applicants", "contracts", "paidcontracts", "statements"],
    };
  },
  methods: {
    // draw(data, xx, yy, label) {
    draw() {
      // console.log(this.stat)
      let data = this.stat;
      let xx = this.xx;
      let yy = this.yy;
      let marks = this.marks;
      if (data == null || data[0] == undefined) {
        // d3.select("#gr" + this.id).text("");
        return;
      }
      let ppp = this;
      var height = 300,
        width = 1000,
        margin = 60;

      width = (window.innerWidth / 12) * 12;
      height = (window.innerHeight / 10) * 10;
      width = this.www;
      height = this.hhh;

      //    width = window.innerWidth;

      d3.select("#gr" + this.id).text("");
      //  d3
      //   .select("#gr" + this.id)
      //   .select("svg")
      //   .remove()

      var svg = d3
        .select("#gr" + this.id)
        .append("svg")
        .attr("class", "axis")
        .attr("width", width)
        .attr("height", height);

      // длина оси X= ширина контейнера svg - отступ слева и справа
      var xAxisLength = width - 2 * margin;

      // длина оси Y = высота контейнера svg - отступ сверху и снизу
      var yAxisLength = height - 2 * margin;
      // находим максимальное значение для оси Y
      // console.log(data)
      var maxValue = d3.max(data, function (s) {
        //        if(s !=null)
        //  console.log(s)
        // console.log(s[yy])
        return d3.max(yy, function (d) {
          return s[d];
        });
      });
      // находим минимальное значение для оси Y
      var minValue = d3.min(data, function (s) {
        //        if(s !=null)
        return d3.min(yy, function (d) {
          return s[d];
        });
      });

      //  const yAxisWidth = svg.select('.y-axis').node().getBBox().width;
      //  console.log(yAxisWidth)

      // функция интерполяции значений на ось Y
      var scaleY = d3.scaleLinear().domain([maxValue, minValue]).range([0, yAxisLength]);

      // создаем ось Y
      var yAxis = d3.axisLeft(scaleY);

      // отрисовка оси Y
      svg
        .append("g")
        .attr("class", "y-axis")
        .attr(
          "transform", // сдвиг оси вниз и вправо на margin
          "translate(" + margin + "," + margin + ")"
        )
        .call(yAxis);

      // const yAxisWidth = svg.select('.y-axis').node().getBBox().width;
      // console.log(yAxisWidth)

      // функция интерполяции значений на ось Х
      var scaleX = d3
        .scaleTime()
        //  d3.time.scale() // от 1 января 2015 года до текущей даты
        .domain([
          // data[0][xx],
          // data[data.length-1][xx],
          d3.min(data, function (d) {
            return d[xx];
          }),
          d3.max(data, function (d) {
            return d[xx];
          }),
        ])
        // .range([0, yAxisWidth])
        .range([0, xAxisLength]);
      // .nice();

      // создаем ось X
      var xAxis;
      if (data.length < 72) {
        xAxis = d3
          .axisBottom(scaleX)
          // .tickFormat(d3.timeFormat("%d.%m.%Y"))
          .tickFormat(d3.timeFormat("%d.%m %H"))
          // .tickFormat(d3.timeFormat("%d.%m %H%M"))
          .ticks(d3.timeHour, 1);
      } else {
        // marks = false
        xAxis = d3
          .axisBottom(scaleX)
          // .tickFormat(d3.timeFormat("%d.%m.%Y"))
          .tickFormat(d3.timeFormat("%d.%m %H"));
        // .tickFormat(d3.timeFormat("%d.%m %H%M"))
        // .ticks(d3.timeDay, 1);
      }

      // отрисовка оси Х
      svg
        .append("g")
        .attr("class", "x-axis")
        .attr(
          "transform", // сдвиг оси вниз и вправо
          "translate(" + margin + "," + (height - margin) + ")"
          //          "translate(" + margin + "," + (height - margin) + ")"
        )
        .call(xAxis)
        //   .call(xAxis.axisBottom(x))
        .selectAll("text")
        // .attr("transform", "translate(-10,60)rotate(-90)")
        .attr("transform", "translate(-10,60)rotate(-90)")
        .style("text-anchor", "start")
        .style("font-size", 14);
      // .style("fill", "#69a3b2")

      // создаем набор вертикальных линий для сетки
      d3.selectAll("g.x-axis g.tick")
        .append("line") // добавляем линию
        .classed("grid-line", true) // добавляем класс
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", 0)
        .attr("y2", -(height - 2 * margin));

      // рисуем горизонтальные линии
      d3.selectAll("g.y-axis g.tick")
        .append("line")
        .classed("grid-line", true)
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", xAxisLength)
        .attr("y2", 0);

      // createChart(usdData, "blue", "usd");
      // let mm = ["p0", "p3", "p3", "p4", "p5"];
      for (var i = 0; i < this.colorgr.length; i++) {
        createChart(data, this.colorgr[i], xx, yy[i], marks);
      }

      var legendTable = svg
        .append("g")
        .attr("transform", "translate(0, " + 20 + ")")
        .attr("class", "legendTable");
      var legend = legendTable
        .selectAll(".legend")
        .data(ppp.hdr)
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function (d, i) {
          return "translate(0, " + i * 20 + ")";
        });
      let ll = 320;
      legend
        .append("text")
        .style("font-size", 12)
        .style("text-anchor", "start")
        .attr("x", width - ll)
        .attr("y", 9)
        .text(function (d) {
          return d;
        });
      legend
        .append("line")
        .attr("x1", width - ll + 120)
        .attr("y1", 5)
        .attr("x2", width - 10 - margin)
        // .attr("x2", width -  margin)
        .attr("y2", 5)
        .style("stroke", function (d, k) {
          return ppp.colorgr[k];
        })
        .style("stroke-width", "2");
      legend
        .append("circle")
        .attr("cx", width - 65 - 3 - margin)
        .attr("cy", 5)
        .attr("r", 6)
        .style("fill", function (d, k) {
          return ppp.colorgr[k];
        })
        .style("stroke", function (d, k) {
          return ppp.colorgr[k];
        })
        .style("stroke-width", "2");

      // общая функция для создания графиков
      function createChart(data, colorStroke, x2, y2, tic) {
        let sm = 0; //18;
        //        sm = yAxisWidth - width + margin
        // sm = yAxisWidth /2
        //        console.log(sm)
        // let mlen = data.length;
        //  console.log(scaleX)
        // функция, создающая по массиву точек линии
        var line = d3
          .line()
          .x(function (d, i) {
            // console.log( d[x2])
            return scaleX(d[x2]) + margin - sm;
            // return scaleX(new Date(d[x2].toISOString().substr(0, 10) +" 00:00")) + margin - sm;

            // return scaleX(d[x2]) + margin - sm;
            // return xAxis(d[x2]) + margin -sm;
          })
          .y(function (d) {
            return scaleY(d[y2]) + margin;
          });

        // var line = d3
        //           .line()
        //           .x(scaleX)
        //           .y(scaleY)

        // d3.selectAll("g.x-axis g.tick")
        //   .append("line") // добавляем линию
        //   .classed("grid-line", true) // добавляем класс
        //   .attr("x1", 0)
        //   .attr("y1", 0)
        //   .attr("x2", 0)
        //   .attr("y2", -(height - 2 * margin));

        var g = svg.append("g");
        g.append("path")
          .attr("d", line(data))
          .style("stroke", colorStroke)
          .style("fill", "none")
          .style("stroke-width", 5);

        // d3.selectAll("g.y-axis g.tick")
        //       .data(data)
        //   .append("line")
        //   .classed("grid-line", true)
        //   .attr("x1", 0)
        //   .attr("y1", 0)
        //   .attr("x2", xAxisLength)
        //   .attr("y2", 0);

        // добавляем отметки к точкам
        if (tic) {
          // d3.selectAll("g.x-axis g.tick")
          //   .append("line") // добавляем линию
          //   .classed("grid-line", true) // добавляем класс
          //   .attr("x1", 0)
          //   .attr("y1", 0)
          //   .attr("x2", 0)
          //   .attr("y2", -(height - 2 * margin));

          svg
            .selectAll(".dot " + y2)
            .data(data)
            .enter()
            .append("circle")
            .style("stroke", colorStroke)
            .style("fill", "white")
            .attr("class", "dot " + y2)
            .attr("r", 5)
            .attr("cx", function (d, i) {
              //            if(i !=0 && i != mlen-1)
              // return scaleX(new Date(d[x2].toISOString().substr(0, 10) +" 00:00")) + margin - sm;
              return scaleX(d[x2]) + margin - sm;
              // if (i != 0) {
              //   return scaleX(d[x2]) + margin - sm;
              // } else {
              //   return scaleX(d[x2]) + margin;
              // }
            })
            .attr("cy", function (d) {
              return scaleY(d[y2]) + margin;
            });

          svg
            .selectAll(".dot " + y2)
            .data(data)
            .enter()
            .append("text")
            .attr("x", function (d, i) {
              // return scaleX(new Date(d[x2].toISOString().substr(0, 10) +" 00:00")) + margin - sm;
              return scaleX(d[x2]) + margin - sm;
              // if (i != 0) {
              //   return scaleX(d[x2]) + margin - sm;
              // } else {
              //   return scaleX(d[x2]) + margin;
              // }
            })
            .attr("y", function (d) {
              return scaleY(d[y2]) + margin - 10;
            })
            .style("text-anchor", "middle")
            //          .style("text-anchor", "right")
            ////          .style("fill", "white")
            .style("fill", "black")
            .style("font-size", 14)
            //        .style("fill", "yellow")
            .text(function (d) {
              return d[y2];
            });
        }
        // .style("stroke", colorStroke)
        //     .style("fill", "white")
        //     .attr("class", "dot " + label)
        //     .attr("r", 3.5)
        //     .attr("cx", function(d) { return scaleX(d.date)+margin; })
        //     .attr("cy", function(d) { return scaleY(d.rate)+margin; });
      }
    },
  },
  watch: {
    // key2: function () {
    //   this.draw();
    // },
  },
  mounted() {
    this.progress = true;
    // console.log("#gr" + this.id)
    // this.init();
    // setInterval(this.init, 1000 * 60 * 30);
  },
  destroyed() {
    //  d3
    //   .select("#gr" + this.id)
    //   .select("svg")
    //   .remove()
    // console.log("ddd#gr" + this.id)
    //  d3.select("#gr" + this.id).text("");
  },
};
</script>
<style>
.axis path,
.axis line {
  fill: none;
  stroke: #333;
}
.axis .grid-line {
  stroke: #000;
  stroke-opacity: 0.2;
}
.axis text {
  font: 10px Arial;
}
thead {
  background-color: #a5d6a7;
}
</style>
