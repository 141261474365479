<template>
  <v-container class="fill-height">
    <!-- <v-row> Kortex </v-row> -->
    <v-row class="justify-center align-center">
      <!-- <v-card v-if="krt && krt.kortex" elevation="7" :loading="progress"> -->
      <v-card elevation="7" :loading="progress">
        <v-card-title class="justify-center">
          <!-- {{ krt.kortex.name }} -->
          {{ cname }}
          <v-spacer></v-spacer>
          <v-btn
            @click="refresh()"
            fab
            icon
            class="justify-center align-center text-center"
          >
            <!-- <v-row class="justify-center align-center text-center"> -->
            <!-- :indeterminate="progress" -->
            <v-progress-circular
              :rotate="-90"
              :value="proc(nn)"
              :size="45"
              :width="5"
              color="info"
              @click="refresh()"
            >
              <span v-if="nn != 0">
                {{ proc(nn).toFixed(0) }}%
              </span></v-progress-circular
            >
            <!-- </v-row> -->
          </v-btn>
        </v-card-title>
        <!-- <v-card-subtitle>IP: {{ krt.kortex.ip }}</v-card-subtitle>
        <v-card-subtitle>Версия ПО: {{ krt.kortex.version }}</v-card-subtitle>
        <v-card-subtitle>Серийный номер: {{ krt.kortex.sn }}</v-card-subtitle> -->
        <v-card-subtitle
          v-if="krt && krt.kortex"
          elevation="7"
          :loading="progress"
        >
          <v-container>
            <v-row> Модель: {{ krt.kortex.name }} </v-row>
            <v-row> IP: {{ ip }} </v-row>
            <v-row> Версия ПО: {{ krt.kortex.version }} </v-row>
            <v-row> Серийный номер: {{ krt.kortex.sn }} </v-row>
          </v-container>
        </v-card-subtitle>
        <v-card-text v-if="krt && krt.kortex">
          <v-container>
            <v-row class="align-center justify-center">
              <!-- <v-row justify="center" v-if="pstate.state == 'on'"> -->
              <v-row justify="center">
                <v-icon large :color="vstate().color" class="mr-4">{{
                  vstate().icon
                }}</v-icon>
                <span :class="'text-h4 ' + vstate().textcolor">{{
                  vstate().text
                }}</span>
              </v-row>
            </v-row>
            <v-row class="justify-center align-center">
              <v-col cols="6">
                <v-switch
                  v-model="in1"
                  label="Состояние цифрового входа IN1"
                  readonly
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="in2"
                  label="Состояние цифрового входа IN2"
                  readonly
                ></v-switch>
              </v-col>
            </v-row>
            <v-row class="justify-center align-center">
              <v-col cols="6">
                <v-switch v-model="relay1" readonly>
                  <template v-slot:label>
                    Состояние реле 1:
                    <v-progress-circular
                      :indeterminate="relay1"
                      :value="0"
                      size="24"
                      class="ml-2"
                      color="green"
                    ></v-progress-circular>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch v-model="relay2" readonly>
                  <template v-slot:label>
                    Состояние реле 1:
                    <v-progress-circular
                      :indeterminate="relay2"
                      :value="0"
                      size="24"
                      class="ml-2"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
            <!-- {{ krt.kortex }} -->
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <!-- <v-row class="justify-center"> -->
          <v-btn
            color="red darken-2"
            class="white--text"
            :disabled="krt.state != 'on' || nn != 0"
            @click="kclose()"
            >Закрыть задвижку</v-btn
          >
          <v-btn
            color="green darken-2"
            class="white--text"
            :disabled="krt.state != 'off' || nn != 0"
            @click="kopen()"
            >Открыть задвижку</v-btn
          >
          <!-- <v-btn color="primary" :disabled="nn != 0" @click="ret()" -->
          <v-btn color="primary" @click="ret()">Выход</v-btn>
          <!-- </v-row>
          <v-row class="justify-center">
            <v-col cols="12"> -->
          <!-- </v-col> -->
          <!-- </v-row> -->
        </v-card-actions>
        <v-card-text>
          <v-row class="justify-center">
            <v-alert dense type="info" v-if="nn != 0">
              Выполняется операция с задвижкой, подождите!
            </v-alert>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { setInterval } from "timers";
import { clearInterval } from "timers";
var i1;
var KORTEX_TIMEOUT = 23;
var KORTEX_REFRESH = 10000;
// var nn
export default {
  components: {},
  props: {
    ip: { type: String, default: "???" },
    cname: String,
  },
  data() {
    return {
      // krt: null,
      nn: 0,
      progress: false,
      krt: { oids: [], kortex: [] },
    };
  },
  methods: {
    ...mapActions([
      {
        loadKortex: "loadKortex",
        kortexUp: "kortexUp",
        kortexDown: "kortexDown",
      },
    ]),
    ret() {
      this.$router.go(-1);
    },
    proc(pp) {
      // return  (pp*100/KORTEX_TIMEOUT).toFixed(0)
      let pr = 100 - (pp * 100) / KORTEX_TIMEOUT;
      if (pr == 100) {
        pr = 0;
      }
      return pr;
    },
    async kopen() {
      await this.$store.dispatch("kortexUp", this.krt.ip);
      this.nn = KORTEX_TIMEOUT;
      i1 = setInterval(this.refresh, KORTEX_REFRESH);
    },
    async kclose() {
      await this.$store.dispatch("kortexDown", this.krt.ip);
      this.nn = KORTEX_TIMEOUT;
      i1 = setInterval(this.refresh, KORTEX_REFRESH);
    },
    async refresh() {
      // console.log("Refresh");
      // this.progress = true;
      // await this.$store.dispatch("loadKortex");
      this.loadsrv();
      // this.progress = false;
      if (this.nn == 0) {
        return;
      }
      this.nn--;
      if (this.nn == 0) {
        clearInterval(i1);
      }
      if (this.error != "") {
        console.log(this.error);
      }
    },
    vstate() {
      let rr = {
        color: "",
        text: "",
        icon: "",
        textcolor: "",
      };
      // this.pstate.state = "up"
      switch (this.krt.state) {
        case "on":
          rr.text = "Задвижка открыта";
          rr.color = "green darken-2";
          rr.textcolor = "green--text";
          rr.icon = "mdi-play-network";
          break;
        case "off":
          rr.text = "Задвижка закрыта";
          rr.color = "red darken-2";
          rr.textcolor = "red--text";
          rr.icon = "mdi-close-network";
          break;
        case "up":
          rr.text = "Задвижка открывается";
          rr.color = "green lighten-2";
          rr.textcolor = "green--text";
          rr.icon = "mdi-play-network";
          break;
        case "down":
          rr.text = "Задвижка закрывается";
          rr.color = "red lighten-2";
          rr.textcolor = "red--text";
          rr.icon = "mdi-close-network";
          break;
        case "upstart":
          rr.text = "Задвижка начинает открываться";
          rr.color = "green lighten-2";
          rr.textcolor = "green--text";
          rr.icon = "mdi-upload-network";
          break;
        case "downstart":
          rr.text = "Задвижка начинает закрываться";
          rr.color = "red lighten-2";
          rr.textcolor = "red--text";
          rr.icon = "mdi-download-network";
          break;
        case "upend":
          rr.text = "Процесс открытия завершен";
          rr.color = "green lighten-2";
          rr.textcolor = "green--text";
          rr.icon = "mdi-upload-network";
          break;
        case "downend":
          rr.text = "Процесс закрытия завершен";
          rr.color = "red lighten-2";
          rr.textcolor = "red--text";
          rr.icon = "mdi-download-network";
          break;
        case "err":
          rr.text = "Ошибка устройства!!!!!";
          rr.color = "red darken-2";
          rr.textcolor = "red--text";
          rr.icon = "mdi-block-helper";
          break;
        case "?":
          rr.text = "Нет информации о состоянии задвижки";
          rr.color = "orange darken-2";
          rr.textcolor = "orange--text";
          rr.icon = "mdi-help-network";
          break;
      }
      return rr;
    },
    async loadsrv() {
      this.progress = true;
      let url;
      url = this.sensors_url + this.sensors_port + "/";
      url += "kortexinfo?ip=" + this.ip;
      let resp = await fetch(url);
      let tmp2 = await resp.json();
      // console.log(url);
      // console.log(tmp2);
      if (tmp2.status == "success") {
        this.krt.state = tmp2.state;
        // this.krt.state = "on";
        this.krt.oids = tmp2.data;
        this.krt.kortex = tmp2.param;
        this.krt.ip = this.ip;
      } else {
        this.krt.state = "?";
        this.krt.oids = [];
        this.krt.kortex = [];
      }
      this.progress = false;
      // console.log(this.krt);
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    ...mapState(["kortex", "error", "sensors_url", "sensors_port"]),
    in1: function () {
      return this.krt.kortex.in1 == "1";
    },
    in2: function () {
      return this.krt.kortex.in2 == "1";
    },
    relay1: function () {
      // return true
      return this.krt.kortex.relay1 == "1";
    },
    relay2: function () {
      return this.krt.kortex.relay2 == "1";
    },
  },
  watch: {
    ip: async function () {
      await this.loadsrv();
    },
  },
  async mounted() {
    // await this.loadKortex();
    // await this.$store.dispatch("loadKortex");
    // console.log(this.ip);
    await this.loadsrv();
    // this.krt = this.kortex["ИТФ"];
  },
};
</script>
