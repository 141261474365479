<template>
  <!-- <wait :progress="progress"></wait> -->
  <!-- import wait from "../progress"; -->

  <v-dialog v-model="progress1" persistent width="500">
    <v-card>
      <v-card-text>
        <v-layout column wrap align-center justify-center>
          <p class="text-xs-center" mt-3></p>
          <p class="text-xs-center text-h5" mt-3>{{ message }}</p>
          <!-- <p class="text-xs-center">Получаю данные с сервера:&nbsp;{{curserver}}</p> -->
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-layout>
        <!-- <v-progress-linear indeterminate color="blue" class="mb-0"></v-progress-linear> -->
      </v-card-text>
      <v-card-actions align-center justify-center>
        <v-spacer></v-spacer>
        <v-btn @click="progress1 = false">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    progress: { type: Boolean, default: false },
    message: { type: String, default: "Подождите..." },
  },
  data() {
    return {
      progress1: false,
    };
  },
  methods: {},
  watch: {
    progress: function () {
      this.progress1 = this.progress;
    },
  },
};
</script>
