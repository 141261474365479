<template>
  <v-container fluid class="ma-1">
    <!-- <v-row justify="center" align="center" wrap> -->
    <v-row class="justify-center align-center text-center">
      <v-col class="title" cols="11">
        <v-row class="justify-center align-center">
          <!-- {{nred}}
          {{ngreen}}
          {{nyellow}} -->
          <!-- {{terr}} -->
          <!-- <itog :bb="badges"></itog> -->
          <!-- <itog :bb="[]"></itog> -->
          <badgemy
            :vis="true"
            nameicon="mdi-home"
            :num="nall"
            coloricon=""
            color="grey darken-2"
            tooltip="Количество объектов"
            class="mr-2"
          ></badgemy>
          <badgemy
            :vis="true"
            nameicon="mdi-alert-outline"
            :num="nred"
            coloricon="red"
            color="grey darken-2"
            tooltip="Количество объектов с ошибками"
            class="mr-2"
          ></badgemy>
          <badgemy
            :vis="true"
            nameicon="mdi-help-circle-outline"
            :num="nyellow"
            coloricon="orange"
            color="grey darken-2"
            tooltip="Количество объектов с неполными данными"
            class="mr-2"
          ></badgemy>
          <badgemy
            :vis="true"
            nameicon="mdi-check-outline"
            :num="ngreen"
            coloricon="green"
            color="grey darken-2"
            tooltip="Количество объектов без ошибок"
            class="mr-9"
          ></badgemy>

          <badgemy
            :vis="kall > 0"
            nameicon="mdi-door"
            :num="kall"
            coloricon=""
            color="grey darken-2"
            tooltip="Количество кабинетов по всем корпусам"
            class="mr-2"
          >
            0" ></badgemy
          >
          <badgemy
            :vis="tnodata > 0"
            nameicon="mdi-clock-alert"
            :num="tnodata"
            coloricon="orange"
            color="orange"
            tooltip="Количество кабинетов с неполными данными по температуре"
            class="mr-2"
          ></badgemy>
          <badgemy
            :vis="hnodata > 0"
            nameicon="mdi-clock-alert"
            :num="hnodata"
            coloricon="blue"
            color="blue"
            tooltip="Количество кабинетов с неполными данными по влажности"
            class="mr-2"
          ></badgemy>
          <badgemy
            :vis="terr > 0"
            nameicon="mdi-thermometer-alert"
            :num="terr"
            coloricon="orange"
            color="red"
            tooltip="Количество кабинетов у которых температура за пределами"
            class="mr-2"
          ></badgemy>
          <badgemy
            :vis="herr > 0"
            nameicon="mdi-weather-cloudy-alert"
            :num="herr"
            coloricon="blue"
            color="red"
            tooltip="Количество кабинетов у которых влажность за пределами"
            class="mr-2"
          ></badgemy>
          <div class="ml-4">
            Температура и влажность на объектах КБГУ на {{ new Date().toLocaleString("ru") }}
          </div>
          <v-progress-circular
            size="24"
            indeterminate
            color="primary"
            class="ml-4"
            v-if="progress"
          >
          </v-progress-circular>
          <v-progress-circular
            size="24"
            value="0"
            color="white"
            class="ml-4"
            v-else
            @click="start()"
          ></v-progress-circular>
        </v-row>
      </v-col>
    </v-row>
    <!-- <v-row class="align-center ml-1">
      <v-col cols="2">
        <v-btn small color="primary" @click="start()">Обновить</v-btn>
      </v-col>
      <v-col cols="6">
        <di
          :rdates="rdt"
          v-on:dateschange="
            sday = $event[0];
            sday2 = $event[1];
          "
        ></di>
      </v-col>
    </v-row> -->
    <v-row class="hidden-lg-and-up justify-center">
      <v-row class="justify-center">
        <v-list>
        <v-col cols="12">
          <!-- <v-list-item v-for="(dd, kk) in corp" :key="kk" class="mb-2" width="700px"> -->
            <!-- {{dd}} -->
            <disk
            v-for="(dd, kk) in corp" :key="kk"
              :disk="dd"
              :name="kk"
              v-on:status="total(kk, $event)"
              v-on:errors="totalerr(kk, $event)"
              class="mb-2"
            ></disk>
          <!-- </v-list-item> -->
        </v-col>
        </v-list>
    </v-row>
    </v-row>
    <v-row class="justify-center align-center d-none d-lg-block" dense>
      <v-row dense class="justify-center">
        <v-col v-for="(dd, kk) in corp" :key="kk" cols="2">
          <!-- {{dd}} -->
          <disk
            :disk="dd"
            :name="kk"
            :key="upd"
            v-on:status="total(kk, $event)"
            v-on:errors="totalerr(kk, $event)"
          ></disk>
        </v-col>
      </v-row>
      <!-- {{ corp }} -->
      <!-- <v-col cols="12" class="hidden-md-and-down"> </v-col>
      <v-col cols="12" class="hidden-lg-and-up"> </v-col> -->
    </v-row>
    <wait :progress="progress && false"></wait>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { setInterval } from "timers";
import { clearInterval } from "timers";
// import d3tree from "./tree";
import { fDATE0, fDATE1, l2t, l2t2 } from "../lib1.js";
import disk from "./disk.vue";
// import di from "../dateinterval";
import wait from "../progress";
import badgemy from "./badge_my.vue";
// import itog from "./itog.vue";

var i1;
export default {
  components: {
    disk,
    wait,
    badgemy,
    // itog,
  },
  props: {
    prg: {
      type: String,
      default: "Датчики",
    },
  },
  data() {
    return {
      dataall: null,
      servers: [],
      server: "",
      data: {},
      treedata: [],
      opened1: [0],
      d1: fDATE0(new Date().toISOString()),
      d0: new Date().toISOString().substring(0, 10),
      sday: new Date().toISOString().substr(0, 10),
      sday2: new Date().toISOString().substr(0, 10),
      rdt: [this.sday, this.sday2],
      // menu1: null,
      // radios: "r1",
      ord: [
        ["korpus", "room", "tip", "date"],
        //        ["arhdate", "Name", "Group", "Name2"]
      ],
      progress: false,
      corp: {}, // {name: "", _children: []},
      cnt: 60,
      upd: 0,
      upd2: 0,
      status: {},
      errors: {},
      nall: 0,
      nred: 0,
      ngreen: 0,
      nyellow: 0,
      kall: 0,
      terr: 0,
      herr: 0,
      tnodata: 0,
      hnodata: 0,
      badges: [],
    };
  },
  methods: {
    ...mapActions({
      loadSensors: "loadSensors",
      loadIR: "loadIR",
      loadBounds: "loadBounds",
    }),
    totalerrcnt() {
      this.terr = 0;
      this.tnodata = 0;
      this.hnodata = 0;
      this.herr = 0;
      let terr0 = 0;
      let herr0 = 0;
      let tnodata0 = 0;
      let hnodata0 = 0;
      for (let kk in this.corp) {
        if (this.errors[kk] && this.errors[kk].terr) {
          terr0 += this.errors[kk].terr;
        }
        if (this.errors[kk] && this.errors[kk].herr) {
          herr0 += this.errors[kk].herr;
        }
        if (this.errors[kk] && this.errors[kk].tnodata) {
          tnodata0 += this.errors[kk].tnodata;
        }
        if (this.errors[kk] && this.errors[kk].hnodata) {
          hnodata0 += this.errors[kk].hnodata;
        }
      }
      this.terr = terr0;
      this.herr = herr0;
      this.tnodata = tnodata0;
      this.hnodata = hnodata0;
    },
    totalerr(nn, e) {
      this.errors[nn] = e;
      // console.log(nn)
      this.totalerrcnt();
    },
    totalcnt() {
      this.nall = 0;
      this.kall = 0;
      this.nred = 0;
      this.ngreen = 0;
      this.nyellow = 0;
      let nall0 = 0;
      let kall0 = 0;
      let nred0 = 0;
      let ngreen0 = 0;
      let nyellow0 = 0;
      for (let kk in this.corp) {
        // console.log(this.status[kk])
        nall0++;
        kall0 += Object.keys(this.corp[kk]).length - 1;
        switch (this.status[kk]) {
          case "red":
            nred0++;
            break;
          case "green":
            ngreen0++;
            break;
          case "yellow":
            nyellow0++;
            break;
        }
      }
      this.nall = nall0;
      this.kall = kall0;
      this.nred = nred0;
      this.ngreen = ngreen0;
      this.nyellow = nyellow0;
      // this.setBages()
    },
    total(nn, e) {
      this.status[nn] = e;
      // console.log(nn)
      this.totalcnt();
      // this.upd2++;
    },
    setBages() {
      this.badges = [
        {
          name: "all",
          vis: true,
          nameicon: "mdi-home",
          num: this.nall,
          coloricon: "",
          color: "grey darken-2",
          tooltip: "Количество объектов",
          class: "mr-2",
        },
        {
          name: "nred",
          vis: true,
          nameicon: "mdi-alert-outline",
          num: this.nred,
          coloricon: "red",
          color: "grey darken-2",
          tooltip: "Количество объектов с ошибками",
          class: "mr-2",
        },
      ];
    },
    loadcancel() {
      this.progress = false;
      this.showalert = true;
      this.erroralert =
        "Ошибка: отмена соединения. Для повтора обновите страницу.";
    },
    async start() {
      this.progress = true;
      await this.loadSensors({ sday: this.sday, sday2: this.sday2 });
      await this.loadIR({ sday: this.sday, sday2: this.sday2 });
      await this.loadBounds();
      // console.log(this.ir);
      this.showtree();
      // this.upd2++;
      this.setBages();
      this.progress = false;
    },
    showtree() {
      let n0 = 0;
      // let d2 = this.senstree;
      let d3 = this.sensors;
      // if (d2 == null) {
      //   //  console.log(this.server)
      //   this.data = {};
      //   this.treedata = [];
      //   return;
      // }
      if (d3 == null) {
        //  console.log(this.server)
        this.data = {};
        this.treedata = [];
        return;
      }
      //  console.log(this.sensors)
      let tmp3 = {};
      d3.forEach((d) => {
        if (d.Корпус != "") {
          if (!tmp3[d.Корпус]) {
            tmp3[d.Корпус] = {};
          }
          if (!tmp3[d.Корпус][d.room]) {
            tmp3[d.Корпус][d.room] = {};
          }
          if (!tmp3[d.Корпус][d.room][d.tip]) {
            tmp3[d.Корпус][d.room][d.tip] = [];
          }
          tmp3[d.Корпус][d.room][d.tip] = d.data;
          // if(d.Корпус in this.bounds) {
          //   tmp3[d.Корпус]['Bounds'] = this.bounds[d.Корпус]
          //   console.log(d.Корпус)
          // }else {
          //   tmp3[d.Корпус]['Bounds'] = this.bounds['Общие']
          // }
        }
      });

      let d4 = this.ir;
      d4.forEach((d) => {
        if (d.Корпус != "") {
          if (!tmp3[d.Корпус]) {
            tmp3[d.Корпус] = {};
          }
          if (!tmp3[d.Корпус][d.room]) {
            tmp3[d.Корпус][d.room] = {};
          }
          if (!tmp3[d.Корпус][d.room][d.tip]) {
            tmp3[d.Корпус][d.room][d.tip] = [];
          }
          tmp3[d.Корпус][d.room][d.tip] = d.data;
          // console.log(d.tip)
          // console.log(  tmp3[d.Корпус][d.room][d.tip])
          // if(d.Корпус in this.bounds) {
          //   tmp3[d.Корпус]['Bounds'] = this.bounds[d.Корпус]
          //   console.log(d.Корпус)
          // }else {
          //   tmp3[d.Корпус]['Bounds'] = this.bounds['Общие']
          // }
        }
      });

      for (let kk in tmp3) {
        // console.log('"'+kk+'"'+":"+'"",')
        if (kk in this.bounds) {
          tmp3[kk]["Bounds"] = this.bounds[kk];
          // console.log(this.bounds[kk])
        } else {
          tmp3[kk]["Bounds"] = this.bounds["Общие"];
        }
      }
      // console.log(tmp3);
      this.corp = tmp3;

      // this.data = l2t("Датчики", d2, this.ord[n0], "val");
      // this.treedata = l2t2("Датчики", d2, this.ord[n0], "val");
      // let tmp = l2t("Датчики", d2, ["korpus", "room", "tip", "date"], "val");
      // tmp.children.shift();
      // let tmp2 = {};
      // tmp.children.forEach((d) => {
      //   //  console.log(d)
      //   if (!tmp2[d.name]) {
      //     tmp2[d.name] = {};
      //   }
      //   d._children.forEach((d2) => {
      //     // console.log(d2);
      //     if (!tmp2[d.name][d2.name]) {
      //       tmp2[d.name][d2.name] = {};
      //     }
      //     if (d2._children != undefined) {
      //       d2._children.forEach((d3) => {
      //         // console.log(d3);
      //         // if (!tmp2[d.name][d2.name][d3.name]) {
      //         //   tmp2[d.name][d2.name] = {};
      //         // }
      //         if (d3._children != undefined) {
      //           tmp2[d.name][d2.name][d3.name] = d3._children;
      //         }
      //       });
      //     }
      //   });
      // });
      // // console.log(tmp2);
      // tmp.children.shift();
      // this.corp = tmp3;
      //  console.log(tmp)
      // this.opened1 = [0];
    },
  },
  watch: {
    radios: function () {
      this.start();
    },
    d0: function () {
      //     this.d1 = fDATE0(this.d0.toISOString())
      this.start();
    },
  },
  computed: {
    //    ...mapGetters(["urlmdlstat"]),
    ...mapState({
      sensors: (state) => state.sensors,
      senstree: (state) => state.senstree,
      bounds: (state) => state.bounds,
      ir: (state) => state.ir,
      //      nogroup: (state) => state.authm.nogroup,
    }),
  },
  mounted() {
    let d0 = new Date();
    d0.setDate(d0.getDate() - 0);
    this.sday = d0.toISOString().substr(0, 10);
    this.rdt = [this.sday, this.sday2];
    this.start();
    i1 = setInterval(this.start, 1000 * this.cnt);
  },
  destroyed() {
    clearInterval(i1);
    i1 = null;
  },
};
</script>
