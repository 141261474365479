<template>
  <v-container>
  <v-tabs>
    <v-tab>Карта</v-tab>
    <v-tab>Инфраструктура</v-tab>
    <v-tab>Датчики</v-tab>
    <v-tab>Занятость аудиторий</v-tab>
    <v-tab>Компьютеры, принтеры</v-tab>
    <v-tab>WiFi</v-tab>
  </v-tabs>      
    {{ obj }}
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    obj: Object,
  },
  data() {
    return {};
  },
  methods: {
    // ...mapActions(["loadSettings", "LoadObjects"]),
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
  },
  mounted() {},
};
</script>
