<template>
  <!-- <div style="width: 100%; height: 80%"> -->
  <!-- <div> -->
  <!-- <v-container fluid>
    id="myMap"
     -->
  <div
    :style="'width: ' + www + 'px; height: ' + hhh + 'px; z-index: 0;'"
    :id="divid"
  ></div>
  <!-- </v-container> -->
  <!-- </div> -->
</template>
<script>
import L from "leaflet";
import { mapState } from "vuex";
export default {
  components: {},
  props: {
    divid: {
      type: String,
      default: "myMap",
    },
    hhh: {
      type: Number,
      default: 400,
    },
    www: {
      type: Number,
      default: 500,
    },
    curOffice: {
      type: Object,
      default: function () {
        return {
          Lat: 43.4781113521496,
          Lng: 43.59849750995637,
          Tooltip: "Филиал ПАО МТС в КБР",
          Popup:
            "<b>Россия, Кабардино-Балкарская Республика, Нальчик, улица Головко, 8, Филиал ПАО МТС в КБР</b>",
        };
      },
    },
  },
  data() {
    return {
      map: null,
      // curOffice:    {
      //   Lat: 43.4781113521496,
      //   Lng: 43.59849750995637,
      //   Tooltip: "Филиал ПАО МТС в КБР",
      //   Popup:
      //     "<b>Россия, Кабардино-Балкарская Республика, Нальчик, улица Головко, 8, Филиал ПАО МТС в КБР</b>",
      // },
    };
  },
  methods: {
    onResize() {
      var div0 = document.getElementById("mmm");
      var div = document.getElementById("title");
      this.hhh = window.innerHeight - div.clientHeight - 200;
      this.www = div0.clientWidth - 40;
    },
    HideMarkers(map) {
      map.eachLayer((m) => {
        if ("icon" in m.options) {
          map.removeLayer(m);
        }
      });
    },
    SetMarkers(map, mm) {
      mm.forEach((m) => {
        var okIcon = new L.divIcon({
          html:
            // "<div class='pin5' style='background:#2aa043'></div><div class='pulse5'></div> <br><div class='office5'><b>" +
            "<div class='pin5 cluster-ok'></div><div class='pulse5'></div> <br><div class='office5'><b>" +
            m.Tooltip +
            "</b></div>",
          iconSize: 0,
          shadowSize: 0,
          popupAnchor: [-4, -33],
          iconAnchor: [0, 15],
        });
        let marker = L.marker([m.Lat, m.Lng], {
          draggable: false,
          icon: okIcon,
        });
        let ppp = this;
        marker.bindPopup(m.Popup);
        marker.on("dblclick", function() { ppp.$emit("selectobj")});
        // marker.on("click", this.vmenu);
        // marker.on("dragend", function () {
        //   ppp.tmp = {
        //     lat: marker.getLatLng().lat,
        //     lng: marker.getLatLng().lng,
        //   };
        // });
        marker.addTo(map);
      });
    },
    gooffice() {
      var latlng = L.latLng(this.curOffice.Lat, this.curOffice.Lng);
      this.map.setView(latlng, 18);
    },
    setmap() {
      var latlng = L.latLng(this.curOffice.Lat, this.curOffice.Lng);
      this.map = L.map(this.divid, { attributionControl: false }); //.setView(latlng, 17);
      // this.map = L.map("myMap", { attributionControl: false }); //.setView(latlng, 17);
      this.map.setView(latlng, 18);
      this.lmap = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "МТС карта",
        maxZoom: 20,
      }).addTo(this.map);
      this.HideMarkers(this.map);
      // this.SetMarkers(this.map, this.offices00);
      this.SetMarkers(this.map, [this.curOffice]);
      L.control.scale({ metric: true, imperial: false }).addTo(this.map);
    },
  },
  watch: {},
  computed: {
    ...mapState(["printers", "urlbase", "offices"]),
  },
  async mounted() {
    //    this.curOffice = this.offices00[0];
    this.setmap();
  },
};
</script>
<style>
.btn5 {
  width: 50px;
  height: 50px;
  background-color: rgb(245, 238, 238);
  border: 1px solid black;
}
.office5 {
  width: 250px;
  color: blue;
}

.cluster5 {
  border-radius: 40px;

  height: 5px;
  margin: 15px;
  width: 5px;

  text-align: center;
  padding-top: 7px;
  font-size: 24px;
  color: white;
}
.cluster-ok5 {
  background: #2aa043;
}
.cluster-info {
  background: #00bfff;
}
.cluster-warning {
  background: #d16e0a;
}
.cluster-average {
  background: #ff6800;
}
.cluster-high {
  background: #eb6e63;
}
.cluster-disaster {
  background: #6f001f;
}

.pin5 {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: gray;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin5:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  /* background: red; */
  position: absolute;
  border-radius: 50%;
}

.pulse5 {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse5:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px grey;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
</style>
