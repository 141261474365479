<template>
  <!-- <div> -->
  <v-data-table
    :headers="headers"
    :items="val"
    :footer-props="{
      showFirstLastPage: true,
      itemsPerPageText: 'Записей на странице',
      itemsPerPageOptions: [12, 24, 48, 96, { text: 'Все записи', value: -1 }],
    }"
    class="elevation-1"
    :mobile-breakpoint="100"
  >
    <template v-slot:item.val.Avg="{ item }">
      <span v-if="item.val" :class="color(item.val.Avg)+'--text'"> {{ item.val.Avg.toFixed(2) }} </span>
    </template>
    <template v-slot:item.val.Min="{ item }">
      <span v-if="item.val" :class="colormin(item.val.Min)+'--text'"> {{ item.val.Min.toFixed(2) }} </span>
    </template>
    <template v-slot:item.val.Max="{ item }">
      <span v-if="item.val" :class="colormax(item.val.Max)+'--text'"> {{ item.val.Max.toFixed(2) }} </span>
    </template>
    <!-- <template v-slot:item.isPresenter="{ item }">
          <span v-if="item.isPresenter=='true'">Да</span>
          <span v-else>Нет</span>
      </template> -->
  </v-data-table>
  <!-- </div> -->
</template>
<script>
export default {
  components: {},
  props: {
    values: Array,
    vmin: Number,
    vmax: Number,
  },
  data() {
    return {
      headers: [
        { text: "Дата", value: "date", align: "left" },
        { text: "Значение min", value: "val.Min", align: "center" },
        { text: "Значение max", value: "val.Max", align: "center" },
        { text: "Значение среднее", value: "val.Avg", align: "center" },
      ],
    };
  },
  methods: {
    color(vv) {
      if (vv >= this.vmin && vv <= this.vmax) {
        return "black";
      } else {
        return "red";
      }
    },
    colormax(vv) {
      if (vv > this.vmax) {
        return "red";
      } else {
        return "black";
      }
    },
    colormin(vv) {
      if (vv < this.vmin) {
        return "red";
      } else {
        return "black";
      }
    },
  },
  computed: {
    val: function () {
      if(this.values== undefined) {
        return []
      }
      let rr = [...this.values];
      return rr.reverse();
    },
  },
  mounted() {
    //    console.log(this.users)
  },
};
</script>