<template>
  <v-dialog v-model="progress1" persistent width="300">
    <v-card>
      <v-card-text>
        <v-layout column wrap align-center justify-center>
          <p class="text-xs-center" mt-3></p>
          <p class="text-xs-center" mt-3>Подождите...</p>
          <!-- <p class="text-xs-center">Получаю данные с сервера:&nbsp;{{curserver}}</p> -->
        </v-layout>
        <v-progress-linear indeterminate color="blue" class="mb-0"></v-progress-linear>
      </v-card-text>
      <v-card-actions align-center justify-center>
        <v-spacer></v-spacer>
        <v-btn @click="progress1=false">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    progress: { type: Boolean, default: false }
  },
  data() {
    return {
      progress1: false
    };
  },
  methods: {},
  watch: {
    progress: function() {
      this.progress1 = this.progress;
    }
  }
};
</script>
