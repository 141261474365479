<template>
  <!-- <div> -->
    <v-data-table
      :headers="headers"
      :items="values"
      :footer-props="{
      showFirstLastPage: true,
      itemsPerPageText: 'Записей на странице',
      itemsPerPageOptions: [24,48,96,{'text':'Все записи','value':-1}]
    }"
      class="elevation-1"
      :mobile-breakpoint="100"
    >
      <template v-slot:item.proc="{ item }">
        <span v-if="item.proc >= 0 ">{{item.proc.toFixed(2)}}% </span>
      </template>
      <template v-slot:item.dd="{ item }">
        {{item.interval.d1}} - {{item.interval.d2}}
      </template>
      <!-- <template v-slot:item.rez="{ item }">
          <span v-if="item.rez == 'Да'" class="green--text">Да</span>
          <span v-if="item.rez= 0 && item.proc < 1" class="red--text">Нет</span>
          <span v-if="item.proc < 0" >Нет данных</span>
      </template> -->
      <template v-slot:item.rez="{ item }">
          <span v-if="item.rez.indexOf('Да') != -1" class="green--text">{{item.rez}}</span>
          <span v-if="item.rez == 'Нет'" class="red--text">{{item.rez}}</span>
          <span v-if="item.rez == '???'" >{{item.rez}}</span>
      </template>
      <!-- <template v-slot:item.rez="{ item }">
          <span v-if="item.proc > 1" class="green--text">Да</span>
          <span v-if="item.proc >= 0 && item.proc < 1" class="red--text">Нет</span>
          <span v-if="item.proc < 0" >Нет данных</span>
      </template> -->
      <!-- <template v-slot:item.isPresenter="{ item }">
          <span v-if="item.isPresenter=='true'">Да</span>
          <span v-else>Нет</span>
      </template> -->
    </v-data-table>
  <!-- </div> -->
</template>
<script>
export default {
  components: {
  },
  props: {
    values: Array
  },
  data() {
    return {
      headers: [
        { text: "Описание", value: "interval.descr", align: "left" },
        { text: "Интервал", value: "dd", align: "left" },
        { text: "Были занятия", value: "rez", align: "center" },
        { text: "Процент движения", value: "proc", align: "center" },
      ]
    };
  },
  methods: {},
  mounted() {
//    console.log(this.users)
  }
};
</script>