<template>
  <!-- <div> -->
      <v-container>
    <!-- <v-btn @click.stop="showobj = !showobj">Показать</v-btn> -->
    <!-- <objshow :obj="obj" :dshow="showobj"  v-if="showobj0"></objshow> -->
    <v-dialog v-model="showd" max-width="600">
      <v-sheet color="white" elevation="1">
        <v-container fluid>
          <v-row class="justify-center align-center">
            <v-col cols="12">
              <div style="height: 600px">
                <!-- <v-card class="fill-height"> -->
                <objshow :obj="obj" v-if="showd"></objshow>
                <!-- </v-card> -->
              </div>
            </v-col>
          </v-row>
          <v-divider>></v-divider>
          <v-row class="justify-center align-center">
            <!-- <v-col cols="12"> -->
              <v-btn @click="btnok()" color="primary" class="mt-5 mb-2">OK</v-btn>
            <!-- </v-col> -->
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>
      </v-container>
  <!-- </div> -->
</template>
<script>
// import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import objshow from "./objshowonmap_m.vue";
export default {
  components: {
    objshow,
  },
  props: {
    obj: Object,
    showd: Boolean,
  },
  data() {
    return {
      //  showd2: false,
      // showobj: false,
      // showobj0: true,
      // obj0: {
      //   //   "Главный корпус": {
      //   Lat: "43.494453586903866",
      //   Lng: "43.59639271992106",
      //   group: "АДМИНИСТРАТИВНЫЕ КОРПУСА",
      //   btnname: "Главный корпус",
      //   mapname: "Главный корпус",
      //   address: "улица Чернышевского, 173",
      //   Tooltip: "Главный корпус",
      //   Popup:
      //     "<b>Россия, Кабардино-Балкарская Республика, Нальчик, улица Чернышевского, 173, Главный корпус</b>",
      //   planimg: "k1.jpg",
      //   //   },
      // },
      // obj: {
      //   //   "Информационный центр": {
      //   Lat: "43.49635",
      //   Lng: "43.59549",
      //   group: "АДМИНИСТРАТИВНЫЕ КОРПУСА",
      //   btnname: "Информационный центр",
      //   mapname: "Информационный центр",
      //   address: "улица Чернышевского, 173",
      //   Tooltip: "Информационный центр",
      //   Popup:
      //     "<b>Россия, Кабардино-Балкарская Республика, Нальчик, улица Чернышевского, 173, Информационный центр</b>",
      //   planimg: "k1.jpg",
      // },
    };
  },
  methods: {
    // ...mapActions(["loadSettings"]),
    btnok() {
      this.$emit("btnok")
    },
  },
  computed: {
    // ...mapGetters(["urlstat"]),
    // ...mapState(["markers"]),
  },
  // watch: {
  //       showd: function() {
  //         this.showd2 = this.showd
  //       }
  // },
  mounted() {},
};
</script>