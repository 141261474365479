<template>
  <timeline2 :vid="false"></timeline2>
</template>
<script>
// import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import timeline2 from "./timeline.vue";
export default {
  components: { timeline2 },
  props: {},
  data() {
    return {};
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
  },
  mounted() {},
};
</script>
