var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"dense":"","color":"grey lighten-4"}},[(false)?_c('v-speed-dial',{staticStyle:{"z-index":"999","margin-top":"50px","margin-right":"0px"},attrs:{"right":"","top":"","absolute":"","direction":"bottom"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","fab":""}},[_c('v-icon',[_vm._v("mdi-printer")])],1)]},proxy:true}],null,false,1384424247)},[_c('v-btn',{attrs:{"color":"indigo","dark":""},on:{"click":function($event){_vm.dlgsel = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Добавить")],1)],1):_vm._e(),_c('v-card-text',{attrs:{"id":"cardtext"},on:{"click":function($event){$event.stopPropagation();return _vm.show($event)}}},[_c('plan',{ref:"pln2",attrs:{"www":_vm.www,"hhh":_vm.hhh,"zoom":_vm.zm,"data":_vm.data,"floors":_vm.floors,"divid":"divflr0","key2":_vm.key2,"key3":_vm.fUpd},on:{"markerclick":function($event){return _vm.mclick($event)},"markerdblclick":function($event){return _vm.mdblclick($event)},"prnmoved":function($event){return _vm.prnmove()},"pickfloor":function($event){return _vm.pickfloor($event)}}})],1),_c('v-card-actions',[_c('v-spacer'),(_vm.mustsave)?_c('v-btn',{on:{"click":function($event){_vm.$emit('prncancel');
          _vm.ismodified = false;
          _vm.fUpd += 1;}}},[_vm._v("Отмена")]):_vm._e(),(_vm.mustsave)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.$emit('prnsave');
          _vm.ismodified = false;
          _vm.dlgsend = false;}}},[_vm._v("Сохранить")]):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"500px"},model:{value:(_vm.dlgsel),callback:function ($$v) {_vm.dlgsel=$$v},expression:"dlgsel"}},[_c('prnselect',{on:{"prnsel":function($event){_vm.dlgsel = false;
        if ($event != '') {
          _vm.printer = $event;
          _vm.prnadd($event);
        }}}})],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dlgsend),callback:function ($$v) {_vm.dlgsend=$$v},expression:"dlgsend"}},[_c('v-card',[_c('v-card-title',[_vm._v("Данные этажа")]),_c('v-card-text',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.data)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.dlgsend = false}}},[_vm._v("OK ")])],1)],1)],1),_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.dlgsel = true}}},[_c('v-list-item-title',[_vm._v("Добавить")])],1)],1)],1),_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":""},model:{value:(_vm.showMenuMarker),callback:function ($$v) {_vm.showMenuMarker=$$v},expression:"showMenuMarker"}},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.prndel()}}},[_c('v-list-item-title',[_vm._v("Удалить")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }