<template>
  <v-container class="fill-height" id="main1" v-resize="rrr">
    <v-row class="justify-center align-center text-center" dense>
      <div class="text-h5 font-weight-black">
        Лента событий на
        {{ new Date().toLocaleString("ru") }}
      </div>
      <v-btn small fab class="ml-4 mb-0">
        <v-progress-circular
          size="25"
          indeterminate
          color="primary"
          class=""
          v-if="progress"
        >
        </v-progress-circular>
        <v-progress-circular
          size="25"
          value="0"
          color="white"
          class=""
          v-else
          @click="start()"
        ></v-progress-circular>
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            fab
            class="ml-4 mb-0"
            @click="soundenable()"
            v-if="bsound"
            v-on="on"
          >
            <v-icon color="blue">mdi-volume-high</v-icon>
          </v-btn>
        </template>
    <span> Включить звуковое оповещение</span>
      </v-tooltip>
    </v-row>
    <v-row
      id="tl0"
      class="justify-center mt-0"
      :style="'overflow: auto; height:' + hhh + 'px;'"
    >
      <v-col cols="9">
        <v-timeline id="tl">
          <v-timeline-item
            v-for="(d, i) in data"
            :key="i"
            :color="d.color"
            large
            :right="vid"
          >
            <template v-slot:icon>
              <v-icon large color="white">{{ d.icon }}</v-icon>
            </template>
            <template v-slot:opposite>
              <v-alert
                type="error"
                class=""
                v-if="
                  (new Date().getTime() - new Date(d.DeviceTime).getTime()) /
                    60000 <=
                  15
                "
                >Событие последних 15 минут!</v-alert
              >
              <v-chip color="#00897B" dark class="text-h5">
                {{ new Date(d.DeviceTime).toLocaleString("ru") }}
              </v-chip>
            </template>
            <v-card class="elevation-4" :color="d.bg">
              <v-card-title class="text-h5" style="word-break: keep-all">
                {{ d.Contents }}
              </v-card-title>
              <v-card-text class="text-body-1" style="word-break: keep-all">
                {{ d.Corpus }}<span v-if="d.Name">,</span> {{ d.Name }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { setInterval } from "timers";
import { clearInterval } from "timers";
// import { Howl, Howler } from "howler";
var i1;
export default {
  components: {},
  props: {
    vid: {
      type: Boolean,
      default: true,
    },
    //    data: Array,
  },
  data() {
    return {
      reverse: false,
      data: [],
      progress: false,
      cnt: 60,
      hhh: 300,
      audio: null,
      issound: false,
      bsound: true,
    };
  },
  methods: {
    ...mapActions({
      loadFireSens: "loadFireSens",
      loadWlSens: "loadWlSens",
      loadWelcome: "loadWelcome",
      // loadFireSensObj: "loadFireSensObj",
    }),
    date2nice(ddd) {
      if (ddd === null) {
        return "?????";
      }
      var dt = ddd.split(" ");
      var d = dt[0].split("-");
      if (dt[1] == null) {
        return d[2] + "." + d[1] + "." + d[0];
      }
      dt = d[2] + "." + d[1] + "." + d[0] + " " + dt[1];
      return dt;
    },
    time2str(tt) {
      if (tt === null) {
        return "?????";
      }
      var min = tt / 60;
      var sec = tt - Math.floor(min) * 60;
      var hh = "";
      min = Math.floor(min);
      if (min >= 60) {
        hh = min / 60;
        min = min - Math.floor(hh) * 60;
        hh = Math.floor(hh) + ":";
      }
      if (min < 10) {
        min = "0" + min;
      }
      sec = Math.floor(sec);
      if (sec < 10) {
        sec = "0" + sec;
      }
      var vrem = hh + min + ":" + sec;
      return vrem;
    },
    getQueue(data) {
      var inque = 0;
      var inwork = 0;
      var wmax = 0;
      var tbl =
        '<table class="table table-bordered table-striped table-condensed table-hover" width="100%">';
      tbl += "<tr>";
      tbl += "<th>" + "<b>Абитуриент</b></th>";
      tbl += "<th>" + "<b>Состояние</b></th>";
      tbl += "<th>" + "<b>Оператор</b></th>";
      tbl += "<th>" + "<b>Время начала</b></th>";
      tbl += "<th>" + "<b>Прошло</b></th>";
      tbl += "</tr>";
      data.forEach((n2) => {
        tbl += "<tr>";
        if (n2.proshlo > 2700) {
          tbl += '<tr style="color:red">';
        }
        tbl += "<td>" + n2.abnum + "</td>";
        tbl += "<td>" + n2.txt + "</td>";
        //    tbl+='<td>'+n2.rmnum+' ('+n2.fullname+')</td>';
        if (n2.rmnum === "-1") {
          tbl += "<td>" + "</td>";
          inque++;
          if (n2.proshlo - 0 > wmax) {
            wmax = n2.proshlo - 0;
          }
        } else {
          tbl += "<td>" + n2.rmnum + "</td>";
          inwork++;
        }
        // tbl += "<td>" + this.date2nice(n2.qdate) + "</td>";
        // tbl += "<td>" + this.time2str(n2.proshlo) + "</td>";
        tbl += "</tr>";
      });
      tbl += "</table>";
      // $("#q_table").html(tbl);
      var skoko1 =
        "В очереди " +
        inque +
        " чел., макс. ожидание " +
        this.time2str(wmax) +
        ", в работе " +
        inwork +
        " чел.";
      //var pass=(ABCUR-inque-inwork);
      // var pass = ABCUR;
      // if (pass < 0 || pass == undefined) pass = "???";
      var skoko;
      // skoko='<h3><b>В очереди '+inque+' чел., макс. ожидание '+time2str(wmax)+', в работе '+inwork+' чел., сдали док-ты '+pass+' чел.<b></h3>';
      skoko =
        "<h3><b>В очереди " +
        inque +
        " чел., макс. ожидание " +
        this.time2str(wmax) +
        ", в работе " +
        inwork +
        " чел.<b></h3>";
      //        skoko='<h3><b>В очереди '+inque+' чел., макс. ожидание '+time2str(wmax)+', в работе '+inwork+' чел.<b></h3>';
      if (inque > 10) {
        // skoko='<h3 style="color:red"><b>В очереди '+inque+' чел., макс. ожидание '+time2str(wmax)+', в работе '+inwork+' чел., сдали док-ты '+pass+' чел.<b></h3>';
        skoko =
          '<h3 style="color:red"><b>В очереди ' +
          inque +
          " чел., макс. ожидание " +
          this.time2str(wmax) +
          ", в работе " +
          inwork +
          " чел.<b></h3>";
        //        skoko='<h3 style="color:red"><b>В очереди '+inque+' чел., макс. ожидание '+time2str(wmax)+', в работе '+inwork+' чел.<b></h3>';
      }
      // $("#q_skoko").html(skoko);
      // $("#q_skoko2").html(skoko1);
      //	$('#json').text(tbl);
      return { nn: inque, str: skoko1 };
    },
    soundenable() {
      this.bsound = false;
    },
    toaudio(ff) {
      // console.log(this.audio[str])
      if (this.audio == null) return false;
      let rr = false;
      let str = ff.Event + " " + ff.TimeVal + " " + ff.Name;
      // let sound = true;
      // console.log(Object.keys(this.audio).length)
      // if (this.issound && str == "37 2022-12-14T10:03:25Z Физмат/ручники мик-элек") {
      //   rr = true
      // }
      //   // console.log(ff);
      //   // var aa = new Audio("alarm.wav");
      //   // aa.play();
      //   var aa2 = new Howl({
      //     src: ["alarm.wav"],
      //   });
      //   aa2.play();
      // }
      if (this.audio[str] == undefined) {
        this.audio[str] = true;
        if (this.issound) {
          rr = true;
        }
      }
      return rr;
    },
    psound() {
      // console.log("Звучит звук...");
      var aa = new Audio("alarm.wav");
      aa.play();
      // var aa = new Howl({
      //   src: ["alarm.wav"],
      // });
      // aa.play();
    },
    async start() {
      this.progress = true;
      await this.loadFireSens();
      await this.loadWlSens();
      //----- Welcome
      await this.loadWelcome();
      if (Object.keys(this.audio).length == 0) {
        this.issound = false;
      } else {
        this.issound = true;
      }
      let fsound = false;
      this.waterleak.forEach((w) => {
        w.color = "blue";
        w.icon = "mdi-water-pump";
        w.bg = "#B3E5FC";
      });
      // console.log(this.firesens)
      this.firesens.forEach((f) => {
        // f.color = "red";
        // f.icon = "mdi-fire";
        // // f.icon = "mdi-alert-decagram";
        // f.bg = "orange lighten-2";
        // console.log(f.Event+ " "+ f.Contents)
        //console.log(f)
        switch (f.Event) {
          case "37":
          case "40":
            // console.log(f)
            // this.toaudio(f.Event + " " + f.TimeVal + " " + f.Name);
            fsound = fsound || this.toaudio(f);
            f.color = "red";
            f.icon = "mdi-fire";
            f.bg = "orange lighten-2";
            break;
          case "44":
            fsound = fsound || this.toaudio(f);
            // this.toaudio(f);
            f.color = "red";
            f.icon = "mdi-alert-decagram";
            f.bg = "#FFE0B2";
            break;
          case "360":
            fsound = fsound || this.toaudio(f);
            // this.toaudio(f);
            f.color = "red";
            f.bg = "orange darken-3";
            f.Contents = "Сигнал в МЧС";
            f.icon = "mdi-fire-truck";
            break;
          case "250":
            f.color = "grey";
            f.icon = "mdi-repeat-off";
            f.bg = "blue-grey lighten-4";
            break;
          case "251":
            f.color = "green";
            f.icon = "mdi-repeat";
            f.bg = "green lighten-4";
            break;
        }
        // if (f.Event == "44") {
        //   f.icon = "mdi-alert-decagram";
        //   f.bg = "#FFE0B2";
        // }
        // if (f.Event == "360") {
        //   f.bg = "orange darken-3";
        //   f.Contents = "Сигнал в МЧС";
        //   f.icon = "mdi-fire-truck";
        // }
      });
      if (fsound) {
        this.psound();
      }
      this.data = this.firesens.concat(this.waterleak);

      // console.log(this.data[0]);

      //----- Welcome
      let str = this.getQueue(this.welcome);

      // console.log(str);

      //----- Welcome
      let ww = {};
      ww.DeviceTime = new Date().toISOString();
      ww.Contents = "Приемная кампания";
      ww.Corpus = str.str;
      // ww.color = "green";
      ww.color = "indigo";
      ww.icon = "mdi-school";
      ww.bg = "green lighten-4";

      // Эта строчка для роверки!!!    
      //  str.nn = 2;

      //----- Welcome
      if (str.nn > 10) {
        ww.bg = "red lighten-4";
        this.data.unshift(ww);
      } else {
        if (str.nn > 0) {
          this.data.unshift(ww);
        }
      }

      // ww.bg = "red lighten-4";

      // console.log(this.waterleak);
      // console.log(this.data);
      this.data.sort((a, b) => {
        if (a.DeviceTime < b.DeviceTime) {
          return 1;
        }
        if (a.DeviceTime > b.DeviceTime) {
          return -1;
        }
        return 0;
      });
      this.data = this.data.slice(0, 100);
      this.progress = false;
    },
    rrr() {
      this.hhh = window.innerHeight - 120;
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
    ...mapState({
      firesens: (state) => state.firesens,
      waterleak: (state) => state.wlsens,
      welcome: (state) => state.welcome,
    }),
    // hh: function() {
    //   console.log(document.getElementById("main1"))
    //   return window.innerHeight-120
    // }
  },
  async mounted() {
    this.audio = new Object();
    this.bsound = true;
    this.start();
    i1 = setInterval(this.start, 1000 * this.cnt);
  },
  destroyed() {
    clearInterval(i1);
    i1 = null;
  },
};
</script>
