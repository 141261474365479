<template>
  <v-card class="mt-1" v-if="item">
    <v-card-title><v-icon class="mr-1">mdi-printer</v-icon>{{ item.name }}</v-card-title>
    <v-card-subtitle><b>{{ item["Model Name 2"] }}</b></v-card-subtitle>
    <v-card-text>
      <div v-if="item['SerialNumber']">S/N: <b>{{ item['SerialNumber'] }}</b></div>
      <div v-if="item.inventory.site_address_a">Адрес:<b> {{ item.inventory.site_address_a }}</b></div>
      <v-divider class="mb-2"></v-divider>
      <div
        v-for="cc in item.items"
        :key="cc.itemid"
        :style="'color:' + getcolor(cc.name) + ';'"
      >
        <div style="margin: 0px;">
          {{ cc.name }}
        </div>
        <!-- : {{ cc.lastvalue }}% -->
        <!-- <br /> -->
        <v-row>
          <div style="width: 80%; height: 17px; border: 1px solid black; padding: 0px; margin: 0px;">
            <div
              :style="
                'background-color:' +
                getcolor(cc.name) +
                '; width: ' +
                cc.lastvalue +
                '%; height: 15px; margin: 0px; padding: 0px;'
              "
            ></div>
          </div>
          <v-spacer></v-spacer>
          <span style="color: black">{{ cc.lastvalue }}%</span>
        </v-row>
        <!-- {{cc.name.split(' ')[2].}} -->
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    getcolor(name) {
      let color = name.split(" ")[2];
      //   console.log(color)
      switch (color) {
        case "Cyan":
          color = "blue";
          break;
        case "Magenta":
          color = "#D500F9";
          break;
        case "Yellow":
          color = "#FFD600";
          break;
        default:
          color = "black";
          break;
      }
      return color;
    },
  },
  mounted() {},
};
</script>
