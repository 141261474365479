<template>
  <v-badge :color="color" class="ml-0" v-if="vis" :overlap="overlap">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <!-- <span v-bind="attrs" v-on="on"> {{ disk }}</span> -->
        <v-icon :color="coloricon" v-bind="attrs" v-on="on"  @click="$emit('clickq')">{{ nameicon }}</v-icon>
      </template>
      <span> {{ tooltip }}</span>
    </v-tooltip>
    <template v-slot:badge> {{ num }} </template>
  </v-badge>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    vis: Boolean,
    nameicon: String,
    num: [Number, String],
    color: String,
    coloricon: String,
    tooltip: String,
    overlap: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  methods: {
    // ...mapActions(["loadSettings", "LoadObjects"]),
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
  },
  mounted() {},
};
</script>
