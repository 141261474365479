// в store
// import authm from '../components/authvuex/authvuex'

// modules: {
//   authm,
// }

// в файл
// import { mapState, mapMutations, mapActions } from "vuex";

// methods: {
//   ...mapMutations({ "setuinfo": "authm/setuinfo" }),
//   ...mapActions({
//     "loadUserInfo": "authm/loadUserInfo",
//   }),
// ...mapActions({
//   "routestomenu": "authm/routestomenu",
// }),

// computed: {
//       ...mapState({
//         uinfo: (state) => state.authm.uinfo,
//       }),
//     },

// async mounted() {
//   await this.loadUserInfo(this.$keycloak);
import router from "../../router";
import { USEKEYCLOAK } from "../../main.js"
// const router = import('../../router')
// import keycloak from '@dsb-norge/vue-keycloak-js'

function showitem(item, keycloak) {
  // console.log(USEKEYCLOAK)
  if (!USEKEYCLOAK) return true
  let rr = true;
  //      console.log(this.Keycloak)
  if (item && item.meta) {
    if (item.meta.hide == true) {
      //|| !item.meta;
      return false;
    }
    if (item.meta.requiresAuth == false) {
      return true;
    }
    if (item.meta.requiresAuth) {
      //      rr = this.$keycloak.authenticated;
      rr = keycloak.authenticated;
      //            console.log(rr)
    }
    //  console.log(item.meta.roles)
    //     console.log(checkrole(["kbsu_admin"], keycloak))
    return (
      rr &&
      (checkrole(item.meta.roles, keycloak) ||
        checkrole(["kbsu_admin"], keycloak))
    );
  } else {
    return false;
  }
}
function checkrole(dd, keycloak) {
  // console.log(keycloak.realmAccess.roles)
  if (dd == undefined) return true;
  if (!keycloak || keycloak.realmAccess == undefined) return false;
  for (let i = 0; i < dd.length; i++) {
    if (keycloak.realmAccess.roles.indexOf(dd[i]) != -1) {
      return true;
    }
  }
  // return true;
  return false;
}

export default {
  namespaced: true,
  methods: {},
  state: {
    uinfo: null,
    menus: [],
    nogroup: [],
  },
  mutations: {
    setuinfo(state, ui) {
      state.uinfo = ui;
    },
    async setmenu(state) {
      let grp = {};
      grp.nogroup = [];
      // console.log("seTmenu")
      //      const router2 = await router.then()
      //      console.log(router2)
      let rr =router
      router.options.routes.forEach((m) => {
        if (m.meta && m.meta.group && m.meta.group != "") {
          if (showitem(m, this._vm.$keycloak)) {
          // let aa = true
          //   if (aa) {
            if (grp[m.meta.group] == undefined) {
              grp[m.meta.group] = [];
              grp[m.meta.group].push(m);
            } else {
              grp[m.meta.group].push(m);
            }
          }
        } else {
          if (showitem(m, this._vm.$keycloak)) {
            grp.nogroup.push(m);
          }
        }
      });
      let menus0 = [];
       //console.log(grp)
      for (let key in grp) {
        // console.log(key)
        if (key == "nogroup") {
          state.nogroup = grp[key];
        } 
        else {
          let ii = router.options.groupicon[key]
            menus0.push({
              name: key,
              menus: grp[key],
              icon: ii,
            });
            // console.log(ii)
          }
      }
      state.menus = menus0
       // console.log(state.nogroup)
      // })
    },
  },
  actions: {
    loadUserInfo: async ({ commit }, kc) => {
      let ui = await kc.loadUserProfile();
      // console.log(ui)
      commit("setuinfo", ui);
    },
    routestomenu: async ({ commit }) => {
      // console.log("!!!!!!!!!")
      commit("setmenu");
    },
  },
  getters: {},
};
