var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"justify-center text-center ma-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-center ma-2"},[_c('h1',[_vm._v("Компьютеры")])]),_c('v-row',{staticClass:"justify-center align-center ma-1"},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"6","lg":"3"}},[_c('v-row',{staticClass:"align-center"},[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.start()}}},[_vm._v("Обновить")]),_c('di',{attrs:{"rdates":_vm.rdt},on:{"dateschange":function($event){_vm.sday = $event[0];
              _vm.sday2 = $event[1];}}})],1)],1)],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-checkbox',{attrs:{"label":"Отрицание"},model:{value:(_vm.selnot),callback:function ($$v) {_vm.selnot=$$v},expression:"selnot"}}),_c('span',{staticClass:"ml-1 text-h3"},[_vm._v("[")]),_c('v-checkbox',{staticClass:"ml-1",attrs:{"label":"Вкл. постоянно","value":"halways"},model:{value:(_vm.sel),callback:function ($$v) {_vm.sel=$$v},expression:"sel"}}),_c('span',{staticClass:"ml-2 mr-1 font-weight-black"},[_vm._v(" ИЛИ ")]),_c('v-checkbox',{staticClass:"ml-2",attrs:{"label":"Найден в сети","value":"hwork"},model:{value:(_vm.sel),callback:function ($$v) {_vm.sel=$$v},expression:"sel"}}),_c('span',{staticClass:"ml-2 mr-1 font-weight-black"},[_vm._v(" ИЛИ ")]),_c('v-checkbox',{staticClass:"ml-2",attrs:{"label":"Есть в Excel","value":"hexcel"},model:{value:(_vm.sel),callback:function ($$v) {_vm.sel=$$v},expression:"sel"}}),_c('span',{staticClass:"ml-2 mr-1 font-weight-black"},[_vm._v(" ИЛИ ")]),_c('v-checkbox',{staticClass:"ml-2",attrs:{"label":"Включен","value":"honline"},model:{value:(_vm.sel),callback:function ($$v) {_vm.sel=$$v},expression:"sel"}}),_c('span',{staticClass:"ml-2 text-h3"},[_vm._v("]")])],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"xs":"12","sm":"11","md":"10","lg":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"justify-center ma-1"},[_c('v-col',{attrs:{"xs":"12","sm":"11","md":"10","lg":"9"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"expanded":_vm.expanded,"single-expand":false,"search":_vm.search,"show-expand":"","group-by":"Build","item-key":"Ip","mobile-breakpoint":100,"footer-props":{
            showFirstLastPage: true,
            itemsPerPageText: 'Записей на странице',
            itemsPerPageOptions: [
              10,
              20,
              50,
              100,
              { text: 'Все записи', value: -1 } ],
          }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.Always",fn:function(ref){
          var item = ref.item;
return [(item.Always)?_c('v-chip',{attrs:{"color":item.Always == true ? 'error' : 'white'}},[_c('span',[_vm._v("Да")])]):_vm._e()]}},{key:"item.Ip",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"inline-block"},[_c('v-icon',{attrs:{"color":item.Lastvalue == '1' ? 'success' : 'warning'}},[_vm._v("mdi-desktop-classic")]),_vm._v(" "+_vm._s(item.Ip)+" ")],1)]}},{key:"item.Lastclock",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dd(item.Lastclock))+" ")]}},{key:"item.nn",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.scolor(item.tip)}},[_vm._v(_vm._s(item.nn)+" ")])]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('graphic',{attrs:{"id":'idgr'+item.Hostsid,"grd":_vm.forGr(item.History),"height":300,"colorgr":"blue","marks":false,"razm":""}})],1)],1),_c('v-row',{staticClass:"justify-center ma-2"},[_c('v-col',{attrs:{"md":"11","lg":"8"}},[_c('values',{attrs:{"values":item.History}})],1)],1)],1)]}}])})],1)],1)],1),_c('wait',{attrs:{"progress":_vm.progress}}),_c('v-snackbar',{attrs:{"timeout":5000,"color":"green","top":"","right":""},model:{value:(_vm.sbok),callback:function ($$v) {_vm.sbok=$$v},expression:"sbok"}},[_vm._v(" "+_vm._s(_vm.strok)+" "),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","fab":""},on:{"click":function($event){_vm.sbok = false}}},[_vm._v("OK")])],1),_c('v-snackbar',{attrs:{"timeout":5000,"color":"red","top":"","right":""},model:{value:(_vm.sberr),callback:function ($$v) {_vm.sberr=$$v},expression:"sberr"}},[_vm._v(" "+_vm._s(_vm.strerr)+" "),_c('v-btn',{staticClass:"ml-5",attrs:{"fab":""},on:{"click":function($event){_vm.sberr = false}}},[_vm._v("OK")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }