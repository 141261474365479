<template>
  <v-app>
    <v-main id="prg">
      <!-- <tbar></tbar> -->
      <router-view></router-view>
      <bbar></bbar>
    </v-main>
  </v-app>

  <!-- <div id="app">
    <router-view/>
  </div> -->
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
// import tbar from "./components/tbar"
import bbar from "./components/bbar";
// import { compile } from 'vue/types/umd';
export default {
  name: "App",
  components: {
    // tbar,
    bbar,
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions({
      routestomenu: "authm/routestomenu",
      collapseAll() {
        this.$refs.expandableTable.collapseAll();
      },
      expandAll() {
        this.$refs.expandableTable.expandAll();
      },
    }),
  },
  computed: {
    ...mapState({
      menus: (state) => state.authm.menus,
      nogroup: (state) => state.authm.nogroup,
    }),
  },
  //  async  updated() {
  //     console.log("updated")
  // //    await this.routestomenu();
  //   },
  async mounted() {
    await this.routestomenu();
    //          console.log(this.menus)
  },
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
