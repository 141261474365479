<template>
  <v-container>
    <v-card v-if="true">
      <v-row>
        <v-col v-if="true">
          <v-card>
            <v-card-text>
              <v-row class="elevation-2">
                <v-breadcrumbs :items="bc1c" :disabled="dis">
                  <template v-slot:divider>
                    <v-icon>mdi-transfer-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-row>
              <v-sheet class="ma-2 pa-2 primary lighten-2">
                <v-text-field
                  v-model="search1c"
                  label="Поиск"
                  dark
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </v-sheet>
              <v-treeview
                :items="data"
                v-model="sel"
                :search="search1c"
                shaped
                hoverable
                activatable
              >
                <template v-slot:append="{ item }">
                  <badgemy
                    :vis="
                      addcnt1c && item != undefined && item.cnt != undefined
                    "
                    nameicon="mdi-account"
                    :num="item.cnt"
                    coloricon="brown"
                    color="primary"
                    tooltip="Сколько проживает"
                    :overlap="false"
                    class="mr-4"
                  >
                  </badgemy>
                </template>
              </v-treeview>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- {{ data }} -->
      </v-row>
    </v-card>
    <wait :progress="progress" :message="waitmsg"></wait>
    <v-snackbar v-model="sberr" color="red" top right>
      {{ strerr }}
      <v-btn @click="sberr = false" class="ml-5" fab>OK</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import { strictEqual } from "assert";
import wait from "../progress2";
import badgemy from "../sensors/badge_my.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {
    wait,
    badgemy,
  },
  props: {
    data1c: Array,
    objsel: {
      type: String,
      default: null,
    },
    bc1c: Array,
    upd: Number,
  },
  data() {
    return {
      data: [],
      k1: "level4",
      k2: "people",
      sel: [],
      progress: false,
      fullstruct: true,
      addcnt1c: true,
      addcntorion: true,
      // bc1c: [{ text: "Общежитие" }, { text: "Этаж" }, { text: "Комната" }],
      tree1c: ["corpus", "floor", "room"],
      search1c: "",
      searchorion: "",
      map1c: {},
      // objsel: "Общежитие 5",
      dormitory: [],
      senstree: {},
      parents: {},
      allrec: false,
      sgdate: "",
      waitmsg: "Подождите, идет загрузка...",
      sberr: false,
      strerr: "Ошибка",
      dis: false,
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
    ...mapActions({
      // loadFireSens: "loadFireSens",
      // loadFireSensDates: "loadFireSensDates",
      // loadFireSensObj: "loadFireSensObj",
      // loadpObjects: "loadpObjects",
      //   loadOtv: "loadOtv",
    }),
    chng() {
      // console.log(this.objsel);
      // console.log(this.data1c);
      // let tmp = this.bc1c
      // console.log(this.bc1c)
      if (this.objsel != null) {
        for (let i = 0; i < this.data1c.length; i++) {
          // console.log(this.data1c[i].name);
          if (this.data1c[i].name == this.objsel) {
            this.data = [this.data1c[i]];
            return;
          }
        }
      }
      // this.bc1c = tmp
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
    ...mapState({}),
  },
  watch: {
    data1c: function () {
      this.data = this.data1c;
      this.chng();
    },
    objsel: function () {
      // console.log(this.objsel);
      this.chng();
    },
  },
  mounted() {
    this.chng();
  },
};
</script>
