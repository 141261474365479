<template>
  <v-container class="fill-height" id="main1" v-resize="rrr">
    <v-row class="justify-center align-center text-center" dense>
      <div class="text-h5 font-weight-black">
        Лента последних 10 событий на
        {{ new Date().toLocaleString("ru") }}
      </div>
      <v-btn small fab class="ml-4 mb-0">
        <v-progress-circular
          size="25"
          indeterminate
          color="primary"
          class=""
          v-if="progress"
        >
        </v-progress-circular>
        <v-progress-circular
          size="25"
          value="0"
          color="white"
          class=""
          v-else
          @click="start()"
        ></v-progress-circular>
      </v-btn>
    </v-row>
    <v-row
      id="tl0"
      class="justify-center mt-0"
      :style="'overflow: auto; height:' + hhh + 'px;'"
    >
      <v-col cols="9">
        <v-timeline id="tl">
          <v-timeline-item
            v-for="(d, i) in data"
            :key="i"
            :color="d.color"
            large
            right
          >
            <template v-slot:icon>
              <v-icon large color="white">{{ d.icon }}</v-icon>
            </template>
            <template v-slot:opposite>
              <!-- <v-alert
                type="error"
                class=""
                v-if="
                  (new Date().getTime() - new Date(d.DeviceTime).getTime()) /
                    60000 <=
                  15
                "
                >Событие последних 15 минут!</v-alert
              > -->
              <v-chip color="#00897B" dark class="text-h5">
                <!-- {{ new Date(d.DeviceTime).toLocaleString("ru") }} -->
                {{ showdate(d.DeviceTime) }}
              </v-chip>
            </template>
            <v-card class="elevation-4" :color="d.bg">
              <v-card-title class="text-h5"> {{ d.Comment }} </v-card-title>
              <v-card-text class="text-body-1">
                {{ d.Corpus }}<span v-if="d.Name">,</span> {{ d.Name }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { setInterval } from "timers";
import { clearInterval } from "timers";
var i1;
export default {
  components: {},
  props: {
    //    data: Array,
  },
  data() {
    return {
      reverse: false,
      data: [],
      progress: false,
      cnt: 60,
      hhh: 300,
    };
  },
  methods: {
    ...mapActions({
      loadSensLastEvent: "loadSensLastEvent",
      // loadFireSensDates: "loadFireSensDates",
      // loadFireSensObj: "loadFireSensObj",
    }),
    showdate(dd) {
      let d = new Date(dd);
      d.setHours(d.getHours() - 3);
      // console.log(d)
      return d.toLocaleString("ru");
    },
    async start() {
      this.progress = true;
      await this.loadSensLastEvent();
      // this.allevent[1].Event=3
      // this.allevent[3].Event=37
      // this.allevent[5].Event=360
      // this.allevent[7].Event=44
      this.allevent.forEach((f) => {
        f.color = "green";
        f.icon = "mdi-clock";
        // f.icon = "mdi-alert-decagram";
        f.bg = "light-green lighten-4";
        switch (f.Event) {
          case "44":
            f.icon = "mdi-alert-decagram";
            f.bg = "#FFE0B2";
            break;
          case "360":
            f.bg = "orange darken-3";
            f.Contents = "Сигнал в МЧС";
            f.icon = "mdi-fire-truck";
            break;
          case "250":
            f.color = "grey";
            f.icon = "mdi-repeat-off";
            f.bg = "blue-grey lighten-4";
            break;
          case "251":
            f.color = "green";
            f.icon = "mdi-repeat";
            f.bg = "green lighten-4";
            break;
          case "37":
            f.color = "red";
            f.icon = "mdi-fire";
            f.bg = "orange lighten-2";
            break;
          case "3":
            f.color = "blue";
            f.icon = "mdi-water-pump";
            f.bg = "#B3E5FC";
            break;
        }
        // if (f.Event == "44") {
        //   // f.color = "white";
        //   f.color = "red";
        //   f.icon = "mdi-alert-decagram";
        //   f.bg = "#FFE0B2";
        // // f.bg = "orange lighten-2";
        // }
        // if (f.Event == "360") {
        //   f.color = "red";
        //   f.bg = "orange darken-3";
        //   f.Contents = "Сигнал в МЧС";
        //   f.icon = "mdi-fire-truck";
        // }
        // if (f.Event == "37") {
        //   f.color = "red";
        //   f.icon = "mdi-fire";
        //   f.bg = "orange lighten-2";
        // }
        // if (f.Event == "3") {
        //   f.color = "blue";
        //   f.icon = "mdi-water-pump";
        //   f.bg = "#B3E5FC";
        // }
      });

      // this.data = this.al.concat(this.waterleak);
      // console.log(this.waterleak);
      // console.log(this.data);
      this.data.sort((a, b) => {
        if (a.DeviceTime < b.DeviceTime) {
          return 1;
        }
        if (a.DeviceTime > b.DeviceTime) {
          return -1;
        }
        return 0;
      });
      this.data = this.allevent;
      this.progress = false;
    },
    rrr() {
      this.hhh = window.innerHeight - 120;
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
    ...mapState({
      allevent: (state) => state.senslastevent,
    }),
    // hh: function() {
    //   console.log(document.getElementById("main1"))
    //   return window.innerHeight-120
    // }
  },
  async mounted() {
    this.start();
    i1 = setInterval(this.start, 1000 * this.cnt);
  },
  destroyed() {
    clearInterval(i1);
    i1 = null;
  },
};
</script>
