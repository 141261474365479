<template>
  <!-- <v-card :color="dcolor()" v-if="name" max-height="150" class="pa-0 mb-0"> -->
  <v-card :color="dcolor()" v-if="name" max-height="150" class="pa-0 mb-0">
    <!-- <v-card-subtitle>Диск {{((disk.maxsize/1024/1024/1024)*1000*1000*1000/1024/1024/1024).toFixed(0)}}</v-card-subtitle> -->
    <!-- <v-card-title>{{ disk.name }}</v-card-title> -->
    <!-- <v-card-subtitle>Объем {{((disk.maxsize/1024/1024/1024)*1000/1024).toFixed(0)}}{{ (disk.size/1024/1024/1024).toFixed(0) }} Свободно {{(((disk.maxsize-disk.size)/1024/1024/1024)*1000/1024).toFixed(0)}}</v-card-subtitle> -->
    <!-- <pipe :pstate="pipe[name].pipe" v-if="pipe && pipe[name]"></pipe> -->
    <pipe :pstate="kortex[name]" :name="name" v-if="kortex && kortex[name]"></pipe>
    <!-- <v-badge color="orange" class="mr-0" overlap left  v-if="kortex && kortex[name]"> -->
    <!-- <template v-slot:badge> 26 </template>
        </v-badge> -->
    <!-- <pipe pstate="off"></pipe> -->
    <v-card-subtitle>
      <v-row class="align-center">
        <!-- <v-img src="img/Главный корпус.jpg" width="20px" height="40px"></v-img> -->
        <v-badge color="grey" class="mr-0" overlap left tile>
          <!-- <v-chip color="" class="text-subtitle-2" @click="showmap(name)"> -->
          <v-chip color="" class="text-body-2" @click="showmap(name)">
            <!-- <v-btn icon> -->
            <!-- <v-icon>mdi-office-building</v-icon> -->
            <!-- <v-icon color="green" v-if="check()">mdi-check-outline</v-icon>
            <v-icon color="red" v-else>mdi-alert-outline</v-icon> -->
            <v-icon :color="icolor()">{{ iname() }}</v-icon>
            <!-- </v-btn> -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"> {{ name.substr(0, 16) }}</span>
              </template>
              <span> {{ name }}</span>
            </v-tooltip>
            <!-- {{ name }} -->
          </v-chip>
          <template v-slot:badge> {{ Object.keys(disk).length - 1 }} </template>
        </v-badge>
        <!-- <v-spacer></v-spacer> -->
        <!-- <v-badge color="orange" class="ml-1" v-if="tnodata > 0">
          <v-icon color="">mdi-clock-alert</v-icon>
          <template v-slot:badge> {{ tnodata }} </template>
        </v-badge> -->
        <!-- <badgemy :vis="tnodata > 0" nameicon="mdi-clock-alert" :num="tnodata " color="orange" tooltip="Температура за сутки (таблица)"></badgemy> -->
        <badgemy
          :vis="tnodata > 0"
          nameicon="mdi-clock-alert"
          :num="tnodata"
          color="orange"
          coloricon="orange"
          tooltip="Количество объектов у которых нет данных по температуре за текущий час"
        ></badgemy>
        <!-- <v-badge color="blue" class="ml-1" v-if="hnodata > 0">
          <v-icon color="">mdi-clock-alert</v-icon>
          <template v-slot:badge> {{ hnodata }} </template>
        </v-badge> -->
        <badgemy
          :vis="hnodata > 0"
          nameicon="mdi-clock-alert"
          :num="hnodata"
          color="blue"
          coloricon="blue"
          tooltip="Количество объектов у которых нет данных по влажности за текущий час"
        ></badgemy>
        <!-- <v-badge color="red" class="" v-if="terr > 0">
          <v-icon color="orange">mdi-thermometer</v-icon>
          <template v-slot:badge> {{ terr }} </template>
        </v-badge> -->
        <badgemy
          :vis="terr > 0"
          nameicon="mdi-thermometer-alert"
          :num="terr"
          coloricon="orange"
          color="red"
          tooltip="Количество объектов у которых температура за пределами"
        ></badgemy>
        <!-- <v-badge color="red" class="" v-if="herr > 0">
          <v-icon color="blue">mdi-water-percent</v-icon>
          <template v-slot:badge> {{ herr }} </template>
        </v-badge> -->
        <badgemy
          :vis="herr > 0"
          nameicon="mdi-weather-cloudy-alert"
          :num="herr"
          coloricon="blue"
          color="red"
          tooltip="Количество объектов у которых влажность за пределами"
        ></badgemy>
        <v-spacer></v-spacer>
        <!-- <v-icon color="" @click="showbounds()">mdi-arrow-expand-horizontal</v-icon> -->
        <iconmy
          nameicon="mdi-arrow-expand-horizontal"
          coloricon=""
          :fun="showbounds"
          tooltip="Допустимые границы параметров объектов"
        ></iconmy>
        <!-- <v-chip>
          {{ disk.name }}
        </v-chip>
        {{disk._children.length}} -->
        <!-- <v-spacer></v-spacer>
        <v-icon color="green" v-if="check()">mdi-check-outline</v-icon>
        <v-icon color="red" v-else>mdi-alert-outline</v-icon> -->
      </v-row>
    </v-card-subtitle>
    <!-- <v-card-subtitle>{{ (disk.size/1024/1024/1024).toFixed(0) }}</v-card-subtitle> -->
    <v-card-text>
      <!-- <v-card-text> -->
      <!-- <v-row dense>
        <v-col cols="12" class=""> -->
      <!-- <v-row class="align-center" dense> -->
      <!-- <v-list-item v-for="uu in disk._children" :key="uu.name" cols="12"> -->
      <!-- {{uu.name}} -->
      <!-- <v-container> -->
      <!-- :style="'height: ' + hhh + 'px; overflow-y: scroll;'" -->

      <v-navigation-drawer permanent width="100%">
        <!-- <v-row>
          <v-col cols="12"> -->
        <v-list dense class="" max-height="82px">
          <!-- <v-subheader>Кабинеты</v-subheader> -->
          <!-- <v-list-item-group color="white"> -->
          <template v-for="(uu, nn) in disk">
            <unit
              v-if="nn != 'Bounds'"
              :unit="uu"
              :disk="nn"
              :name="name"
              :bounds="disk.Bounds"
              :iskortex="kortex != null  && kortex[name] != null"
              :key="nn + uu"
            ></unit>
            <v-divider
              :key="nn"
              v-if="Object.keys(disk).length > 2"
            ></v-divider>
          </template>
          <!-- </v-list-item-group> -->
        </v-list>
        <!-- </v-col>
      </v-row> -->
      </v-navigation-drawer>
      <!-- <template v-slot:badge v-if="kortex && kortex[name]"> {{ disk["каб. 201"]}} </template> -->
      <!-- <template v-slot:badge> {{ disk["каб. 201"]["Температура"][disk["каб. 201"]["Температура"].length - 1].val.Avg}} </template> -->
      <!-- <template v-slot:badge> {{ disk[name]["Температура"][disk[name]["Температура"].length - 1].val.Avg}} </template> -->
      <!-- </v-container> -->
      <!-- </v-list-item> -->
      <!-- <v-btn  @click="unitdel(uu)" class="ml-2" small>-</v-btn> -->
      <!-- </v-row> -->
      <!-- </v-col>
      </v-row> -->
    </v-card-text>
    <objmap :obj="obj" :showd="showobj" v-on:btnok="dclose()"></objmap>
    <!-- <v-dialog v-model="dbounds" persistent max-width="500px">
      <v-card>
        <v-card-title> Границы </v-card-title>
        <v-card-text> -->
    <bounds
      :bounds="disk.Bounds"
      :show="dbounds"
      v-on:dsave="bsave($event)"
      v-on:dcancel="bcancel()"
    ></bounds>
    <!-- </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="dbounds = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
import unit from "./unit.vue";
import objmap from "../obj_on_map/objshowonmap_d.vue";
import bounds from "./bounds.vue";
import badgemy from "./badge_my.vue";
import iconmy from "./icon_my.vue";
import pipe from "../kortex/kortex.vue";

export default {
  components: {
    unit,
    objmap,
    bounds,
    badgemy,
    iconmy,
    pipe,
  },
  props: {
    name: String,
    disk: Object,
  },
  data() {
    return {
      hhh: 80,
      obj: null,
      showobj: false,
      upd: 0,
      terr: 0,
      herr: 0,
      tnodata: 0,
      hnodata: 0,
      dbounds: false,
      diffhours: 0,
    };
  },
  methods: {
    // ...mapActions(["loadSettings", "LoadObjects"]),
    ...mapActions(["LoadObjects", "loadKortex"]),
    iname() {
      if (this.check()) {
        if (this.tnodata > 0 || this.hnodata > 0) {
          return "mdi-help-circle-outline";
        } else {
          return "mdi-check-outline";
        }
      } else {
        return "mdi-alert-outline";
      }
    },
    icolor() {
      if (this.check()) {
        if (this.tnodata > 0 || this.hnodata > 0) {
          // return "yellow";
          return "orange";
        } else {
          return "green";
        }
      } else {
        return "red";
      }
    },
    bsave(e) {
      this.disk.Bounds = JSON.parse(JSON.stringify(e));
      this.dbounds = false;
    },
    bcancel() {
      this.dbounds = false;
    },
    showbounds() {
      // console.log(this.disk.Bounds)
      this.dbounds = true;
    },
    dclose() {
      this.showobj = false;
    },
    showmap(nm) {
      // console.log(nm);
      let tr = {
        "Бизнес инкубатор": "Бизнес-инкубатор «СТАРТ»",
        "Бизнес-инкубатор": "Бизнес-инкубатор «СТАРТ»",
        "Главный корпус": "Главный корпус",
        "Бюро пропусков": "Социально-гуманитарный институт (СГИ)",
        ИПЭиФ: "Институт права, экономики и финансов",
        ИТФ: "Институт архитектуры строительства и дизайна",
        ФМФ: "Институт физики и математики",
        Физмат: "Институт физики и математики",
        Ректорат: "Ректорат",
        "Общ.№1": "Общежитие №1 (ул. Осипенко 20)",
        "Общ.№2": "Общежитие №2 (ул. Чернышевского 165)",
        "Общ.№3": "Общежитие №3 (ул. Чернышевского 226)",
        "Общ.№4": "Общежитие №4 (ул. Толстого 179)",
        "Общ.№10": "Общежитие №10 (ул. Чернышевского 167)",
        КИТиЭ: "Колледж информационных технологий и экономики",
        "Информационный центр": "Информационный центр",
        "Мед.Фак №1": "Медицинский факультет(корпус 1 - ул. И. Арманд,1)",
        "Мед.Фак №2": "Медицинский факультет(корпус 2 - ул. Горького,5)",
        "Мед.колледж №3":
          "Медицинский колледж(корпус 3- ул. Чернышевского, 165)",
        "Мед.колледж №4":
          "Медицинский колледж(корпус 4- ул. Чернышевского, 165)",
        "Мед.колледж№2":
          "Медицинский колледж(корпус 2- ул. Чернышевского, 165)",
        "Общ.№12": "Общежитие №12 (ул. Толстого 187)",
        "Общ.№13": "Общежитие №13 (ул. Вовчок 9)",
        "Общ.№5": "Общежитие №5 (ул. Толстого 181)",
        "Общ.№6": "Общежитие №6 (ул. Толстого 183)",
        "Общ.№7": "Общежитие №7 (ул. Горького 19)",
        "Общ.№9": "Общежитие №9 (ул. Ногмова 87)",
        "Пед.институт": "Институт педагогики, психологии и ФСО",
        СГИ: "Социально-гуманитарный институт (СГИ)",
        ФСК: "Физкультурно-спортивый комплекс",
        Филология: "Социально-гуманитарный институт (СГИ)",
        ЭУНК: "",
        "Юрид-эконом": "Институт права, экономики и финансов",
      };
      this.obj = this.objects[nm];
      if (!this.obj) {
        this.obj = this.objects[tr[nm]];
      }
      // console.log(this.objects);
      // console.log(this.obj);
      this.showobj = true;
      // this.upd++;
    },
    dcolor() {
      if (this.check()) {
        if (this.tnodata > 0 || this.hnodata > 0) {
          // this.disk.color ="yellow"
          this.$emit("status", "yellow");
          // return "yellow lighten-4";
          return "green lighten-4";
        } else {
          this.$emit("status", "green");
          return "green lighten-4";
        }
      } else {
        this.$emit("status", "red");
        return "red lighten-4";
      }
    },
    check() {
      let terr0 = 0;
      let herr0 = 0;
      let tnodata0 = 0;
      let hnodata0 = 0;
      let bb = true;
      let hour; // = new Date().toISOString().substr(11, 2)
      hour = Number(new Date().toLocaleString("ru").substr(12, 2));
      for (let kk in this.disk) {
        // console.log(this.disk[kk]["Температура"]);
        if (kk == "Bounds") {
          continue;
        }
        // console.log(kk)
        if (this.disk[kk]["Температура"]) {
          let bt1 =
            this.disk[kk]["Температура"][
              this.disk[kk]["Температура"].length - 1
            ].val.Avg >= this.disk.Bounds.tmin;
          let bt2 =
            this.disk[kk]["Температура"][
              this.disk[kk]["Температура"].length - 1
            ].val.Avg <= this.disk.Bounds.tmax;
          if (!bt1 || !bt2) {
            terr0++;
          }
          bb = bb && bt1 && bt2;
          // проверка на данные
          let lasth =
            this.disk[kk]["Температура"][
              this.disk[kk]["Температура"].length - 1
            ].date;
          lasth = Number(lasth.substr(11, 2));
          if (hour - lasth > this.diffhours) {
            tnodata0++;
          }
          // console.log(hour+ " "+kk + " " + lastd);
        } else {
          tnodata0++;
          // bb = false
        }
        if (this.disk[kk]["Влажность"]) {
          let bh =
            this.disk[kk]["Влажность"][this.disk[kk]["Влажность"].length - 1]
              .val.Avg >= this.disk.Bounds.hmin &&
            this.disk[kk]["Влажность"][this.disk[kk]["Влажность"].length - 1]
              .val.Avg <= this.disk.Bounds.hmax;
          if (!bh) {
            herr0++;
          }
          bb = bb && bh;
          // проверка на данные
          let lasth =
            this.disk[kk]["Влажность"][this.disk[kk]["Влажность"].length - 1]
              .date;
          lasth = Number(lasth.substr(11, 2));
          if (hour - lasth > this.diffhours) {
            hnodata0++;
          }
        } else {
          hnodata0++;
          // bb = false
        }

        // this.disk[kk]["Температура"].forEach((d) => {
        //   // console.log(d)
        //   if (d[d.length - 1]) {
        //    console.log(d[d.length - 1].val.Avg)
        //     bb = bb && d[d.length - 1].val.Avg > 21;
        //   }
        // });
      }
      // console.log(this.terr);
      this.terr = terr0;
      this.herr = herr0;
      this.tnodata = tnodata0;
      this.hnodata = hnodata0;
      this.$emit("errors", {
        terr: this.terr,
        herr: this.herr,
        tnodata: this.tnodata,
        hnodata: this.hnodata,
      });
      return bb;
    },
  },
  computed: {
    ...mapState(["objects", "kortex"]),
  },
  async mounted() {
    await this.$store.dispatch("loadObjects");
    await this.$store.dispatch("loadKortex");
    // console.log(this.disk)
  },
};
</script>