<template>
  <div>
    <v-card dense>
      <v-card-title class="text-h6" v-if="data">
        <!-- {{ getFloorName(data.name) }} -->
        <!-- <v-spacer></v-spacer> -->
        <template v-for="(ff, ii) in floors">
          <v-btn
            :key="ii"
            v-if="getFloorName(data.name) != getFloorName(ff.name)"
            class="mr-2"
            @click="$emit('pickfloor', ff.name)"
            >{{ getFloorName(ff.name) }}</v-btn
          >
          <span v-else :key="ii" class="mr-2">{{ getFloorName(data.name) }}</span>
        </template>
      </v-card-title>
      <v-card-text>
        <div
          :style="'width: ' + (www - 20) + 'px; height: ' + hhh + 'px; z-index: 0;'"
          :ref="divid"
          :id="divid"
        ></div>
      </v-card-text>
    </v-card>
    <!-- <v-dialog v-model="dlg" width="500"><printercard :item="tmp"></printercard></v-dialog> -->
  </div>
</template>
<script>
import { mapState} from "vuex";
import L from "leaflet";
// import printercard from "./printercard";
export default {
  components: {
    // printercard,
  },
  props: {
    www: { type: Number, default: 300 },
    hhh: { type: Number, default: 300 },
    zoom: { type: Number, default: 4 },
    divid: { type: String, default: "mapplan99" },
    data: {
      type: Object,
      default: undefined,
    },
    key2: Number,
    floors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      mapf: null,
      floor: null,
      ibounds: null,
      tmp: {},
      // dlg: false,
    };
  },
  methods: {
    prnover(evt) {
      console.log(evt);
    },
    getFloorName(name) {
      let ss = name.split(",");
      return ss.pop().trim(); //  delele (ss.length-1)
    },
    HideMarkers(map) {
      map.eachLayer((m) => {
        if ("icon" in m.options) {
          map.removeLayer(m);
        }
      });
    },
    markerclick(mm) {
      mm.originalEvent.stopPropagation();
      //      console.log(mm)
      //      this.$emit('markerclick',mm.target.number)
      this.$emit("markerclick", mm);
    },
    markerdblclick(mm) {
      mm.originalEvent.stopPropagation();
      this.$emit("markerdblclick", mm);
      // this.dlg = true;
      // console.log(mm);
    },
    SetMarkers(map, mm) {
      mm.forEach((m, ii) => {
        // var okIcon = new L.icon({
        //   iconUrl: "printer_green.png",
        //   iconSize: [40, 40],
        // });
        // console.log(this.icons[m.iconid_on][0].image)
        // console.log(m.iconid_off)
        let icn
        // icn = "'printer_green2.png"
        // if(m.iconid_off) {
        icn = "data:image/jpg;base64," + this.icons[m.iconid_off][0].image
        // }
        let html =
          // "<div style='text-align: center; width: 80px; margin-left: -20px; margin-top: -5px;'><img src='printer_green2.png'><span class='office' style='background-color: white;'><b>" +
          "<div style='text-align: center; width: 80px; margin-left: -20px; margin-top: -5px;'><img src='"+icn+"'><span class='office' style='background-color: white;'><b>" +
          m.prnname +
          "</b></span></div>";
        switch (m.label_location) {
          // case "-1":
          case "1":
            html =
              "<div style='text-align: center; width: 150px; margin-left: -20px; margin-top: -5px;'>" +
              "<span class='office' style='background-color: white;'><b>" +
              m.prnname +
              "</b></span>" +
              "<img src='printer_green2.png' style=' vertical-align: middle;'>" +
              "</div>";
            break;
          case "2":
            html =
              "<div style='text-align: center; width: 150px; margin-left: -20px; margin-top: -5px;'>" +
              "<img src='printer_green2.png' style=' vertical-align: middle;'>" +
              "<span class='office' style='background-color: white;'><b>" +
              m.prnname +
              "</b></span>" +
              "</div>";
            break;
          case "3":
            html =
              "<div style='text-align: center; width: 80px; margin-left: -20px; margin-top: -5px;'>" +
              "<span class='office' style='background-color: white;'><b>" +
              m.prnname +
              "</b></span>" +
              "<img src='printer_green2.png'>" +
              "</div>";
            break;
        }
        var okIcon = new L.divIcon({
          // html: "<div><img src='printer_green.png'>"+ "<div>"+ m.prnname+ "</div></div>",
          html: html,
          // html:
          //   "<div style='text-align: center; width: 80px; margin-left: -20px; margin-top: -5px;'><img src='printer_green2.png'><span class='office' style='background-color: white;'><b>" +
          //   m.prnname +
          //   "</b></span></div>",
          iconSize: 0,
          shadowSize: 0,
        });
        // var okIcon = new L.divIcon({
        //   html:
        //     "<div class='pin' style='background:#2aa043'></div><div class='pulse'></div> <br><div class='office' style='background: white;'><b>" +
        //     // "<div class='pin' style='background:red'></div><div class='pulse'></div> <br><div class='office'><b>" +
        //     // m.elements[0].hostid +
        //      "<p style='color: red;'>" +
        //     m.prnname +
        //     "</p>"+
        //     "</b></div>",
        //   iconSize: 0,
        //   shadowSize: 0,
        //   popupAnchor: [-4, -33],
        //   iconAnchor: [0, 15],
        // });
        let marker = L.marker([this.data.height - m.y, m.x], {
          draggable: true,
          icon: okIcon,
          // alt: m.prnname,
        });
        // .bindTooltip(m.prnname, {
        //   permanent: true,
        //   direction: "bottom",
        //   offset: L.point(0, 15),
        //   className: "office",
        // });
        ////// marker.bindPopup(m.Popup);
        // marker.korpus = m;
        marker.number = ii;
        marker.prnname = m.prnname;
        marker.on("click", this.markerclick);
        marker.on("dblclick", this.markerdblclick);
        let ppp = this;
        marker.on("dragend", function () {
          ppp.data.selements[ii].x = Math.floor(marker.getLatLng().lng);
          ppp.data.selements[ii].y =Math.floor(ppp.data.height - marker.getLatLng().lat);
          ppp.$emit("prnmoved", true);
        });
        marker.addTo(map);
      });
    },
    zoommap() {
      let zoomlevel = this.mapf.getZoom();
      console.log("Zoom:" + zoomlevel);
      let layerBounds = this.mapf.getBounds();
      console.log(layerBounds);
    },
    setmap() {
      if (this.data == undefined) {
        return;
      }
      // console.log("Set map");
      // if (this.mapf != null) {
      //   this.mapf.remove();
      //   this.mapf = null
      // }
      this.ibounds = [
        [0, 0],
        [this.data.height, this.data.width],
      ];

      this.mapf = L.map(this.divid, {
        attributionControl: false,
        crs: L.CRS.Simple,
        minZoom: -10,
        maxBounds: this.ibounds,
      }); //.setView(latlng, 17);
      this.floor = L.imageOverlay(this.data.image, this.ibounds); // .addTo(this.mapf);
      // this.mapf.on("mouseover", this.prnover);
      // this.floor.on("mouseover", this.prnover);
      //       this.$nextTick(() => {
      this.floor.addTo(this.mapf);
      //  console.log(this.key2)
      // if(this.key2 == 0) {
      this.mapf.setView([this.data.height / 2, this.data.width / 2], this.zoom);
      // }
      // console.log(this.zoom)
      this.SetMarkers(this.mapf, this.data.selements);
      L.easyPrint({
        title: "Печать плана",
        // position: "bottomright",{Current: 'Current Size', A4Landscape: 'A4 Landscape', A4Portrait: 'A4 Portrait'}
        position: "topleft",
        customWindowTitle: this.data.name,
        sizeModes: ["A4Portrait", "A4Landscape"],
        defaultSizeTitles: {Current: 'Текущий размер', A4Landscape: 'A4 Альбомная', A4Portrait: 'A4 Книжная'},
      }).addTo(this.mapf); // var printPlugin = L.easyPrint({
      //   hidden: false,
      //   sizeModes: ["A4Portrait"],
      // }).addTo(this.map);
      // printPlugin.printMap("A4Portrait", "MyFileName");
      // this.mapf.on("zoom", this.zoommap);
      // });
      // L.control.scale({ metric: true, imperial: false }).addTo(this.mapf);
    },
  },
  watch: {
    key2: function () {
      if (this.data == null) {
        return;
      }
      // console.log(this.key2)
      if (this.key2 != 0) {
        // this.setmap();
        this.HideMarkers(this.mapf);
        this.SetMarkers(this.mapf, this.data.selements);
        // return
      } else {
        if (this.mapf != null) {
          this.mapf.remove();
          this.mapf = null;
        }
        this.setmap();
      }
    },
    // data: function () {
    //   if(this.data == null) {
    //     return
    //   }
    //      console.log(this.data)
    //   if (this.mapf != null) {
    //     this.mapf.remove();
    //   }
    //   this.$nextTick(() => {
    //     this.setmap();
    //   });
    // },
  },
  computed: {
    ...mapState(["icons"]),
    // floors2: function () {
    //    this.floors.sort((a, b) => {
    //     let a1 = this.getFloorName(a);
    //     let b1 = this.getFloorName(b);
    //     return a1 < b1 ? -1 : Number(a1 > b1);
    //   });
    // },
  },
  mounted() {
    // console.log("Plan mounted");
    //    this.setmap();
    this.setmap();
    // this.mapf.setView([this.data.height / 2, this.data.width / 2], this.zoom);
    // this.$nextTick(() => {
    //   this.floor.addTo(this.mapf);
    //   this.mapf.setView([this.data.height / 2, this.data.width / 2], this.zoom);
    //   // console.log(this.zoom)
    //   this.SetMarkers(this.mapf, this.data.selements);
    // });
    // this.$nextTick(() => {
    // });
  },
  destroyed() {
    //   console.log("destroyed")
    //   console.log(this.mapf)
    // if (this.mapf != null) {
    //   console.log("remove")
    //   try {
    //   this.mapf.remove();
    //   this.mapf = null
    //   }
    //   catch {
    //   console.log("catch")
    //   }
    //  }
  },
};
</script>
