<template>
  <!-- <div class="kbsu_obj"></div> -->
  <v-card class="">
    <v-toolbar elevation="0" dense>
      <v-app-bar-nav-icon @click="vis = !vis">
        <!-- <template v-slot:default>
          <v-speed-dial
            v-model="fab"
            direction="bottom"
            :open-on-hover="false"
            transition="slide-x-transition"
            color="green"
          >
            <template v-slot:activator>
              <v-icon class="align-center justify-center"> mdi-menu</v-icon>
            </template>
            <v-btn fab dark small color="green">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn fab dark small color="indigo">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn fab dark small color="red">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-speed-dial>
        </template> -->
      </v-app-bar-nav-icon>

      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <!-- <v-card-text v-if="vis"> jhlkdsjflkjdsflkjdlk </v-card-text> -->
    <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
    <v-card-text v-if="vis">
      {{dd}}
      <template v-for="(b, i) in act">
        <badgemy
          :key="i"
          :vis="b.vis"
          :nameicon="b.icon"
          :num="b.num"
          :coloricon="b.coloricon"
          :color="b.color"
          :tooltip="b.tooltip"
          class="ml-2"
          v-on:clickq="
            cc = i;
            dd[i] = true;
            upd++;
          "
        ></badgemy>
        <!-- <dcomp :name="comp[i]" :dd="dd[i]" :key="i+1000+upd"> </dcomp> -->
      <v-dialog v-model="dd[i]" transition="fab-transition" :key="i+1000+upd">
        <v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small fab @click="dd[i] = false; upd++;"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-actions>
          <v-card-text>
            <component :is="dyncomp2(i)"> </component>
          </v-card-text>
        </v-card>
      </v-dialog>
      </template>
      <!-- <v-card-text v-if="cc == 2">
        <component :is="'waterleak'"> </component>
      </v-card-text> -->
    </v-card-text>
    <!-- <v-card-text>{{cc}}</v-card-text> -->
  </v-card>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import badgemy from "../sensors/badge_my.vue";
import dcomp from "./dyncomp.vue"
// import firesens from "./firesens/firesens.vue";
// import waterleak from "./waterleak/waterleak.vue";
export default {
  components: {
    badgemy,
    dcomp
    //  firesens, waterleak
  },
  props: {
    title: { type: String, default: "Название объекта" },
    subtitle: { type: String, default: "Доп. информация" },
    act: {
      type: Array,
      default: function () {
        return [
          {
            vis: true,
            icon: "mdi-fire",
            num: 0,
            coloricon: "red",
            color: "red",
            tooltip: "Тестовая",
          },
          {
            vis: true,
            icon: "mdi-water-pump",
            num: 0,
            coloricon: "blue",
            color: "blue",
            tooltip: "Тестовая",
          },
        ];
      },
    },
  },
  data() {
    return {
      fab: null,
      vis: false,
      cc: 0,
      dd: [false,false,false],
      upd: 0,
      comp: ["./firesens/firesens.vue", "./waterleak/waterleak.vue"],
    };
  },
  methods: {
    //  ...mapActions([{loadSettings: "loadSettings", LoadObjects: "LoadObjects"}]),
    // dyncomp(name) {
    //   return () => import(name);
    // },
    // async dyncomp(c0) {
    //   await this.dyncomp0(c0)
    //   this.upd++
    // },
    async dyncomp2(c0) {
      // return () => import("./firesens/firesens.vue");
       console.log(c0)
      let tmp = this.comp[c0]
      //  console.log(`${tmp} `)
      // this.upd++
      return ()  => import(`${tmp}`);
    },
  },
  computed: {
    // ...mapGetters(["urlmdlstat"]),
    // ...mapState(["printers"]),
    dyncomp() {
      // return () => import("./firesens/firesens.vue");
      console.log(this.cc);
      let tmp = this.comp[this.cc];
      //  console.log(`${tmp} `)
      // this.upd++
      return () => import(`${tmp}`);
    },
  },
  mounted() {},
};
</script>
<style scoped>
.kbsu_obj {
  width: 100px;
  height: 100px;
  border: 1px solid red;
  margin: 10px 10px;
}
</style>
